import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { IRecordListItem, TimeTableEventTabType } from '@App/types';
import { RootState } from '@App/store';

import recordListApi from '@App/services/recordList/recordListApi';
import { setActiveTab } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { eventsApi } from '@App/services/events/eventsApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

export interface IRecordListState {
  changeTimestamp: number; // Tohle trigeruje mount/unmout pooling kontejneru, tak aby se forsnul reload při znovu kliku (jinak se vysypal state)
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: IRecordListItem[];
}

const initialState: IRecordListState = {
  changeTimestamp: 0,
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
};

export const getRecordListDataAsync = createAsyncThunk(
  'recordList/fetchData',
  async (timeTableId: number, { dispatch, rejectWithValue, signal }) => {
    try {
      const response = await recordListApi.fetchRecordListData(timeTableId, signal);

      const thunkDispatch = dispatch as ThunkDispatch<any, any, AnyAction>; // Nutno to přetypovat, TypeScript si s tím nějak
      thunkDispatch(eventsApi.util.updateQueryData('getEventsStates', '', draft => {
        draft.states[timeTableId] = response.eventState;
      }));

      dispatch(pollingSucceeded());

      return response.data;
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'Recordlist', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const recordListSlice = createSlice({
  name: 'recordListSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecordListDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getRecordListDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.data = action.payload;
      })
      .addCase(getRecordListDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(setActiveTab, (state, action) => { // Clear state
        if (action.payload === TimeTableEventTabType.Records) {
          state.changeTimestamp = Date.now();
        } else {
          Object.assign(state, initialState);
        }
      })
      .addCase(setSelectedTimeTableId, (state, action) => {
        if (action.payload.hasChanged) {
          Object.assign(state, initialState);
        } else {
          state.changeTimestamp = Date.now();
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.recordList.isFetching;
export const selectError = (state: RootState) => state.recordList.error;
export const selectDataLoaded = (state: RootState) => state.recordList.dataLoaded;
export const selectData = (state: RootState) => state.recordList.data;
export const selectChangeTimestamp = (state: RootState) => state.recordList.changeTimestamp;

export default recordListSlice.reducer;