import React from 'react';
import { EventState } from '@App/types';
import EventStateIcon from '@Components/eventStateIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

function getStateDescription(eventState: EventState, isStartlistOfficial: boolean) {
  switch (eventState) {
    case EventState.InProgress:
      return 'In progress';
    case EventState.Judging:
      return 'Judging Photofinish';
    case EventState.Official:
      return 'Official result';
    case EventState.Scheduled:
      return 'Scheduled';
    case EventState.Unofficial:
      return 'Unofficial result';
    default:
      return null;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0.15rem 0.5rem 0.14rem',
      background: theme.palette.records.lighter,
      borderRadius: '4px',
      fontSize: '15px',
      fontFamily: 'WorldAthleticsHeadline',
      lineHeight: '20px',
      opacity: '0.9',
      textTransform: 'uppercase',

      '& .state-icon': {
        paddingRight: '7px',
      }
    },
    confirmedStartlist: {
      fontFamily: 'WorldAthletics',
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: '13px',
      opacity: '0.6',
      lineHeight: '15px',
    },
    liveResultsFromTrasponders: {
      fontFamily: 'WorldAthletics',
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: '13px',
      opacity: '0.6',
      lineHeight: '15px',
      textAlign: 'right',
      animation: 'blinker 1.2s infinite',
    }
  }),
);

type EventStateIndicatorProps = {
  eventState: EventState,
  isStartlistOfficial: boolean,
  liveResultsFromTrasponders: boolean,
}

function EventStateIndicator({ eventState, isStartlistOfficial, liveResultsFromTrasponders }: EventStateIndicatorProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <EventStateIcon eventState={eventState} isStartlistOfficial={isStartlistOfficial} />
      {getStateDescription(eventState, isStartlistOfficial)}
      {eventState === EventState.Scheduled && isStartlistOfficial && <div className={classes.confirmedStartlist}>Startlist is confirmed</div>}
      {eventState === EventState.Scheduled && !isStartlistOfficial && <div className={classes.confirmedStartlist}>Startlist not yet confirmed</div>}
      {liveResultsFromTrasponders && eventState === EventState.InProgress && <div className={classes.liveResultsFromTrasponders}>Live results from transponders</div>}
    </div>
  );
}

export default EventStateIndicator;
