import React from 'react';
import { ITimeTableCurrentEvent, ITimeTableItem, EventState, ITimeTableItemState, } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import EventStateIndicator from './EventStateIndicator';
import clsx from 'clsx';
import { getStateColor } from '@Components/eventStateIcon/EventStateIcon';

type StyleProps = {
  eventState: EventState;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    currentButton: {
      position: 'relative',
      width: '100%',
      height: '100%',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      textTransform: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',
      borderTop: ({ eventState }) => eventState === EventState.Scheduled ? '2px solid rgba(255, 255, 255, 0.10)' : `2px solid ${getStateColor(eventState)}`,

      '&.is-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: `2px solid ${theme.palette.primary.light}`,
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: `2px solid rgba(255, 255, 255, 0.30)`,
      },
      '& .name': {
        fontWeight: 'bold',
      },
      '& .start-time': {
        position: 'absolute',
        top: '0px',
        right: '5px',
        fontSize: '11px',
        opacity: 0.7,
      },
      '& .state': {
        position: 'absolute',
        top: '-3px',
        left: '-3px',
      },
      '& .event-info': {
        marginTop: '19px',
        whiteSpace: 'nowrap',
      }
    },
  }),
);

type CurrentEventButtonProps = {
  event: ITimeTableCurrentEvent,
  timeTableItem: ITimeTableItem,
  timeTableSelectedEventId: number | undefined,
  timeTableStates: { [id: number]: ITimeTableItemState },
  onEventSelected: (timeTableId: number, eventState: EventState) => void;
}

function CurrentEventButton({ event, timeTableItem, timeTableSelectedEventId, timeTableStates, onEventSelected }: CurrentEventButtonProps ) {
  const eventState = (timeTableStates ? timeTableStates[event.id].state : event.state) || event.state;
  const classes = useStyles({ eventState });

  if (!timeTableItem) {
    return null;
  }

  const handleEventButtonClick = () => {
    onEventSelected(event.id, eventState);
  };

  return (
    <Button onClick={handleEventButtonClick} className={clsx(classes.currentButton, timeTableSelectedEventId === event.id && 'is-selected')}>
      <span className="state">
        <EventStateIndicator eventState={eventState} isStartlistOfficial={timeTableItem.isStartlistOfficial} />
      </span>
      <span className="start-time">{timeTableItem.startTime}</span>
      <div className="event-info">
        <span className="name">{(timeTableItem.eventNameShort || timeTableItem.eventName)} {timeTableItem.eventCategory} {timeTableItem.eventPhaseShort} {timeTableItem.eventPhaseNumber}</span>
      </div>
    </Button>
  );
}

export default CurrentEventButton;