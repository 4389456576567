import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

function getExponent(dayOfMonth: number) {
  switch (dayOfMonth) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '1.2em',
      marginTop: '0.2rem',
    },
    bordered: {
      padding: '3px 6px',
      background: theme.palette.records.darker,
      borderRadius: '4px 4px 0 0',
    },
    startTime: {
      fontWeight: 'bold',
      fontSize: '1.8em',
      textAlign: 'center',
      lineHeight: '1',
      paddingBottom: '0.25rem',
      position: 'relative',
    },
    startDate: {
      fontSize: '14px',
      textAlign: 'center',
    },
    startDateWrapped: {
      position: 'absolute',
      left: '0px',
      top: '1px',
      width: '100%',
    },
    startDayNumber: {
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 20%, ${theme.palette.gradient.third} 100%)`,
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '15px',
      borderRadius: '0 0 4px 4px',
      position: 'relative',
      width: '77.63px',
      height: '21px',
      '&.with-fading span:nth-child(1)': {
        animationName: 'startDateAndTimeFadeInOut',
        animationFillMode: 'both',
        animationIterationCount: 'infinite',
        animationDuration: '2s',
        animationDirection: 'alternate-reverse',
      },
      '&.with-fading span:nth-child(2)': {
        animationName: 'startDateAndTimeFadeInOut',
        animationFillMode: 'both',
        animationIterationCount: 'infinite',
        animationDuration: '2s',
        animationDirection: 'alternate',
      },
    },
    exp: {
      lineHeight: '1',
    }
  }),
);


type EventStartDateTimeProps = {
  startDateTime: string;
  dayNumber: number;
  canUseThreeRows: boolean;
  hasMultipleDays: boolean;
};

function EventStartDateTime({ startDateTime, dayNumber, canUseThreeRows, hasMultipleDays }: EventStartDateTimeProps) {
  const classes = useStyles();
  const mStartDateTime = moment(startDateTime);
  const mStartDayOfMonth = mStartDateTime.date();

  return (
    <div className={classes.root}>
      <div className={classes.bordered}>
        <div className={classes.startTime}>{mStartDateTime.format('HH:mm')}</div>
        {canUseThreeRows && <div className={classes.startDate}>{mStartDateTime.format('ddd D')}<sup className={classes.exp}>{getExponent(mStartDayOfMonth)}</sup></div>}
      </div>
      <div className={clsx(classes.startDayNumber, !canUseThreeRows && hasMultipleDays && 'with-fading')}>
        <span>Day {dayNumber}</span>
        {!canUseThreeRows && hasMultipleDays && <span className={classes.startDateWrapped}>{mStartDateTime.format('ddd D')}<sup className={classes.exp}>{getExponent(mStartDayOfMonth)}</sup></span>}
      </div>
    </div>
  );
}

export default EventStartDateTime;
