import { ITransponderIntermediateResultList, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type TransponderIntermediateTimesApiResponse = {
  data: ITransponderIntermediateResultList,
  eventState: ITimeTableItemState,
}

export function fetchTransponderIntermediateTimesData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/resultlist-transponders/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<TransponderIntermediateTimesApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get TransponderIntermediateTimes data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchTransponderIntermediateTimesData
};

export default defaultExport;
