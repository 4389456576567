import React, { useState } from 'react';
import { ITimeTableItem } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Image from 'material-ui-image'

type PhotofinishIndicatorProps = {
  event: ITimeTableItem,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0.15rem 0.5rem 0.14rem',
      background: theme.palette.records.lighter,
      borderRadius: '4px',
      fontSize: '15px',
      fontFamily: 'WorldAthleticsHeadline',
      lineHeight: '20px',
      opacity: '0.9',
      textTransform: 'uppercase',
      marginRight: '10px',
      cursor: 'pointer',
      position: 'relative',

      '&:hover': {
        background: theme.palette.records.darker,
      },
      '& .state-icon': {
        paddingRight: '7px',
        color: theme.palette.primary.light,
      },
      '& .click-area': {
        width: '230px',
        height: '40px',
        position: 'absolute',
        top: '-5px',
        left: '-20px',
        zIndex: 100000
      }
    },
    photofinishWrap: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      zIndex: 2000,
      border: `5px solid black`,
      borderRadius: 10,
      overflow: 'hidden',

      '& .photofinish-close-button': {
        position: 'absolute',
        height: 40,
        width: 40,
        top: 20,
        right: 20,
        fontSize: 30,
        textAlign: 'center',
        backgroundColor: 'rgba(196, 28, 44, 0.4)',
        borderRadius: 8,
        zIndex: 200,
      },
    }
  }),
);

function PhotofinishIndicator({ event }: PhotofinishIndicatorProps) {
  const classes = useStyles();

  const [ photofinishOpened, setPhotofinishOpened ] = useState(false);

  const handleOpenPhotofinishClick = () => {
    setPhotofinishOpened(true);
  };

  const handleClosePhotofinishClick = () => {
    setPhotofinishOpened(false);
  };

  if (photofinishOpened) {
    return (
      <div className={classes.photofinishWrap} onClick={handleClosePhotofinishClick}>
        <span className="photofinish-close-button" onClick={handleClosePhotofinishClick}>
          <i className="fas fa-times"></i>
        </span>
        <Image
          src={`${process.env.PUBLIC_URL}/api/v1/resultlist/omega-photofinish/${event.cameraIdOmega}`}
          style={{ position: 'initial', backgroundColor: 'none' }}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className="click-area" onClick={handleOpenPhotofinishClick} />
      <span className="state-icon">
        <i className="fas fa-camera"></i>
      </span>
      <span>Photofinish</span>
    </div>
  );
}

export default PhotofinishIndicator;