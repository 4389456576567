import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';
import { memoize } from 'lodash';
import { RootState } from '@App/store';
import { EventState, ITimeTableItem, TimeTableFilters, TimeTableGenderFilters, ITimeTableItemPoints } from '@App/types';
import { DisciplineType } from '@App/disciplines';
import { EventsResponseById, EventsStatesResponse } from '@App/services/events/eventsApi';
import { createResolver } from '@Utils/func';

export const selectDayFilterOnAppLoadChecked = (state: RootState) => state.timeTable.dayFilterOnAppLoadChecked;
export const selectActiveFilters = (state: RootState) => state.timeTable.activeFilters;
export const selectActiveGenderFilters = (state: RootState) => state.timeTable.activeGenderFilters;
export const selectActiveDayNumberFilter = (state: RootState) => state.timeTable.activeDayNumberFilter;
export const selectActivePolyathlonFilter = (state: RootState) => state.timeTable.activePolyathlonFilter;
export const selectSelectedTimeTableId = (state: RootState) => state.timeTable.selectedTimeTableId;
export const selectFinalsOnlyFilter = (state: RootState) => state.timeTable.finalsOnlyFilter;
export const selectActiveOnlyFilter = (state: RootState) => state.timeTable.activeOnlyFilter;
export const selectFiltersCount = (state: RootState) => {
  const timeTableState = state.timeTable;
  let filtersCount = timeTableState.activeFilters ? timeTableState.activeFilters.length : 0;

  if (timeTableState.activeGenderFilters) {
    filtersCount += 1;
  }
  if (timeTableState.finalsOnlyFilter) {
    filtersCount += 1;
  }
  if (timeTableState.activeOnlyFilter) {
    filtersCount += 1;
  }
  if (timeTableState.activeDayNumberFilter) {
    filtersCount += 1;
  }
  if (timeTableState.activePolyathlonFilter) {
    filtersCount += 1;
  }

  return filtersCount;
};

function isVisibleByDayFilter(dayFilter: number, timeTableItem: ITimeTableItem): boolean {
  return timeTableItem.day === dayFilter;
}

function isVisibleByGenderFilter(genderFilter: TimeTableGenderFilters, timeTableItem: ITimeTableItem): boolean {
  switch (genderFilter) {
    case TimeTableGenderFilters.Female:
      return timeTableItem.gender === 'W';
    case TimeTableGenderFilters.Male:
      return timeTableItem.gender === 'M';
    case TimeTableGenderFilters.Mixed:
      return !timeTableItem.gender;
    default:
      return false;
  }
}

function isVisibleByPolyathlonFilter(activePolyathlonFilter: ITimeTableItemPoints, event: ITimeTableItem): boolean {
  if (activePolyathlonFilter.categoryId !== event.categoryId) {
    return false;
  }
  if (!event.eventSubMeeting) {
    return false;
  }

  return activePolyathlonFilter.eventName.localeCompare(event.eventSubMeeting, undefined, { sensitivity: 'base' }) === 0;
}

function isVisibleByFilter(filters: TimeTableFilters[], timeTableItem: ITimeTableItem): boolean {
  const filtersLength = filters.length;
  let anyMatch = false;

  for (let i=0; i<filtersLength; i += 1) {
    const eachFilter = filters[i];

    switch (eachFilter) {
      case TimeTableFilters.Sprints:
        anyMatch = timeTableItem.disciplineType === DisciplineType.Sprint ||
                  timeTableItem.disciplineType === DisciplineType.Hurdles ||
                  timeTableItem.disciplineType === DisciplineType.Sprint400M;
        break;
      case TimeTableFilters.MiddleDistance:
        anyMatch = timeTableItem.disciplineType === DisciplineType.Run800M;
        break;
      case TimeTableFilters.LongDistance:
        anyMatch = timeTableItem.disciplineType === DisciplineType.LongRun ||
                  timeTableItem.disciplineType === DisciplineType.Walk ||
                  timeTableItem.disciplineType === DisciplineType.Steelplechase;
        break;
      case TimeTableFilters.Relays:
        anyMatch = timeTableItem.disciplineType === DisciplineType.Relays;
        break;
      case TimeTableFilters.VerticalJumps:
        anyMatch = timeTableItem.disciplineType === DisciplineType.HighJump ||
                  timeTableItem.disciplineType === DisciplineType.PoleValut;
        break;
      case TimeTableFilters.HorizontalJumps:
        anyMatch = timeTableItem.disciplineType === DisciplineType.LongJump ||
                  timeTableItem.disciplineType === DisciplineType.TripleJump;
        break;
      case TimeTableFilters.Throws:
        anyMatch = timeTableItem.disciplineType === DisciplineType.Discus ||
                  timeTableItem.disciplineType === DisciplineType.Hammer ||
                  timeTableItem.disciplineType === DisciplineType.Javelin ||
                  timeTableItem.disciplineType === DisciplineType.CricketBall ||
                  timeTableItem.disciplineType === DisciplineType.ShotPut;
        break;
      case TimeTableFilters.AllFieldEvents:
        anyMatch = timeTableItem.disciplineType === DisciplineType.HighJump ||
                  timeTableItem.disciplineType === DisciplineType.PoleValut ||
                  timeTableItem.disciplineType === DisciplineType.LongJump ||
                  timeTableItem.disciplineType === DisciplineType.TripleJump ||
                  timeTableItem.disciplineType === DisciplineType.Discus ||
                  timeTableItem.disciplineType === DisciplineType.Hammer ||
                  timeTableItem.disciplineType === DisciplineType.Javelin ||
                  timeTableItem.disciplineType === DisciplineType.CricketBall ||
                  timeTableItem.disciplineType === DisciplineType.ShotPut;
        break;
      case TimeTableFilters.AllTrackEvents:
        anyMatch = timeTableItem.disciplineType === DisciplineType.Sprint ||
                  timeTableItem.disciplineType === DisciplineType.Hurdles ||
                  timeTableItem.disciplineType === DisciplineType.Sprint400M ||
                  timeTableItem.disciplineType === DisciplineType.Run800M ||
                  timeTableItem.disciplineType === DisciplineType.LongRun ||
                  timeTableItem.disciplineType === DisciplineType.Walk ||
                  timeTableItem.disciplineType === DisciplineType.Relays ||
                  timeTableItem.disciplineType === DisciplineType.Steelplechase;
        break;
    }

    if (anyMatch) {
      break;
    }
  }

  return anyMatch;
}

function isVisibleByActiveFilter(event: ITimeTableItem, eventStates: EventsStatesResponse | undefined | null, serverTime: Moment, activeEventFilterMinuteThreshold: number | undefined): boolean {
  const eventState = (eventStates ? eventStates.states[event.id]?.state : event.state) || event.state;

  if (eventState === EventState.InProgress || eventState === EventState.Judging || eventState === EventState.Unofficial) {
    return true;
  }

  if (activeEventFilterMinuteThreshold) {
    if (event.isOfficial && event.dateOfficial) {
      const officialMinuteDiff = serverTime.diff(event.dateOfficial, 'minutes');
      if (officialMinuteDiff >= 0 && officialMinuteDiff <= activeEventFilterMinuteThreshold) {
        return true;
      }
    }

    const startTimeMinuteDiff = Math.abs(serverTime.diff(event.startDateTime, 'minutes'));
    if (startTimeMinuteDiff <= activeEventFilterMinuteThreshold) {
      return true;
    }
  }

  // todo: handle serverNow
  // - x mins since official
  // - x mins before and after now

  return false;
}

export const filterTimeTable = (
  allEvents: ITimeTableItem[] | undefined,
  eventStates: EventsStatesResponse | undefined | null,
  activeFilters: TimeTableFilters[] | null,
  activeGenderFilers: TimeTableGenderFilters | null,
  activeDayNumberFilter: number | null,
  activePolyathlonFilter: ITimeTableItemPoints | null,
  finalsOnlyFilter: boolean,
  activeOnlyFilter: boolean,
  serverTime: string | undefined,
  activeEventFilterMinuteThreshold: number | undefined) => {

  if (!allEvents) {
    return null;
  }

  return allEvents.filter(event => {
    let isVisible = true;

    if (activeGenderFilers) {
      isVisible = isVisibleByGenderFilter(activeGenderFilers, event);
    }

    if (activeFilters && activeFilters.length > 0) {
      isVisible = isVisible && isVisibleByFilter(activeFilters, event);
    }

    if (activeDayNumberFilter) {
      isVisible = isVisible && isVisibleByDayFilter(activeDayNumberFilter, event);
    }

    if (finalsOnlyFilter) {
      isVisible = isVisible && event.isFinalPhase;
    }

    if (activeOnlyFilter) {
      isVisible = isVisible && isVisibleByActiveFilter(event, eventStates, moment(serverTime), activeEventFilterMinuteThreshold);
    }

    if (activePolyathlonFilter) {
      isVisible = isVisible && isVisibleByPolyathlonFilter(activePolyathlonFilter, event);
    }

    return isVisible;
  });
}

export const makeFilteredTimeTableSelector = memoize((eventStates: EventsStatesResponse | undefined | null /* memoized by first arg */, allEvents: ITimeTableItem[] | undefined, serverTime: string | undefined, activeEventFilterMinuteThreshold: number | undefined) => createSelector(
  [selectActiveFilters, selectActiveGenderFilters, selectActiveDayNumberFilter, selectActivePolyathlonFilter, selectFinalsOnlyFilter, selectActiveOnlyFilter],
  (activeFilters, activeGenderFilers, activeDayNumberFilter, activePolyathlonFilter, finalsOnlyFilter, activeOnlyFilter) => filterTimeTable(allEvents, eventStates, activeFilters, activeGenderFilers, activeDayNumberFilter, activePolyathlonFilter, finalsOnlyFilter, activeOnlyFilter, serverTime, activeEventFilterMinuteThreshold)
), createResolver());

export const makeTimeTableSelectedEventSelector = memoize((timeTableResponse: EventsResponseById | undefined) => createSelector(
  [selectSelectedTimeTableId],
  (selectedTimeTableId) => {
    if (!selectedTimeTableId || !timeTableResponse || !timeTableResponse.timeTableById) {
      return null;
    }
    return timeTableResponse.timeTableById[selectedTimeTableId];
  }
));

export const makeAnyFiltersUsedSelector = () => createSelector(
  [selectActiveFilters, selectActiveGenderFilters, selectActiveDayNumberFilter, selectFinalsOnlyFilter],
  (activeFilters, activeGenderFilers, activeDayNumberFilter, finalsOnlyFilter) => {
    if (activeFilters && activeFilters.length > 0) {
      return true;
    }
    if (activeGenderFilers || activeDayNumberFilter || finalsOnlyFilter) {
      return true;
    }

    return false;
  }
);
