import { keyBy } from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITimeTableItem, ITimeTableItemPoints, ITimeTableItemState, ITimeTableItemStatePoints, ITimeTableDay, IEventRecord, ITimeTableCurrentEvent } from '@App/types';

type EventsResponse = {
  timeTable: ITimeTableItem[],
  timeTablePoints: ITimeTableItemPoints[],
  days: ITimeTableDay[],
  records: {[recordsKey: string]: IEventRecord[] },
  serverTime: string,
  activeEventFilterMinuteThreshold: number,
}

type EventsPointsResponse = ITimeTableItemPoints[];

export type EventsStatesResponse = {
  states: { [id: number]: ITimeTableItemState },
  currentEvents: ITimeTableCurrentEvent[],
}

export type EventsStatesPointsResponse = {
  [id: number]: ITimeTableItemStatePoints;
}

export type EventsResponseById = EventsResponse & { timeTableById: { [id: number]: ITimeTableItem } }

export type EventsPointsResponseById = { timeTable: ITimeTableItemPoints[], timeTableById: { [id: number]: ITimeTableItemPoints } }

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.PUBLIC_URL}/api/v1`
  }),
  tagTypes: ['Events', 'EventsPoints', 'EventsStates', 'EventsStatesPoints'],
  endpoints: (build) => ({
    getEvents: build.query<EventsResponseById, string>({
      query: () => 'events',
      providesTags: ['Events'],
      transformResponse: (response: EventsResponse): EventsResponseById => ({
        ...response,
        timeTableById: keyBy(response.timeTable, x => x.id),
      }),
    }),
    getEventsPoints: build.query<EventsPointsResponseById, string>({
      query: () => 'events/points',
      providesTags: ['EventsPoints'],
      transformResponse: (response: EventsPointsResponse): EventsPointsResponseById => ({
        timeTable: response,
        timeTableById: keyBy(response, x => x.id),
      }),
    }),
    getEventsStates: build.query<EventsStatesResponse, string>({
      query: () => 'events/states',
      providesTags: ['EventsStates'],
    }),
    getEventsStatesPoints: build.query<EventsStatesPointsResponse, string>({
      query: () => 'events/points/states',
      providesTags: ['EventsStatesPoints'],
    }),
  }),
});

export const { useGetEventsQuery, useGetEventsStatesQuery, useGetEventsPointsQuery, useGetEventsStatesPointsQuery } = eventsApi;