import { createLogger } from 'redux-logger';
import prodStoreConfig from './store.prod';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = createLogger({
  collapsed: true,
  level: 'info',
});

const storeConfig = {
  reducers: prodStoreConfig.reducers,
  middlewares: [
    ...prodStoreConfig.middlewares,
    //logger,
  ],
};

export default storeConfig;