import { IFoulAssistItem } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export function fetchFoulAssistData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/foul-assist/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<IFoulAssistItem[]> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get FoulAssist data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchFoulAssistData,
};

export default defaultExport;
