import React from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IRecordsSummaryItem, IFrontendSettings } from '@App/types';

import { getFlagOrClubImage, getCountryFlagImage } from '@Utils/ui';
import { flatten } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    summaryTable: {
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '950px',
      margin: '0 auto',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '14px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '350px',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '&.has-bibs': {
            width: '320px',
          }
        },
        '& .athlete-name': {
          fontSize: '16px',

          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
          '& .relay-label': {
            paddingLeft: '7px',
            opacity: '1',
          },
          '& .relay-members': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            listStyleType: 'none',
            fontWeight: 'normal',
            fontSize: '14px',
            margin: '0',
            padding: '0',

            '& li': {
              padding: '2px 5px 2px 0',
              fontSize: '12px',
              width: '220px',
            },
            '& .member-yob': {
              opacity: '0.5',
              paddingLeft: '4px',
            },
            '& .member-last-name': {
              fontWeight: 'bold',
              fontSize: '13px',
              paddingLeft: '3px',
            },
            '& .member-first-name': {
              opacity: '0.8',
            },
            '& .member-order': {
              background: theme.palette.secondary.dark,
              borderRadius: '4px',
              padding: '2px 4px',
              fontWeight: 'bold',
            }
          }
        },
        '& .club-name': {
          fontWeight: 'normal',
          color: theme.palette.textTabs.club,
          fontSize: '13px',
        },
        '& .startlist-note': {
          fontSize: '12px',
          display: 'inline-block',
          marginLeft: '5px',
          fontWeight: 'normal',
          background: theme.palette.primary.main,
          borderRadius: '4px',
          padding: '1px 6px 0',
          opacity: '0.9',
        },
        '& .bib': {
          fontSize: '17px',
          color: theme.palette.textTabs.bib,
          position: 'absolute',
          right: '0',
          top: '9px',
        }
      },
      '& td.col-record-type': {
        fontSize: '19px',
        textAlign: 'center',
        verticalAlign: 'top',
        padding: 0,

        '& span': {
          backgroundColor: '#ffffffc2',
          color: '#000',
          width: '100%',
          display: 'block',
        }
      },
      '& td.col-result': {
        fontSize: '16px'
      },
      '& td.col-event': {
        '& .event-phase': {
          fontWeight: 'normal',
          opacity: '0.7'
        }
      },
      '& tr.record-separator td': {
        borderTop: '2px solid #ffffffc2',
      },

      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },
    }
  }),
);

type RecordsSummaryTableProps = {
  recordsSummary: IRecordsSummaryItem[],
  frontendSettings: IFrontendSettings,
};

function MedalStandingsTable({ recordsSummary, frontendSettings }: RecordsSummaryTableProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <table className={classes.summaryTable}>
        <colgroup>
          <col style={{ width: '80px' }} />
          <col style={{ width: '420px' }} />
          <col style={{ width: '260px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Record</th>
            <th className="text-left">Athlete / Relay</th>
            <th className="text-left">Event</th>
            <th className="text-right">Result</th>
          </tr>
        </thead>
        <tbody>
          {flatten(recordsSummary.map(recordGroup => recordGroup.participiants.map((participiant, pidx) => (
            <tr key={`${recordGroup.record}-${participiant.participiantId}`} className={clsx(pidx === 0 && 'record-separator')}>
              {pidx === 0 && <td className="col-record-type" rowSpan={recordGroup.participiants.length}><span>{recordGroup.record}</span></td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className="athlete-container">
                    <div className="athlete-name">
                      {!participiant.isRelay && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', participiant.isRelay && 'relay-name')}>{participiant.firstName}</span>
                      {participiant.isRelay && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                    </div>
                    {!participiant.isRelay && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}</strong> - </>}{participiant.clubFull}</div>}
                  </div>
                </div>
              </td>
              <td className="col-event">
                {participiant.eventName} {participiant.eventCategory}
                {participiant.eventPhase && <span className="event-phase"> {participiant.eventPhase} {participiant.eventPhaseNumber}</span>}
              </td>
              <td className="text-right col-result">{participiant.result}</td>
            </tr>
          ))))}
        </tbody>
      </table>
    </div>
  );
}

export default MedalStandingsTable;