import React from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IMedalStandingsItem, IFrontendSettings } from '@App/types';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass } from '@Utils/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    standingsTable: {
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '950px',
      margin: '0 auto',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '14px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '&.gold': {
          color: '#FFD700',
        },
        '&.silver': {
          color: '#D3D3D3',
        },
        '&.bronze': {
          color: '#DC8B53',
        },
        '&.sum': {
          color: '#fff',
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-gold, td.col-silver, td.col-bronze': {
        fontSize: '18px',
      },
      '& td.col-gold': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-silver': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-bronze': {
        borderRight: `3px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-sum': {
        fontSize: '16px',
      },
      '& td.col-team': {
        position: 'relative',

        '& .team-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .team-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '350px',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '& .team-short-name': {
            fontWeight: 'normal',
            opacity: '0.6',
            fontSize: '13px',
          },
        },
        '& .team-name': {
          fontSize: '16px',
        },
      },
      '& td.col-place': {
        fontSize: '18px',

        '&.place-first span, &.place-second span, &.place-third span': {
          color: '#000',
          borderRadius: '4px',
          padding: '3px 10px',
        },
        '&.place-first span': {
          backgroundColor: '#FFC540',
        },
        '&.place-second span': {
          backgroundColor: '#D3D3D3',
        },
        '&.place-third span': {
          backgroundColor: '#DC8B53',
        },
      },

      '& .no-medal': {
        fontWeight: 'normal',
        opacity: '0.4',
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },
    }
  }),
);

type MedalStandingsTableProps = {
  medalStandings: IMedalStandingsItem[],
  frontendSettings: IFrontendSettings,
};

function MedalStandingsTable({ medalStandings, frontendSettings }: MedalStandingsTableProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <table className={classes.standingsTable}>
        <colgroup>
          <col style={{ width: '80px' }} />
          <col style={{ width: '480px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Order</th>
            <th className="text-left">{frontendSettings.isInternationalMeeting ? 'Country' : 'Team'}</th>
            <th className="gold">Gold <i className="fas fa-medal"></i></th>
            <th className="silver">Silver <i className="fas fa-medal"></i></th>
            <th className="bronze">Bronze <i className="fas fa-medal"></i></th>
            <th className="sum">Total &Sigma;</th>
          </tr>
        </thead>
        <tbody>
          {medalStandings.map(standing => (
            <tr key={standing.id}>
              <td className={clsx('text-center', 'col-place', getPlaceCssClass(standing.place))}><span>{standing.place}</span></td>
              <td className="col-team">
                <div className="team-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(standing.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(standing.country, standing.shortName)}')`}} />
                  </div>
                  <div className="team-container">
                    <div className="team-name">{standing.fullName}</div>
                    <div className="team-short-name">{standing.shortName}</div>
                  </div>
                </div>
              </td>
              <td className="text-center col-gold">{standing.gold ? standing.gold : <span className="no-medal">-</span>}</td>
              <td className="text-center col-silver">{standing.silver ? standing.silver : <span className="no-medal">-</span>}</td>
              <td className="text-center col-bronze">{standing.bronze ? standing.bronze : <span className="no-medal">-</span>}</td>
              <td className="text-center col-sum">{standing.totalMedals}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MedalStandingsTable;