import { TopListType, ITopListItem, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type TopListApiResponse = {
  data: ITopListItem[],
  eventState: ITimeTableItemState,
}

export function fetchTopListData(timeTableId: number, abortSignal: AbortSignal, topListType: TopListType, isIndoor: boolean, seasonYear: number) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/top-list/${timeTableId}?topListType=${topListType}&isIndoor=${isIndoor}&seasonYear=${seasonYear}`, {
    signal: abortSignal,
  }).then(async (response): Promise<TopListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get TopList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchTopListData
};

export default defaultExport;
