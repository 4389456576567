import {range} from "lodash";
import React from 'react';
import clsx from 'clsx';
import {
  ITimeTableItem,
  IResultList,
  IFrontendSettings,
  EventState,
  ITimeTableItemState,
  StartCardType
} from '@App/types';

import { useStyles } from './ResultsRunsResultListTabContent.styles';
import { DisciplineType } from '@App/disciplines';

import RecordsIndicator from '../_components/RecordsIndicator';
import StartCardIndicator from '../_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass, getUnofficialPlaceCssClass, getCompetitorCount, getResultThousandsPart } from '@Utils/ui';

type ResultsRunsResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
}

function ResultsRunsResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching }: ResultsRunsResultListTabContentProps) {
  const resultListDataItems = resultListData ? resultListData.resultItems : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);

  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;
  const showStartListData = selectedEventState?.state === EventState.Scheduled;
  const showPointsColumn =  frontendSettings?.hasPoints &&  (frontendSettings?.isPolyathlonMeeting ? selectedEvent.isCombinedDiscipline : true)

  const classes = useStyles({ hasAnyQualify });

  if (!frontendSettings) {
    return null;
  }

  if (showStartListData) {
    return (
      <div className={classes.root}>
      <table className={clsx(classes.startListTable, 'has-startlist-data')}>
          <colgroup>
            <col style={{ width: '60px' } /* Lane */} />
            <col style={{ width: isRelays ? '560px' : '380px' } /* Athlete */} />
            {!isRelays && <col style={{ width: '80px' } /* Age */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col style={{ width: '100px' } /* SB */} />
            {selectedEvent.hasQualifyBest && <col style={{ width: '90px' } /* Qualify Best */} />}
            {frontendSettings?.isInternationalMeeting && <col style={{ width: '90px' } /* WA Rank */} />}
            <col />
          </colgroup>
          <thead>
            <tr>
              {<th>Lane</th>}
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                {isRelays ? 'Relay' : 'Athlete'}
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
              </th>
              {!isRelays && <th>Age</th>}
              <th className="text-center">PB</th>
              <th className="text-center">SB</th>
              {selectedEvent.hasQualifyBest && <th className="text-center" style={{ paddingLeft: '23px', paddingRight: '0px'}}>Qualify</th>}
              {frontendSettings?.isInternationalMeeting && <th className="text-center">WA Rank</th>}
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              {<td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays')}>{participiant.lane}</td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                    <div className="athlete-name">
                      {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                      {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                      {(participiant.hasDNSStatus || participiant.startlistNote) && <span className={clsx('startlist-note', (participiant.startlistNote === 'PACE' || participiant.startlistNote === 'PC') && 'note-pace')}>{participiant.hasDNSStatus ? 'DNS' : participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      {isRelays && participiant.relayMembers &&
                      <ul className="relay-members">
                        {participiant.relayMembers.map(member => (
                          <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                        ))}
                      </ul>}
                    </div>
                    {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                  </div>
                  {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              {!isRelays && <td className="text-center col-yob">{participiant.age || '-'}<span>{participiant.yearOfBirth || ''}</span></td>}
              <td className="text-center col-pb">{participiant.personalBest}</td>
              <td className="text-center col-sb">{participiant.seasonBest}</td>
              {selectedEvent.hasQualifyBest && <td className="text-center col-qualify"><span className="qualify-type">{participiant.qualifyBestType}</span>{participiant.qualifyBest}</td>}
              {frontendSettings?.isInternationalMeeting && <td className="text-center col-warank">{participiant.worldRanking}</td>}
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
    </div>
    );
  }

  return (
    <div className={classes.root}>
      <table className={classes.startListTable}>
          <colgroup>
            <col style={{ width: '65px' } /* Place */} />
            <col style={{ width: '30px' } /* Lane */} />
            <col style={{ width: isRelays ? '550px' : '380px' } /* Athlete */} />
            <col style={{ width: '80px' } /* Result */} />
            <col style={{ width: '30px' } /* Gap */} />
            {showPointsColumn && <col style={{ width: '30px' } /* Points */} />}
            {selectedEvent.withJudgeOffice && <col style={{ width: '90px' } /* RedCards */} />}
            {selectedEvent.hasReactions && <col style={{ width: '30px' } /* Reaction */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Rank</th>
              <th className="text-center" style={{ paddingLeft: '5px' }}>LN</th>
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                {isRelays ? 'Relay' : 'Athlete'}
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{getCompetitorCount(resultListDataItems)}</strong> total)</span>}
                {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
              </th>
              <th className="text-right">Result</th>
              <th className="text-right">Gap</th>
              {showPointsColumn && <th className="text-right">Points</th>}
              {selectedEvent.withJudgeOffice && <th className="text-left">Red Cards</th>}
              {selectedEvent.hasReactions && <th className="text-right">Reaction</th>}
              <th className="text-center">PB / SB</th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays', selectedEvent.isFinalPhase ? getPlaceCssClass(participiant.place) : getUnofficialPlaceCssClass(participiant.place))}><span>{participiant.place}</span></td>
              <td className={clsx('text-center col-result-lane', isRelays && 'is-relays')}>{participiant.lane}</td>
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                    <div className="athlete-name">
                      {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                      {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                      {participiant.startlistNote && <span className={clsx('startlist-note', (participiant.startlistNote === 'PACE' || participiant.startlistNote === 'PC') && 'note-pace')}>{participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      {isRelays && participiant.relayMembers &&
                      <ul className="relay-members">
                        {participiant.relayMembers.map(member => (
                          <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                        ))}
                      </ul>}
                    </div>
                    {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                  </div>
                  {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              <td className={clsx('text-right col-result', participiant.qualify?.length === 2 && 'has-group-qualify')}>
                <div className="result-record-container">
                  <span className={clsx('result', participiant.records?.length > 0 && 'has-records', participiant.showThousandsResult && 'has-thousands')}>
                    {participiant.status || participiant.result}
                    {participiant.showThousandsResult && <span className="result-thousands">{getResultThousandsPart(participiant.resultThousands)}</span>}
                  </span>
                  {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                  <RecordsIndicator records={participiant.records} />
                </div>
                {participiant.qualify && <span className={clsx('result-qualify', isRelays && 'is-relays', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting', participiant.qualify.length === 2 && 'group-qualify')}>{participiant.qualify}</span>}
              </td>
              <td className="text-right col-gap">{participiant.timeGap}</td>
              {showPointsColumn && <td className="text-right col-points">{participiant.points}</td>}
              {selectedEvent.withJudgeOffice && <td className="text-left col-red-cards">
                {participiant.judgeOfficeCardsCount && range(0, participiant.judgeOfficeCardsCount).map(i => (<StartCardIndicator key={i} startCard={StartCardType.Red} />))}
              </td>}
              {selectedEvent.hasReactions && <td className="text-right col-reaction">
                <span className={clsx(participiant.reactionRank === 1 && 'fastest')}>{participiant.reaction}</span> {participiant.reactionRank && <span className="reaction-rank">{participiant.reactionRank}</span>}
              </td>}
              <td className="text-center col-result-pb-sb">
                <div className="result-pb">{participiant.personalBest}</div>
                <div className="result-sb">{participiant.seasonBest}</div>
              </td>
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
      </table>
    </div>
  );
}

export default ResultsRunsResultListTabContent;
