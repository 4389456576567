import React, { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@App/hooks';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSnackbar  } from 'notistack';
import ReactGA from 'react-ga4';

import { useGetFrontendSettingsQuery } from '@App/services/settings/frontendSettingsApi';
import { useGetSystemInfoQuery } from '@App/services/settings/systemInfoApi';

import AppBar from './features/appBar';
import './App.css';

import { CircularProgress } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import MainScreen from '@Features/mainScreen';
import PointsScreen from '@Features/pointsScreen';
import FlashQuotesScreen from '@Features/flashQuotesScreen';
import MedalStandingsScreen from '@Features/medalStandingsScreen';
import RecordsSummaryScreen from '@Features/recordsSummaryScreen';
import FinalLapCounterScreen from '@Features/finalLapCounterScreen';
import SettingsInitErrorHandler from '@Features/errorHandler/init/SettingsInitErrorHandler';
import { IFrontendSettings, ISystemInfo, ScreenTypes } from '@App/types';

import { selectFinalLapCounterModeEnabled, setTopListIsIndoor, setTopListSeasonYear } from '@App/appUserStateSlice';
import { getSafetyRefreshDateFromSession, setSafetyRefreshDateToSession } from '@App/services/settings/sessionSettingsApi';
import clsx from 'clsx';

interface IAppProps {
  onFrontendSettingsLoaded: (frontendSettings: IFrontendSettings) => void;
}

function App({ onFrontendSettingsLoaded }: IAppProps) {
  const dispatch = useAppDispatch();

  const finalLapCounterFocusModeEnabled = useAppSelector(selectFinalLapCounterModeEnabled);

  const { enqueueSnackbar } = useSnackbar();

  const [storedAppVersion, setStoredAppVersion] = useState('');
  const [storedBuildDate, setStoredBuildDate] = useState('');
  const [ signalRConnection, setSignalRConnection ] = useState<HubConnection|null>(null);

  // Force to load frontend settings before everthing else
  const {
    data: frontendSettings,
    error: frontendSettingsError,
    isLoading: isFrontendSettingsLoading
  } = useGetFrontendSettingsQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000, // 5 min
  });

  const { data: systemInfo } = useGetSystemInfoQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: 30000,
  });

  useEffect(() => { // todo: jakmile budou pushe tak todle do samostané komponenty!
    const newConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.PUBLIC_URL}/cis-hub`)
        .withAutomaticReconnect()
        .build();

        setSignalRConnection(newConnection);
  }, []);

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.start()
            .then(result => {
                console.log('SignalR hub Connected!');

                signalRConnection.on('refreshBrowser', () => {
                    console.warn('Refresh browser from SignalR!');
                    window.location.reload();
                });
            })
            .catch(e => console.error('Connection failed: ', e));
      }
  }, [signalRConnection]);

  useEffect(() => {
    if (frontendSettings) {
      dispatch(setTopListIsIndoor(frontendSettings.isIndoor));
      dispatch(setTopListSeasonYear(frontendSettings.seasonYear));

      // Set background for theme
      if (frontendSettings.theme) {
        const rootElement = document.getElementById('root');
        if (rootElement) {
          rootElement.style.backgroundImage = `url('${process.env.PUBLIC_URL}/${frontendSettings.theme.background}')`;
        }
      }

      onFrontendSettingsLoaded(frontendSettings); // Možná by šlo posunout useGetFrontendSettingsQuery přímo do index.tsx?
    }
  }, [ dispatch, frontendSettings, onFrontendSettingsLoaded ]);

  useEffect(() => {
    let newVersionAvailable = false;
    if (storedAppVersion && systemInfo?.appVersion) {
      newVersionAvailable = storedAppVersion !== systemInfo?.appVersion;
    }
    if (!newVersionAvailable && storedBuildDate && systemInfo?.buildDate) {
      newVersionAvailable = storedBuildDate !== systemInfo?.buildDate;
    }

    if (newVersionAvailable) {
      enqueueSnackbar('New application version is available, will reload shortly...',{
        variant: 'info',
      });

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    setStoredAppVersion(systemInfo?.appVersion || '');
    setStoredBuildDate(systemInfo?.buildDate || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, systemInfo?.appVersion, systemInfo?.buildDate]);

  useEffect(() => {
    if (systemInfo?.serverTime) {
      const now = moment();

      if (now.hour() === 8) {
        const lastSafetyRefreshDate = getSafetyRefreshDateFromSession();

        if (!lastSafetyRefreshDate) {
          setSafetyRefreshDateToSession(now.format());
          window.location.reload();
        } else {
          const mLastSafetyRefreshDate = moment(lastSafetyRefreshDate);

          if (!mLastSafetyRefreshDate.isSame(now, 'day')) {
            setSafetyRefreshDateToSession(now.format());
            window.location.reload();
          }
        }
      }
    }
  }, [systemInfo?.serverTime]);

  useEffect(() => {
    setTimeout(() => {
      if (frontendSettings && frontendSettings.isPublicInstance) {
        ReactGA.initialize('G-6LKS36YE75');
      }
    }, 1000);
  }, [frontendSettings]);

  if (isFrontendSettingsLoading) {
    return (
      <div className="app-container">
        <div className="main-loader">
          <CircularProgress size={70} />
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      {!frontendSettings && frontendSettingsError && <SettingsInitErrorHandler error={frontendSettingsError} />}
      {frontendSettings && !finalLapCounterFocusModeEnabled && <AppBar settings={frontendSettings as IFrontendSettings} systemInfo={systemInfo as ISystemInfo} />}
      <div className={clsx('app-content-container', finalLapCounterFocusModeEnabled && 'last-lap-focus-mode')}>
      {frontendSettings &&
      <Switch>
        {frontendSettings?.availableScreens.includes(ScreenTypes.Points) &&
        <Route path="/points">
          <PointsScreen />
        </Route>}
        {frontendSettings?.availableScreens.includes(ScreenTypes.FlashQuotes) &&
        <Route path="/flash-quotes">
          <FlashQuotesScreen />
        </Route>}
        {frontendSettings?.availableScreens.includes(ScreenTypes.MedalStandings) &&
        <Route path="/medal-standings">
          <MedalStandingsScreen />
        </Route>}
        {frontendSettings?.availableScreens.includes(ScreenTypes.RecordsSummary) &&
        <Route path="/records-summary">
          <RecordsSummaryScreen />
        </Route>}
        {frontendSettings?.availableScreens.includes(ScreenTypes.FinalLapCounter) &&
        <Route path="/final-lap-counter">
          <FinalLapCounterScreen />
        </Route>}
        <Route path="/timetable">
          <MainScreen />
        </Route>
        <Route path="/">
          <Redirect to="/timetable" />
        </Route>
      </Switch>}
      </div>
    </div>
  );
}

export default App;