import React from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

type StyleProps = {
  addedWidth?: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    filterButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',
      padding: '4px 4px',
      height: '48px',
      width: ({ addedWidth }) => addedWidth ? `${184 + addedWidth}px` : '184px',

      '& .text': {
        display: 'inline-block',
        width: ({ addedWidth }) => addedWidth ? `${110 + addedWidth}px` : '110px',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px'
      },
      '& i.fas, i.far': {
        fontSize: '20px',
        lineHeight: '30px',
        width: '36px',
      },
      '& .MuiSvgIcon-root': {
        opacity: '0.1',
      },
      '& img, i.fas, i.far': {
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderRadius: '4px',
        padding: '3px',
        height: '36px',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '&.selected': {
        borderColor: theme.palette.primary.light,

        '& .MuiSvgIcon-root': {
          opacity: '1',
        },
      }
    }
  }),
);

export enum IconType {
  Image = 1,
  FontAwesome = 2,
  FontAwesomeRegular = 3,
};

type FilterToggleButtonProps = {
  selected?: boolean;
  iconName: string;
  iconType: IconType;
  iconColor?: string | null;
  text: string;
  value: any;
  onChange?: (value: any) => void;
  addedWidth?: number;
}

function FilterToggleButton({ iconName, value, iconType, iconColor, text, selected, addedWidth, onChange }: FilterToggleButtonProps) {
  const classes = useStyles({ addedWidth });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick} className={clsx(classes.filterButton, selected && 'selected')}>
      {(iconType === IconType.FontAwesome || iconType === IconType.FontAwesomeRegular) ? (<i className={`${iconType === IconType.FontAwesomeRegular ? 'far' : 'fas'} ${iconName}`} style={iconColor ? { color: iconColor } : undefined } />) : (<img src={`${process.env.PUBLIC_URL}/disciplines/${iconName}.png`} alt={`${value} icon`} className="discipline-icon" />)}
      <span className="text">{text}</span>
      <DoneIcon />
    </Button>
  );
}

export default FilterToggleButton;