import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem, TimeTableFilters, TimeTableGenderFilters, ITimeTableItemPoints } from '@App/types';

import { debounce } from 'lodash';
import SimpleBar from 'simplebar-react';

import {
  setDayFilterOnAppLoadChecked,
  setActiveFilters,
  setActiveGenderFilters,
  setActiveDayNumberFilter,
  setSelectedTimeTableIdAndCheckActiveTabAsync,
  checkActiveTabOnLoadAsync,
  toggleFinalsOnlyFilter,
  toggleActiveOnlyFilter,
  clearAllFilters,
  setActivePolyathlonFilter,
} from './timeTableSlice';
import { setTimeTableScrollPosition, selectTimeTableScrollPosition } from '@App/appUserStateSlice';

import {
  selectDayFilterOnAppLoadChecked,
  selectActiveFilters,
  selectActiveGenderFilters,
  selectActiveDayNumberFilter,
  selectSelectedTimeTableId,
  selectFinalsOnlyFilter,
  selectActiveOnlyFilter,
  selectFiltersCount,
  makeFilteredTimeTableSelector,
  makeAnyFiltersUsedSelector,
  selectActivePolyathlonFilter,
} from './selectors';

import { systemInfoApi } from '@App/services/settings/systemInfoApi';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';
import { useGetEventsQuery, useGetEventsStatesQuery } from '@App/services/events/eventsApi';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress,  Card, CardContent } from '@material-ui/core';

import TimeTableLegacyTouchList from './TimeTableLegacyTouchList';
import TimeTableFilter from './filter/TimeTableFilter';
import Clock from './clock/Clock';
import { TIME_TABLE_LIST_ELEMENT_ID, TIME_TABLE_SCROLLABLE_PARENT_ID } from '@App/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    scrollWrapper: {
      height: '330px', // Magic
      flexGrow: 1,
      overflowY: 'auto',
    },
    simpleBar: {
      height: '100%',
    },
    card: {
      height: 'calc(100% - 3px)',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    cardContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-24px',
      padding: '0 0 8px 0 !important',
    },
    headingContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      margin: 0,
      background: theme.palette.background.paper,
      color: theme.palette.primary.light,
      textTransform: 'uppercase',
      padding: '4px 10px',
      fontSize: '18px',
      letterSpacing: '1px',
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      display: 'flex',
    },
    headingText: {
      paddingTop: '2px',
      fontFamily: 'WorldAthleticsHeadline',
      display: 'inline-block',
      flexGrow: 1,
    },
    topBorder: {
      display: 'block',
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
      width: '100%',
    },
    progress: {
      position: 'absolute',
      right: '10px',
      top: '3px',
    },
    listHeader: {
      display: 'flex',
      background: theme.palette.records.lighter,
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1px',
      color: theme.palette.primary.light,
      fontFamily: 'WorldAthleticsHeadline',
      borderBottom: `1px solid ${theme.palette.table.borderColor}`,
      alignItems: 'center',

      '& .col-day': {
        width: '37px',
        padding: '6px 8px 5px 6px',
        borderRight: `0px solid ${theme.palette.table.borderColor}`,
      },
      '& .col-time': {
        width: '55px',
        padding: '6px 8px 5px 8px',
        borderRight: `0px solid ${theme.palette.table.borderColor}`,
        textAlign: 'center',
      },
      '& .col-event': {
        padding: '6px 8px 5px 8px',
        flexGrow: 1,
        position: 'relative',

        '& .scroll-to-now': {
          color: '#43BF4D',
          cursor: 'pointer',
          display: 'inline-block',
          marginLeft: '10px',
          position: 'relative',

          '& .click-area': {
            display: 'inline-block',
            position: 'absolute',
            top: '-45%',
            left: '-50%',
            width: '200%',
            height: '200%',
          }
        }
      },
    },
    initialLoadInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiCircularProgress-root': {
        marginTop: '30px',
        marginBottom: '15px'
      }
    },
    noEvents: {
      textAlign: 'center',
      padding: '30px 0',
      fontSize: '16px',
    }
  }),
);

const selectAnyFiltersUsed = makeAnyFiltersUsedSelector();

type FilteringChangedHandler = (newFilters: TimeTableFilters[] | null) => void;
type GenderFilteringChangedHandler = (newFilters: TimeTableGenderFilters | null) => void;
type DayFilteringChangedHandler = (newFilters: number | null) => void;
type PolyathlonFilteringChangedHandler = (newFilter: ITimeTableItemPoints | null) => void;
type ClearAllFiltersHandler = () => void;
type ToggleFinalsOnlyFilterHandler = () => void;
type ToggleActiveOnlyFilterHandler = () => void;
type TimeTableIdSelectedHandler = (newSelectedTimeTableItem: ITimeTableItem) => void;

function TimeTable() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const scrollableNodeRef = React.createRef<any>();
  const [ scrollToNowAvailable, setScrollToNowAvailable ] = useState<boolean>(false);

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const { data: systemInfo } = systemInfoApi.endpoints.getSystemInfo.useQueryState('');

  const { data, isLoading, isFetching } = useGetEventsQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: frontendSettings?.timeTableRefreshInterval,
  });

  const { data: eventStatesData } = useGetEventsStatesQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: frontendSettings?.statesRefreshInterval,
  });

  const dayFilterOnAppLoadChecked = useAppSelector(selectDayFilterOnAppLoadChecked);
  const timeTableScrollPosition = useAppSelector(selectTimeTableScrollPosition);
  const activeFilters = useAppSelector(selectActiveFilters);
  const activeGenderFilters = useAppSelector(selectActiveGenderFilters);
  const activeDayNumberFilter = useAppSelector(selectActiveDayNumberFilter);
  const activePolyathlonFilter = useAppSelector(selectActivePolyathlonFilter);
  const filteredTimeTable = useAppSelector(makeFilteredTimeTableSelector(eventStatesData, data?.timeTable, systemInfo?.serverTime, frontendSettings?.activeEventFilterMinuteThreshold));
  const selectedTimeTableId = useAppSelector(selectSelectedTimeTableId);
  const finalsOnlyFilter = useAppSelector(selectFinalsOnlyFilter);
  const activeOnlyFilter = useAppSelector(selectActiveOnlyFilter);
  const anyFiltersUsed = useAppSelector(selectAnyFiltersUsed);
  let filtersCount = useAppSelector(selectFiltersCount);
  const anyTimeTableEvents = data && data.timeTable && data.timeTable.length > 0;
  const anyMixedGenderEvents = anyTimeTableEvents && data && data.timeTable.some(t => !t.gender);
  const anyFilteredTimeTableEvents = filteredTimeTable && filteredTimeTable.length > 0;
  const hasMultipleDays = !!frontendSettings?.dateTo;


  useEffect(() => {
    // Problém, pokud při hlavním loadu je vyselektovaná například summary results ze session noo a již není k dispozici
    // Samo to nebude fungovat, pokud se nepovede initial load, ale aktuálně není lepší řešení
    let checkHnd:NodeJS.Timeout|null = null;

    checkHnd = setTimeout(() => {
      dispatch(checkActiveTabOnLoadAsync());
    }, 1000);

    return () => {
      if (checkHnd) {
        clearTimeout(checkHnd);
      }
    }
  }, [dispatch] /* componentDidMount */);

  useEffect(() => {
    if (data && systemInfo && !dayFilterOnAppLoadChecked) {
      if (data.days.length > 1 && systemInfo.currentDayNumber > 0) {
        if (activeDayNumberFilter !== systemInfo.currentDayNumber) {
          dispatch(setTimeTableScrollPosition(0));
          dispatch(setActiveDayNumberFilter(systemInfo.currentDayNumber));
        }
      }

      dispatch(setDayFilterOnAppLoadChecked(true));
    }
  }, [dispatch, dayFilterOnAppLoadChecked, data, systemInfo, activeDayNumberFilter]);

  useEffect(() => { // Uložit aktuální scroll position časáku
    scrollableNodeRef.current?.addEventListener('scroll',
      debounce((event: any) => {
        dispatch(setTimeTableScrollPosition(event.target.scrollTop));
      }, 500)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { // Při mountu a jak jsou data nastav scroll position časáku
    if (filteredTimeTable && timeTableScrollPosition && scrollableNodeRef.current) {
      scrollableNodeRef.current.scrollTop = timeTableScrollPosition;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInterval(() => { // Musíme to nechat vyrenderovat a hlavně průběžně kontrolovat, zda už závod začal, jinak se to nezobrazí
      setScrollToNowAvailable(!!document.getElementById('timeTableNow'));
    }, 5000);
  }, []);


  if (!hasMultipleDays && activeDayNumberFilter) { // Normálně počítáme filtr dnů jako jeden další filtr, ale u jednodenní akce nemá smysl
    filtersCount -= 1;
  }

  const handleFilteringChanged: FilteringChangedHandler = (newFilters) => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(setActiveFilters(newFilters));
    keepSelectedTimeTableEventScrollVisible();
  };

  const handleGenderFilteringChanged: GenderFilteringChangedHandler = (newFilters) => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(setActiveGenderFilters(newFilters));
    keepSelectedTimeTableEventScrollVisible();
  };

  const handleDayFilteringChanged: DayFilteringChangedHandler = (newFilteredDay) => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(setActiveDayNumberFilter(newFilteredDay));
    keepSelectedTimeTableEventScrollVisible();
  };

  const handleClearAllFilters: ClearAllFiltersHandler = () => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(clearAllFilters());
    handleScrollToNowClick();
  };

  const handleTimeTableEventSelected: TimeTableIdSelectedHandler = (newSelectedTimeTableItem) => {
    dispatch(setSelectedTimeTableIdAndCheckActiveTabAsync({
      timeTableItem: newSelectedTimeTableItem,
      hasChanged: newSelectedTimeTableItem.id !== selectedTimeTableId
    }));
  };

  const handleToggleFinalsOnlyFilter: ToggleFinalsOnlyFilterHandler = () => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(toggleFinalsOnlyFilter());
  };

  const handleToggleActiveOnlyFilter: ToggleActiveOnlyFilterHandler = () => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(toggleActiveOnlyFilter());
  };

  const handlePolyathlonFilteringChanged: PolyathlonFilteringChangedHandler = (newFilters) => {
    dispatch(setTimeTableScrollPosition(0));
    dispatch(setActivePolyathlonFilter(newFilters));
    keepSelectedTimeTableEventScrollVisible();
  };

  const handleScrollToNowClick = () => {
    const nowElement = document.getElementById('timeTableNow');

    if (scrollableNodeRef.current && nowElement) {
      scrollableNodeRef.current.scrollTop = nowElement.offsetTop - 40;
    }
  };

  const keepSelectedTimeTableEventScrollVisible = () => {
    const tmpScrollableNodeRef = scrollableNodeRef.current; // V set timeoutu je jinak null

    setTimeout(() => {
      const selectedTimeTableEventElement = document.querySelector(`#${TIME_TABLE_LIST_ELEMENT_ID} div.Mui-selected`) as any;

      if (tmpScrollableNodeRef && selectedTimeTableEventElement) {
        tmpScrollableNodeRef.scrollTop = selectedTimeTableEventElement.offsetTop - 40;
      }
    }, 150);
  };

  return (
    <div className={classes.root}>
      <div className={classes.topBorder} />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.listHeader}>
            {hasMultipleDays && <div className="col-day">Day</div>}
            <div className="col-time">Time</div>
            <div className="col-event">
              Event
              {scrollToNowAvailable && <span className="scroll-to-now">Now <i className="fas fa-arrow-down" /> <span className="click-area" onClick={handleScrollToNowClick} /></span>}
              { /* Mini loader, když už je timetable poprvé načtený */}
              {isFetching && <CircularProgress className={classes.progress} size={20} />}
            </div>
            {frontendSettings && systemInfo && <Clock isPublicInstance={frontendSettings.isPublicInstance} serverTime={systemInfo.serverTime} />}
          </div>
          <div id={TIME_TABLE_SCROLLABLE_PARENT_ID} className={classes.scrollWrapper}>
            <SimpleBar autoHide={false} scrollbarMaxSize={400} scrollbarMinSize={125} scrollableNodeProps={{ ref: scrollableNodeRef }} className={classes.simpleBar}>
              {isLoading &&
              <div className={classes.initialLoadInfo}>
                <CircularProgress size={40} />
                Waiting for time table to be loaded...
              </div>}

              {!isLoading && anyFiltersUsed && !anyFilteredTimeTableEvents &&
              <div className={classes.noEvents}>
                There are no events satisfying filter conditions
              </div>}

              {!isLoading && !anyFiltersUsed && !anyTimeTableEvents &&
              <div className={classes.noEvents}>
                There are no events in time table yet
              </div>}
              {filteredTimeTable && data && data.timeTableById &&
              <TimeTableLegacyTouchList
                legacyTouchEnabled={frontendSettings?.legacyTouchEnabled || false}
                filteredTimeTable={filteredTimeTable}
                timeTableById={data?.timeTableById}
                selectedTimeTableId={selectedTimeTableId}
                onTimeTableItemSelected={handleTimeTableEventSelected}
                hasMultipleDays={hasMultipleDays}
                eventStatesData={eventStatesData}
                serverTime={systemInfo ? systemInfo.serverTime : null} />}
            </SimpleBar>
          </div>
          {filteredTimeTable && data &&
          <TimeTableFilter
            activeFilters={activeFilters}
            activeGenderFilters={activeGenderFilters}
            finalsOnlyFilter={finalsOnlyFilter}
            activeOnlyFilter={activeOnlyFilter}
            activeDayNumberFilter={activeDayNumberFilter}
            activePolyathlonFilter={activePolyathlonFilter}
            days={data.days}
            filtersCount={filtersCount}
            anyMixedGenderEvents={anyMixedGenderEvents}
            isPolyathlonMeeting={frontendSettings?.isPolyathlonMeeting}
            polyathlonEvents={frontendSettings?.isPolyathlonMeeting ? data.timeTablePoints : null}
            onFilteringChanged={handleFilteringChanged}
            onGenderFilteringChanged={handleGenderFilteringChanged}
            onClearAllFilters={handleClearAllFilters}
            onToggleFinalsOnlyFilter={handleToggleFinalsOnlyFilter}
            onToggleActiveOnlyFilter={handleToggleActiveOnlyFilter}
            onDayFilteringChanged={handleDayFilteringChanged}
            onPolyathlonFilteringChanged={handlePolyathlonFilteringChanged}
          />}
        </CardContent>
      </Card>
    </div>
  );
}

export default TimeTable;