import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@App/store';
import { TechnicalSortType, TopListType } from '@App/types';
import { getAppUserStateFromSession, setAppUserStateToSession } from '@App/services/settings/sessionSettingsApi';

export interface IAppUserSessionState {
  intermediateTimesVisible: boolean;
  heightProgressionVisible: boolean;
  finalLapCounterFocusMode: boolean;
  followCurrentCompetitor: boolean;
  technicalSort: TechnicalSortType;
  topListType: TopListType;
  topListIsIndoor: boolean;
  topListSeasonYear: number;
  timeTableScrollPosition: number;
}

let initialState: IAppUserSessionState = {
  intermediateTimesVisible: true,
  heightProgressionVisible: true,
  finalLapCounterFocusMode: false,
  followCurrentCompetitor: true,
  technicalSort: TechnicalSortType.ByOrder,
  topListType: TopListType.Season,
  topListIsIndoor: false,
  topListSeasonYear: 0,
  timeTableScrollPosition: 0,
};

const sessionInitialState = getAppUserStateFromSession();
if (sessionInitialState) {
  initialState = {
    ...initialState,
    ...sessionInitialState,
  }
}

export const appUserStateSlice = createSlice({
  name: 'appUserState',
  initialState,
  reducers: {
    toggleIntermediateTimes: (state) => {
      state.intermediateTimesVisible = !state.intermediateTimesVisible

      setAppUserStateToSession(state);
    },
    toggleHeightProgression: (state) => {
      state.heightProgressionVisible = !state.heightProgressionVisible

      setAppUserStateToSession(state);
    },
    toggleFinalLapCounterFocusMode: (state) => {
      state.finalLapCounterFocusMode = !state.finalLapCounterFocusMode

      setAppUserStateToSession(state);
    },
    setTechnicalSort: (state, action: PayloadAction<TechnicalSortType>) => {
      state.technicalSort = action.payload;

      setAppUserStateToSession(state);
    },
    setFollowCurrentCompetitor: (state, action: PayloadAction<boolean>) => {
      state.followCurrentCompetitor = action.payload;

      setAppUserStateToSession(state);
    },
    setTopListType: (state, action: PayloadAction<TopListType>) => {
      state.topListType = action.payload;

      setAppUserStateToSession(state);
    },
    setTopListIsIndoor: (state, action: PayloadAction<boolean>) => {
      state.topListIsIndoor = action.payload;

      setAppUserStateToSession(state);
    },
    setTopListSeasonYear: (state, action: PayloadAction<number>) => {
      state.topListSeasonYear = action.payload;

      setAppUserStateToSession(state);
    },
    setTimeTableScrollPosition: (state, action: PayloadAction<number>) => {
      state.timeTableScrollPosition = action.payload;

      setAppUserStateToSession(state);
    },
  }
});

export const {
  toggleIntermediateTimes,
  toggleHeightProgression,
  toggleFinalLapCounterFocusMode,
  setTechnicalSort,
  setFollowCurrentCompetitor,
  setTopListType,
  setTopListIsIndoor,
  setTopListSeasonYear,
  setTimeTableScrollPosition,
} = appUserStateSlice.actions

export const selectIntermediateTimesVisible = (state: RootState) => state.userState.intermediateTimesVisible;
export const selectHeightProgressionVisible = (state: RootState) => state.userState.heightProgressionVisible;
export const selectFinalLapCounterModeEnabled = (state: RootState) => state.userState.finalLapCounterFocusMode;
export const selectTechnicalSort = (state: RootState) => state.userState.technicalSort;
export const selectFollowCurrentCompetitor = (state: RootState) => state.userState.followCurrentCompetitor;
export const selectTopListType = (state: RootState) => state.userState.topListType;
export const selectTopListIsIndoor = (state: RootState) => state.userState.topListIsIndoor;
export const selectTopListSeasonYear = (state: RootState) => state.userState.topListSeasonYear;
export const selectTimeTableScrollPosition = (state: RootState) => state.userState.timeTableScrollPosition;

export default appUserStateSlice.reducer;