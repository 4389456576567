import counterReducer from '../features/counter/counterSlice';
import appUserStateReducer from './appUserStateSlice';
import timeTableReducer from '../features/timeTable/timeTableSlice';
import timeTableSelectedEventReducer from '../features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import timeTablePointsSelectedEventReducer from '../features/pointsScreen/pointsScreenSlice';
import startListSliceReducer from '../features/timeTableSelectedEvent/startList/startListSlice';
import recordListSliceReducer from '../features/timeTableSelectedEvent/recordList/recordListSlice';
import resultListSliceReducer from '../features/timeTableSelectedEvent/resultList/resultListSlice';
import summaryResultListSliceReducer from '../features/timeTableSelectedEvent/summaryResultList/summaryResultListSlice';
import topListSliceReducer from '../features/timeTableSelectedEvent/topList/topListSlice';
import finalLapCounterReducer from '../features//finalLapCounterScreen/finalLapCounterSlice';
import victoryCeremonyReducer from '../features/timeTableSelectedEvent/victoryCeremony/victoryCeremonySlice';
import flashQuotesReducer from '../features/flashQuotesScreen/flashQuotesSlice';
import medalStandingsReducer from '../features/medalStandingsScreen/medalStandingsSlice';
import recordsSummaryReducer from '../features/recordsSummaryScreen/recordsSummarySlice';
import transponderIntermediateTimesReducer from '../features/timeTableSelectedEvent/transponderIntermediateTimes/transponderIntermediateTimesSlice';
import foulAssistReducer from '../features/timeTableSelectedEvent/foulAssist/foulAssistSlice';

import commonPointsReducer from '../features/pointsScreen/commonPoints/commonPointsSlice';
import polyathlonPointsReducer from '../features/pointsScreen/polyathlonPoints/polyathlonPointsSlice';

import initErrorHandlerReducer from '../features/errorHandler/init/initErrorHandlerSlice';
import pollingErrorHandlerReducer from '../features/errorHandler/polling/pollingErrorHandlerSlice';
import { frontendSettingsApi } from './services/settings/frontendSettingsApi';
import { systemInfoApi } from './services/settings/systemInfoApi';
import { eventsApi } from './services/events/eventsApi';

const storeConfig = {
  reducers: {
    counter: counterReducer,
    userState: appUserStateReducer,
    timeTable: timeTableReducer,
    timeTableSelectedEvent: timeTableSelectedEventReducer,
    timeTablePointsSelectedEvent: timeTablePointsSelectedEventReducer,
    startList: startListSliceReducer,
    recordList: recordListSliceReducer,
    resultList: resultListSliceReducer,
    summaryResultList: summaryResultListSliceReducer,
    topList: topListSliceReducer,
    finalLapCounter: finalLapCounterReducer,
    victoryCeremony: victoryCeremonyReducer,
    flashQuotes: flashQuotesReducer,
    medalStandings: medalStandingsReducer,
    recordsSummary: recordsSummaryReducer,
    transponderIntermediateTimes: transponderIntermediateTimesReducer,
    foulAssist: foulAssistReducer,

    commonPoints: commonPointsReducer,
    polyathlonPoints: polyathlonPointsReducer,
    initError: initErrorHandlerReducer,
    pollingError: pollingErrorHandlerReducer,
    [frontendSettingsApi.reducerPath]: frontendSettingsApi.reducer,
    [systemInfoApi.reducerPath]: systemInfoApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
  },
  middlewares: [
    frontendSettingsApi.middleware,
    systemInfoApi.middleware,
    eventsApi.middleware,
  ],
};

export default storeConfig;