import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',
      padding: '4px 4px',
      height: '48px',
      width: '184px',

      '& .text': {
        display: 'inline-block',
        width: '110px',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px'
      },
      '& .date': {
        display: 'flex',
        flexDirection: 'column',
        width: '110px',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',

        '& .day-of-week': {
          paddingTop: '1px',
        },
        '& .date': {
          fontWeight: 'normal',
          textTransform: 'none',
          fontSize: '12px',
        }
      },
      '& i.fas, i.far': {
        fontSize: '20px',
        lineHeight: '30px',
        width: '36px',
      },
      '& .MuiSvgIcon-root': {
        opacity: '0.1',
      },
      '& img, i.fas, i.far': {
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderRadius: '4px',
        padding: '3px',
        height: '36px',
      },
      '& .day-number-wrapper': {
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderRadius: '4px',
        padding: '3px',
        height: '36px',
        width: '36px',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '5px',

        '& .day-number': {
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '14px',
          letterSpacing: '1px',
          paddingLeft: '2px',
        },
        '& .day-number-text': {
          fontSize: '11px',
          lineHeight: '17px',
        }
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '&.selected': {
        borderColor: theme.palette.primary.light,

        '& .MuiSvgIcon-root': {
          opacity: '1',
        },
      }
    }
  }),
);

type FilterDayToggleButtonProps = {
  selected?: boolean;
  isAllDays?: boolean;
  date?: string;
  value: any;
  onChange?: (value: any) => void;
}

function FilterDayToggleButton({ isAllDays, date, value, selected, onChange }: FilterDayToggleButtonProps) {
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onChange) {
      onChange(value);
    }
  };

  const mDate = isAllDays ? null : moment(date);

  return (
    <Button variant="outlined" onClick={handleClick} className={clsx(classes.filterButton, selected && 'selected')}>
      {isAllDays &&
      <>
        <i className="far fa-calendar-alt" />
        <span className="text">All days</span>
      </>}
      {!isAllDays &&
      <>
        <span className="day-number-wrapper">
          <span className="day-number">{value}.</span>
          <span className="day-number-text">Day</span>
        </span>
        <span className="date">
          <span className="day-of-week">{mDate?.format('dddd')}</span>
          <span className="date">{mDate?.format('MMMM Do')}</span>
        </span>
      </>}
      <DoneIcon />
    </Button>
  );
}

export default FilterDayToggleButton;