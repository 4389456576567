import React, { useState, useEffect } from 'react';
import {debounce, range} from 'lodash';
import SimpleBar from 'simplebar-react';
import clsx from 'clsx';
import { useAppSelector } from '@App/hooks';
import { ITimeTableItem, IResultList, IFrontendSettings, EventState, ITimeTableItemState, TechnicalSortType } from '@App/types';
import { HI_RES_DEVICE_THRESHOLD_PX, RETIRED_MARK, FINAL_ATTEMPT_MARK, PASSES_PARTIAL_ATTEMPT_MARK, RENDER_CURRENT_ATTEMPT_TIMEOUT } from '@App/constants';

import {selectFollowCurrentCompetitor, selectTechnicalSort} from '@App/appUserStateSlice';

import { useStyles } from './VerticalJumpsTechnicalResultListTabContent.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RecordsIndicator from '../_components/RecordsIndicator';
import StartCardIndicator from '../_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass, getUnofficialPlaceCssClass, getCompetitorCount } from '@Utils/ui';

const EMPTY_PREVIOUS_ATEMPT = { attemptNumber: -1, participiantId: -1, attemptValue: null };

function shouldRenderAttemptCursor(attemptResult?: string | undefined) {
  if (!attemptResult) { // Ještě žádné výkony
    return true;
  }
  if (attemptResult === '-') { // Vynechal
    return false;
  }
  if (attemptResult.length === 3) { // Tři pokusy na dané výšce
    return false;
  }
  if (attemptResult[attemptResult.length - 1] === 'O') { // Zdařený pokus na výšce
    return false;
  }
  if (attemptResult.indexOf(RETIRED_MARK) !== -1) { // Ukončil
    return false;
  }

  return true;
}

function getAttemptCursor(attemptResult?: string | undefined) {
  if (!shouldRenderAttemptCursor(attemptResult)) {
    return null;
  }
  return (<span className="attempt-cursor" />);
}

function getActiveCompetitorsCountIndicator(allCompetitors: number, activeCompetitorsCount: number) {
  if (!allCompetitors || activeCompetitorsCount === null || activeCompetitorsCount === undefined) {
    return null;
  }
  if (activeCompetitorsCount < 0) {
    return null;
  }
  if (activeCompetitorsCount === 0) {
    return (<span className="athlete-count-active">, <span>all</span> finished</span>);
  }
  if (allCompetitors === activeCompetitorsCount) {
    return null;
  }

  return (<span className="athlete-count-active">, <span>{activeCompetitorsCount}</span> competing</span>);
}

interface IPrevAciveParticipiantAttempt {
  attemptNumber: number;
  participiantId: number | null | undefined;
  attemptValue: string | null | undefined;
}

type VerticalJumpsTechnicalResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
  scrollableNodeRef: React.Ref<any>,
}

function VerticalJumpsTechnicalResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching, scrollableNodeRef }: VerticalJumpsTechnicalResultListTabContentProps) {
  const horizontalScrollableNodeRef = React.createRef<any>();
  const [attemptsHeaderStickyOffset, setAttemptsHeaderStickyOffset] = useState<number | undefined | null>();

  const followCurrentCompetitor = useAppSelector(selectFollowCurrentCompetitor);

  const resultListDataItems = resultListData ? resultListData.technicalResultItems : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);
  const technicalSort = useAppSelector(selectTechnicalSort);
  const [renderAttempCursor, setRenderAttemptCursor] = useState(false);
  const [previousParticipiantAttempt, setPreviousParticipiantAttempt] = useState<IPrevAciveParticipiantAttempt | undefined>(EMPTY_PREVIOUS_ATEMPT);

  const showStartListData = selectedEventState?.state === EventState.Scheduled;
  const showFinalResultsData = selectedEventState?.state === EventState.Unofficial || selectedEventState?.state === EventState.Official;
  const showPointsColumn =  frontendSettings?.hasPoints &&  (frontendSettings?.isPolyathlonMeeting ? selectedEvent.isCombinedDiscipline : true);
  const currentAttemptNumber = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.currentAttemptNumber : -1;
  const currentParticipiantId = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.currentParticipiantId : -1;
  const activeCompetitorsCount = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.athletesRemainingInCompetitionCount : -1;
  const athletesRemainingInCompetition = resultListData && resultListData.technicalResultState ? (resultListData.technicalResultState.athletesRemainingInCompetition || {}) : {};
  const heightProgressions = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.heightProgression : null;

  const jumpOffAfterHeight = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.jumpOffAfterHeight : null;

  const isHiResDevice = useMediaQuery(`(min-width:${HI_RES_DEVICE_THRESHOLD_PX})`); // Když nemáme komplet zaplněný row, tak se PB/SB vlezou
  const classes = useStyles({ hasAnyQualify });

  const activeParticipiant = resultListDataItems && resultListDataItems.find(x => x.isCurrent);
  const activeParticipiantId = activeParticipiant?.participiantId;
  const activeParticipiantAttempt = currentAttemptNumber ? activeParticipiant?.attempts?.[currentAttemptNumber] : null;

  useEffect(() => {
    if (!showStartListData && !showFinalResultsData) {
      const currentRow = document.querySelector('#vertialJumpsResultTable tr.current-attempt-row') as HTMLElement;

      if (currentRow && currentRow.offsetTop > 200 && scrollableNodeRef) {
        (scrollableNodeRef as any).current.scrollTop = currentRow.offsetTop;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ showStartListData, showFinalResultsData ]);

  useEffect(() => {
    let timeoutPtr:NodeJS.Timeout | null = null;

    if (showStartListData || showFinalResultsData) {
      setPreviousParticipiantAttempt(EMPTY_PREVIOUS_ATEMPT);
      setRenderAttemptCursor(false);

      if (timeoutPtr) {
        clearTimeout(timeoutPtr);
      }
    } else {
      if (previousParticipiantAttempt?.attemptNumber === currentAttemptNumber &&
          previousParticipiantAttempt?.participiantId === activeParticipiantId &&
          previousParticipiantAttempt?.attemptValue === activeParticipiantAttempt &&
          renderAttempCursor) {
        // Do nothing, prevent setState loop
      } else {
        if (previousParticipiantAttempt?.attemptNumber === currentAttemptNumber && previousParticipiantAttempt?.participiantId === activeParticipiantId) {
          // Pokud je hodnota pokusu aktuálního závodníka stejná, tak vyrenderuj až po 15 sekundách (zřejmě dojde ke změně závodníka) po dalším výsledku (nechceme hned indikátor při změně z X -> XX)
          // Pokud je jiná, tak nerenderuj indikátor vůbec, čekáme na změnu
          // Nicméně useEffect se vyvolá jen při změně activeParticipiantAttempt, takže vlastně pokud se přechozí a aktuální nerovná, tak došlo k té zmeně a čekáme
          if (previousParticipiantAttempt.attemptValue === activeParticipiantAttempt) {
            setRenderAttemptCursor(true);
            if (timeoutPtr) {
              clearTimeout(timeoutPtr);
            }
          } else {
            // Nu ale pokud novější výkon má méně znaků, tak lze předpokládat, že v Infieldu umazali a hnedle zobrazíme indikátor
            if ((activeParticipiantAttempt?.length || 0) < (previousParticipiantAttempt?.attemptValue?.length || 0)) {
              setRenderAttemptCursor(true);

              if (timeoutPtr) {
                clearTimeout(timeoutPtr);
              }
            } else {
              if (activeParticipiantAttempt === RETIRED_MARK || activeParticipiantAttempt === FINAL_ATTEMPT_MARK || activeParticipiantAttempt?.indexOf(PASSES_PARTIAL_ATTEMPT_MARK) !== -1) {
                // Pokud je aktální výkon XXX, R, nebo O, XO, XXO tak už kurzor nemusí být
                if (timeoutPtr) {
                  clearTimeout(timeoutPtr);
                }
              } else {
                setRenderAttemptCursor(false);

                if (timeoutPtr) {
                  clearTimeout(timeoutPtr);
                }
                timeoutPtr = setTimeout(() => {
                  setRenderAttemptCursor(true);
                }, RENDER_CURRENT_ATTEMPT_TIMEOUT);
              }
            }
          }
        } else {
          // Došlo ke změně pokusu nebo závodníka, vždy pokud je platné renderuj indikátor
          setPreviousParticipiantAttempt({ attemptNumber: currentAttemptNumber, participiantId: activeParticipiantId, attemptValue: activeParticipiantAttempt });
          setRenderAttemptCursor(true);

          if (timeoutPtr) {
            clearTimeout(timeoutPtr);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStartListData, showFinalResultsData, currentAttemptNumber, activeParticipiantId, activeParticipiantAttempt, previousParticipiantAttempt]);

  useEffect(() => {
    if (!followCurrentCompetitor || !horizontalScrollableNodeRef?.current) return;

    if (showFinalResultsData) {
      horizontalScrollableNodeRef.current.scrollLeft = 10000; // U výsledku chceme asi defaultně vidět poslední pokusy
    } else {
      const attemptColumnElement = document.getElementById(`col_attempt_${currentAttemptNumber}`);
      const horizontalScrollNode = document.querySelector('#verticalJumpsAttemptsScrollContainer .simplebar-content-wrapper');

      if (attemptColumnElement && horizontalScrollNode) {
        const scrollWidth = horizontalScrollNode.getBoundingClientRect().width;

        horizontalScrollableNodeRef.current.scrollLeft = attemptColumnElement.offsetLeft - scrollWidth + 140;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followCurrentCompetitor, currentAttemptNumber]);

  useEffect(() => {
    if (!followCurrentCompetitor || !(scrollableNodeRef as any).current) return;

    if (showFinalResultsData) {
      (scrollableNodeRef as any).current.scrollTop = 0;
    } else {
      const verticalScrollNode = document.querySelector('#resultListScrollContainer .simplebar-content-wrapper');
      const currentRowElement = document.querySelector('#vertialJumpsResultTable .current-attempt-row') as HTMLElement | null;

      if (verticalScrollNode && currentRowElement) {
        const scrollHeight = verticalScrollNode.getBoundingClientRect().height;

        (scrollableNodeRef as any).current.scrollTop = currentRowElement.offsetTop - scrollHeight + 200;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followCurrentCompetitor, currentParticipiantId]);

  function handleVerticalJumpsScrollerStickyHeader() {
    // Tohle je big big hack, protože pokud máme ještě horizontální scroller, tak vůbec nefunguje position: sticky, ....
    const verticalScrollNode = document.querySelector('#resultListScrollContainer .simplebar-content-wrapper');

    if (verticalScrollNode) {
      setAttemptsHeaderStickyOffset(verticalScrollNode.scrollTop);
    } else {
      setAttemptsHeaderStickyOffset(0);
    }

    if (verticalScrollNode) {
      const horizontalScrollbarTopOffset = verticalScrollNode?.getBoundingClientRect().height + verticalScrollNode.scrollTop - 15;
      (document.querySelector(':root') as any).style.setProperty('--attempts-horizontal-scrollbar-top-offset', `${horizontalScrollbarTopOffset}px`);
    }
  }

  const debouncedHandleVerticalJumpsScrollerStickyHeader = debounce(handleVerticalJumpsScrollerStickyHeader, 25);

  useEffect(() => {
    const current = (scrollableNodeRef as any)?.current;
    if (current) {
      current.addEventListener('scroll', debouncedHandleVerticalJumpsScrollerStickyHeader);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', debouncedHandleVerticalJumpsScrollerStickyHeader);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!frontendSettings) {
    return null;
  }

  if (showStartListData) {
    return (
      <div className={classes.root}>
      <table className={clsx(classes.startListTable, 'has-startlist-data')}>
          <colgroup>
            <col style={{ width: '60px' } /* Lane */} />
            <col style={{ width: '380px' } /* Athlete */} />
            {<col style={{ width: '80px' } /* Age */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col style={{ width: '100px' } /* SB */} />
            {selectedEvent.hasQualifyBest && <col style={{ width: '90px' } /* Qualify Best */} />}
            {frontendSettings?.isInternationalMeeting && <col style={{ width: '90px' } /* WA Rank */} />}
            <col />
          </colgroup>
          <thead>
            <tr>
              {<th>Order</th>}
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                Athlete
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
              </th>
              <th>Age</th>
              <th className="text-center">PB</th>
              <th className="text-center">SB</th>
              {selectedEvent.hasQualifyBest && <th className="text-center" style={{ paddingLeft: '23px', paddingRight: '0px'}}>Qualify</th>}
              {frontendSettings?.isInternationalMeeting && <th className="text-center">WA Rank</th>}
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              {<td className="text-center col-lane">{participiant.lane}</td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && 'has-bibs')}>
                    <div className="athlete-name">
                      {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                      {(participiant.hasDNSStatus || participiant.startlistNote) && <span className="startlist-note">{participiant.hasDNSStatus ? 'DNS' : participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                    </div>
                    <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>
                  </div>
                  {frontendSettings?.displayBIBs && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              <td className="text-center col-yob">{participiant.age || '-'}<span>{participiant.yearOfBirth || ''}</span></td>
              <td className="text-center col-pb">{participiant.personalBest}</td>
              <td className="text-center col-sb">{participiant.seasonBest}</td>
              {selectedEvent.hasQualifyBest && <td className="text-center col-qualify"><span className="qualify-type">{participiant.qualifyBestType}</span>{participiant.qualifyBest}</td>}
              {frontendSettings?.isInternationalMeeting && <td className="text-center col-warank">{participiant.worldRanking}</td>}
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
    </div>
    );
  }

  return (
    <div className={classes.root}>
      <table id="vertialJumpsResultTable" className={clsx(classes.startListTable, 'single-row-attempts', 'personal-data')}>
        <colgroup>
          <col style={{ width: '65px' } /* Place */} />
          {!showFinalResultsData && <col style={{ width: '30px' } /* Order */} />}
          <col style={{ width: isHiResDevice ? '320px' : '270px' } /* Athlete */} />
          <col style={{ width: '80px' } /* Result */} />
          <col style={{ width: '30px' } /* Gap */} />
          {showPointsColumn && <col style={{ width: '40px' } /* Points */} />}
          {isHiResDevice && <col style={{ width: '100px' } /* PB */} />}
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>
              Rnk
              {!showFinalResultsData && technicalSort === TechnicalSortType.ByPlace && <span className="sort-indicator fas fa-long-arrow-alt-down" />}
            </th>
            {!showFinalResultsData && <th className="text-center" style={{ paddingLeft: 0, paddingRight: 0 }}>
              Ord
              {!showFinalResultsData && technicalSort === TechnicalSortType.ByOrder && <span className="sort-indicator fas fa-long-arrow-alt-down" />}
            </th>}
            <th className={clsx('text-left col-athlete-header', isHiResDevice ? 'hi-res' : 'low-res')} style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
              Athlete
              {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{getCompetitorCount(resultListDataItems)}</strong> total{!showFinalResultsData && !jumpOffAfterHeight && getActiveCompetitorsCountIndicator(resultListDataItems.length, activeCompetitorsCount)})</span>}
              {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
            </th>
            <th className="text-right">Result</th>
            <th className="text-right">Gap</th>
            {showPointsColumn && <th className="text-right">Pnts</th>}
          </tr>
        </thead>
        <tbody>
        {resultListData && resultListDataItems && resultListDataItems.map((participiant, idx) => (
          <tr key={participiant.participiantId} className={clsx(!showFinalResultsData && participiant.isCurrent && 'current-attempt-row')}>
            <td className={clsx('text-center', 'col-lane', selectedEvent.isFinalPhase && showFinalResultsData ? getPlaceCssClass(participiant.place) : getUnofficialPlaceCssClass(participiant.place))}>
              <span>{participiant.place}</span>
            </td>
            {!showFinalResultsData && <td className="text-center col-result-lane">
              <span className="container"><span className="lane">{participiant.lane}</span> {!showFinalResultsData && !athletesRemainingInCompetition[participiant.participiantId] && !participiant.status && <span className="inactive-indicator" />}</span>
            </td>}
            <td className="text-left col-athlete">
              <div className="athlete-flag-container">
                <div className="flag-container">
                  <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                  <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                </div>
                <div className={clsx('athlete-container', isHiResDevice && 'hi-res', frontendSettings?.displayBIBs && 'has-bibs')}>
                  <div className="athlete-name">
                    {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                    {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                    {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                  </div>
                  <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>
                </div>
                {frontendSettings?.displayBIBs && <span className="bib">{participiant.bib}</span>}
              </div>
            </td>
            <td className="text-right col-result">
              <div className="result-record-container">
                <span className={clsx('result', participiant.records?.length > 0 && 'has-records')}>
                  {participiant.status || (participiant.result === '0' ? '' : participiant.result)}
                </span>
                {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                <RecordsIndicator records={participiant.records} />
              </div>
              {participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
            </td>
            <td className="text-right col-gap">{participiant.timeGap}</td>
            {showPointsColumn && <td className="text-right col-points">{participiant.points}</td>}
          </tr>
        ))}
        {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
        <tr>
          <td colSpan={100} className="col-no-athletes">
            <div>There are no athletes in this event yet</div>
          </td>
        </tr>}
        </tbody>
      </table>
      <div id="verticalJumpsAttemptsScrollContainer" className={classes.verticalJumpsAttemptsScrollWrapper}>
        <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.horizontalSimpleBar} scrollableNodeProps={{ref: horizontalScrollableNodeRef}}>
          <table id="stickedVerticalJumpAttemptsTable" className={clsx(classes.startListTable, 'single-row-attempts')}>
            <thead>
            <tr>
              <th className="col-height-header" style={{ top: `${attemptsHeaderStickyOffset}px`}}>
                <div className="height-attempts-container">
                  {heightProgressions && heightProgressions.map((height, idx) => {
                    const isJumpOffStartAttempt = jumpOffAfterHeight === (idx + 1);

                    return (<span id={`col_attempt_${(idx + 1)}`}
                                  className={clsx('height-header', !showFinalResultsData && currentAttemptNumber === (idx + 1) && 'current-height', isJumpOffStartAttempt && 'jump-off-start-attempt')}
                                  key={`${idx}_${height}`}>{height}m{isJumpOffStartAttempt && <span className="jump-off-start-indicator">Jump-Off</span>}</span>);
                  })}
                </div>
              </th>
              {isHiResDevice && <th className="text-center">PB/SB</th>}
              <th className="no-padding"></th>
            </tr>
            </thead>
            <tbody>
            {resultListData && resultListDataItems && resultListDataItems.map((participiant, idx) => (
              <tr key={`att_${participiant.participiantId}`} className={clsx(!showFinalResultsData && participiant.isCurrent && 'current-attempt-row')}>
                <td className="col-height-attempts">
                  <div className="height-attempts-container">
                    {heightProgressions && participiant.attempts && range(1, heightProgressions.length + 1).map(attemptNumber => {
                      const isCurrentHeightAttemptCol = !showFinalResultsData && (currentAttemptNumber === attemptNumber) && participiant.attempts[attemptNumber + 1] !== RETIRED_MARK;
                      const isCurrentHeightAttempt = isCurrentHeightAttemptCol && participiant.isCurrent;
                      const isJumpOffStartAttempt = jumpOffAfterHeight === attemptNumber;

                      const attemptResult = participiant.attempts[attemptNumber];
                      const isFinalAttempt = !athletesRemainingInCompetition[participiant.participiantId] && !participiant.attempts[attemptNumber + 1];

                      return (
                        <span className={clsx('height-attempt', isCurrentHeightAttempt && 'current-height', isCurrentHeightAttemptCol && 'current-height-col', !showFinalResultsData && isFinalAttempt && 'final-attempt', isJumpOffStartAttempt && 'jump-off-start-attempt')}
                            key={`${participiant.participiantId}_${attemptNumber}`}>
                              <span className="attempt-result">{attemptResult}</span> {renderAttempCursor && !isFinalAttempt && isCurrentHeightAttempt && getAttemptCursor(attemptResult)}
                        </span>
                      );
                    })}
                    {heightProgressions && !participiant.attempts && range(1, heightProgressions.length + 1).map(attemptNumber => {
                      const isCurrentHeightAttemptCol = !showFinalResultsData && (currentAttemptNumber === attemptNumber);
                      const isCurrentHeightAttempt = isCurrentHeightAttemptCol && participiant.isCurrent;
                      const isJumpOffStartAttempt = jumpOffAfterHeight === attemptNumber;

                      // Tady ještě vůbec nemáme v DB daný řádek, ale chceme předrenderovat pokus je vybraný
                      return (
                        <span className={clsx('height-attempt', isCurrentHeightAttempt && 'current-height', isCurrentHeightAttemptCol && 'current-height-col', isJumpOffStartAttempt && 'jump-off-start-attempt')}
                            key={`${participiant.participiantId}_${attemptNumber}`}>
                              {renderAttempCursor && isCurrentHeightAttempt && getAttemptCursor()}
                        </span>
                      );
                    })}
                  </div>
                </td>
                {isHiResDevice && <td className="text-center col-result-pb-sb">
                  <div className="result-pb">{participiant.personalBest}</div>
                  <div className="result-sb">{participiant.seasonBest}</div>
                </td>}
                <td className="no-padding"></td>
              </tr>
            ))}
            </tbody>
          </table>
        </SimpleBar>
      </div>
    </div>
  );
}

export default VerticalJumpsTechnicalResultListTabContent;
