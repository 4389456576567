import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useAppSelector, useAppDispatch } from '@App/hooks';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  getFinalLapCounterDataAsync,
} from './finalLapCounterSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './FinalLapCounter.styles';

import { Grid } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import PollingErrorHandler from '@Features/errorHandler/polling/PollingErrorHandler';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import { getFlagOrClubImage, getCountryFlagImage } from '@Utils/ui';

type PollTimeoutTickHandler = () => PollingPromise;

function FlashQuotesScreen() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const finalLapListData = useAppSelector(selectData);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getFinalLapCounterDataAsync(''));
  };

  return (
    <div className={classes.root}>
      <Helmet title="CIS - Final lap counter" />
      <Grid container spacing={0} className="main-screen-grid">
        <Grid item xs={12} className={classes.spacerGrid}>
          <div className={classes.topBorder} />
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {frontendSettings &&
                <PollingContainer
                  interval={frontendSettings.resultsRefreshInterval}
                  onPollTimeoutTick={handlePollTimeoutTick}
                />}
              {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
              <div id="flashQuotesScrollContainer" className={classes.scrollContainer}>
                <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
                  <LegacyTouchWrapper>
                  <table className={classes.finalLapCounterTable}>
                      <colgroup>
                        <col style={{ width: '650px' }} />
                        <col style={{ width: '60px' }} />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                            Name
                            {<span className="bib">Bib</span>}
                          </th>
                          <th className="text-right" />
                          <th className="text-right" />
                        </tr>
                      </thead>
                      <tbody>
                      {finalLapListData && finalLapListData.map(participiant => (
                        <tr key={participiant.participiantId}>
                          <td className="text-left col-athlete">
                            <div className="athlete-flag-container">
                              <div className="flag-container">
                                <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                                <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                              </div>
                              <div className="athlete-container has-bibs">
                                <div className="athlete-name">
                                  {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                                </div>
                                <div className="club-name"><strong>{participiant.club}</strong> - {participiant.clubFull}</div>
                              </div>
                              <span className="bib">{participiant.bib}</span>
                            </div>
                          </td>
                          <td className="col-final-indicator">
                            <div className="wrapper">
                              <div>Final</div>
                              <div>lap</div>
                            </div>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      ))}
                      {!error && finalLapListData && finalLapListData.length === 0 &&
                      <tr>
                        <td colSpan={100} className="col-no-athletes">
                          <div>There are no athletes <br />for final lap yet</div>
                        </td>
                      </tr>}
                      </tbody>
                    </table>
                  </LegacyTouchWrapper>
                </SimpleBar>
              </div>
              {/* <FocusModeToggle /> */}
              <PollingErrorHandler errorCountThreshold={3} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default FlashQuotesScreen;