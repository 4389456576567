import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppSelector, useAppDispatch } from '@App/hooks';

import { clearPollingErrors } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';
import PollingErrorHandler from '@Features/errorHandler/polling/PollingErrorHandler';

import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';
import { useGetEventsPointsQuery, useGetEventsStatesPointsQuery } from '@App/services/events/eventsApi';
import { setActiveTab, selectActiveTab } from './pointsScreenSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CircularProgress, Card, CardContent, Tabs, Tab } from '@material-ui/core';

import TabPanel from './TabPanel';
import TabHeader from './TabHeader';
import PointsTabContent from './PointsTabContent';
import PointListError from './PointListError';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    spacerGrid: {
      height: '100%',
      padding: theme.spacing(1),
    },
    card: {
      height: 'calc(100% - 3px)',
      borderRadius: '0 0 4px 4px',
      marginTop: '0',
      background: 'transparent',
    },
    cardContent: {
      height: '100%',
      position: 'relative',
      padding: '0 !important',
    },
    progress: {
      position: 'absolute',
      right: '5px',
      top: '5px',
    },
    initialLoadInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiCircularProgress-root': {
        marginTop: '30px',
        marginBottom: '15px'
      }
    },
    noEvents: {
      textAlign: 'center',
      padding: '30px 0',
      fontSize: '16px',
    },
    tabs: {
      minHeight: '40px',

      '& .MuiTab-textColorPrimary': {
        color: '#fff',
        fontWeight: 'bold',
        letterSpacing: '1px',
      },
      '& .MuiTabs-indicator': {
        bottom: 'auto',
        top: '0',
        background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      },
      '& .MuiButtonBase-root': {
        paddingTop: '2px',
        paddingBottom: '0',
        minHeight: '50px',
        lineHeight: '21px',
        // borderRight: '1px solid #4a4d50',
        marginRight: '2px',
        background: theme.palette.tabs.tabStripBackground,
      },
      '& .MuiButtonBase-root:first-child': {
        borderTopLeftRadius: '4px',
      },
      '& .MuiButtonBase-root:last-child': {
        borderRight: '0',
        borderTopRightRadius: '4px',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        background: theme.palette.records.lighter,
      },
      '& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons': {
        background: theme.palette.primary.main,
      }
    },
    tabsPanelWrapper: {
      position: 'relative',
      background: theme.palette.background.paper,
      height: 'calc(100vh - 123px)',

      '& > div': { // To je první div v contentu tabu
        height: '100%',
      },
      '& > div > div': {
        height: '100%',
      },
    },
  }),
);

type ActiveTabChangedHandler = (event: React.ChangeEvent<{}>, newTab: number) => void;

function PointsScreen() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const { data, isLoading, isFetching, error: eventsPointsError } = useGetEventsPointsQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: frontendSettings?.timeTableRefreshInterval,
  });

  const { data: eventStatesData } = useGetEventsStatesPointsQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: frontendSettings?.statesRefreshInterval,
  });

  const handleActiveTabChange: ActiveTabChangedHandler = (event: React.ChangeEvent<{}>, newTab: number) => {
    dispatch(clearPollingErrors());
    dispatch(setActiveTab({ activeTab: newTab, changed: newTab !== activeTab}));
  };

  const activeTab = useAppSelector(selectActiveTab);
  const anyTimeTableEvents = data && data.timeTable && data.timeTable.length > 0;

  useEffect(() => {
    if (activeTab) {
      // Je třeba zkontrolovat, zda je vyselektovaný tab ještě v časáku
      if (anyTimeTableEvents && data) {
        if (!data.timeTableById[activeTab]) {
          dispatch(setActiveTab({ activeTab: data.timeTable[0].id, changed: true }));
        }
        // ... je v časáku
      } else {
        // dispatch(setActiveTab(null)); ... tohle ne, jinak se při F5 přepíše prvním tabem
      }
    } else  {
      if (anyTimeTableEvents && data) {
        dispatch(setActiveTab({ activeTab: data.timeTable[0].id, changed: true }));
      }
    }
  }, [dispatch, activeTab, anyTimeTableEvents, data]);

  return (
    <div className={classes.root}>

      <Helmet title="CIS - Points" />
      <Grid container spacing={0} className="main-screen-grid">
        <Grid item xs={12} className={classes.spacerGrid}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {isFetching && <CircularProgress className={classes.progress} size={20} />}

              {isLoading &&
              <div className={classes.initialLoadInfo}>
                <CircularProgress size={40} />
                Waiting for point list to be loaded...
              </div>}

              {!eventsPointsError && !isLoading && !anyTimeTableEvents &&
              <div className={classes.noEvents}>
                There are no points events in time table yet
              </div>}

              {anyTimeTableEvents && data && activeTab &&
              <>
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={data.timeTable.length > 4 ? 'scrollable' : 'fullWidth'}
                  scrollButtons="auto"
                  onChange={handleActiveTabChange}
                  className={classes.tabs}
                >
                  {data.timeTable.map(event => (<Tab key={event.id} value={event.id} label={<TabHeader pointsEvent={event} pointsEventState={eventStatesData ? eventStatesData[event.id] : null} />} />))}
                </Tabs>
                <div className={classes.tabsPanelWrapper}>
                  {!eventsPointsError && <PollingErrorHandler errorCountThreshold={3} />}
                  {data.timeTable.map(event => (
                    <TabPanel key={event.id} value={activeTab} timeTableId={event.id}>
                      <PointsTabContent pointsEvent={event} pointsEventState={eventStatesData ? eventStatesData[event.id] : null} />
                    </TabPanel>)
                  )}
                </div>
              </>}
              {eventsPointsError && <PointListError error={eventsPointsError} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default PointsScreen;