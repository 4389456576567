import React, { useState } from 'react';
import { ITimeTableDay, TimeTableFilters, TimeTableGenderFilters, ITimeTableItemPoints } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Drawer, Button, Typography, Badge, Select, MenuItem, ListSubheader } from '@material-ui/core';

import FilterToggleButtonGroup from './FilterToggleButtonGroup';
import FilterToggleButton, { IconType } from './FilterToggleButton';
import FilterDayToggleButton from './FilterDayToggleButton';
import ClearFiltersButton from './ClearFiltersButton';
import clsx from 'clsx';
import { groupBy } from 'lodash';

function getFilterBadgeCount(filterCount: number): string | null {
  if (!filterCount) {
    return null;
  }
  if (filterCount === 1) {
    return '1 Filter';
  }
  return `${filterCount} Filters`;
}

const ExclusiveFilterValues = [ TimeTableFilters.AllTrackEvents, TimeTableFilters.AllFieldEvents ];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    topBorder: {
      display: 'block',
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
      width: '100%',
    },
    filtersButtonWrapper: {
      padding: '8px 8px 0 8px',
    },
    filtersBadge: {
      width: '100%',
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: '4px',
        right: '27px',
        fontSize: '13px',
        letterSpacing: '1px',
        padding: '0 8px',
      },
    },
    filtersButton: {
      display: 'block',
      fontWeight: 'bold',
      width: '100%',
      letterSpacing: '1px',
      fontSize: '15px',
      padding: '4px 8px',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '& i': {
        paddingRight: '8px',
      }
    },
    drawer: {
      '& .MuiPaper-root': {
        alignItems: 'flex-end',
      }
    },
    drawerHeader: {
      display: 'flex',
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      width: '100%',
      padding: '2px 8px',
      flexDirection: 'column',
      letterSpacing: '1px',
    },
    drawerTitle: {
      textTransform: 'uppercase',
      color: theme.palette.secondary.dark,
      fontFamily: 'WorldAthleticsHeadline',
      fontSize: '16px',
      alignSelf: 'flex-end'
    },
    toggleButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      border: '2px solid rgba(255, 255, 255, 0.10)',

      '&.Mui-selected': {
        backgroundColor: '#5a1a13',
        borderColor: theme.palette.gradient.third
      },
      '& .MuiToggleButton-label': {
        color: '#fff'
      },
    },
    filterSections: {
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: '6px 8px 20px 8px',

      '& .section': {
        paddingRight: '35px',
      },
      '& .section.no-label': {
        paddingTop: '36px',
      },
      '& .MuiTypography-overline': {
        fontSize: '0.85rem',
      },
      '& .simple-button-group': {
        display: 'flex',
        flexDirection: 'column',

        '& button': {
          marginBottom: '4px',
        },
        '& button:last-child': {
          marginBottom: '0',
        }
      }
    },
    polyathlonSelectWrapper: {
      position: 'relative',
      marginBottom: '57px',

      '& .polyathlon-event-name': {
        position: 'absolute',
        userSelect: 'none',
        top: '22px',
        left: '14px',
        cursor: 'pointer',
      }
    },
    polyathlonSelect: {
      border: '2px solid rgba(255, 255, 255, 0.10)',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      borderRadius: '4px',
      height: '48px',
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
      width: '100%',

      '& .MuiSelect-selectMenu.MuiInputBase-input': {
        paddingLeft: '12px',
        paddingBottom: '15px',
        paddingTop: '15px',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& .MuiSelect-icon': {
        color: 'rgba(255, 255, 255, 0.20)',
        right: '6px'
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '&.selected': {
        borderColor: theme.palette.primary.light,

        '& .MuiSelect-selectMenu.MuiInputBase-input': {
          paddingLeft: '12px',
          paddingTop: '0px',
          paddingBottom: '22px',
          marginTop: '4px',
        },
        '& .MuiSelect-icon': {
          color: '#fff',
        },
      }
    }
  }),
);

function mapPolyathlonEventSelect(polyathlonEvents: ITimeTableItemPoints[] | null) {
  const groupedEvents = groupBy(polyathlonEvents, 'eventName');
  const mappedEvents = [];

  for (const polyEvent in groupedEvents) {
    const events = groupedEvents[polyEvent];

    mappedEvents.push(<ListSubheader key={polyEvent}>{polyEvent}</ListSubheader>)

    for (const event of events) {
      mappedEvents.push(<MenuItem key={event.id} value={event.id}>{event.eventCategoryFull}</MenuItem>)
    }
  }

  return mappedEvents;
}

type TimeTableFilterProps = {
  activeFilters: TimeTableFilters[] | null,
  activeGenderFilters: TimeTableGenderFilters | null,
  finalsOnlyFilter: boolean,
  activeOnlyFilter: boolean;
  activeDayNumberFilter: number | null,
  activePolyathlonFilter: ITimeTableItemPoints | null,
  days: ITimeTableDay[],
  filtersCount: number,
  anyMixedGenderEvents: boolean | undefined;
  isPolyathlonMeeting: boolean | undefined;
  polyathlonEvents: ITimeTableItemPoints[] | null;
  onFilteringChanged: (newFilters: TimeTableFilters[] | null) => void;
  onGenderFilteringChanged: (newFilters: TimeTableGenderFilters | null) => void;
  onToggleFinalsOnlyFilter: () => void;
  onToggleActiveOnlyFilter: () => void;
  onClearAllFilters: () => void;
  onDayFilteringChanged: (newFilters: number | null) => void;
  onPolyathlonFilteringChanged: (newFilter: ITimeTableItemPoints | null) => void;
};

function TimeTableFilter({
  activeFilters, activeGenderFilters, finalsOnlyFilter, activeOnlyFilter, activeDayNumberFilter, activePolyathlonFilter, days, filtersCount, anyMixedGenderEvents, isPolyathlonMeeting, polyathlonEvents,
  onFilteringChanged, onGenderFilteringChanged, onClearAllFilters, onDayFilteringChanged, onToggleFinalsOnlyFilter, onToggleActiveOnlyFilter, onPolyathlonFilteringChanged }: TimeTableFilterProps) {
  const classes = useStyles();
  const [filterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [polyathlonEventsOpen, setPolyathlonEventsOpen] = React.useState(false);

  const handleFilteringChanged = (newFilters: TimeTableFilters[] | null) => {
    onFilteringChanged(newFilters);
  };

  const handleGenderFilteringChanged = (newFilters: TimeTableGenderFilters | null) => {
    onGenderFilteringChanged(newFilters);
  };

  const handleDaysFilteringChanged = (newFilters: number | null) => {
    onDayFilteringChanged(newFilters);
  };

  const handlePolyathlonFilteringChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    let eventId: number | null = event.target.value as number;
    if (eventId === 0) {
      eventId = null;
    }

    if (!polyathlonEvents || !eventId) {
      onPolyathlonFilteringChanged(null);
    } else {
      onPolyathlonFilteringChanged(polyathlonEvents.find(evt => evt.id === eventId) || null);
    }
  };

  const handleFiltersButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsFilterDrawerOpen(true);
  };

  const handleDrawerClosed = () => {
    setIsFilterDrawerOpen(false);
  }

  const handlePolyathlonEventsClose = () => {
    setPolyathlonEventsOpen(false);
  };

  const handlePolyathlonEventsOpen = () => {
    setPolyathlonEventsOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.topBorder} />
      <div className={classes.filtersButtonWrapper}>
        <Badge badgeContent={getFilterBadgeCount(filtersCount)} color="primary" className={classes.filtersBadge}>
          <Button onClick={handleFiltersButtonClick} className={classes.filtersButton}>
            <i className="fas fa-filter" /> Filter timetable
          </Button>
        </Badge>
      </div>

      <Drawer anchor="bottom" open={filterDrawerOpen} onClose={handleDrawerClosed} className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div className={classes.drawerTitle}>
            Filter timetable
          </div>
        </div>
        <div className={classes.filterSections}>
          <div className="section no-label">
            <ClearFiltersButton onClick={onClearAllFilters}  />
          </div>
          <div className={clsx('section simple-button-group', !isPolyathlonMeeting && 'no-label')}>
            {isPolyathlonMeeting && polyathlonEvents && polyathlonEvents.length > 0 && <>
              <Typography variant="overline" display="block">
                Combined events
              </Typography>
              <div className={classes.polyathlonSelectWrapper}>
                <Select
                  open={polyathlonEventsOpen}
                  onClose={handlePolyathlonEventsClose}
                  onOpen={handlePolyathlonEventsOpen}
                  onChange={handlePolyathlonFilteringChanged}
                  value={activePolyathlonFilter?.id || 0}
                  className={clsx(classes.polyathlonSelect, activePolyathlonFilter?.id && 'selected')}>
                  <MenuItem value={0}>All events</MenuItem>
                  {mapPolyathlonEventSelect(polyathlonEvents)}
                </Select>
                {activePolyathlonFilter && <span className="polyathlon-event-name" onClick={handlePolyathlonEventsOpen}>{activePolyathlonFilter.eventName}</span>}
              </div>
            </>}
            <FilterToggleButton
                iconName="fa-medal"
                iconType={IconType.FontAwesome}
                iconColor="#FFC540"
                text="Final events only"
                value={true}
                onChange={onToggleFinalsOnlyFilter}
                selected={finalsOnlyFilter}
                addedWidth={20}
              />
            <FilterToggleButton
                iconName="fa-history"
                iconType={IconType.FontAwesome}
                iconColor="rgb(33, 150, 243)"
                text="Active events only"
                value={true}
                onChange={onToggleActiveOnlyFilter}
                selected={activeOnlyFilter}
                addedWidth={20}
              />
          </div>
          {days && days.length > 1 &&
          <div className="section">
            <Typography variant="overline" display="block">
              Days
            </Typography>
            <FilterToggleButtonGroup value={activeDayNumberFilter} onChange={handleDaysFilteringChanged} exclusive>
              <FilterDayToggleButton
                  isAllDays
                  value={null}
                />
              {days.map(day => (
                <FilterDayToggleButton
                  key={day.date}
                  date={day.date}
                  value={day.number}
                />
              ))}
            </FilterToggleButtonGroup>
          </div>}
          <div className="section">
            <Typography variant="overline" display="block">
              Gender
            </Typography>
            <FilterToggleButtonGroup value={activeGenderFilters} onChange={handleGenderFilteringChanged} exclusive>
              <FilterToggleButton
                iconName="fa-venus"
                iconType={IconType.FontAwesome}
                text="Female"
                value={TimeTableGenderFilters.Female}
              />
              <FilterToggleButton
                iconName="fa-mars"
                iconType={IconType.FontAwesome}
                text="Male"
                value={TimeTableGenderFilters.Male}
              />
              {anyMixedGenderEvents &&
              <FilterToggleButton
                iconName="fa-venus-mars"
                iconType={IconType.FontAwesome}
                text="Mixed"
                value={TimeTableGenderFilters.Mixed}
              />}
            </FilterToggleButtonGroup>
          </div>
          <div className="section">
            <Typography variant="overline" display="block">
              Field events
            </Typography>
            <FilterToggleButtonGroup value={activeFilters} onChange={handleFilteringChanged} exclusiveValues={ExclusiveFilterValues}>
            <FilterToggleButton
                iconName="Field"
                iconType={IconType.Image}
                text="All field events"
                value={TimeTableFilters.AllFieldEvents}
              />
              <FilterToggleButton
                iconName="PoleValut"
                iconType={IconType.Image}
                text="Vetical jumps"
                value={TimeTableFilters.VerticalJumps}
              />
              <FilterToggleButton
                iconName="LongJump"
                iconType={IconType.Image}
                text="Horizontal jumps"
                value={TimeTableFilters.HorizontalJumps}
              />
              <FilterToggleButton
                iconName="Javelin"
                iconType={IconType.Image}
                text="Throws"
                value={TimeTableFilters.Throws}
              />
            </FilterToggleButtonGroup>
          </div>
          <div className="section">
            <Typography variant="overline" display="block">
              Track events
            </Typography>
            <FilterToggleButtonGroup value={activeFilters} onChange={handleFilteringChanged} exclusiveValues={ExclusiveFilterValues}>
              <FilterToggleButton
                iconName="Track"
                iconType={IconType.Image}
                text="All track events"
                value={TimeTableFilters.AllTrackEvents}
              />
              <FilterToggleButton
                iconName="Sprint"
                iconType={IconType.Image}
                text="Sprints"
                value={TimeTableFilters.Sprints}
              />
              <FilterToggleButton
                iconName="Run800M"
                iconType={IconType.Image}
                text="Middle distance"
                value={TimeTableFilters.MiddleDistance}
              />
              <FilterToggleButton
                iconName="LongRun"
                iconType={IconType.Image}
                text="Long distance"
                value={TimeTableFilters.LongDistance}
              />
              <FilterToggleButton
                iconName="Relays"
                iconType={IconType.Image}
                text="Relays"
                value={TimeTableFilters.Relays}
              />
            </FilterToggleButtonGroup>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default TimeTableFilter;
