import StartCardIndicator from "@Features/timeTableSelectedEvent/resultList/_components/StartCardIndicator";
import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem } from '@App/types';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectHeightProgressions,
  selectChangeTimestamp,
  getStartListDataAsync,
} from './startListSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './StartListTabContent.styles';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';
import { DisciplineType, isTechnicalDisciplineClass } from '@App/disciplines';

import { getFlagOrClubImage, getCountryFlagImage } from '@Utils/ui';
import HeightProgression from './../resultList/runs/_components/HeightProgression';

type StartListTabContentProps = {
  selectedEvent: ITimeTableItem,
}

type PollTimeoutTickHandler = () => PollingPromise;

function StartListTabContent({ selectedEvent }: StartListTabContentProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const startListData = useAppSelector(selectData);
  const heightProgressions = useAppSelector(selectHeightProgressions);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);
  const isTechnicalDiscipline = isTechnicalDisciplineClass(selectedEvent.disciplineClass);
  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getStartListDataAsync(selectedEvent.id));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.refreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="startListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          <table className={classes.startListTable}>
            <colgroup>
              <col style={{ width: '60px' }} />
              <col style={{ width: isRelays ? '560px' : '380px' }} />
              {!isRelays && <col style={{ width: '80px' }} />}
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              {selectedEvent.hasQualifyBest && <col style={{ width: '90px' }} />}
              {frontendSettings?.isInternationalMeeting && <col style={{ width: '90px' }} />}
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>{isTechnicalDiscipline ? 'Order' : 'Lane'}</th>
                <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                  {isRelays ? 'Relay' : 'Athlete'}
                  {startListData && startListData.length > 0 && <span className="athlete-count">({startListData.length} total)</span>}
                  {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
                </th>
                {!isRelays && <th>Age</th>}
                <th className="text-center">PB</th>
                <th className="text-center">SB</th>
                {selectedEvent.hasQualifyBest && <th className="text-center" style={{ paddingLeft: '23px', paddingRight: '0px'}}>Qualify</th>}
                {frontendSettings?.isInternationalMeeting && <th className="text-center">WA Rank</th>}
                <th className="text-right" />
              </tr>
            </thead>
            <tbody>
            {startListData && startListData.map(participiant => (
              <tr key={participiant.participiantId}>
                <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays')}>{participiant.lane}</td>
                <td className="text-left col-athlete">
                  <div className="athlete-flag-container">
                    <div className="flag-container">
                      <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                      <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                    </div>
                    <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                      <div className="athlete-name">
                        {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                        {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                        {(participiant.hasDNSStatus || participiant.startlistNote) && <span className={clsx('startlist-note', (participiant.startlistNote === 'PACE' || participiant.startlistNote === 'PC') && 'note-pace')}>{participiant.hasDNSStatus ? 'DNS' : participiant.startlistNote}</span>}
                        {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                        {isRelays && participiant.relayMembers &&
                        <ul className="relay-members">
                          {participiant.relayMembers.map(member => (
                            <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                          ))}
                        </ul>}
                      </div>
                      {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                    </div>
                    {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                  </div>
                </td>
                {!isRelays && <td className="text-center col-yob">{participiant.age || '-'}<span>{participiant.yearOfBirth || ''}</span></td>}
                <td className="text-center col-pb">{participiant.personalBest}</td>
                <td className="text-center col-sb">{participiant.seasonBest}</td>
                {selectedEvent.hasQualifyBest && <td className="text-center col-qualify"><span className="qualify-type">{participiant.qualifyBestType}</span>{participiant.qualifyBest}</td>}
                {frontendSettings?.isInternationalMeeting && <td className="text-center col-warank">{participiant.worldRanking}</td>}
                <td>&nbsp;</td>
              </tr>
            ))}
            {!error && startListData && startListData.length === 0 && !isFetching &&
            <tr>
              <td colSpan={100} className="col-no-athletes">
                <div>There are no athletes in this event yet</div>
              </td>
            </tr>}
            </tbody>
          </table>
        </LegacyTouchWrapper>
      </SimpleBar>
      { heightProgressions && heightProgressions.length > 0 && <HeightProgression heightProgression={heightProgressions} />}
      </div>
    </div>
  );
}

export default StartListTabContent;
