import { IVictoryCeremonyList, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type VictoryCeremonyApiResponse = {
  data: IVictoryCeremonyList,
  eventState: ITimeTableItemState,
}

export function fetchVictoryCeremonyData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/victory-ceremony/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<VictoryCeremonyApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get Victory ceremony data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchVictoryCeremonyData
};

export default defaultExport;
