import { eventsApi } from '@App/services/events/eventsApi';
import foulAssistApi from '@App/services/foulAssist/foulAssistApi';
import { RootState } from '@App/store';
import { IFoulAssistItem, TimeTableEventTabType } from '@App/types';
import {
  pollingFailed,
  pollingSucceeded,
  pollingErrorHandlerSlice,
} from '@Features/errorHandler/polling/pollingErrorHandlerSlice';
import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setActiveTab } from '../timeTableSelectedEventSlice';

export interface IFoulAssistStore {
  loading: boolean;
  loaded: boolean;
  error: any;
  attempts: IFoulAssistItem[];
  changeTimestamp: number;
}

const initialState: IFoulAssistStore = {
  loading: false,
  loaded: false,
  error: null,
  attempts: [],
  changeTimestamp: 0,
};

export const getFoulAssistDataAsync = createAsyncThunk('foulAssist/fetchData',
  async (timeTableId: number, { dispatch, rejectWithValue, signal }) => {
    try {
      const response = await foulAssistApi.fetchFoulAssistData(timeTableId, signal);
      dispatch(pollingSucceeded());
      return response;
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'FoulAssist', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const foulAssistSlice = createSlice({
  name: 'foulAssistSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFoulAssistDataAsync.pending, state => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getFoulAssistDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.attempts = action.payload;
      })
      .addCase(getFoulAssistDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.loading = false;
          state.loaded = false;
          state.error = action.payload;
        }
      })
      .addCase(setActiveTab, (state, action) => { // Clear state
        if (action.payload === TimeTableEventTabType.FoulAssist) {
          state.changeTimestamp = Date.now();
        } else {
          Object.assign(state, initialState);
        }
      })
      .addCase(setSelectedTimeTableId, (state, action) => {
        if (action.payload.hasChanged) {
          Object.assign(state, initialState);
        } else {
          state.changeTimestamp = Date.now();
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const makeSelectLoading = (state: RootState) => state.foulAssist.loading;
export const makeSelectLoaded = (state: RootState) => state.foulAssist.loaded;
export const makeSelectError = (state: RootState) => state.foulAssist.error;
export const makeSelectAttempts = (state: RootState) => state.foulAssist.attempts;
export const makeSelectChangeTimestamp = (state: RootState) => state.foulAssist.changeTimestamp;

export default foulAssistSlice.reducer;
