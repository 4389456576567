import React from 'react';
import clsx from 'clsx';
import { IPolyathlonDiscipline, EventState } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getDisciplineIcon } from '@Utils/ui';

import EventStateIcon from '@Components/eventStateIcon';

type DisciplinesHeaderProps = {
  disciplines: IPolyathlonDiscipline[],
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      height: '71px',
    },
    disciplineList: {
      listStyleType: 'none',
      display: 'flex',
      margin: 0,
      padding: 0,
      height: '100%',

      '& li': {
        width: '80px',
        whiteSpace: 'normal',
        textTransform: 'none',
        fontSize: '11px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',

        '&.not-official': {
          opacity: 0.5,
        },
        '& .state-icon': {
          position: 'absolute',
          top: '4px',
          right: '5px',
        },
      }
    },
    disciplineName: {
      marginTop: '2px',
    },
    disciplineIcon: {
      alignSelf: 'center',
    },
  }),
);


function DisciplinesHeader({ disciplines }: DisciplinesHeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ul className={classes.disciplineList}>
        {disciplines.map(disc => {
          const disciplineIcon = getDisciplineIcon(disc.disciplineType);

          return (
            <li key={disc.disciplineId} className={clsx(!disc.isOfficial && 'not-official')}>
              {disc.isOfficial && <EventStateIcon eventState={EventState.Official} />}
              <img src={`${process.env.PUBLIC_URL}/disciplines/${disciplineIcon}.png`} width={30} height={30} alt="Discipline icon" className={classes.disciplineIcon} />
              <div className={classes.disciplineName}>{disc.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DisciplinesHeader;