import React from 'react';
import { EventState } from '@App/types';
import theme from '@App/theme';

function getStateIcon(eventState: EventState, isStartlistOfficial?: boolean) {
  switch (eventState) {
    case EventState.InProgress:
      return 'fas fa-history';
    case EventState.Judging:
      return 'fas fa-video';
    case EventState.Official:
      return 'fa fa-check-circle';
    case EventState.Scheduled:
      if (isStartlistOfficial) {
        return 'far fa-calendar-check';
      }
      return 'far fa-calendar-alt';
    case EventState.Unofficial:
      return 'fas fa-check';
    default:
      return null;
  }
}

export function getStateColor(eventState: EventState, isStartlistOfficial?: boolean) {
  switch (eventState) {
    case EventState.InProgress:
      return theme.palette.info.main;
    case EventState.Judging:
      return theme.palette.warning.main;
    case EventState.Official:
      return theme.palette.success.main;
    case EventState.Scheduled:
      return theme.palette.text.primary;
    case EventState.Unofficial:
      return theme.palette.warning.main;
    default:
      return '#fff';
  }
}

type EventStateIconProps = {
  eventState: EventState,
  isStartlistOfficial?: boolean,
}

function EventStateIcon({ eventState, isStartlistOfficial }: EventStateIconProps) {
  const icon = getStateIcon(eventState, isStartlistOfficial);
  const color = getStateColor(eventState, isStartlistOfficial);

  if (!icon) {
    return null;
  }
  return (
    <span className="state-icon">
      <i className={icon} style={{ color }}></i>
    </span>
  )
}

export default EventStateIcon;

