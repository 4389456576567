import {getStartCardImage} from "@Utils/ui";
import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StartCardType } from '@App/types';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      width: '9px',
      height: '16px',
      display: 'inline-block',
      borderRadius: '2px',
      marginLeft: '9px',
      marginBottom: '-3px',
      transform: 'rotate(10deg)',
    }
  }),
);

type StartCardIndicatorProps = {
  startCard: string,
}

function StartCardIndicator({ startCard }: StartCardIndicatorProps) {
  const classes = useStyles();

  if (!startCard || startCard === StartCardType.None) {
    return null;
  }

  return (
    <span className={clsx(classes.root, `card-${startCard}`)}>
      <img src={getStartCardImage(startCard)} width="9" height="16" alt={startCard} />
    </span>
  )
}

export default StartCardIndicator;