import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem } from '@App/types';
import moment from 'moment';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getTopListDataAsync,
} from './topListSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './TopListTabContent.styles';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';
import TopListFilters from './_components/TopListFilters';

import { getCountryFlagImage, getFlagOrClubImage } from '@Utils/ui';

type TopListTabContentProps = {
  selectedEvent: ITimeTableItem,
}

type PollTimeoutTickHandler = () => PollingPromise;

function TopListTabContent({ selectedEvent }: TopListTabContentProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const topListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting: frontendSettings?.isInternationalMeeting, currentSeasonYear: frontendSettings?.seasonYear }));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.refreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="topListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          <table className={classes.startListTable}>
            <colgroup>
              <col style={{ width: '60px' }} />
              <col style={{ width: '1%', minWidth: '350px' }} />
              <col style={{ width: '110px' } /* Result */} />
              {!frontendSettings?.isInternationalMeeting && <col style={{ width: '70px' } /* Pos */} />}
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-left">
                  Athlete
                </th>
                <th className="text-right">Result</th>
                {!frontendSettings?.isInternationalMeeting && <th className="text-center">Pos</th>}
                <th className="text-left">Venue / Date</th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>
            {topListData && topListData.map((topListItem, idx) => (
              <tr key={`${topListItem.id}`}>
                <td className={clsx('text-center', 'col-type')}>
                  {frontendSettings?.isInternationalMeeting ? topListItem.rank : (idx + 1)}
                </td>
                <td className="text-left col-athlete">
                  <div className="athlete-flag-container">
                    <div className="flag-container">
                      <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(frontendSettings?.isInternationalMeeting ? topListItem.country : 'CZE')}')`}} />
                      <div className="flag flag-transparent" style={{backgroundImage: `url('${getFlagOrClubImage(frontendSettings?.isInternationalMeeting ? topListItem.country : 'CZE', topListItem.country)}')`}} />
                    </div>
                    <div className="athlete-container">
                      <div className="athlete-name">
                        {topListItem.name}
                      </div>
                      <div className="club-name">
                        <strong>{topListItem.country}</strong>{topListItem.club && " - "}{topListItem.club}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-right col-result">
                  {topListItem.result}
                  {topListItem.wind && <div className="wind">{topListItem.wind} m/s</div>}
                </td>
                {!frontendSettings?.isInternationalMeeting && <td className="text-right col-pos">
                  {topListItem.rank}
                </td>}
                <td className="text-left col-venue-date">
                  <div className="venue">{topListItem.venue}</div>
                  <div className="date">{topListItem.date && moment(topListItem.date).format('YYYY-MM-DD')}</div>
                </td>
                <td>&nbsp;</td>
              </tr>
            ))}
            {!error && topListData && topListData.length === 0 && !isFetching &&
            <tr>
              <td colSpan={100} className="col-no-athletes">
                <div>There are no top lists for this event</div>
              </td>
            </tr>}
            </tbody>
          </table>
        </LegacyTouchWrapper>
      </SimpleBar>
      </div>
      {frontendSettings &&
      <div className={classes.topListFilters}>
        <TopListFilters selectedEvent={selectedEvent} currentSeasonYear={frontendSettings.seasonYear} isInternationalMeeting={frontendSettings.isInternationalMeeting} />
      </div>}
    </div>
  );
}

export default TopListTabContent;
