import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& button': {
        marginBottom: '4px',
      },
      '& button:last-child': {
        marginBottom: '0',
      }
    }
  }),
);

function isValueSelected(value: any, candidate: any) {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
}

type FilterToggleButtonGroupProps = {
  value: any
  onChange: (newFilters: any | null) => void;
  exclusive?: boolean;
  exclusiveValues?: any[],
  children: React.ReactNode[],
};

function FilterToggleButtonGroup({ value, onChange, exclusive, exclusiveValues, children }: FilterToggleButtonGroupProps) {
  const classes = useStyles();

  const handleChange = (buttonValue: any) => {
    if (!onChange) {
      return;
    }
    let newValue;

    if (exclusiveValues && exclusiveValues.indexOf(buttonValue) >= 0) { // This value is exclusive
      const exclusiveIndex = value && value.indexOf(buttonValue);

      if (value && exclusiveIndex >= 0) { // Toggle exclusive value
        newValue = value.slice();
        newValue.splice(exclusiveIndex, 1);
      } else {
        newValue = [ buttonValue ];
      }
    } else { // Non exclusive value
      let tempValue = value;

      if (tempValue && exclusiveValues) { // Remove exclusive values
        tempValue = tempValue.filter((v:any) => exclusiveValues.indexOf(v) === -1);
      }

      const index = tempValue && tempValue.indexOf(buttonValue);

      if (tempValue && index >= 0) { // Remove value
        newValue = tempValue.slice();
        newValue.splice(index, 1);
      } else {
        newValue = tempValue ? tempValue.concat(buttonValue) : [buttonValue]; // Add value
      }
    }

    onChange(newValue);
  };

  const handleExclusiveChange = (buttonValue: any) => {
    if (!onChange) {
      return;
    }

    onChange(value === buttonValue ? null : buttonValue);
  };

  return (
    <div className={classes.root}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          onChange: exclusive ? handleExclusiveChange : handleChange,
          selected:
            child.props.selected === undefined
              ? isValueSelected(child.props.value, value)
              : child.props.selected,
        });
      })}
    </div>
  );
}

export default FilterToggleButtonGroup;
