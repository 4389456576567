import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
  AppBar as MaterialAppBar,
  Toolbar
} from '@material-ui/core';

import MeetingInfoBar from '@Features/meetingInfoBar';
import NavbarTop from '@Components/navbarTop';

import { IFrontendSettings, ISystemInfo } from '@App/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: `linear-gradient(90deg, ${theme.palette.appBarGradient.first} 0%, ${theme.palette.appBarGradient.second} 60%, ${theme.palette.appBarGradient.third} 100%)`,
      paddingLeft: '0px',
    },
    toolbar: {
      paddingLeft: '16px',
    },
    button: {
      margin: theme.spacing(1),
    },

  }),
);

type AppBarProps = {
  settings: IFrontendSettings,
  systemInfo: ISystemInfo,
};

function AppBar({ settings, systemInfo }: AppBarProps) {
  const classes = useStyles();

  return (
    <MaterialAppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <MeetingInfoBar settings={settings} />
        <NavbarTop settings={settings} systemInfo={systemInfo} />
      </Toolbar>
    </MaterialAppBar>
  );
}

export default AppBar;