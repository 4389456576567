import { useAppDispatch } from '@App/hooks';
import { IFoulAssistAttempt, IStartListItem, ITimeTableItem } from '@App/types';
import PollingContainer, { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import {
  makeSelectAttempts,
  makeSelectChangeTimestamp,
  makeSelectError,
  makeSelectLoaded,
  makeSelectLoading,
  getFoulAssistDataAsync,
} from './foulAssistSlice';
import { Button, CircularProgress } from '@material-ui/core';
import SimpleBar from 'simplebar-react';
import useStyles from './foulAssistTabContent.styles';
import {
  getFlagOrClubImage,
  getCountryFlagImage,
} from '@Utils/ui';
import Image from 'material-ui-image'


type PollTimeoutTickHandler = () => PollingPromise;

interface IFoulAssistProps {
  selectedEvent: ITimeTableItem;
}

interface IFoulAssistState {
  currentAttempt: string;
  currentFrame: number;
  athleteData: IStartListItem | null;
}

const FoulAssistTabContent: React.FC<IFoulAssistProps> = ({ selectedEvent }: IFoulAssistProps) => {
  const [state, setState] = useState<IFoulAssistState>({
    currentAttempt: '',
    currentFrame: 10,
    athleteData: null,
  });
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');

  const foulAssistData = useSelector(makeSelectAttempts);
  const loading = useSelector(makeSelectLoading);
  const loaded = useSelector(makeSelectLoaded);
  const error = useSelector(makeSelectError);
  const changeTimeStamp = useSelector(makeSelectChangeTimestamp);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getFoulAssistDataAsync(selectedEvent.id));
  };

  const handleOnAttemptClick = (athleteId: number, attempt: number, athleteData: IStartListItem | null) => {
    setState({
      ...state,
      currentAttempt: `${athleteId}A${attempt}`,
      athleteData,
    });
  }

  const renderAttempt = (athleteData: IStartListItem, attempt: number, attempts: IFoulAssistAttempt[]): React.ReactNode => {
    const aux = attempts.find((a) => a.attempt === attempt);
    if (!aux) {
      return '';
    }

    let buttonClass = 'attempt-ok';
    // Attempt to solve weird '-0.4' value which has been determined as a success instead of failure
    // UPDATE: error is caused by floating comma (instead of floating point) (should be solved by aux.value.indexOf('-'))
    // if (parseFloat(aux.value) < 0) {
    if (aux.value.indexOf('-') > -1) {
      buttonClass = 'attempt-fail';
    }

    let displayValue = aux.value.replace(/,/g, '.');
    if (Math.abs(parseFloat(aux.value)) > 100) {
      return (<Button
        variant="outlined"
        className={buttonClass}
      >
        <span className="infinity">&#8734;</span>
      </Button>);
    } else if (displayValue.indexOf('.') === -1) {
      displayValue = `${displayValue}.0`;
    }

    return (<Button
      variant="outlined"
      onClick={() => handleOnAttemptClick(athleteData.participiantId, attempt, athleteData)} /* className={clsx(classes.filterButton, selected && 'selected')} */
      className={buttonClass}
    >
      {displayValue}
    </Button>);
  };

  const handleOnClose = () => setState({
    ...state,
    currentAttempt: '',
    currentFrame: 10,
  });

  const handleMoveToCenterImage = () => setState({
    ...state,
    currentFrame: 10,
  });

  const handleOnPrevImage = () => setState({
    ...state,
    currentFrame: (state.currentFrame - 1) < 0 ? 20 : (state.currentFrame - 1),
  });

  const handleOnNextImage = () => setState({
    ...state,
    currentFrame: (state.currentFrame + 1) > 20 ? 0 : (state.currentFrame + 1),
  });

  return (<div className={classes.root}>
    {frontendSettings &&
    <PollingContainer
      argDeps={selectedEvent.id}
      changeTimeStamp={changeTimeStamp}
      interval={frontendSettings.resultsRefreshInterval}
      onPollTimeoutTick={handlePollTimeoutTick}
    />}
    {(loading || error) && <CircularProgress className={clsx(classes.progress, loaded && 'data-loaded')} size={loaded ? 20 : 60} />}
    <div id="startListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <table className={classes.foulAssistTabs}>
          <colgroup>
            <col style={{ width: '60px' }} />
            <col style={{ width: '380px' }} />
            <col style={{ width: '60px' }} />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Order</th>
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                {foulAssistData && foulAssistData.length > 0 && <span className="athlete-count">({foulAssistData.length} total)</span>}
                {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
              </th>
              <th>Age</th>
              <th style={{ width: '80px' }}>1</th>
              <th style={{ width: '80px' }}>2</th>
              <th style={{ width: '80px' }}>3</th>
              <th style={{ width: '80px' }}>4</th>
              <th style={{ width: '80px' }}>5</th>
              <th style={{ width: '80px' }}>6</th>
            </tr>
          </thead>
          <tbody>
          {foulAssistData && foulAssistData.length > 0 && foulAssistData.map(foulAssist => (<tr
            key={foulAssist.athleteData.participiantId}
          >
            <td className={clsx('text-center', 'col-lane')}>{foulAssist.athleteData.lane}</td>
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(foulAssist.athleteData.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(foulAssist.athleteData.country, foulAssist.athleteData.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && 'has-bibs')}>
                    <div className="athlete-name">
                      {foulAssist.athleteData.lastName?.toLocaleUpperCase()} <span className={clsx('first-name')}>{foulAssist.athleteData.firstName}</span>
                      {(foulAssist.athleteData.hasDNSStatus || foulAssist.athleteData.startlistNote) &&
                        <span className="startlist-note">{foulAssist.athleteData.hasDNSStatus ? 'DNS' : foulAssist.athleteData.startlistNote}</span>}
                    </div>
                    <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{foulAssist.athleteData.club}</strong> - </>}{foulAssist.athleteData.clubFull}</div>
                  </div>
                  {frontendSettings?.displayBIBs && <span className="bib">{foulAssist.athleteData.bib}</span>}
                </div>
              </td>
              {<td className="text-center col-yob">{foulAssist.athleteData.age || '-'}<span>{foulAssist.athleteData.yearOfBirth || ''}</span></td>}
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 1, foulAssist.athleteAttempts)}</td>
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 2, foulAssist.athleteAttempts)}</td>
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 3, foulAssist.athleteAttempts)}</td>
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 4, foulAssist.athleteAttempts)}</td>
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 5, foulAssist.athleteAttempts)}</td>
              <td className="text-center">{renderAttempt(foulAssist.athleteData, 6, foulAssist.athleteAttempts)}</td>
          </tr>))}
          {!error && foulAssistData && foulAssistData.length === 0 && loaded &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
      </SimpleBar>
    </div>
    {!!state.currentAttempt && <div className={classes.foulAssistWrap}>
      <span className="foul-assist-close-button" onClick={handleOnClose}>
        <i className="fas fa-times"></i>
      </span>
      <span className="foul-assist-center-image" onClick={handleMoveToCenterImage}>
        <i className="fas fa-compress-alt"></i>
      </span>
      <span className="foul-assist-arrow foul-assist-arrow-right" onClick={handleOnNextImage}>
        <i className="fas fa-chevron-right" />
      </span>
      <span className="foul-assist-arrow foul-assist-arrow-left" onClick={handleOnPrevImage}>
        <i className="fas fa-chevron-left" />
      </span>
      {state.athleteData && <div className="foul-assist-info-box">
        <div className="header">{state.athleteData.firstName} {state.athleteData.lastName}</div>
        <div>
          <div className="flag-container">
            <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(state.athleteData.country)}')`}} />
            <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(state.athleteData.country, state.athleteData.club)}')`}} />
          </div>
          <div>
            {state.athleteData.club || state.athleteData.country}
          </div>
        </div>
        <div>
          <div style={{ marginLeft: 5 }}>Attempt:</div>
          <div style={{ flex: 1 }}>{state.currentAttempt[state.currentAttempt.length - 1]}</div>
        </div>
      </div>}
      <Image
        src={`${process.env.PUBLIC_URL}/api/v1/foul-assist/${state.currentAttempt}/frame/${state.currentFrame}`}
        style={{ position: 'initial', backgroundColor: 'none' }}
      />
    </div>}
  </div>);
}

export default FoulAssistTabContent;
