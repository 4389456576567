import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { memoize } from 'lodash';
import moment from 'moment';

import { RootState } from '@App/store';

import { timeTableSelectedEventSlice } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';

const APP_REFRESH_MINUTE_THRESHOLD = 4;

export type PollingFailedPayload = {
  sectionName: string;
  error: any;
}

export interface IPollingErrorHandlerState {
  errorCounter: number;
  lastError: any;
  errorWindowStart: string | null;
  pollSectionName: string | null;
}

let initialState:IPollingErrorHandlerState = {
  errorCounter: 0,
  lastError: null,
  errorWindowStart: null,
  pollSectionName: null,
};

export const pollingErrorHandlerSlice = createSlice({
  name: 'pollingError',
  initialState,
  reducers: {
    pollingSucceeded: (state) => {
      Object.assign(state, initialState);
    },
    pollingFailed: (state, action: PayloadAction<PollingFailedPayload>) => {
      if (state.errorCounter === 0) {
        state.errorWindowStart = moment().format();
      }

      state.lastError = action.payload.error;
      state.pollSectionName = action.payload.sectionName; // Je vždy stejné, při změně obrazovky nebo tabu se celé vyresetuje
      state.errorCounter += 1;
    },
    clearPollingErrors: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(timeTableSelectedEventSlice.actions.setActiveTab, (state) => {
        Object.assign(state, initialState);
      });
  }
});

export const {
  pollingSucceeded,
  pollingFailed,
  clearPollingErrors,
} = pollingErrorHandlerSlice.actions;

export const selectErrorCounter = (state: RootState) => state.pollingError.errorCounter;
export const selectLastError = (state: RootState) => state.pollingError.lastError;
export const selectErrorWindowStart = (state: RootState) => state.pollingError.errorWindowStart;
export const selectPollSectionName = (state: RootState) => state.pollingError.pollSectionName;

export const makeShouldDisplayPollingErrorHandler = memoize((errorCountThreshold: number) => createSelector(
  [selectErrorCounter],
  (errorCount) => errorCount > errorCountThreshold
));

export function shouldReloadApplication(strErrorWindowStart: string | null): boolean {
  if (!strErrorWindowStart) {
    return false;
  }

  const now = moment();
  const errorWindowStart = moment(strErrorWindowStart);
  const durationBetween = moment.duration(now.diff(errorWindowStart));

  return durationBetween.asMinutes() > APP_REFRESH_MINUTE_THRESHOLD;
}


export default pollingErrorHandlerSlice.reducer;