import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useAppSelector, useAppDispatch } from '@App/hooks';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getRecordsSummaryDataAsync,
} from './recordsSummarySlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './RecordsSummaryScreen.styles';

import { Grid } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import PollingErrorHandler from '@Features/errorHandler/polling/PollingErrorHandler';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';
import RecordsSummaryTable from './RecordsSummaryTable';
import RecordsSummaryTotalsTable from './RecordsSummaryTotalsTable';

type PollTimeoutTickHandler = () => PollingPromise;

function RecordsSummaryScreen() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const recordsSummaryData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getRecordsSummaryDataAsync());
  };

  return (
    <div className={classes.root}>
      <Helmet title="CIS - Records summary" />
      <Grid container spacing={0} className="main-screen-grid">
        <Grid item xs={12} className={classes.spacerGrid}>
          <div className={classes.topBorder} />
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {frontendSettings &&
              <PollingContainer
                changeTimeStamp={changeTimeStamp}
                interval={frontendSettings.refreshInterval}
                onPollTimeoutTick={handlePollTimeoutTick}
              />}
              {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
              <div id="recordsSummaryScrollContainer" className={classes.scrollContainer}>
                <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
                  <LegacyTouchWrapper>
                    {recordsSummaryData && recordsSummaryData.records.length > 0 && frontendSettings && <RecordsSummaryTable recordsSummary={recordsSummaryData.records} frontendSettings={frontendSettings} />}
                    {!error && recordsSummaryData && recordsSummaryData.records.length === 0 && !isFetching &&
                    <h2 className={classes.noStandings}>There are no records summary data yet, please check again later...</h2>}
                  </LegacyTouchWrapper>
                </SimpleBar>
              </div>
              {recordsSummaryData && recordsSummaryData.records.length > 0 && <RecordsSummaryTotalsTable totalsTable={recordsSummaryData.totals} />}
              <PollingErrorHandler errorCountThreshold={3} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default RecordsSummaryScreen;