import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { IFlashQuoteItem, IFrontendSettings } from '@App/types';

import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';

import { getFlagOrClubImage, getCountryFlagImage } from '@Utils/ui';

function getPlaceSuffix(place: string) {
  if (!place) {
    return null;
  }
  if (place.indexOf('1') !== -1) {
    return place + 'st';
  }
  if (place.indexOf('2') !== -1) {
    return place + 'nd';
  }
  if (place.indexOf('3') !== -1) {
    return place + 'rd';
  }
  return place + 'th';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '7px 35px 5px 15px',
      maxWidth: '1000px',
      width: '100%',
      margin: '0 auto',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.records.lighter,
      padding: '2px 8px 3px',
      borderRadius: '4px',
    },
    athleteInfo: {
      display: 'flex',

      '& .flag-container': {
        position: 'relative',
        width: '45px',
        height: '30px',
        marginRight: '10px',
        marginTop: '5px',
        border: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& .flag': {
        width: '45px',
        height: '30px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '45px 30px',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& .athlete-name': {
        fontSize: '17px',
        fontWeight: 'bold',

        '& .first-name': {
          fontWeight: 'normal',
          opacity: '0.8',
        },
        '& .relay-name': {
          fontSize: '19px',
          fontWeight: 'bold',
          opacity: '1',
        },
      },
      '& .club-name': {
        fontWeight: 'normal',
        color: theme.palette.textTabs.club,
        fontSize: '13px',
        lineHeight: '14px',
      },
    },
    eventInfo: {
      textAlign: 'right',

      '& .event-info-result': {
        '& .event-name-container': {
          opacity: '0.8',
        },
        '& .result': {
          fontWeight: 'bold',
          fontSize: '15px',
        },
        '& .result::before': {
          content: '" - "',
          fontWeight: 'normal',
        }
      },
      '& .place-records-container': {
        '& .records': {
          paddingRight: '5px',
          color: theme.palette.primary.light,
        }
      }
    },
    interviewText: {
      position: 'relative',
      zIndex: 1,

      '& div p': {
        margin: '10px 0px 5px 0',
        fontSize: '14px',
      },
      '& div:first-child p': {
        textIndent: '35px',
      }
    },
    interviewDate: {
      opacity: '0.6',
      fontSize: '12px',
    },
    quoteLeft: {
      position: 'absolute',
      zIndex: 1,
      opacity: '0.15',
      left: '23px',
      top: '63px',
    },
    quoteRight: {
      position: 'absolute',
      zIndex: 1,
      opacity: '0.2',
      right: '45px',
      bottom: '11px',
    }
  }),
);

type FlashQuotesListItemProps = {
  quote: IFlashQuoteItem,
  frontendSettings: IFrontendSettings | undefined,
};

function FlashQuotesListItem({ quote, frontendSettings }: FlashQuotesListItemProps) {
  const classes = useStyles();

  return (
    <ListItem key={quote.id} button>
      <Grid container spacing={0}>
        <Grid item xs={12}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.athleteInfo}>
              <div className="flag-container">
                <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(quote.country)}')`}} />
                <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(quote.country, quote.club)}')`}} />
              </div>
              <div className="athlete-container">
                <div className="athlete-name">
                  {!quote.isRelays && quote.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', quote.isRelays && 'relay-name')}>{quote.firstName}</span>
                </div>
                {!quote.isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{quote.club}</strong> - </>}{quote.clubFull}</div>}
              </div>
            </div>
            <div className={classes.eventInfo}>
              <div className="event-info-result">
                <span className="event-name-container">
                  <span className="event-name">{quote.eventName}</span> {quote.eventPhase && <span>&nbsp;{quote.eventPhase}</span>}{quote.eventPhaseNumber && <span>&nbsp;{quote.eventPhaseNumber}</span>}
                  <span className="event-category">{quote.eventCategory}</span>
                </span>
                {quote.result && <span className="result">{quote.result}</span>}
              </div>
              <div className="place-records-container">
                <span className="records">
                  {quote.records && quote.records.length > 1 && <span className="records">{quote.records.join(', ')}</span>}
                </span>
                <span className="place">{quote.status || getPlaceSuffix(quote.place)} {quote.place && "place"}</span>
              </div>
            </div>
          </div>
          <div className={classes.interviewText}>
            {quote.htmlTextEnglish && <div dangerouslySetInnerHTML={{ __html: quote.htmlTextEnglish }} />}
            {quote.htmlText && <div dangerouslySetInnerHTML={{ __html: quote.htmlText }} />}
          </div>
          <div className={classes.interviewDate}>
            {moment(quote.interviewDate).format('DD.MM.YYYY HH:mm')}
          </div>
          <i className={clsx('fas fa-quote-left', classes.quoteLeft)} />
          <i className={clsx('fas fa-quote-right', classes.quoteRight)} />
        </div>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default FlashQuotesListItem;