import React, { useState } from 'react';
import moment from 'moment';
import { useAppDispatch } from '@App/hooks';
import { useHistory, useRouteMatch } from "react-router-dom";

import { clearPollingErrors } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FunctionsIcon from '@material-ui/icons/Functions';
import AlarmIcon from '@material-ui/icons/Alarm';

import NavButton from './NavButton';
import { IFrontendSettings, ISystemInfo, ScreenTypes } from '@App/types';

import FullScreenToggle from '@Components/fullScreenToggle';
import { MAX_DEFAULT_SCREENS } from '@App/constants';

const SCREEN_PATHS = {
  timeTable: '/timetable',
  points: '/points',
  flashQuotes: '/flash-quotes',
  medalStandings: '/medal-standings',
  recordsSummary: '/records-summary',
  finalLapCounter: '/final-lap-counter'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    menuButton: {
      marginLeft: 0,
      marginRight: '-16px',
      marginTop: '-6px',
      position: 'relative',

      '& .icon-tip': {
        textTransform: 'uppercase',
        fontSize: '10px',
        display: 'block',
        position: 'absolute',
        bottom: '3px',
        left: '8px',
      }
    },
    flashQuotesBadge: {
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: '9px',
        right: '13px',
      }
    },
    flashQuotesBadgeDrawer: {
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: '10px',
        right: '60px',
      }
    },
    drawer: {

    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '15px 0',
    },
    drawerSpacer: {
      flexGrow: 1
    },
    drawerBottomContent: {
      marginLeft: '5px'
    },
    appInfoContainer: {
      padding: '0 25px 0',
      marginTop: '15px',

      '& h4': {
        fontSize: '14px',
        margin: '5px 0 5px 0',
      },
      '& ul': {
        fontSize: '12px',
        margin: '0 0 10px 0',
        padding: 0,

        '& li': {
          listStyleType: 'none',
        }
      }
    },
    menu: {
      '& li': {
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '20px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '15px',
        letterSpacing: '1px',
      },
      '& .MuiBadge-root': {
        width: '100%',

        '& li': {
          width: '100%',
        },
      },
      '& .Mui-selected': {
        background: '#fff',
        color: 'rgba(41, 56, 71, 0.9)',

        '& .fas, .far, .MuiListItemIcon-root': {
          color: '#000',
          opacity: 0.85,
        },
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.9)',
        }
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.white,
        opacity: 0.7,
        minWidth: '40px',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
          opacity: 0.7,
          color: theme.palette.common.white,
        },
      },
      '& .fas': {
        opacity: 0.7,
        paddingLeft: '4px',
      },
      '& .far': {
        opacity: 0.7,
        paddingLeft: '6px',
      }
    },
    cis: {
      color: theme.palette.gradient.first,
      textTransform: 'uppercase',
      fontSize: '11px',
      letterSpacing: '1px',
      margin: '2px 13px -5px 17px',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
  }),
);

type NavButtonClickHandler = () => void;

type NavbarTopProps = {
  settings: IFrontendSettings,
  systemInfo: ISystemInfo,
}

function NavbarTop({ settings, systemInfo }: NavbarTopProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [menuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

  const hideDrawerDelayed = () => {
    setTimeout(() => setIsMenuDrawerOpen(false), 300);
  }

  const handleNavButtonClick:NavButtonClickHandler = () => {
    dispatch(clearPollingErrors());
  };

  const handleDrawerClosed = () => {
    setIsMenuDrawerOpen(false);
  }

  const handleDrawerOpened = () => {
    setIsMenuDrawerOpen(true);
  }

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuDrawerOpen(true);
  };

  const handleGoToTimeTableClick = () => {
    history.push(SCREEN_PATHS.timeTable);
    hideDrawerDelayed();
  };

  const handleGoToPointsClick = () => {
    history.push(SCREEN_PATHS.points);
    hideDrawerDelayed();
  };

  const handleGoToFlashQuotesClick = () => {
    history.push(SCREEN_PATHS.flashQuotes);
    hideDrawerDelayed();
  };

  const handleGoToMedalStandingsClick = () => {
    history.push(SCREEN_PATHS.medalStandings);
    hideDrawerDelayed();
  };

  const handleGoToRecordsSummaryClick = () => {
    history.push(SCREEN_PATHS.recordsSummary);
    hideDrawerDelayed();
  };

  const handleGoToFinalLapCounterClick = () => {
    history.push(SCREEN_PATHS.finalLapCounter);
    hideDrawerDelayed();
  };

  const timeTableInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.TimeTable);
  const pointsInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.Points);
  const flashQuotesInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.FlashQuotes);
  const medalStandingsInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.MedalStandings);
  const recordsSummaryInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.RecordsSummary);
  const finalLapCounterInDefaultScreens = settings?.defaultScreens.includes(ScreenTypes.FinalLapCounter) && !settings.isPublicInstance;

  let defaultScreenList = [];

  if (timeTableInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.TimeTable);
  }
  if (pointsInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.Points);
  }
  if (flashQuotesInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.FlashQuotes);
  }
  if (medalStandingsInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.MedalStandings);
  }
  if (recordsSummaryInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.RecordsSummary);
  }
  if (finalLapCounterInDefaultScreens) {
    defaultScreenList.push(ScreenTypes.FinalLapCounter);
  }

  defaultScreenList = defaultScreenList.slice(0, MAX_DEFAULT_SCREENS)

  const timeTableInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.TimeTable);
  const pointsInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.Points);
  const flashQuotesInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.FlashQuotes);
  const medalStandingsInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.MedalStandings);
  const recordsSummaryInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.RecordsSummary);
  const finalLapCounterInAdditionalScreens = settings?.availableScreens.includes(ScreenTypes.FinalLapCounter) && !settings.isPublicInstance;;

  const timeTableRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.timeTable, exact: true });
  const pointsRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.points, exact: true });
  const flashQuotesRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.flashQuotes, exact: true });
  const medalStandingsRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.medalStandings, exact: true });
  const recordsSummaryRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.recordsSummary, exact: true });
  const finalLapCounterRouteActive = !!useRouteMatch({ path: SCREEN_PATHS.finalLapCounter, exact: true });

  if (timeTableRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.TimeTable)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.TimeTable;
    }
  } else if (pointsRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.Points)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.Points;
    }
  } else if (flashQuotesRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.FlashQuotes)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.FlashQuotes;
    }
  } else if (medalStandingsRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.MedalStandings)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.MedalStandings;
    }
  } else if (recordsSummaryRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.RecordsSummary)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.RecordsSummary;
    }
  } else if (finalLapCounterRouteActive) {
    if (!defaultScreenList.includes(ScreenTypes.FinalLapCounter)) {
      defaultScreenList[MAX_DEFAULT_SCREENS - 1] = ScreenTypes.FinalLapCounter;
    }
  }

  return (
    <>
      <div className={classes.sectionDesktop}>
        {defaultScreenList.map(scr => {
          switch (scr) {
            case ScreenTypes.TimeTable:
              return (
                <NavButton
                  key={ScreenTypes.TimeTable}
                  className={classes.button}
                  activeOnlyWhenExact
                  onClick={handleNavButtonClick}
                  to={SCREEN_PATHS.timeTable}
                >
                  Timetable
                </NavButton>
              );
            case ScreenTypes.Points:
              return (
              <NavButton
                key={ScreenTypes.Points}
                className={classes.button}
                onClick={handleNavButtonClick}
                to={SCREEN_PATHS.points}
              >
                Points
              </NavButton>
              );
            case ScreenTypes.FlashQuotes:
              return (
                <Badge key={ScreenTypes.FlashQuotes} badgeContent={systemInfo?.flashQuotesCount || 0} color="error" className={classes.flashQuotesBadge}>
                  <NavButton
                    className={classes.button}
                    onClick={handleNavButtonClick}
                    to={SCREEN_PATHS.flashQuotes}
                  >
                    Flash quotes
                  </NavButton>
                </Badge>
              );
            case ScreenTypes.MedalStandings:
              return (
                <NavButton
                  key={ScreenTypes.MedalStandings}
                  className={classes.button}
                  onClick={handleNavButtonClick}
                  to={SCREEN_PATHS.medalStandings}
                >
                  Medal standings
                </NavButton>
              );
            case ScreenTypes.RecordsSummary:
              return (
                <NavButton
                  key={ScreenTypes.RecordsSummary}
                  className={classes.button}
                  onClick={handleNavButtonClick}
                  to={SCREEN_PATHS.recordsSummary}
                >
                  Records summary
                </NavButton>
              );
            case ScreenTypes.FinalLapCounter:
              return (
                <NavButton
                  key={ScreenTypes.FinalLapCounter}
                  className={classes.button}
                  onClick={handleNavButtonClick}
                  to={SCREEN_PATHS.finalLapCounter}
                >
                  Final lap counter
                </NavButton>
              );
            default:
              return null;
          }
        })}
      </div>
      <IconButton className={classes.menuButton} edge="start" color="inherit" aria-label="menu" onClick={handleMenuButtonClick}>
        <MenuIcon />
        <span className="icon-tip">Menu</span>
      </IconButton>
      <SwipeableDrawer anchor="right" open={menuDrawerOpen} onClose={handleDrawerClosed} onOpen={handleDrawerOpened} className={classes.drawer}>
        <div className={classes.drawerContent}>
          <h4 className={classes.cis}>Commentator Information System</h4>
          <MenuList className={classes.menu}>
            {timeTableInAdditionalScreens &&
            <MenuItem onClick={handleGoToTimeTableClick} selected={timeTableRouteActive}>
              <ListItemIcon>
                <i className="far fa-clipboard"></i>
              </ListItemIcon>
              Timetable
            </MenuItem>}
            {pointsInAdditionalScreens &&
            <MenuItem onClick={handleGoToPointsClick} selected={pointsRouteActive}>
              <ListItemIcon>
                <i className="fas fa-list-ol"></i>
              </ListItemIcon>
              Points
            </MenuItem>}
            {flashQuotesInAdditionalScreens &&
            <MenuItem onClick={handleGoToFlashQuotesClick} selected={flashQuotesRouteActive}>
              <ListItemIcon>
                <i className="fas fa-quote-left"></i>
              </ListItemIcon>
              <Badge badgeContent={systemInfo?.flashQuotesCount || 0} color="error" className={classes.flashQuotesBadgeDrawer}>
                Flash quotes
              </Badge>
            </MenuItem>
            }
            {medalStandingsInAdditionalScreens &&
            <MenuItem onClick={handleGoToMedalStandingsClick} selected={medalStandingsRouteActive}>
              <ListItemIcon>
                <i className="fas fa-medal"></i>
              </ListItemIcon>
              Medal standings
            </MenuItem>}
            {recordsSummaryInAdditionalScreens &&
            <MenuItem onClick={handleGoToRecordsSummaryClick} selected={recordsSummaryRouteActive}>
              <ListItemIcon>
                <FunctionsIcon fontSize="medium" />
              </ListItemIcon>
              Records summary
            </MenuItem>}
            {finalLapCounterInAdditionalScreens &&
            <MenuItem onClick={handleGoToFinalLapCounterClick} selected={finalLapCounterRouteActive}>
              <ListItemIcon>
                <AlarmIcon fontSize="medium" />
              </ListItemIcon>
              Final lap counter
            </MenuItem>}
          </MenuList>
          <div className={classes.drawerSpacer} />
          <div className={classes.drawerBottomContent}>
            <FullScreenToggle onClick={handleDrawerClosed} overrideFullscreenAvailable={finalLapCounterRouteActive} />
            <div className={classes.appInfoContainer} onClick={handleDrawerClosed}>
              <ul>
                <li><strong>Version:</strong> {systemInfo?.appVersion}</li>
                <li><strong>Build date:</strong> {systemInfo?.buildDate ? moment(systemInfo.buildDate).format('DD.MM.YYYY HH:mm:ss') : '-'}</li>
                <li style={{ opacity: 0.4 }}><strong>Url:</strong> {window.location.origin}</li>
              </ul>

              <div className="copyright">&#169; On line system s.r.o.</div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default NavbarTop;