import { ISummaryResultList, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type SummaryResultListApiResponse = {
  data: ISummaryResultList,
  eventState: ITimeTableItemState,
}

export function fetchSummaryResultListData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/summary-resultlist/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<SummaryResultListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get SummaryResultList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchSummaryResultListData
};

export default defaultExport;
