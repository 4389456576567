import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    scrollContainer: {
      height: '100%',
      flexGrow: 1,
      overflowY: 'auto'
    },
    simpleBar: {
      height: '100%',
    },
    progress: {
      position: 'absolute',
      right: '50%',
      bottom: '50%',

      '&.data-loaded': {
        position: 'absolute',
        right: '7px',
        bottom: '7px',
      }
    },
    stateAfterInfo: {
      background: theme.palette.records.lighter,
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1px',
      color: '#fff',
      padding: '6px 8px 0px 13px',

      '& .spacer': {
        maxWidth: '75%',
        display: 'flex',
        flexWrap: 'wrap',
      },
      '& .state-text': {
        whiteSpace: 'nowrap',
        paddingRight: '10px',
      },
      '& .discipline': {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',

        '&:after': {
          content: "' - '",
          opacity: 0.7,
          padding: '0 5px',
        },
        '&:last-child:after': {
          content: "''",
        }
      },
    },
    startListTable: {
      borderCollapse: 'collapse',
      width: '100%',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '& .bib': {
          float: 'right',
          opacity: '0.5',
        },
        '& .athlete-count': {
          paddingLeft: '8px',
          color: '#fff',
          fontWeight: 'normal',
          opacity: '0.8',
          paddingRight: '8px',
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-record': {
          background: theme.palette.table.altColBackground,
        }
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-record': {
          background: theme.palette.table.altOddColBackground,
        }
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-lane': {
        fontSize: '18px',

        '&.place-first span, &.place-second span, &.place-third span': {
          color: '#000',
          borderRadius: '4px',
          padding: '3px 10px',
        },
        '&.place-first span': {
          backgroundColor: '#FFC540',
        },
        '&.place-second span': {
          backgroundColor: '#D3D3D3',
        },
        '&.place-third span': {
          backgroundColor: '#DC8B53',
        },
      },
      '& td.col-lane-relays': {
        verticalAlign: 'top',
        paddingTop: '12px',
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container.has-bibs': {
          paddingRight: '45px',
        },
        '& .athlete-name': {
          fontSize: '16px',

          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
        },
        '& .club-name': {
          fontWeight: 'normal',
          color: theme.palette.textTabs.club,
          fontSize: '13px',
        },
        '& .startlist-note': {
          fontSize: '12px',
          display: 'inline-block',
          marginLeft: '5px',
          fontWeight: 'normal',
          background: theme.palette.primary.main,
          borderRadius: '4px',
          padding: '1px 6px 0',
          opacity: '0.9',
        },
        '& .bib': {
          fontSize: '17px',
          color: theme.palette.textTabs.bib,
          position: 'absolute',
          right: '0',
          top: '9px',
        }
      },
      '& td.col-result': {
        position: 'relative',
        paddingLeft: '15px',
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        paddingRight: '8px',

        '& .result-record-container': {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        '& .result-disqualify-rule': {
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '24px',
          paddingRight: '6px',
          opacity: '0.7',
        },
        '& .result': {
          fontSize: '16px',
          color: theme.palette.textTabs.result,
        },
      },
      '& td.col-gap': {
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
        opacity: '0.7',
      },
      '& .col-no-athletes': {
        fontSize: '18px',
        textAlign: 'center',
        padding: '30px 10px',

        '& div': {
          /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
          animationName: 'fadeIn',
          animationDuration: '1s',
          transitionTimingFunction: 'linear',
        }
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },

      '&:not(.has-startlist-data)': {
        '& th .bib': {
          marginRight: '8px',
        },
        '& td.col-athlete': {
          paddingRight: '30px',

          '& .bib': {
            right: '8px',
          }
        },
      }
    },
  }),
);