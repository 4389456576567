import React from 'react';

interface ITabPanelProps {
  children?: React.ReactNode;
  timeTableId: number;
  value: number;
}

function TabPanel({ children, value, timeTableId, ...other }: ITabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== timeTableId}
      id={`selectedPointsEvent-tabpanel-${timeTableId}`}
      {...other}
    >
      {value === timeTableId && (
        <div>{children}</div>
      )}
    </div>
  );
}

export default TabPanel;
