import React from 'react';
import { ITimeTableItem, IResultList, IFrontendSettings, ITimeTableItemState } from '@App/types';
import { DisciplineClass } from '@App/disciplines';

import CommonTechnicalResultListTabContent from './CommonTechnicalResultListTabContent';
import VerticalJumpsTechnicalResultListTabContent from './VerticalJumpsTechnicalResultListTabContent';

type TechnicalResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
  scrollableNodeRef: React.Ref<any>,
}

function TechnicalResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching, scrollableNodeRef }: TechnicalResultListTabContentProps) {
  if (selectedEvent.disciplineClass === DisciplineClass.HighJump) {
    return (
      <VerticalJumpsTechnicalResultListTabContent
        selectedEvent={selectedEvent}
        selectedEventState={selectedEventState}
        resultListData={resultListData}
        frontendSettings={frontendSettings}
        hasError={hasError}
        isFetching={isFetching}
        scrollableNodeRef={scrollableNodeRef}
      />
    );
  }

  return (
    <CommonTechnicalResultListTabContent
      selectedEvent={selectedEvent}
      selectedEventState={selectedEventState}
      resultListData={resultListData}
      frontendSettings={frontendSettings}
      hasError={hasError}
      isFetching={isFetching}
      scrollableNodeRef={scrollableNodeRef}
    />
  );
}

export default TechnicalResultListTabContent;
