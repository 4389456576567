import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  hasAnyQualify?: boolean | null;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      position: 'relative',
    },
    verticalJumpsAttemptsScrollWrapper: {
      overflowX: 'auto',
      height: '100%',
      width: 'calc(100% - 540px)',
    },
    horizontalSimpleBar: {
      height: '100%',
    },
    startListTable: {
      borderCollapse: 'collapse',
      width: '100%',
      fontWeight: 'bold',

      '&.personal-data': {
        width: '540px',
        borderRight: `2px solid ${theme.palette.table.borderColorLighter}`,
      },

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& .height-attempts-container': {
        display: 'flex',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '& .bib': {
          float: 'right',
          opacity: '0.5',
        },
        '& .athlete-count': {
          paddingLeft: '8px',
          color: '#fff',
          fontWeight: 'normal',
          opacity: '0.8',
          paddingRight: '8px',

          '& .athlete-count-active': {
            '& span': {
              color: theme.palette.primary.light,
              fontWeight: 'bold',
            }
          },
        },

        '& .sort-indicator': {
          display: 'inline-block',
          marginLeft: '3px',
          color: '#DADADA',
        },

        '& .height-attempts-container': {

        },
        '&.col-height-header': {
          padding: '0px',
          borderRight: `2px solid ${theme.palette.table.borderColorLighter}`,

          '& .height-header': {
            textTransform: 'none',
            display: 'inline-block',
            width: '50px',
            textAlign: 'center',
            borderRight: `1px solid ${theme.palette.table.borderColor}`,
            borderBottom: `1px solid ${theme.palette.table.borderColor}`,
            padding: '3px 0',

            '&.current-height': {
              backgroundColor: '#fff',
              color: '#000',
            },
            '&.jump-off-start-attempt': {
              borderLeft: `2px dashed ${theme.palette.table.borderColorLighter}`,
              position: 'relative',
            },
            '& .jump-off-start-indicator': {
              color: '#000',
              backgroundColor: 'rgba(255, 255, 255, 0.98)',
              border: '1px solid #6b6b6b',
              textTransform: 'uppercase',
              fontSize: '11px',
              fontWeight: 'normal',
              padding: '0 6px',
              borderRadius: '4px',
              position: 'absolute',
              top: '67px',
              left: '-39px',
              transform: 'rotate(-90deg)',
            }
          }
        },
        '&.col-athlete-header': {
          '&.low-res': {
            '& .athlete-count': {
              fontSize: '10px',
            }
          }
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-reaction, .col-record, .col-attempt-final': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
        '&.current-attempt-row': {
          '& td.col-athlete .athlete-container::after': {
            background: `linear-gradient(90deg, transparent 0%, #58626B 100%)`,
          },
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-reaction, .col-record, .col-attempt-final,': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, #313F4C 100%)`,
        },
        '&.current-attempt-row': {
          '& td.col-athlete .athlete-container::after': {
            background: `linear-gradient(90deg, transparent 0%, #58626D 100%)`,
          },
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
        height: '49px', /* tohle je důležité, jinak u pokusů nefunguje height: '100%' */
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& tbody tr.inactive-competitor': {
        opacity: '0.4',
      },
      '& td.col-lane': {
        fontSize: '18px',
        position: 'relative',

        '&.place-first span, &.place-second span, &.place-third span': {
          color: '#000',
          borderRadius: '4px',
          padding: '3px 10px',
        },
        '&.place-first span': {
          backgroundColor: '#FFC540',
        },
        '&.place-second span': {
          backgroundColor: '#D3D3D3',
        },
        '&.place-third span': {
          backgroundColor: '#DC8B53',
        },
        '&.unof-place-first span, &.unof-place-second span, &.unof-place-third span': {
          textDecoration: 'underline',
          textDecorationThickness: '3px',
          textUnderlineOffset: '2px',
        },
        '&.unof-place-first span': {
          textDecorationColor: '#FFC540',
        },
        '&.unof-place-second span': {
          textDecorationColor: '#D3D3D3',
        },
        '&.unof-place-third span': {
          textDecorationColor: '#DC8B53',
        },
        '& .final-round-indicator': {
          position: 'absolute',
          left: '0',
          bottom: '-9px',
          color: '#000',
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          textTransform: 'uppercase',
          fontSize: '11px',
          fontWeight: 'normal',
          padding: '1px 6px 0 6px',
          letterSpacing: '1px',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '350px',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '&.has-bibs': {
            width: '320px',
          }
        },
        '& .athlete-name': {
          fontSize: '16px',

          '&.inactive-competitor .last-name': {
            textDecoration: 'underline',
            textDecorationColor: 'rgba(255, 0, 0, 0.6)',
            textDecorationThickness: '3px',
          },
          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
          '& .relay-label': {
            paddingLeft: '7px',
            opacity: '1',
          },
          '& .relay-members': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            listStyleType: 'none',
            fontWeight: 'normal',
            fontSize: '14px',
            margin: '0',
            padding: '0',

            '& li': {
              padding: '2px 5px 2px 0',
              fontSize: '12px',
              width: '220px',
            },
            '& .member-yob': {
              opacity: '0.5',
              paddingLeft: '4px',
            },
            '& .member-last-name': {
              fontWeight: 'bold',
              fontSize: '13px',
              paddingLeft: '3px',
            },
            '& .member-first-name': {
              opacity: '0.8',
            },
            '& .member-order': {
              background: theme.palette.secondary.dark,
              borderRadius: '4px',
              padding: '2px 4px',
              fontWeight: 'bold',
            }
          }
        },
        '& .club-name': {
          fontWeight: 'normal',
          color: theme.palette.textTabs.club,
          fontSize: '13px',
        },
        '& .startlist-note': {
          fontSize: '12px',
          display: 'inline-block',
          marginLeft: '5px',
          fontWeight: 'normal',
          background: theme.palette.primary.main,
          borderRadius: '4px',
          padding: '1px 6px 0',
          opacity: '0.9',
        },
        '& .bib': {
          fontSize: '17px',
          color: theme.palette.textTabs.bib,
          position: 'absolute',
          right: '0',
          top: '9px',
        }
      },
      '& td.col-yob': {
        fontWeight: 'normal',
        '& span': {
          display: 'block',
          fontSize: '11px',
          opacity: '0.5'
        }
      },
      '& td.col-result': {
        position: 'relative',
        paddingLeft: '15px',
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        paddingRight: ({ hasAnyQualify }) => hasAnyQualify ? '22px' : '8px',

        '& .result-record-container': {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        '& .result-disqualify-rule': {
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '24px',
          paddingRight: '6px',
          opacity: '0.7',
        },
        '& .result': {
          display: 'flex',
          flexDirection: 'column',
          fontSize: '17px',
          marginTop: '-2px',
          color: theme.palette.textTabs.result,

          '& .result-gap': {
            fontSize: '13px',
            fontWeight: 'normal',
            opacity: '0.5'
          },
          '&.has-records': {
            paddingTop: '9px',
          }
        },
        '& .result-qualify': {
          fontSize: '14px',
          color: theme.palette.primary.light,
          fontWeight: 'normal',
          paddingLeft: '0',
          position: 'absolute',
          top: '13px',
          right: '6px',

          '&.conflicting': {
            fontSize: '12px',
            top: '15px',
            right: '4px',
          }
        }
      },
      '& td.col-gap': {
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
        opacity: '0.7',
      },
      '& td.col-points': {
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        lineHeight: '13px',
      },
      '& td.col-result-lane': {
        fontWeight: 'normal',
        fontSize: '13px',

        '& .inactive-indicator': {
          position: 'absolute',
          top: '4px',
          right: '-12px',
          width: '6px',
          height: '6px',
          borderRadius: '6px',
          backgroundColor: 'rgba(255, 0, 0, 0.85)'
        },
        '& .container': {
          position: 'relative',
        },
        '& .lane': {
          opacity: '0.7',
        }
      },
      '& td.col-result-pb-sb': {
        borderLeft: 'none',
        fontWeight: 'normal',
        fontSize: '13px',

        '& div': {
          opacity: '0.7',
        },
        '& .result-sb': {
          fontSize: '11px',
        }
      },
      '& td.col-pb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-sb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-warank': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
      },
      '& td.col-qualify': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',

        '& .qualify-type': {
          color: theme.palette.primary.light,
          fontWeight: 'bold',
          display: 'inline-block',
          width: '23px',
          marginRight: '3px',
          fontSize: '13px',
        }
      },
      '& td.col-height-attempts': {
        borderRight: `2px solid ${theme.palette.table.borderColorLighter}`,
        padding: '0px',

        '& .height-attempts-container': {
          height: '100%',
        },
        '& .height-attempt': {
          display: 'inline-block',
          position: 'relative',
          width: '50px',
          height: '50%',
          textAlign: 'center',
          fontSize: '15px',
          lineHeight: '23px',
          borderRight: `1px solid ${theme.palette.table.borderColorLighter}`,
          borderBottom: `1px solid ${theme.palette.table.borderColorLighter}`,
          paddingTop: '1px',

          '&.current-height-col': {
            backgroundColor: 'rgba(255,255,255,0.08)',
          },
          '&.current-height': {
            backgroundColor: '#fff',
            color: '#000',
          },
          '&.jump-off-start-attempt': {
            borderLeft: `2px dashed ${theme.palette.table.borderColorLighter}`,
          },
          '&.final-attempt': {
            '& .attempt-result': {
              textDecoration: 'underline',
              textDecorationColor: 'rgba(255, 0, 0, 0.7)',
              textDecorationThickness: '3px',
            },
          },
          '& .attempt-result': {
            letterSpacing: '1px',
          },
          '& .attempt-cursor': {
            width: '11px',
            height: '2px',
            background: 'rgba(0, 0, 0, 0.88)',
            animation: 'blinker 1.2s infinite',
            borderRadius: '4px',
            display: 'inline-block',
            marginLeft: '-2px',
          }
        }
      },
      '& td.no-padding, th.no-padding': {
        padding: 0,
      },
      '& tr.current-attempt-row': {
        '& td': {
          background: 'rgba(255, 255, 255, 0.18) !important',
          borderLeftColor: 'rgba(255, 255, 255, 0.28) !important',
          borderRightColor: 'rgba(255, 255, 255, 0.28) !important',

          '& .result-gap, .club-name': {
            opacity: '0.8 !important',
          },
          '&.col-height-attempts': {
            background: 'rgba(255, 255, 255, 0.08) !important',
          }
        },
        '& td.current-attempt-col': {
          background: 'rgba(0, 0, 0, 0.25) !important',
          position: 'relative',
        },
      },
      '& .col-spacer': {
        padding: '0px',
      },
      '& .col-no-athletes': {
        fontSize: '18px',
        textAlign: 'center',
        padding: '30px 10px',

        '& div': {
          /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
          animationName: 'fadeIn',
          animationDuration: '1s',
          transitionTimingFunction: 'linear',
        }
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },

      '&:not(.has-startlist-data)': {
        '& th .bib': {
          marginRight: '8px',
        },
        '& td.col-athlete': {
          paddingRight: '30px',

          '& .bib': {
            right: '8px',
          },
          '& .athlete-container': {
            width: '220px',

            '&.hi-res': {
              width: '270px',
            },
            '&.has-bibs': {
              width: '180px',
              '&.hi-res': {
                width: '230px',
              },
            },
          }
        },
      },

      '&.single-row-attempts': {
        '& thead .height-attempts-container': {
          height: '29px',
          paddingTop: '1px',

          '& .height-header': {
            paddingTop: '5px',
          }
        },
        '& tbody .height-attempts-container .height-attempt': {
          height: '49px',
          paddingTop: '13px',
        }
      },
    },
  }),
);