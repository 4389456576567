import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '13px',
      lineHeight: '14px',
      marginTop: '1px',

      '& .board': {
        fontSize: '11px',
        lineHeight: '13px',
      }
    },
  }),
);

function TakeOffBoardTabHeader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>Take off</span>
      <span className="board">board</span>
    </div>
  );
}

export default TakeOffBoardTabHeader;