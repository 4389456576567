import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: `2px dashed ${theme.palette.primary.light}`,
      padding: '4px 4px',
      height: '48px',
      width: '152px',

      '& .text': {
        display: 'inline-block',
        width: '100px',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px'
      },
      '& i.fas': {
        fontSize: '20px',
        lineHeight: '30px',
        width: '36px',
      },
      '& img, i.fas': {
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderRadius: '4px',
        padding: '3px',
        height: '36px',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
    }
  }),
);

type ClearFiltersButtonProps = {
  onClick: () => void;
}

function ClearFiltersButton({ onClick }: ClearFiltersButtonProps) {
  const classes = useStyles();

  return (
    <Button variant="outlined" onClick={onClick} className={classes.filterButton}>
      <i className="fas fa-eraser" />
      <span className="text">Clear filters</span>
    </Button>
  );
}

export default ClearFiltersButton;