import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { IResultList, EventState, TechnicalSortType, TimeTableEventTabType } from '@App/types';
import { RootState } from '@App/store';

import resultListApi from '@App/services/resultList/resultListApi';
import { setActiveTab } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { eventsApi, EventsStatesResponse } from '@App/services/events/eventsApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { selectTechnicalSort } from '@App/appUserStateSlice';

export interface IGetResultListDataPayload {
  timeTableId: number;
}

export interface IResultListState {
  changeTimestamp: number; // Tohle trigeruje mount/unmout pooling kontejneru, tak aby se forsnul reload při znovu kliku (jinak se vysypal state)
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: IResultList | null;
}

const initialState: IResultListState = {
  changeTimestamp: 0,
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: null,
};

// Toto je big hack jak se v thunku dostat ke stavu z RTK query, bohužel u callbacku z PollingContaineru vznikaly jakési divné "capture scope" kdy tam byly jiné hodnoty
const selectEventStatesData = (state: RootState) => state.eventsApi.queries['getEventsStates("")']?.data as EventsStatesResponse;

export const getResultListDataAsync = createAsyncThunk(
  'resultList/fetchData',
  async ({ timeTableId }: IGetResultListDataPayload, { dispatch, rejectWithValue, signal, getState }) => {
    try {
      const state = getState() as RootState;
      const eventStatesData = selectEventStatesData(state);
      const eventState = eventStatesData.states[timeTableId]?.state || EventState.Unofficial;

      let technicalSort;
      if (eventState === EventState.Scheduled) {
        technicalSort = TechnicalSortType.ByOrder;
      } else if ( eventState === EventState.Official || eventState === EventState.Unofficial) {
        technicalSort = TechnicalSortType.ByPlace;
      } else {
        technicalSort = selectTechnicalSort(getState() as RootState);
      }
      const response = await resultListApi.fetchResultListData(timeTableId, signal, technicalSort);

      const thunkDispatch = dispatch as ThunkDispatch<any, any, AnyAction>; // Nutno to přetypovat, TypeScript si s tím nějak
      thunkDispatch(eventsApi.util.updateQueryData('getEventsStates', '', draft => {
        draft.states[timeTableId] = response.eventState;
      }));

      dispatch(pollingSucceeded());

      return response.data;
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'ResultList', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const resultListSlice = createSlice({
  name: 'resultListSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResultListDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getResultListDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getResultListDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(setActiveTab, (state, action) => { // Clear state
        if (action.payload === TimeTableEventTabType.Results) {
          state.changeTimestamp = Date.now();
        } else {
          Object.assign(state, initialState);
        }
      })
      .addCase(setSelectedTimeTableId, (state, action) => {
        if (action.payload.hasChanged) {
          Object.assign(state, initialState);
        } else {
          state.changeTimestamp = Date.now();
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.resultList.isFetching;
export const selectError = (state: RootState) => state.resultList.error;
export const selectDataLoaded = (state: RootState) => state.resultList.dataLoaded;
export const selectData = (state: RootState) => state.resultList.data;
export const selectChangeTimestamp = (state: RootState) => state.resultList.changeTimestamp;

export default resultListSlice.reducer;