import React, { useEffect, useState } from 'react';
import { getErrorMessage } from '@Utils/error';
import { useAppSelector } from '@App/hooks';
import { selectEventsLoaded, selectEventsLoadError } from './initErrorHandlerSlice';

import { pingBackend } from '@App/services/pingApi';
import { PING_BACKEND_INTERVAL } from '@App/constants';

const PAGE_REFRESH_TIMEOUT = 20000;

function InitErrorHandler() {
  const [autoReload, setAutoReload] = useState(true);

  const [backendAvailable, setBackendAvailable] = useState(false);

  const eventsLoaded = useAppSelector(selectEventsLoaded);
  const eventsLoadError = useAppSelector(selectEventsLoadError);

  const handleRefreshButtonClick = () => {
    window.location.reload(true);
  };

  const handleCancelAutoReload = () => {
    setAutoReload(false);
  };

  useEffect(() => {
    let reloadTimeoutHandle:NodeJS.Timeout | null;

    if (autoReload && backendAvailable && !eventsLoaded && eventsLoadError) {
      reloadTimeoutHandle = setInterval(() => {
        console.warn('Auto reloading application after initialization failure...');
        window.location.reload(true);
      }, PAGE_REFRESH_TIMEOUT);
    }

    return () => {
      if (reloadTimeoutHandle) {
        clearInterval(reloadTimeoutHandle);
      }
    }
  }, [autoReload, eventsLoaded, backendAvailable, eventsLoadError]);

  useEffect(() => {
    let hnd: NodeJS.Timeout | null = null;

    function scheduleNextCheck() {
      hnd = setTimeout(() => {
        pingBackend().then(backendAvailable => {
          setBackendAvailable(backendAvailable);

          scheduleNextCheck();
        });
      }, PING_BACKEND_INTERVAL);
    }

    if (!eventsLoaded && eventsLoadError) {
      scheduleNextCheck();
    }

    return () => {
      if (hnd) {
        clearTimeout(hnd);
      }
    };
  }, [eventsLoaded, eventsLoadError]);

  if (eventsLoaded /* init load ok */ || !eventsLoadError /* no error, maybe pending request */) {
    return null;
  }

  return (
    <div className="global-error-root">
      <div className="aligner">
        <i className="fas fa-exclamation-triangle" />
        <h1>Failed to initialize CIS application!</h1>
        <h2>{backendAvailable ? 'Application will reload automatically...' : 'Backend network connection is currently unavailable.'}</h2>
        <div>
          {backendAvailable && <button onClick={handleRefreshButtonClick} className="reload-app-button">Reload application</button>}
        </div>
        {(eventsLoadError) &&
        <div className="global-error-diagnostic-info">
          <h3>Diagnostic information:</h3>
          <pre>
            {getErrorMessage(eventsLoadError)}
          </pre>
        </div>}
        <div>
          {backendAvailable && autoReload && <button onClick={handleCancelAutoReload} className="reload-app-button cancel">Cancel auto reload</button>}
        </div>
      </div>
    </div>
  );
}

export default InitErrorHandler;