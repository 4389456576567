export enum DisciplineType {
  /**
   * 40y, 100y, 50m, 60m, 80m 100m 150m, 200m, 300m
   */
  Sprint = 0,

  /**
   * 400m
   */
  Sprint400M = 1,

  /**
   * 500m, 600m, 800m, 1000m
   */
  Run800M = 2,

  /**
   * 1500m, 1 míle, 2000m, 3000m, 4000m, ..., hodinovka běh
   */
  LongRun = 3,

  /**
   * 40y př., 50m př., 60m př., 100m př., 110m př., 200m př., 300m př., 400m př.
   */
  Hurdles = 4,

  /**
   * 1500m př., 2000m př., 3000m př.
   */
  Steelplechase = 5,

  /**
   * 4x60m, 4x100m, ...
   */
  Relays = 6,

  /**
   * Celkový bodový stav
   */
  CommonPoints = 7,

  /**
   * Body u vícebojů
   */
  PointsPolyathlon = 8,

  /**
   * Pětiboj, Pětiboj hala
   */
  Pentathlon = 9,

  /**
   * Sedmiboj, sedmiboj hala
   */
  Heptathlon = 10,

  /**
   * osmiboj IAAF
   */
  Octathlon = 11,

  /**
   * trojboj
   */
  Triathlon = 12,

  /**
   * čtyřboj
   */
  Quadrathlon = 13,

  /**
   * Hod břemenem, hod diskem
   */
  Discus = 14,

  /**
   * Hod kladivem
   */
  Hammer = 15,

  /**
   * Oštěp
   */
  Javelin = 16,

  /**
   * Kriketový míček
   */
  CricketBall = 17,

  /**
   * Koule
   */
  ShotPut = 18,

  /**
   * Dálka
   */
  LongJump = 19,

  /**
   * Trojskok
   */
  TripleJump = 20,

  /**
   * Chůze
   */
  Walk = 21,

  /**
   * Výška
   */
  HighJump = 22,

  /**
   * PoleValut
   */
  PoleValut = 23,

  Unknown = 24,

  /**
   * Desetiboj
   */
  Decathlon = 25
}

export enum DisciplineClass {
  RunsWithReaction = 0,
  RunsStartInLanes = 1, // Jedině 500m, 600m, 800m
  Runs = 2,
  RelaysLanes = 3,
  Relays = 4,
  HighJump = 5,
  LongJump = 6,
  Throws = 7,
  Triathlon = 8,
  Pentathlon = 9,
  Heptathlon = 10,
  Decathlon = 11,
  Points = 12,
  PointsPolyathlon = 13,
  PointsTeams = 14,
}

export function isTechnicalDisciplineClass(discClass: DisciplineClass) {
  return (
    discClass === DisciplineClass.HighJump ||
    discClass === DisciplineClass.LongJump ||
    discClass === DisciplineClass.Throws
  )
}
