import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    height: '100%',
    position: 'relative',
  },
  scrollContainer: {
    height: 'calc(100% - 51px)',
    flexGrow: 1,
    overflowY: 'auto'
  },
  simpleBar: {
    height: '100%',
  },
  progress: {
    position: 'absolute',
    right: '50%',
    bottom: '50%',

    '&.data-loaded': {
      position: 'absolute',
      right: '7px',
      bottom: '7px',
    }
  },
  startListTable: {
    borderCollapse: 'collapse',
    width: '100%',
    fontWeight: 'bold',

    '& td, th': {
      whiteSpace: 'nowrap',
    },
    '& thead th': {
      border: `0px solid ${theme.palette.table.borderColor}`,
      background: theme.palette.records.lighter,
      borderTop: '0',
      borderLeft: '0',
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1px',
      color: theme.palette.primary.light,
      padding: '6px 8px 5px 6px',
      position: 'sticky',
      zIndex: '2',
      top: '0',
    },
    '& thead th:last-child': {
      borderRight: '0',
    },
    '& tbody tr:nth-child(even)': {
      background: theme.palette.table.altRowBackground,

      '& .col-pb, .col-sb': {
        background: theme.palette.table.altColBackground,
      }
    },
    '& tbody tr:nth-child(odd)': {
      '& .col-pb, .col-sb': {
        background: theme.palette.table.altOddColBackground,
      }
    },
    '& tbody tr td': {
      borderBottom: `1px solid ${theme.palette.table.borderColor}`,
      borderTop: '0',
      borderLeft: '0',
      padding: '3px 8px',
    },
    '& tbody tr td:last-child': {
      borderRight: '0',
    },
    '& td.col-type': {
      fontSize: '18px',

      '& .country-nr': {
        color: theme.palette.primary.light,
        fontSize: '11px',
      }
    },
    '& td.col-athlete': {
      position: 'relative',

      '& .athlete-flag-container': {
        display: 'flex',
      },
      '& .flag-container': {
        position: 'relative',
        width: '41px',
        height: '27px',
        marginRight: '15px',
        marginTop: '5px',
        border: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& .flag': {
        width: '41px',
        height: '27px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '41px 27px',
        backgroundColor: '#4a4d50',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& .flag.flag-transparent': {
        backgroundColor: 'transparent',
      },
      '& .athlete-name': {
        fontSize: '16px',

        '& .first-name': {
          fontWeight: 'normal',
          opacity: '0.8',
        }
      },
      '& .club-name': {
        fontWeight: 'normal',
        color: theme.palette.textTabs.club,
        fontSize: '13px',
      },
      '& .pending-indicator': {
        fontSize: '11px',
        display: 'inline-block',
        marginLeft: '5px',
        fontWeight: 'normal',
        background: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '1px 6px 0',
        opacity: '0.9',
        color: '#fff'
      }
    },
    '& td.col-result': {
      fontSize: '18px',

      '& .wind': {
        opacity: '0.6',
        fontSize: '12px',
        fontWeight: 'normal',
      }
    },
    '& td.col-pos': {
      textAlign: 'center',
    },
    '& td.col-venue-date': {
      '& .venue': {

      },
      '& .date': {
        opacity: 0.6,
        fontSize: '13px',
        fontWeight: 'normal'
      }
    },
    '& .col-no-athletes': {
      fontSize: '18px',
      textAlign: 'center',
      padding: '30px 10px',

      '& div': {
        /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
        animationName: 'fadeIn',
        animationDuration: '1.5s',
        transitionTimingFunction: 'linear',
      }
    },
    '& .text-right': {
      textAlign: 'right',
    },
    '& .text-left': {
      textAlign: 'left',
    },
    '& .text-center': {
      textAlign: 'center',
    },
  },
  topListFilters: {
    background: theme.palette.records.lighter,
    height: '51px'
  }
}),
);
