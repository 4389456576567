class HttpRequestError extends Error {
  public status: number;
  public body: string | undefined;

  constructor(status: number, message: string, body?: string) {
    super(message);

    this.status = status;

    if (body) {
      try {
        const jsonBody = JSON.parse(body);

        if (jsonBody.error && jsonBody.errorType) {
          this.body = `Backend error: ${jsonBody.error} (${jsonBody.errorType})`;
        } else {
          this.body = body;
        }
      } catch (err) {
        console.warn('HttpRequestError failed to parse body as JSON ->' + body)
        this.body = body;
      }
    }
  }
}

export default HttpRequestError;
