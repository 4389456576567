import React from 'react';
import { EventState } from '@App/types';
import EventStateIcon from '@Components/eventStateIcon';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CURRENT_EVENTS_STATE_THRESHOLD_PX } from '@App/constants';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

function getStateDescription(eventState: EventState) {
  switch (eventState) {
    case EventState.InProgress:
      return 'In progress';
    case EventState.Judging:
      return 'Judging';
    case EventState.Official:
      return 'Official';
    case EventState.Scheduled:
      return 'Scheduled';
    case EventState.Unofficial:
      return 'Unofficial';
    default:
      return null;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0.15rem 0.5rem 0.14rem',
      fontSize: '12px',
      lineHeight: '20px',
      opacity: '0.7',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',

      '& .state-icon': {
        paddingRight: '4px',
      }
    },
  }),
);

type EventStateIndicatorProps = {
  eventState: EventState,
  isStartlistOfficial: boolean,
}

function EventStateIndicator({ eventState, isStartlistOfficial }: EventStateIndicatorProps) {
  const classes = useStyles();
  const showStateDescription = useMediaQuery(`(min-width:${CURRENT_EVENTS_STATE_THRESHOLD_PX})`);

  return (
    <div className={classes.root}>
      <EventStateIcon eventState={eventState} isStartlistOfficial={isStartlistOfficial} />
      {showStateDescription && getStateDescription(eventState)}
    </div>
  );
}

export default EventStateIndicator;
