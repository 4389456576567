import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import defaultTheme, { createCustomTheme } from './app/theme';

import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import 'simplebar/dist/simplebar.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { IFrontendSettings } from '@App/types';

function RuntimeThemeProvider() {
  const [runtimeThemeLoaded, setRuntimeThemeLoaded] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);

  const handleFrontendSettingsLoaded = (frontendSettings: IFrontendSettings) => {
    if (!runtimeThemeLoaded && frontendSettings.theme) {
      const newTheme = createCustomTheme(frontendSettings.theme);
      setRuntimeThemeLoaded(true); // Jinak při změně state nastane smyška :)
      setCurrentTheme(newTheme);
    }
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <App onFrontendSettingsLoaded={handleFrontendSettingsLoaded} />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <RuntimeThemeProvider />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {

}