import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    progress: {
      position: 'absolute',
      right: '50%',
      bottom: '50%',

      '&.data-loaded': {
        position: 'absolute',
        right: '7px',
        bottom: '7px',
      }
    },
    spacerGrid: {
      height: '100%',
      padding: theme.spacing(1),
    },
    card: {
      height: 'calc(100% - 3px)',
      borderRadius: '0 0 4px 4px',
      marginTop: '0',
      background: 'transparent',
    },
    cardContent: {
      height: '100%',
      position: 'relative',
      padding: '0 !important',
      background: theme.palette.background.paper,
    },
    topBorder: {
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
    },
    scrollContainer: {
      height: '100%',
      overflowY: 'auto'
    },
    simpleBar: {
      height: '100%',
    },
    noStandings: {
      textAlign: 'center',
      marginTop: '60px',
    }
  }),
);
