import React from 'react';
import { uniqBy } from 'lodash';
import { IEventRecord } from '@App/types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  headerHasThreeRows: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
    },
    recordsList: {
      listStyleType: 'none',
      margin: ({ headerHasThreeRows }) => headerHasThreeRows ? '0.34rem 0 0 -3px' : '0.23rem 0 0 -3px',
      padding: 0,
    },
    recordsListItem: {
      float: 'left',
      marginRight: '15px',

      '& span.type': {
        fontWeight: 'bold',
        padding: '0.15rem 0.4rem 0.14rem',
        background: theme.palette.records.darker,
        borderRadius: '4px 0 0 4px',
      },
      '& span.mark': {
        padding: '0.15rem 0.4rem 0.14rem',
        background: theme.palette.records.lighter,
        borderRadius: '0 4px 4px 0',
      }
    }
  }),
);


type EventRecordsType = {
  records: IEventRecord[],
  headerHasThreeRows: boolean;
}

function EventRecords({ records, headerHasThreeRows }: EventRecordsType) {
  const classes = useStyles({ headerHasThreeRows });
  
  return (
    <div className={classes.root}>
      <ul className={classes.recordsList}>
        {uniqBy(records, r => r.type + r.result).map(r => (
          <li key={`${r.recordsKey}_${r.id}_${r.result}`} className={classes.recordsListItem}>
            <span className="type">{r.type}</span><span className="mark">{r.result}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EventRecords;
