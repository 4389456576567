import React from 'react';
import moment from 'moment';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: '9px',
      opacity: 0.70,
    },
    exp: {
      fontSize: '0.5rem',
      paddingRight: '2px',
    }
  }),
);

function getExponent(dayOfMonth: number) {
  switch (dayOfMonth) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

type MeetingDateProps = {
  dateFrom: string,
  dateTo: string | undefined,
}

function MeetingDate({ dateFrom, dateTo }: MeetingDateProps) {
  const classes = useStyles();
  const mDateFrom = moment(dateFrom);
  const mDateFromDayOfMonth = mDateFrom.date();

  if (!dateTo) {
    return (
      <span className={classes.root}>
        {mDateFrom.format('MMMM')} {mDateFromDayOfMonth}<sup className={classes.exp}>{getExponent(mDateFromDayOfMonth)}</sup> {mDateFrom.year()}
      </span>
    );
  }

  const mDateTo = moment(dateTo);
  const mDateToDayOfMonth = mDateTo.date();

  if (mDateFrom.year() !== mDateTo.year()) {
    return (
      <span className={classes.root}>
        {mDateFrom.format('MMMM')} {mDateFromDayOfMonth}<sup className={classes.exp}>{getExponent(mDateFromDayOfMonth)}</sup> {mDateFrom.year()} - {mDateTo.format('MMMM')} {mDateToDayOfMonth}<sup className={classes.exp}>{getExponent(mDateToDayOfMonth)}</sup> {mDateTo.year()}
      </span>
    );
  }

  if (mDateFrom.month() !== mDateTo.month()) {
    return (
      <span className={classes.root}>
        {mDateFrom.format('MMMM')} {mDateFromDayOfMonth}<sup className={classes.exp}>{getExponent(mDateFromDayOfMonth)}</sup> - {mDateTo.format('MMMM')} {mDateToDayOfMonth}<sup className={classes.exp}>{getExponent(mDateToDayOfMonth)}</sup> {mDateTo.year()}
      </span>
    );
  }

  return (
    <span className={classes.root}>
      {mDateFrom.format('MMMM')} {mDateFromDayOfMonth}<sup className={classes.exp}>{getExponent(mDateFromDayOfMonth)}</sup> - {mDateToDayOfMonth}<sup className={classes.exp}>{getExponent(mDateToDayOfMonth)}</sup> {mDateTo.year()}
    </span>
  );
}

export default MeetingDate;
