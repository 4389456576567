import React from 'react';
import { IFrontendSettings } from '@App/types';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MeetingDate from './MeetingDate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    meetingLogo: {
      height: '48px',
      marginRight: '10px',
    },
    meetingName: {
      fontFamily: 'WorldAthleticsHeadline',
      marginBottom: 0,
      marginTop: '5px',
      fontSize: '22px',
      //maxWidth: '700px',
      overflow: 'hidden',
    },
    meetingVenue: {
      lineHeight: '1.5',
      fontSize: '0.73rem',
      marginBottom: '0.4rem',
    }
  }),
);

type MeetingInforBarProps = {
  settings: IFrontendSettings
};

function MeetingInfoBar({ settings }: MeetingInforBarProps) {
  const classes = useStyles();

  const meetingLogo = settings.theme.meetingLogo || settings.meetingLogo;
  const hideMeetingName = settings.theme.hideMeetingName || settings.hideMeetingName;

  return (
    <div className={classes.root}>
      <img src={`${process.env.PUBLIC_URL}/meetingLogos/${meetingLogo}`} alt="Meeting logo" className={classes.meetingLogo} />
      {!hideMeetingName &&
      <div>
        <h3 className={classes.meetingName}>{settings.meetingName}</h3>
        <Typography variant="overline" display="block" gutterBottom className={classes.meetingVenue}>
          {settings.meetingPlace}
          <MeetingDate dateFrom={settings.dateFrom} dateTo={settings.dateTo} />
        </Typography>
      </div>}
    </div>
  );
}

export default MeetingInfoBar;
