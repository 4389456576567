import React from 'react';
import { TimeTableEventTabType } from '@App/types';

interface ITabPanelProps {
  children?: React.ReactNode;
  tabType: TimeTableEventTabType;
  value: TimeTableEventTabType;
}

function TabPanel({ children, value, tabType, ...other }: ITabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== tabType}
      id={`selectedEvent-tabpanel-${tabType}`}
      {...other}
    >
      {value === tabType && (
        <div>{children}</div>
      )}
    </div>
  );
}

export default TabPanel;
