import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ISystemInfo } from '@App/types';

export const systemInfoApi = createApi({
  reducerPath: 'systemInfoApi',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.PUBLIC_URL}/api/v1`
  }),
  tagTypes: ['ISystemInfo'],
  endpoints: (build) => ({
    getSystemInfo: build.query<ISystemInfo, string>({
      query: () => 'settings/system-info',
      providesTags: ['ISystemInfo'],
    })
  }),
});

export const { useGetSystemInfoQuery } = systemInfoApi;