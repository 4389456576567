import React from 'react';
import { IEventRecord, ITimeTableItem, ITimeTableItemState } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import EventStartDateTime from './EventStartDateTime';
import EventStateIndicator from './EventStateIndicator';
import WindIndicator from './WindIndicator';
import EventRecords from './EventRecords';
import PhotofinishIndicator from './PhotofinishIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    eventDetailsColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      position: 'relative',
    },
    eventName: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: '1.8em',
      fontFamily: 'WorldAthleticsHeadline',
    },
    eventPhase: {
      fontWeight: 'normal',
      fontFamily: 'WorldAthletics',
    },
    eventPhaseCount: {
      opacity: '0.5',
    },
    eventSubmeeting: {
      fontFamily: 'WorldAthletics',
      fontWeight: 'normal',
      fontSize: '17px',
      paddingLeft: '5px',
      opacity: '0.8',
    },
    disciplineIcon: {
      height: '55px',
      marginRight: '-0.1rem',
      marginLeft: '0.5rem',
      marginTop: '0.25rem',
    },
    qualification: {
      fontSize: '14px',
      marginTop: '-1px',
    },
    recordsAndWindWrapper: {

    },
    statePhotofinishWrapper: {
      position: 'absolute',
      right: '0px',
      top: '3px',
      display: 'flex'
    }
  }),
);

type EventHeaderProps = {
  event: ITimeTableItem,
  eventState: ITimeTableItemState | null,
  records: IEventRecord[] | null,
  hasMultipleDays: boolean;
};

function EventHeader({ event, eventState, records, hasMultipleDays }: EventHeaderProps) {
  /*
  *- full event name
  *- start time / date
  - wind
  - event state
  *- sub meeting
  - qualification
  - temperature / humidity (only when official)
  - 5x discipline records
  */
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <EventStartDateTime
        startDateTime={event.startDateTime}
        dayNumber={event.day}
        canUseThreeRows={!!event.qualifyFull}
        hasMultipleDays={hasMultipleDays}
      />
      <div className={classes.eventDetailsColumn}>
        <h2 className={classes.eventName}>
          {event.eventNameFull} {event.eventCategoryFull}
          <span className={classes.eventPhase}>
            {event.eventPhase && <span>&nbsp;{event.eventPhase}</span>}{event.eventPhaseNumber && <span>&nbsp;{event.eventPhaseNumber}{event.eventPhaseCount > 1 && <span className={classes.eventPhaseCount}>/{event.eventPhaseCount}</span>}</span>}
          </span>
          {event.eventSubMeeting && <span className={classes.eventSubmeeting}>- {event.eventSubMeeting}</span>}
        </h2>
        {event.qualifyFull && <div className={classes.qualification}>{event.qualifyFull}</div>}
        <div className={classes.recordsAndWindWrapper}>
          {records && <EventRecords records={records} headerHasThreeRows={!!event.qualifyFull} />}
        </div>
        <div className={classes.statePhotofinishWrapper}>
          {eventState && eventState.photofinishAvailable && <PhotofinishIndicator event={event} />}
          {eventState && <EventStateIndicator eventState={eventState.state} isStartlistOfficial={eventState.isStartlistOfficial} liveResultsFromTrasponders={eventState.liveResultsFromTrasponders} />}
        </div>
        {eventState && eventState.wind && <WindIndicator wind={eventState.wind} />}
      </div>
    </div>
  );
}

export default EventHeader;
