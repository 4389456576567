
import { ITimeTableState } from '@Features/timeTable/timeTableSlice';
import { ISelectedTimeTableEventState } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { ISelectedTimeTablePointsEventState } from '@Features/pointsScreen/pointsScreenSlice';
import { IAppUserSessionState } from '@App/appUserStateSlice';

const SESSION_STORAGE_TIME_TABLE_KEY = 'cis2TimeTable';
const SESSION_STORAGE_SELECTED_EVENT_KEY = 'cis2SelectedEvent';
const SESSION_STORAGE_SELECTED_POINTS_EVENT_KEY = 'cis2SelectedPointsEvent';
const SESSION_STORAGE_APP_USER_STATE_KEY = 'cis2AppUserState';
const SESSION_STORAGE_SAFETY_REFRESH_DATE = 'cis2SaeftyRefreshDate';

export function getTimeTableStateFromSession(): ITimeTableState | null {
  const strState = window.sessionStorage.getItem(SESSION_STORAGE_TIME_TABLE_KEY);

  if (!strState) {
    return null;
  }
  return JSON.parse(strState);
}

export function setTimeTableStateToSession(timeTableState: ITimeTableState) {
  window.sessionStorage.setItem(SESSION_STORAGE_TIME_TABLE_KEY, JSON.stringify(timeTableState));
}

export function getTimeTableSelectedEventStateFromSession(): ISelectedTimeTableEventState | null {
  const strState = window.sessionStorage.getItem(SESSION_STORAGE_SELECTED_EVENT_KEY);

  if (!strState) {
    return null;
  }
  return JSON.parse(strState);
}

export function setTimeTableSelectedEventStateToSession(timeTableState: ISelectedTimeTableEventState) {
  window.sessionStorage.setItem(SESSION_STORAGE_SELECTED_EVENT_KEY, JSON.stringify(timeTableState));
}

export function getAppUserStateFromSession(): IAppUserSessionState | null {
  const strState = window.sessionStorage.getItem(SESSION_STORAGE_APP_USER_STATE_KEY);

  if (!strState) {
    return null;
  }
  return JSON.parse(strState);
}

export function setAppUserStateToSession(appSessionState: IAppUserSessionState) {
  window.sessionStorage.setItem(SESSION_STORAGE_APP_USER_STATE_KEY, JSON.stringify(appSessionState));
}

export function getTimeTablePointsSelectedEventStateFromSession(): ISelectedTimeTablePointsEventState | null {
  const strState = window.sessionStorage.getItem(SESSION_STORAGE_SELECTED_POINTS_EVENT_KEY);

  if (!strState) {
    return null;
  }
  return JSON.parse(strState);
}

export function setTimeTablePointsSelectedEventStateToSession(timeTableState: ISelectedTimeTablePointsEventState) {
  window.sessionStorage.setItem(SESSION_STORAGE_SELECTED_POINTS_EVENT_KEY, JSON.stringify(timeTableState));
}

export function getSafetyRefreshDateFromSession(): string | null {
  const strState = window.sessionStorage.getItem(SESSION_STORAGE_SAFETY_REFRESH_DATE);

  if (!strState) {
    return null;
  }
  return JSON.parse(strState);
}

export function setSafetyRefreshDateToSession(saeftyRefreshDate: string) {
  window.sessionStorage.setItem(SESSION_STORAGE_SAFETY_REFRESH_DATE, JSON.stringify(saeftyRefreshDate));
}
