import React from 'react';
import moment from 'moment';
import { EventState, ITimeTableItem, ITimeTableItemState } from '@App/types';
import { EventsStatesResponse } from '@App/services/events/eventsApi';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { List } from '@material-ui/core';
import TimeTableListItem from './TimeTableListItem';
import { TIME_TABLE_LIST_ELEMENT_ID, HI_RES_DEVICE_THRESHOLD_PX } from '@App/constants';

interface EventWithStateAndNow {
  startDateTime: string;
  hasNow: boolean;
  state: ITimeTableItemState | null,
}

type StyleProps = {
  isHiResDevice: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    list: {
      padding: 0,

      '& .MuiListItem-root': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        padding: 0
      },
      '& .MuiListItem-root:nth-child(even)': {
        background: theme.palette.table.altRowBackground,
      },
      '& .Mui-selected': {
        background: 'rgba(255, 255, 255, 0.18) !important'
      },
      '& .item-day': {
        width: '37px',
        padding: '13px 8px 12px 8px',
        borderColor: theme.palette.table.borderColor,
        borderRight: `0px solid ${theme.palette.table.borderColor}`,
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& .item-time': {
        width: '55px',
        padding: '13px 8px 12px 8px',
        borderColor: theme.palette.table.borderColor,
        borderRight: `0px solid ${theme.palette.table.borderColor}`,
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& .item-event': {
        padding: '7px 8px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        paddingRight: '20px',
        width: 'calc(100% - 56px)',

        '& .event-name-wrapper': {
          whiteSpace: 'nowrap',
          position: 'relative',
          overflow: 'hidden',
          width: 'calc(100% - 16px)',
        },
        '& .event-name': {
          fontWeight: 'bold',
          fontSize: '15px',
        },
        '& .event-sub-meeting-category': {
          lineHeight: '10px',
          fontSize: '11px',
          overflow: 'hidden',
          height: '11px',
          maxWidth: ({ isHiResDevice }) => isHiResDevice ? '240px' : '160px',

          '&.has-sub-meeting .slide-container': {
            position: 'relative',
            animationName: 'timeTableSubmeetingFadeInOut',
            animationDuration: '10s',
            animationIterationCount: 'infinite',
            backfaceVisibility: 'hidden',
            transform: 'translateZ(0)',
          },
        },
        '& .event-sub-meeting': {
          color: theme.palette.primary.light,
          textTransform: 'uppercase',
          letterSpacing: '1px',
          backfaceVisibility: 'hidden',
          transform: 'translateZ(0)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        '& .event-category': {
          color: theme.palette.primary.light,
          textTransform: 'uppercase',
          letterSpacing: '1px',
          marginLeft: '1px',
          whiteSpace: 'nowrap',
          backfaceVisibility: 'hidden',
          transform: 'translateZ(0)',

          '& i': {
            color: '#fff',
          }
        },
        '& .state-icon': {
          position: 'absolute',
          right: '18px',
          top: '12px',
          height: '100%',
          fontSize: '15px',
          opacity: '0.7'
        }
      }
    },
  }),
);

type TimeTableListProps = {
  filteredTimeTable: ITimeTableItem[],
  selectedTimeTableId: number | null,
  timeTableById: { [id: number]: ITimeTableItem }
  hasMultipleDays: boolean,
  eventStatesData: EventsStatesResponse | undefined,
  onTimeTableItemSelected: (newSelectedTimeTableItem: ITimeTableItem) => void,
  serverTime: string | null,
};

function TimeTableList({ filteredTimeTable, selectedTimeTableId, timeTableById, hasMultipleDays, eventStatesData, serverTime, onTimeTableItemSelected }: TimeTableListProps) {
  const isHiResDevice = useMediaQuery(`(min-width:${HI_RES_DEVICE_THRESHOLD_PX})`);
  const classes = useStyles({ isHiResDevice });

  return (
    <List id={TIME_TABLE_LIST_ELEMENT_ID} component="nav" className={classes.list}>
      {mapTimeTableItems(filteredTimeTable, eventStatesData, timeTableById, selectedTimeTableId, hasMultipleDays, onTimeTableItemSelected, serverTime)}
    </List>
  );
}

function mapTimeTableItems(
  filteredTimeTable: ITimeTableItem[], eventStatesData: EventsStatesResponse | undefined, timeTableById: { [id: number]: ITimeTableItem }, selectedTimeTableId: number | null,
  hasMultipleDays: boolean, onTimeTableItemSelected: (newSelectedTimeTableItem: ITimeTableItem) => void, serverTime: string | null) {

  const eventsWithStates = new Array<EventWithStateAndNow>();
  const mappedEvents = [];
  const mServerTime = serverTime ? moment(serverTime) : null;
  //const mServerTime = moment('2023-02-28 14:03:00');
  let foundAfterEvent = false;
  let foundBeforeEvent = false;
  let jumpedBackToScheduledEvent = false;

  if (mServerTime) {
    for (let i=0; i<filteredTimeTable.length; i += 1) {
      let eachEvent = filteredTimeTable[i];
      let hasNowIndicator = false;

      if (mServerTime && mServerTime.isSame(eachEvent.startDateTime, 'day')) {
        if (!foundAfterEvent) {
          if (mServerTime.isAfter(eachEvent.startDateTime)) {
            foundAfterEvent = true;
          }
        } else if (!foundBeforeEvent) {
          if (mServerTime.isBefore(eachEvent.startDateTime)) {
            hasNowIndicator = true;
            foundBeforeEvent = true;
          }
        }
      }

      // Ok, našli jsme nejbližší další event v časáku, ale pokud není minutový časák, tak se musíme podívat "dozadu" na poslední scheduled
      if (hasNowIndicator) {
        let prevNowCandidate = null;

        for (let j = i - 1; j >= 0; j -= 1) {
          const eachPrevEventWithState = eventsWithStates[j];
          const prevEventState = eachPrevEventWithState.state?.state;
          const eachPrevEvent = eachPrevEventWithState.state ? timeTableById[eachPrevEventWithState.state.id] : null;

          if (prevEventState === EventState.InProgress && eachPrevEvent && (eachEvent.disciplineId === eachPrevEvent.disciplineId && eachEvent.categoryId === eachPrevEvent.categoryId) && (eachEvent.startDateTime === eachPrevEvent.startDateTime)) { // Pokud jsou dva rozběhy na dlouhé běhy ve stejném čase tak indikátor zobrazit pod tim co je "in progress"
            prevNowCandidate = eachPrevEventWithState;
            break;
          }
          else if (prevEventState === EventState.Scheduled && eachPrevEvent && (eachEvent.startDateTime === eachPrevEvent.startDateTime)) { // Pokud je scheduled, tak ale musí mít stejný čas startu v minutovém časáku
            // jj ale indikátor ukazumeme až na následujícím
            prevNowCandidate = eventsWithStates[j + 1];
          } else {
            // Ještě je zde varianta, kdy je třeba 14:03, máme najjitý další závod ve 14:05, ale v časáku jsou závody ve 14:00, které ještě neproběhly / shleduled
            // takže půjdeme dozadu na schleduled
            if (!jumpedBackToScheduledEvent && eachPrevEvent !== null && prevEventState === EventState.Scheduled) {
              eachEvent = eachPrevEvent; // tohle fakticky přepne do těch větví výše
              jumpedBackToScheduledEvent = true; // Ale jen jednou toto
            } else {
              break;
            }
          }
        }

        if (prevNowCandidate) {
          prevNowCandidate.hasNow = true;
          hasNowIndicator = false;
        }
      }

      // eventState.state ---> fast update from polling
      // t.state ---> standard (backup) update with timetable
      const eventState = eventStatesData ? eventStatesData.states[eachEvent.id] : null;

      eventsWithStates.push({
        startDateTime: eachEvent.startDateTime,
        hasNow: hasNowIndicator,
        state: eventState,
      });
    }
  }

  for (let i=0; i<filteredTimeTable.length; i += 1) {
    const eachEvent = filteredTimeTable[i];
    const eachEventWithState = mServerTime ? eventsWithStates[i] : null;

    mappedEvents.push(<TimeTableListItem
      key={eachEvent.id}
      timeTableItem={eachEvent}
      selectedTimeTableId={selectedTimeTableId}
      hasMultipleDays={hasMultipleDays}
      eventState={eachEventWithState ? eachEventWithState.state : null}
      hasNowIndicator={eachEventWithState?.hasNow || false}
      onTimeTableItemSelected={onTimeTableItemSelected}
    />);
  }

  return mappedEvents;
}

export default TimeTableList;