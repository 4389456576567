import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      marginLeft: '5px',
      marginRight: '10px',
      marginBottom: '-1px',

      '&.no-venue-time': {
        marginTop: '-2px',
      }
    },
    mainFlexWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexWrapper: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'right',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '16px',
      width: '100%',
      height: '100%',
    },
    hours: {
      position: 'relative',
      zIndex: 1,
      lineHeight: '15px',
    },
    minutes: {
      position: 'relative',
      zIndex: 1,
      lineHeight: '15px',
    },
    seconds: {
      position: 'relative',
      zIndex: 1,
      color: `${theme.palette.primary.light}`,
      padding: '0 3px',
      fontSize: '22px',
      marginTop: '-4px',
      width: '10px',
      marginLeft: '-1px',
      animation: 'blinker 2s step-start infinite',
      lineHeight: '27px',
    },
    venueTime: {
      fontSize: '9px',
      lineHeight: '10px',
      marginRight: '0px',
      marginTop: '2px',
      marginBottom: '-4px',
      opacity: '0.8',
      letterSpacing: '2px',
      fontWeight: 'normal',
    }
  }),
);

type ClockProps = {
  isPublicInstance: boolean,
  serverTime: string,
};

function Clock({ isPublicInstance, serverTime }: ClockProps) {
  const classes = useStyles();
  const [ millisecondsDiff, setMillisecondsDiff ] = useState<number>(0);

  useEffect(() => {
    let diffTimmer:NodeJS.Timeout|null = null;
    const startLocalTime = moment();

    diffTimmer = setInterval(() => {
      if (!startLocalTime) return;

      const currentTime = moment();
      const timeDiff = moment.duration(currentTime.diff(startLocalTime)).seconds();

      setMillisecondsDiff(timeDiff);
    }, 500);

    return () => {
      if (diffTimmer) {
        clearTimeout(diffTimmer);
      }
    };
  }, [serverTime]);

  const currentTime = moment(serverTime).add(millisecondsDiff, 's');

  return (
    <div className={clsx(classes.root, !isPublicInstance && 'no-venue-time')}>
      <div className={classes.mainFlexWrapper}>
        {isPublicInstance && <div className={classes.venueTime}>Venue time</div>}
        <div className={classes.flexWrapper}>
          <div className={classes.hours}>{currentTime.format('HH')}</div>
          <div className={classes.seconds}>:</div>
          <div className={classes.minutes}>{currentTime.format('mm')}</div>
        </div>
      </div>
    </div>
  );
}

export default Clock;