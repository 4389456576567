import { IFinalLapListItem } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type FinalLapListApiResponse = IFinalLapListItem[];

export function fetchFinalLapListData(abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/final-lap-list`, {
    signal: abortSignal,
  }).then(async (response): Promise<FinalLapListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get FinalLapList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchFinalLapListData
};

export default defaultExport;
