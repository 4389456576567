import { IPointListItem, IPolyathlonPointList, ITimeTableItemStatePoints } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type PointListApiResponse = {
  data: IPointListItem[],
  eventState: ITimeTableItemStatePoints,
}

export type PolyathlonPointListApiResponse = {
  data: IPolyathlonPointList,
  eventState: ITimeTableItemStatePoints,
}

export function fetchPointListData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/pointlist/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<PointListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get Points data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

export function fetchPolyathlonPointListData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/pointlist/${timeTableId}/polyathlon`, {
    signal: abortSignal,
  }).then(async (response): Promise<PolyathlonPointListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get Polyathlon Points data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}



const defaultExport = {
  fetchPointListData,
  fetchPolyathlonPointListData,
};

export default defaultExport;
