import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem } from '@App/types';

import { useStyles } from './VictoryCeremonyTabContent.styles';
import { DisciplineType, isTechnicalDisciplineClass } from '@App/disciplines';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getVictroyCeremonyDataAsync,
} from './victoryCeremonySlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import RecordsIndicator from '@Features/timeTableSelectedEvent/resultList/_components/RecordsIndicator';
import StartCardIndicator from '@Features/timeTableSelectedEvent/resultList/_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass } from '@Utils/ui';


type VictoryCeremonyTabContentProps = {
  selectedEvent: ITimeTableItem
}

type PollTimeoutTickHandler = () => PollingPromise;

function VictoryCeremonyTabContent({ selectedEvent }: VictoryCeremonyTabContentProps) {
  const dispatch = useAppDispatch();
  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');

  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const resultListDataItems = resultListData ? resultListData.resultItems : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);

  const classes = useStyles({ hasAnyQualify });
  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;
  const isTechnicalDiscipline = isTechnicalDisciplineClass(selectedEvent.disciplineClass);
  const hasMultiplePhases = resultListData && resultListData.phaseNumbers && resultListData.phaseNumbers.length > 1;

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getVictroyCeremonyDataAsync(selectedEvent.id));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.resultsRefreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
    {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
    <div id="resultListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          {hasMultiplePhases &&
          <div className={classes.victoryCeremonyInfo}>
            Victory ceremony of <span>{selectedEvent.eventNameFull} {selectedEvent.eventCategoryFull} {selectedEvent.eventPhase} {resultListData?.phaseNumbers.join(', ')}</span>
          </div>}
          <table className={clsx(classes.startListTable, hasMultiplePhases && 'has-multiple-phases')}>
              <colgroup>
                <col style={{ width: '65px' } /* Place */} />
                <col style={{ width: '1%', minWidth: isRelays ? '590px' : '410px' } /* Athlete */} />
                <col style={{ width: '80px' } /* Result */} />
                <col style={{ width: '30px' } /* Gap */} />
                {frontendSettings?.hasPoints && <col style={{ width: '30px' } /* Points */} />}
                {!isTechnicalDiscipline && selectedEvent.hasReactions && <col style={{ width: '30px' } /* Reaction */} />}
                {hasMultiplePhases && <col style={{ width: '30px' } /* Lane */} />}
                <col style={{ width: '100px' } /* PB */} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                    {isRelays ? 'Relay' : 'Athlete'}
                    {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
                  </th>
                  <th className="text-right">Result</th>
                  <th className="text-right">Gap</th>
                  {frontendSettings?.hasPoints && <th className="text-right">Points</th>}
                  {!isTechnicalDiscipline && selectedEvent.hasReactions && <th className="text-right">Reaction</th>}
                  {hasMultiplePhases && <th className="text-center" style={{ paddingLeft: '25px' }}>Phase</th>}
                  <th className="text-center">PB / SB</th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
              {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
                <tr key={participiant.participiantId} className={getPlaceCssClass(participiant.place)}>
                  <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays')}><span>{participiant.place}</span></td>
                  <td className="text-left col-athlete">
                    <div className="athlete-flag-container">
                      <div className="flag-container">
                        <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                        <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                      </div>
                      <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                        <div className="athlete-name">
                          {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                          {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                          {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                          {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                          {isRelays && participiant.relayMembers &&
                          <ul className="relay-members">
                            {participiant.relayMembers.map(member => (
                              <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                            ))}
                          </ul>}
                        </div>
                        {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                        {participiant.coach && <div className="athlete-coach"><span>Coach:</span> {participiant.coach}</div>}
                        {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                      </div>
                    </div>
                  </td>
                  <td className="text-right col-result">
                    <div className="result-record-container">
                      <span className="result">{participiant.status || participiant.result}</span>
                      {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                      <RecordsIndicator records={participiant.records} />
                    </div>
                    {participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
                  </td>
                  <td className="text-right col-gap">{participiant.timeGap}</td>
                  {frontendSettings?.hasPoints && <td className="text-right col-points">{participiant.points}</td>}
                  {!isTechnicalDiscipline && selectedEvent.hasReactions && <td className="text-right col-reaction">
                    <span className={clsx(participiant.reactionRank === 1 && 'fastest')}>{participiant.reaction}</span> {participiant.reactionRank && <span className="reaction-rank">{participiant.reactionRank}</span>}
                  </td>}
                  {hasMultiplePhases && <td className="text-center col-result-lane">{participiant.placeInPhase}</td>}
                  <td className="text-center col-result-pb-sb">
                    <div className="result-pb">{participiant.personalBest}</div>
                    <div className="result-sb">{participiant.seasonBest}</div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
              {!error && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
              <tr>
                <td colSpan={100} className="col-no-athletes">
                  <div>There are no victory ceremony data for this event yet</div>
                </td>
              </tr>}
              </tbody>
            </table>
          </LegacyTouchWrapper>
        </SimpleBar>
      </div>
    </div>
  );
}

export default VictoryCeremonyTabContent;
