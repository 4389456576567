import React from 'react';
import styled from 'styled-components';
import { ITimeTableItem, ITimeTableItemState, } from '@App/types';
import EventStateIcon from '@Components/eventStateIcon';

import { ListItem } from '@material-ui/core';
import clsx from 'clsx';
import { shortenEventPhase } from '@Utils/ui';

/**
 * Now indicator
 */
interface INowIndicatorProps {
  hasStartOfMainProgramme: boolean;
}

const NowIndicator = styled.div<INowIndicatorProps>`
  border: 1px solid #43BF4D;
  position: absolute;
  width: 100%;
  top: -1px;
  left: 0;
  opacity: 0.8;
  visibility: ${props => props.hasStartOfMainProgramme ? 'hidden' : 'visible'};

  &:after {
    content: 'NOW';
    position: absolute;
    top: 0;
    left: 1px;
    font-size: 10px;
    color: #43BF4D;
  }
`;

/**
 * Start of main programme indicator
 */
interface IStartOfMainProgrammeIndicatorProps {
  hasNowIndicator: boolean;
}

const StartOfMainProgrammeIndicator = styled.div<IStartOfMainProgrammeIndicatorProps>`
  border: 1px solid ${props => props.hasNowIndicator ? '#43BF4D' : '#FFF'};
  position: absolute;
  width: 100%;
  top: -5px;
  left: 0;
  opacity: 0.8;

  &:after {
    content: 'MAIN PROGRAMME START';
    position: absolute;
    top: 0;
    left: 1px;
    font-size: 10px;
    color: ${props => props.hasNowIndicator ? '#43BF4D' : '#FFF'};
  }
`;

function getGenderIconName(gender: string | undefined) {
  switch (gender) {
    case 'M':
      return 'mars';
    case 'W':
      return 'venus';
    default:
      return 'venus-mars';
  }
}

type TimeTableIdSelectedHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

type TimeTableListItemProps = {
  timeTableItem: ITimeTableItem,
  selectedTimeTableId: number | null,
  hasMultipleDays: boolean,
  eventState: ITimeTableItemState | null,
  hasNowIndicator: boolean,
  onTimeTableItemSelected: (newSelectedTimeTableItem: ITimeTableItem) => void,
};

function TimeTableListItem({ timeTableItem, selectedTimeTableId, hasMultipleDays, eventState, hasNowIndicator, onTimeTableItemSelected }: TimeTableListItemProps) {
  const handleTimeTableIdSelected: TimeTableIdSelectedHandler = (event) => {
    onTimeTableItemSelected(timeTableItem);
  };

  return (
    <ListItem
      key={timeTableItem.id}
      button
      selected={timeTableItem.id === selectedTimeTableId}
      onClick={handleTimeTableIdSelected}
      data-timetableid={timeTableItem.id}
      data-starttime={timeTableItem.startDateTime}
      id={hasNowIndicator ? 'timeTableNow' : ''}
      className={hasNowIndicator ? 'now-indicator' : ''}>
        {hasNowIndicator && <NowIndicator hasStartOfMainProgramme={timeTableItem.startOfMainProgramme} />}
        {timeTableItem.startOfMainProgramme && <StartOfMainProgrammeIndicator hasNowIndicator={hasNowIndicator} />}
        {hasMultipleDays && <span className="item-day">{timeTableItem.day}</span>}
        <span className="item-time">{timeTableItem.startTime}</span>
        <span className="item-event">
          <span className="event-name-wrapper">
            <span className="event-name">{timeTableItem.eventName}</span> {timeTableItem.eventPhase && <span>&nbsp;{shortenEventPhase(timeTableItem.eventName, timeTableItem.eventPhase)}</span>}{timeTableItem.eventPhaseNumber && <span>&nbsp;{timeTableItem.eventPhaseNumber}</span>}
          </span>

          <div className={clsx('event-sub-meeting-category', timeTableItem.eventSubMeeting && 'has-sub-meeting')}>
            <div className="slide-container">
              {timeTableItem.eventSubMeeting && <div className="event-sub-meeting">{timeTableItem.eventSubMeeting}</div>}
              <div className="event-category"><i className={`fas fa-${getGenderIconName(timeTableItem.gender)}`}></i> {timeTableItem.eventCategoryFull}</div>
            </div>
          </div>
          <EventStateIcon eventState={eventState ? eventState.state : timeTableItem.state} isStartlistOfficial={eventState ? eventState.isStartlistOfficial : timeTableItem.isStartlistOfficial} />
        </span>
    </ListItem>
  );
}

export default TimeTableListItem;