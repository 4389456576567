import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
  Link as RouterLink,
  useRouteMatch
} from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',

      '&.MuiButton-outlined': {
        background: '#fff',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        color: theme.palette.primary.dark,
      }
    }
  }),
);

type NavButtonProps = {
  to: string;
  className: string;
  activeOnlyWhenExact?: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

function NavButton({ to, className, activeOnlyWhenExact, children, onClick }: NavButtonProps) {
  const classes = useStyles();

  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Button
      variant={match ? 'outlined' : 'contained'}
      color={match ? 'inherit' : 'primary'}
      className={`${className} ${classes.button}`}
      component={RouterLink}
      to={to}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default NavButton;