import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  hasAnyQualify?: boolean | null;
}


export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
createStyles({
  root: {
    height: '100%',
    position: 'relative',
  },
  scrollContainer: {
    height: '100%',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'auto'
  },
  horizontalScrollContainer: {
    display: 'flex',
    height: '100%',
  },
  simpleBar: {
    height: '100%',
  },
  progress: {
    position: 'absolute',
    right: '50%',
    bottom: '50%',

    '&.data-loaded': {
      position: 'absolute',
      right: '7px',
      bottom: '7px',
    }
  },
  unofficialTransponderInfo: {
    background: theme.palette.records.lighter,
    fontSize: '14px',
    letterSpacing: '1px',
    color: '#fff',
    padding: '6px 8px 0px 13px',
    textTransform: 'uppercase',

    '& span': {
      fontWeight: 'bold',
      fontSize: '15px',
      color: theme.palette.primary.light,
    }
  },
  intermediateTimesTableScrollWrapper: {
    overflowX: 'auto',
    height: '100%',
    width: 'calc(100% - 660px)',
  },
  intermediateTimesTable: {
    borderCollapse: 'collapse',
    width: '100%',

    '& td, th': {
      whiteSpace: 'nowrap',
    },
    '& thead th': {
      border: `0px solid ${theme.palette.table.borderColor}`,
      background: theme.palette.records.lighter,
      borderTop: '0',
      borderLeft: '0',
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1px',
      color: theme.palette.primary.light,
      padding: '6px 8px 5px 6px',
      position: 'sticky',
      zIndex: '2',
      top: '0',

      '&.col-metas-header': {
        textTransform: 'none',

        '&.is-current': {
          color: '#000',
          backgroundColor: '#fff',
        }
      }
    },
    '& thead th:last-child': {
      borderRight: '0',
    },
    '& tbody tr:nth-child(even)': {
      background: theme.palette.table.altColBackground,
    },
    '& tbody tr:nth-child(odd)': {
      background: theme.palette.table.altOddColBackground,
    },
    '& tbody tr td': {
      borderBottom: `1px solid ${theme.palette.table.borderColor}`,
      borderTop: '0',
      borderLeft: '0',
      padding: '3px 8px',
      height: '49px',
    },
    '& tbody tr td:last-child': {
      borderRight: '0',
    },
    '& td.col-intermediate-times': {
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      borderRight: `1px solid ${theme.palette.table.borderColor}`,
      textAlign: 'right',

      '& .intermediate-time': {
        '& .place': {
          opacity: '0.8',
          fontWeight: 'normal',
          fontSize: '13px',
        },
        '& .place::before': {
          content: '"("',
          paddingRight: '1px',
          opacity: '0.4',
        },
        '& .place::after': {
          content: '")"',
          paddingLeft: '1px',
          opacity: '0.4',
        }
      }
    },
  },
  startListTable: {
    borderCollapse: 'collapse',
    width: '660px',
    fontWeight: 'bold',
    borderRight: `2px solid ${theme.palette.table.borderColor}`,

    '& td, th': {
      whiteSpace: 'nowrap',
    },
    '& thead th': {
      border: `0px solid ${theme.palette.table.borderColor}`,
      background: theme.palette.records.lighter,
      borderTop: '0',
      borderLeft: '0',
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1px',
      color: theme.palette.primary.light,
      padding: '6px 8px 5px 6px',
      position: 'sticky',
      zIndex: '2',
      top: '0',

      '& .bib': {
        float: 'right',
        opacity: '0.8',
      },
      '& .athlete-count': {
        paddingLeft: '8px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '0.8',
        paddingRight: '8px',
      },
      '&.col-metas-header': {
        textTransform: 'none',
      }
    },
    '& thead th:last-child': {
      borderRight: '0',
    },
    '& tbody tr:nth-child(even)': {
      background: theme.palette.table.altRowBackground,

      '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-laps, .col-red-cards, .col-reaction, .col-record, .col-intermediate-times': {
        background: theme.palette.table.altColBackground,
      },
      '& td.col-athlete .athlete-container::after': {
        background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
      },
    },
    '& tbody tr:nth-child(odd)': {
      '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-laps, .col-red-cards, .col-reaction, .col-record, .col-intermediate-times': {
        background: theme.palette.table.altOddColBackground,
      },
      '& td.col-athlete .athlete-container::after': {
        background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
      },
    },
    '& tbody tr td': {
      borderBottom: `1px solid ${theme.palette.table.borderColor}`,
      borderTop: '0',
      borderLeft: '0',
      padding: '3px 8px',
      height: '49px',
    },
    '& tbody tr td:last-child': {
      borderRight: '0',
    },
    '& td.col-lane': {
      fontSize: '18px',

      '&.place-first span, &.place-second span, &.place-third span': {
        color: '#000',
        borderRadius: '4px',
        padding: '3px 10px',
      },
      '&.place-first span': {
        backgroundColor: '#FFC540',
      },
      '&.place-second span': {
        backgroundColor: '#D3D3D3',
      },
      '&.place-third span': {
        backgroundColor: '#DC8B53',
      },
      '&.unof-place-first span, &.unof-place-second span, &.unof-place-third span': {
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textUnderlineOffset: '2px',
      },
      '&.unof-place-first span': {
        textDecorationColor: '#FFC540',
      },
      '&.unof-place-second span': {
        textDecorationColor: '#D3D3D3',
      },
      '&.unof-place-third span': {
        textDecorationColor: '#DC8B53',
      },
    },
    '& td.col-lane-relays': {
      verticalAlign: 'top',
      paddingTop: '12px',
    },
    '& td.col-athlete': {
      position: 'relative',

      '& .athlete-flag-container': {
        display: 'flex',
      },
      '& .flag-container': {
        position: 'relative',
        width: '41px',
        height: '27px',
        marginRight: '15px',
        marginTop: '5px',
        border: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& .flag': {
        width: '41px',
        height: '27px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '41px 27px',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& .athlete-container.has-bibs': {
        position: 'relative',
        overflow: 'hidden',
        width: '250px',

        '&::after': {
          content: "''",
          position: 'absolute',
          height: '100%',
          width: '20px',
          right: 0,
          top: 0,
        },
        '&.has-bibs': {
          width: '210px',
        }
      },
      '& .athlete-name': {
        fontSize: '16px',

        '& .first-name': {
          fontWeight: 'normal',
          opacity: '0.8',
        },
        '& .relay-name': {
          fontSize: '19px',
          fontWeight: 'bold',
          opacity: '1',
        },
        '& .relay-label': {
          paddingLeft: '7px',
          opacity: '1',
        },
        '& .relay-members': {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          listStyleType: 'none',
          fontWeight: 'normal',
          fontSize: '14px',
          margin: '0',
          padding: '0',

          '& li': {
            padding: '2px 5px 2px 0',
            fontSize: '12px',
            width: '220px',
          },
          '& .member-yob': {
            opacity: '0.5',
            paddingLeft: '4px',
          },
          '& .member-last-name': {
            fontWeight: 'bold',
            fontSize: '13px',
            paddingLeft: '3px',
          },
          '& .member-first-name': {
            opacity: '0.8',
          },
          '& .member-order': {
            background: theme.palette.secondary.dark,
            borderRadius: '4px',
            padding: '2px 4px',
            fontWeight: 'bold',
          }
        }
      },
      '& .club-name': {
        fontWeight: 'normal',
        color: theme.palette.textTabs.club,
        fontSize: '13px',
      },
      '& .startlist-note': {
        fontSize: '12px',
        display: 'inline-block',
        marginLeft: '5px',
        fontWeight: 'normal',
        background: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '1px 6px 0',
        opacity: '0.9',
      },
      '& .bib': {
        fontSize: '17px',
        color: theme.palette.textTabs.bib,
        position: 'absolute',
        right: '0',
        top: '9px',
      }
    },
    '& td.col-yob': {
      fontWeight: 'normal',
      '& span': {
        display: 'block',
        fontSize: '11px',
        opacity: '0.5'
      }
    },
    '& td.col-result': {
      position: 'relative',
      paddingLeft: '15px',
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      borderRight: `1px solid ${theme.palette.table.borderColor}`,
      paddingRight: ({ hasAnyQualify }) => hasAnyQualify ? '22px' : '8px',

      '& .result-record-container': {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      '& .result-disqualify-rule': {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '24px',
        paddingRight: '6px',
        opacity: '0.7',
      },
      '& .result': {
        fontSize: '16px',
        color: theme.palette.textTabs.result,

        '&.has-records': {
          paddingTop: '9px',
        },
        '& .result-last-meta': {
          color: '#fff',
          display: 'block',
          fontSize: '12px',
          fontWeight: 'normal',

          '& .last-meta-sort': {
            opacity: 0.5,
            paddingRight: '3px',
          }
        }
      },
      '& .result-qualify': {
        fontSize: '14px',
        color: theme.palette.primary.light,
        fontWeight: 'normal',
        paddingLeft: '0',
        position: 'absolute',
        top: '13px',
        right: '6px',

        '&.conflicting': {
          fontSize: '12px',
          top: '15px',
          right: '4px',
        }
      }
    },
    '& td.col-gap': {
      borderRight: `1px solid ${theme.palette.table.borderColor}`,
      fontWeight: 'normal',
      opacity: '0.7',
    },
    '& td.col-laps': {
      borderRight: `3px solid ${theme.palette.table.borderColorLighter}`,
      fontWeight: 'normal',
      opacity: '0.7',
    },
    '& td.col-red-cards': {
      borderRight: `3px solid ${theme.palette.table.borderColorLighter}`,
      fontWeight: 'normal',
      opacity: '0.7',
    },
    '& td.col-result-lane': {
      fontWeight: 'normal',
      fontSize: '13px',
      opacity: '0.7',
      paddingLeft: '5px',
    },
    '& td.col-result-pb-sb': {
      borderLeft: 'none',
      opacity: '0.7',
      fontWeight: 'normal',
      fontSize: '13px',

      '& .result-sb': {
        fontSize: '11px',
      }
    },
    '& td.col-pb': {
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
    },
    '& td.col-sb': {
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
    },
    '& td.col-warank': {
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      borderRight: `1px solid ${theme.palette.table.borderColor}`,
      fontWeight: 'normal',
    },
    '& td.col-qualify': {
      borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      fontWeight: 'normal',

      '& .qualify-type': {
        color: theme.palette.primary.light,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '23px',
        marginRight: '3px',
        fontSize: '13px',
      }
    },
    '& .col-no-athletes': {
      fontSize: '18px',
      textAlign: 'center',
      padding: '30px 10px',

      '& div': {
        /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
        animationName: 'fadeIn',
        animationDuration: '1s',
        transitionTimingFunction: 'linear',
      }
    },
    '& .text-right': {
      textAlign: 'right',
    },
    '& .text-left': {
      textAlign: 'left',
    },
    '& .text-center': {
      textAlign: 'center',
    },

    '&:not(.has-startlist-data)': {
      '& th .bib': {
        marginRight: '8px',
      },
      '& td.col-athlete': {
        paddingRight: '30px',

        '& .bib': {
          right: '8px',
        }
      },
    }
  },
}),
);
