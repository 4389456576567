import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { ITopListItem, TimeTableEventTabType } from '@App/types';
import { RootState } from '@App/store';

import topListApi from '@App/services/topList/topListApi';
import { setActiveTab } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { eventsApi } from '@App/services/events/eventsApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { selectTopListType, selectTopListIsIndoor, selectTopListSeasonYear } from '@App/appUserStateSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

export interface IGetTopListDataAsyncPayload {
  timeTableId: number;
  isInternationalMeeting: boolean | undefined;
  currentSeasonYear: number | undefined;
}

export interface ITopListState {
  changeTimestamp: number; // Tohle trigeruje mount/unmout pooling kontejneru, tak aby se forsnul reload při znovu kliku (jinak se vysypal state)
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: ITopListItem[] | null;
}

const initialState: ITopListState = {
  changeTimestamp: 0,
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: null,
};

export const getTopListDataAsync = createAsyncThunk(
  'topList/fetchData',
  async ({ timeTableId, isInternationalMeeting, currentSeasonYear }: IGetTopListDataAsyncPayload, { dispatch, rejectWithValue, signal, getState }) => {
    try {
      const state = getState() as RootState;
      const topListType = selectTopListType(state);
      const isIndoor = selectTopListIsIndoor(state);
      let seasonYear = selectTopListSeasonYear(state);

      if (isInternationalMeeting && currentSeasonYear !== undefined) {
        seasonYear = currentSeasonYear;
      }

      const response = await topListApi.fetchTopListData(timeTableId, signal, topListType, isIndoor, seasonYear);

      const thunkDispatch = dispatch as ThunkDispatch<any, any, AnyAction>; // Nutno to přetypovat, TypeScript si s tím nějak
      thunkDispatch(eventsApi.util.updateQueryData('getEventsStates', '', draft => {
        draft.states[timeTableId] = response.eventState;
      }));

      dispatch(pollingSucceeded());

      return response.data;
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'TopList', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const topListListSlice = createSlice({
  name: 'topListListSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopListDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getTopListDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getTopListDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(setActiveTab, (state, action) => { // Clear state
        if (action.payload === TimeTableEventTabType.TopList) {
          state.changeTimestamp = Date.now();
        } else {
          Object.assign(state, initialState);
        }
      })
      .addCase(setSelectedTimeTableId, (state, action) => {
        if (action.payload.hasChanged) {
          Object.assign(state, initialState);
        } else {
          state.changeTimestamp = Date.now();
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.topList.isFetching;
export const selectError = (state: RootState) => state.topList.error;
export const selectDataLoaded = (state: RootState) => state.topList.dataLoaded;
export const selectData = (state: RootState) => state.topList.data;
export const selectChangeTimestamp = (state: RootState) => state.topList.changeTimestamp;

export default topListListSlice.reducer;