import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TimeTable from '@Features/timeTable';
import TimeTableSelectedEvent from '@Features/timeTableSelectedEvent';
import InitErrorHandler from '@Features/errorHandler/init/InitErrorHandler';

import { HI_RES_DEVICE_THRESHOLD_PX } from '@App/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    columnLeft: {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(0.5),
    },
    columnRight: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingBottom: '4px',
    }
  }),
);

function MainScreen() {
  const classes = useStyles();
  const isHiResDevice = useMediaQuery(`(min-width:${HI_RES_DEVICE_THRESHOLD_PX})`);
  return (
    <div className={classes.root}>
      <InitErrorHandler />
      <Helmet title="CIS" />
      <Grid container spacing={0} className={clsx('main-screen-grid', isHiResDevice ? 'hi-res' : 'low-res')}>
        <Grid item xs={9} className={classes.columnLeft}>
          <TimeTableSelectedEvent />
        </Grid>
        {<Grid item xs={3} className={classes.columnRight}>
          <TimeTable />
        </Grid>}
      </Grid>
    </div>
  );
}

export default MainScreen;