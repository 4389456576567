import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {debounce, range} from 'lodash';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import {EventState, ITimeTableItem, ITimeTableItemState, StartCardType} from '@App/types';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getTransponderIntermediateTimesDataAsync,
} from './transponderIntermediateTimesSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './TransponderIntermediateTimesTabContent.styles';

import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';
import { DisciplineType } from '@App/disciplines';
import RecordsIndicator from '../resultList/_components/RecordsIndicator';
import StartCardIndicator from '../resultList/_components/StartCardIndicator';
import { getCompetitorCount, getCountryFlagImage, getFlagOrClubImage, getPlaceCssClass, getUnofficialPlaceCssClass } from '@Utils/ui';
import SmallPlaceChangeIndicator from './SmallPlaceChangeIndicator';

type TransponderIntermediateTimesTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
}

type PollTimeoutTickHandler = () => PollingPromise;

function TransponderIntermediateTimesTabContent({ selectedEvent, selectedEventState }: TransponderIntermediateTimesTabContentProps) {
  const dispatch = useAppDispatch();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const verticalScrollableNodeRef = React.createRef<any>();
  const horizontalScrollableNodeRef = React.createRef<any>();
  const [prevCurrentMeta, setPrevCurrentMeta] = useState<number | undefined | null>();
  const [intermediateTimesHeaderStickyOffset, setIntermediateTimesHeaderStickyOffset] = useState<number | undefined | null>();

  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const resultListMetas = resultListData && resultListData.metas;
  const currentMeta = resultListData?.currentMeta;
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const showStartListData = selectedEventState?.state === EventState.Scheduled;

  const resultListDataItems = resultListData ? resultListData.intermediateTimes : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);

  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;
  const classes = useStyles({ hasAnyQualify });

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getTransponderIntermediateTimesDataAsync({ timeTableId: selectedEvent.id}));
  };

  useEffect(() => {
    if (!currentMeta) {
      setPrevCurrentMeta(null);
    } else if (currentMeta.meta !== prevCurrentMeta) {
      setPrevCurrentMeta(currentMeta.meta);
      const metaColumElement = document.getElementById(`col_mta_${currentMeta}`);

      if (metaColumElement && horizontalScrollableNodeRef.current) {
        horizontalScrollableNodeRef.current.scrollLeft = metaColumElement.offsetLeft - 110;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentMeta ]);

  function handleVerticalScrollerStickyHeader() {
    // Tohle je big big hack, protože pokud máme ještě horizontální scroller, tak vůbec nefunguje position: sticky, ....
    const topInfoRowHeight = 26; // Výška řádku "Unofficial results from ...."
    const verticalScrollNode = document.querySelector('#transponderIntermediateTimesScrollContainer .simplebar-content-wrapper');

    if (verticalScrollNode && verticalScrollNode.scrollTop > topInfoRowHeight) {
      setIntermediateTimesHeaderStickyOffset(verticalScrollNode.scrollTop - topInfoRowHeight);
    } else {
      setIntermediateTimesHeaderStickyOffset(0);
    }

    if (verticalScrollNode) {
      const horizontalScrollbarTopOffset = verticalScrollNode?.getBoundingClientRect().height + verticalScrollNode.scrollTop - 40;
      (document.querySelector(':root') as any).style.setProperty('--intermediate-times-horizontal-scrollbar-top-offset', `${horizontalScrollbarTopOffset}px`);
    }
  }

  const debouncedHandleVerticalScrollerStickyHeader = debounce(handleVerticalScrollerStickyHeader, 30);

  useEffect(() => {
    const current = verticalScrollableNodeRef.current;
    if (current) {
      current.addEventListener('scroll', debouncedHandleVerticalScrollerStickyHeader);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', debouncedHandleVerticalScrollerStickyHeader);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!frontendSettings) {
    return null;
  }

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.resultsRefreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="transponderIntermediateTimesScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar} scrollableNodeProps={{ ref: verticalScrollableNodeRef }}>
          {resultListData &&
          <div className={classes.unofficialTransponderInfo}>
            Unofficial intermediate results from transponders {currentMeta?.meta && <>- current distance <span>{currentMeta.metaName}</span></>}
          </div>}
          <div className={classes.horizontalScrollContainer}>
            <table className={classes.startListTable}>
              <colgroup>
                <col style={{ width: '65px' } /* Place */} />
                <col style={{ width: '30px' } /* Lane */} />
                <col style={{ width: isRelays ? '550px' : '250px' } /* Athlete */} />
                <col style={{ width: '80px' } /* Result */} />
                <col style={{ width: '30px' } /* Gap */} />
                <col style={{ width: '36px' } /* Lap */} />
                {selectedEvent.withJudgeOffice && <col style={{ width: '90px' } /* RedCards */} />}
              </colgroup>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th className="text-center" style={{ paddingLeft: '5px' }}>LN</th>
                  <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px' } : { }}>
                    {isRelays ? 'Relay' : 'Athlete'}
                    {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{getCompetitorCount(resultListDataItems)}</strong> total)</span>}
                    {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
                  </th>
                  <th className="text-right">{currentMeta?.meta || showStartListData ? `Time / Dist` : 'Photofinish'}</th>
                  <th className="text-right">Gap</th>
                  <th className="text-center">Laps</th>
                  {selectedEvent.withJudgeOffice && <th className="text-left">Red Crds</th>}
                </tr>
              </thead>
              <tbody>
              {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
                <tr key={participiant.participiantId}>
                  <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays', selectedEvent.isFinalPhase && !currentMeta?.meta ? getPlaceCssClass(participiant.place) : getUnofficialPlaceCssClass(participiant.place))}><span>{participiant.place}</span></td>
                  <td className="text-center col-result-lane">{participiant.lane}</td>
                  <td className="text-left col-athlete">
                    <div className="athlete-flag-container">
                      <div className="flag-container">
                        <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                        <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                      </div>
                      <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                        <div className="athlete-name">
                          {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                          {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                          {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                          {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                          {isRelays && participiant.relayMembers &&
                          <ul className="relay-members">
                            {participiant.relayMembers.map(member => (
                              <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                            ))}
                          </ul>}
                        </div>
                        {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                      </div>
                      {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                    </div>
                  </td>
                  <td className="text-right col-result">
                    <div className="result-record-container">
                      <span className={clsx('result', participiant.records?.length > 0 && 'has-records')}>
                        {participiant.status || participiant.result}
                        {currentMeta?.meta && participiant.transponderLastMeta && <span className="result-last-meta">{participiant.transponderLastMetaSort && <span className="last-meta-sort">( {participiant.transponderLastMetaSort} )</span>} {participiant.transponderLastMetaName}</span>}
                      </span>
                      {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                      {!currentMeta?.meta && <RecordsIndicator records={participiant.records} />}
                    </div>
                    {!currentMeta?.meta && participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
                  </td>
                  <td className="text-right col-gap">{participiant.timeGap}</td>
                  <td className="text-center col-laps">{participiant.transponderLaps}</td>
                  {selectedEvent.withJudgeOffice && <td className="text-left col-red-cards">
                    {participiant.judgeOfficeCardsCount && range(0, participiant.judgeOfficeCardsCount).map(i => (<StartCardIndicator key={i} startCard={StartCardType.Red} />))}
                  </td>}
                </tr>
              ))}
              {!error && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
              <tr>
                <td colSpan={100} className="col-no-athletes">
                  <div>There are no athletes in this event yet</div>
                </td>
              </tr>}
              </tbody>
            </table>

            <div  id="intermediateTimesScrollContainer" className={classes.intermediateTimesTableScrollWrapper}>
              <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar} scrollableNodeProps={{ ref: horizontalScrollableNodeRef }}>
                <table id="stickedIntermediateTimesTable" className={classes.intermediateTimesTable}>
                  <colgroup>
                    {resultListMetas && resultListMetas.map((meta) => (<col key={`col_${meta.meters}`} style={{ width: '103px' } } />))}
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      {resultListMetas && resultListMetas.map((meta) => (<th id={`col_mta_${meta.meters}`} key={`col_mta_${meta.meters}`} className={clsx('col-metas-header', currentMeta?.meta === meta.meters && 'is-current')} style={{ top: `${intermediateTimesHeaderStickyOffset}px`}}>{meta.splitName}</th>))}
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
                      <tr key={`interm_${participiant.participiantId}`}>
                          {resultListMetas && resultListMetas.map((meta) => {
                          const intermediateTime = participiant.intermediateTimes?.[meta.meters];

                          if (!intermediateTime) {
                            return (<td key={`im-${participiant.participiantId}_${meta.meters}`} className="col-intermediate-times" />);
                          }
                          return (
                            <td key={`im-${participiant.participiantId}_${meta.meters}`} className="col-intermediate-times">
                              <span className="intermediate-time">
                                <span className="place">{intermediateTime.place}</span>
                                <SmallPlaceChangeIndicator placeChange={intermediateTime.placeChange} />
                                <span className="time">{intermediateTime.transponderTime}</span>
                              </span>
                            </td>
                          );
                        })}
                      <td>&nbsp;</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </SimpleBar>
            </div>
          </div>
      </SimpleBar>
      </div>
    </div>
  );
}

export default TransponderIntermediateTimesTabContent;
