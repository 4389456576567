import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@App/store';
import { getTimeTablePointsSelectedEventStateFromSession, setTimeTablePointsSelectedEventStateToSession } from '@App/services/settings/sessionSettingsApi';

export interface ISelectedTimeTablePointsEventState {
  activeTab: number | null,
}

let initialState: ISelectedTimeTablePointsEventState = {
  activeTab: null,
};

type SetActiveTabPayload = {
  activeTab: number | null,
  changed: boolean,
}

const sessionInitialState = getTimeTablePointsSelectedEventStateFromSession();
if (sessionInitialState) {
  initialState = {
    ...initialState,
    ...sessionInitialState,
  }
}

export const timeTablePointsSelectedEventSlice = createSlice({
  name: 'timeTablePointsSelectedEvent',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<SetActiveTabPayload>) => {
      state.activeTab = action.payload.activeTab;

      setTimeTablePointsSelectedEventStateToSession(state);
    },
  }
});

export const {
  setActiveTab,
} = timeTablePointsSelectedEventSlice.actions

export const selectActiveTab = (state: RootState) => state.timeTablePointsSelectedEvent.activeTab;

export default timeTablePointsSelectedEventSlice.reducer;