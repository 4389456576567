import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { IStartListItem, TimeTableEventTabType } from '@App/types';
import { RootState } from '@App/store';

import startListApi from '@App/services/startList/startListApi';
import { setActiveTab } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { eventsApi } from '@App/services/events/eventsApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

export interface IStartListState {
  changeTimestamp: number; // Tohle trigeruje mount/unmout pooling kontejneru, tak aby se forsnul reload při znovu kliku (jinak se vysypal state)
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: IStartListItem[];
  heightProgression: string[] | null;
}

const initialState: IStartListState = {
  changeTimestamp: 0,
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
  heightProgression: null,
};

export const getStartListDataAsync = createAsyncThunk(
  'startList/fetchData',
  async (timeTableId: number, { dispatch, rejectWithValue, signal }) => {
    try {
      const response = await startListApi.fetchStartListData(timeTableId, signal);

      const thunkDispatch = dispatch as ThunkDispatch<any, any, AnyAction>; // Nutno to přetypovat, TypeScript si s tím nějak
      thunkDispatch(eventsApi.util.updateQueryData('getEventsStates', '', draft => {
        draft.states[timeTableId] = response.eventState;
      }));

      dispatch(pollingSucceeded());

      return { startListData: response.data, heightProgression: response.heightProgression };
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'Startlist', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const startListSlice = createSlice({
  name: 'startListSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStartListDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getStartListDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.data = action.payload.startListData;
        state.heightProgression = action.payload.heightProgression;
      })
      .addCase(getStartListDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(setActiveTab, (state, action) => { // Clear state
        if (action.payload === TimeTableEventTabType.StartList) {
          state.changeTimestamp = Date.now();
        } else {
          Object.assign(state, initialState);
        }
      })
      .addCase(setSelectedTimeTableId, (state, action) => {
        if (action.payload.hasChanged) {
          Object.assign(state, initialState);
        } else {
          state.changeTimestamp = Date.now();
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.startList.isFetching;
export const selectError = (state: RootState) => state.startList.error;
export const selectDataLoaded = (state: RootState) => state.startList.dataLoaded;
export const selectData = (state: RootState) => state.startList.data;
export const selectHeightProgressions = (state: RootState) => state.startList.heightProgression;
export const selectChangeTimestamp = (state: RootState) => state.startList.changeTimestamp;

export default startListSlice.reducer;