import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import prodStoreConfig from './store.prod';
import devStoreConfg from './store.dev';

const storeConfig = (process.env.NODE_ENV === 'production') ? prodStoreConfig : devStoreConfg;

export const store = configureStore({
  reducer: storeConfig.reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(storeConfig.middlewares),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
