import React from 'react';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import clsx from 'clsx';
import SimpleBar from 'simplebar-react';

import { toggleIntermediateTimes, selectIntermediateTimesVisible } from '@App/appUserStateSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { IIntermediateTime } from '@App/types';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      bottom: '7px',
      right: '15px',
      zIndex: 2,
      width: '340px',
    },
    rootNotVisible: {
      position: 'absolute',
      bottom: '7px',
      right: '9px',
      zIndex: 2,
    },
    simpleBar: {
      height: '100%',
      overflowX: 'hidden',
    },
    scrollContainer: {
      /* Nutno takto napřesno nastavit výšku řádků, jinak to bude uskakovat */
      height: '115px',
      borderLeft: `1px solid ${theme.palette.records.lighter}`,
      borderRight: `1px solid ${theme.palette.records.lighter}`,
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

      '&.times-count-3': {
        height: '104px',
      },
      '&.times-count-2': {
        height: '77px',
      },
      '&.times-count-1': {
        height: '50px',
      }
    },
    tabHeader: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row-reverse',

      '& .border': {
        background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
        paddingTop: '2px',
      },
      '& .background': {
        background: theme.palette.records.lighter,
        padding: '1px 8px 0px 6px',
      },
      '& .header': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '1px',
        fontSize: '11px',
        padding: '0 3px 0 7px',

        '& .fas': {
          paddingLeft: '5px',
          color: theme.palette.primary.light,
        }
      },

      '&.not-visible': {
        '& .background': {
          padding: '4px 8px 3px 6px',
        },
        '& .header': {
          fontSize: '13px',
          padding: '3px 3px 4px 7px',
        }
      }
    },
    intermediateTimesTable: {
      borderCollapse: 'collapse',
      fontWeight: 'bold',
      width: '340px',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '4px 8px 2px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',
      },
      '& tbody tr': {
        background: '#2B3947',

        '& .col-result': {
          background: theme.palette.table.altOddColBackground,
        }
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-result': {
          background: theme.palette.table.altColBackground,
        }
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.records.lighter}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& td.col-distance': {
        borderLeft: `1px solid ${theme.palette.records.lighter}`,
        textAlign: 'center',
      },
      '& td.col-athlete': {
        borderLeft: `1px solid ${theme.palette.records.lighter}`,
        paddingRight: '15px',

        '& .name': {
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '130px',
          marginBottom: '-7px',
        },
        '& .club': {
          fontWeight: 'normal',
          fontSize: '12px',
          opacity: '0.6'
        }
      },
      '& td.col-result': {
        borderLeft: `1px solid ${theme.palette.records.lighter}`,
        textAlign: 'right',
        paddingRight: '12px',
        paddingLeft: '0',
      }
    },
    showButton: {
      display: 'block',
      fontWeight: 'bold',
      width: '100%',
      letterSpacing: '1px',
      fontSize: '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',
      padding: '3px 8px 1px 8px',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '& i': {
        paddingRight: '4px',
      }
    },
  }),
);

type ToggleIntermediateTimesHandler = (event: React.MouseEvent<HTMLElement>) => void;

type IntermediateTimesProps = {
  intermediateTimes: IIntermediateTime[]
}

function IntermediateTimes({ intermediateTimes }: IntermediateTimesProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const intermediateTimesVisible = useAppSelector(selectIntermediateTimesVisible);

  const handleIntermediateTimesToggle: ToggleIntermediateTimesHandler = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleIntermediateTimes());
  };

  if (!intermediateTimesVisible) {
    return (
      <div className={classes.rootNotVisible}>
        <Button onClick={handleIntermediateTimesToggle} className={classes.showButton}>
          <i className="fas fa-stopwatch" /> Show Intermediate times
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabHeader} onClick={handleIntermediateTimesToggle}>
        <div className="border">
          <div className="background">
            <span className="header">Intermediate times <i className="fas fa-times" /></span>
          </div>
        </div>
      </div>
      <div id="intermediateTimesScrollContainer" className={clsx(classes.scrollContainer, `times-count-${intermediateTimes.length}`)}>
        <SimpleBar autoHide={false} scrollbarMinSize={95} className={classes.simpleBar}>
          <table className={classes.intermediateTimesTable}>
            <colgroup>
              <col style={{ width: '65px' } /* Distance */} />
              <col />
              <col style={{ width: '82px' } /* Result */} />
            </colgroup>
            <thead>
              <tr>
                <th>Distance</th>
                <th style={{ textAlign: 'left' }}>Athlete</th>
                <th style={{ textAlign: 'right', paddingRight: '12px' }}>Result</th>
              </tr>
            </thead>
            <tbody>
              {intermediateTimes.map((time, idx) => (
                <tr key={idx}>
                  <td className="col-distance">{time.distance}m</td>
                  <td className="col-athlete"><span className="name">{time.name}</span> {time.club && <span className="club">({time.club})</span>}</td>
                  <td className="col-result">{time.result}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SimpleBar>
      </div>
    </div>
  );
}

export default IntermediateTimes;