import { TechnicalSortType, IResultList, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type ResultListApiResponse = {
  data: IResultList,
  eventState: ITimeTableItemState,
}

export function fetchResultListData(timeTableId: number, abortSignal: AbortSignal, technicalSortType?: TechnicalSortType) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/resultlist/${timeTableId}?${(technicalSortType ? `technicalSort=${technicalSortType}` : '')}`, {
    signal: abortSignal,
  }).then(async (response): Promise<ResultListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get ResultList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchResultListData
};

export default defaultExport;
