import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    scrollContainer: {
      height: '100%',
      flexGrow: 1,
      overflowY: 'auto'
    },
    simpleBar: {
      height: '100%',
    },
    progress: {
      position: 'absolute',
      right: '50%',
      bottom: '50%',

      '&.data-loaded': {
        position: 'absolute',
        right: '7px',
        bottom: '7px',
      }
    },
    foulAssistTabs: {
      borderCollapse: 'collapse',
      width: '100%',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '& .bib': {
          float: 'right',
          opacity: '0.8',
          paddingRight: '15px',
        },
        '& .athlete-count': {
          paddingLeft: '8px',
          color: '#fff',
          fontWeight: 'normal',
          opacity: '0.8',
          paddingRight: '8px',
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-pb, .col-sb': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-pb, .col-sb': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-lane': {
        fontSize: '18px',
      },
      '& td.col-lane-relays': {
        verticalAlign: 'top',
        paddingTop: '12px',
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '350px',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '&.has-bibs': {
            width: '320px',
          }
        },
        '& .athlete-name': {
          fontSize: '16px',

          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
          '& .relay-label': {
            paddingLeft: '7px',
            opacity: '1',
          },
          '& .relay-members': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            listStyleType: 'none',
            fontWeight: 'normal',
            fontSize: '14px',
            margin: '0',
            padding: '0',

            '& li': {
              padding: '2px 5px 2px 0',
              fontSize: '12px',
              width: '220px',
            },
            '& .member-yob': {
              opacity: '0.5',
              paddingLeft: '4px',
            },
            '& .member-last-name': {
              fontWeight: 'bold',
              fontSize: '13px',
              paddingLeft: '3px',
            },
            '& .member-first-name': {
              opacity: '0.8',
            },
            '& .member-order': {
              background: theme.palette.secondary.dark,
              borderRadius: '4px',
              padding: '2px 4px',
              fontWeight: 'bold',
            }
          }
        },
        '& .club-name': {
          fontWeight: 'normal',
          color: theme.palette.textTabs.club,
          fontSize: '13px',
        },
        '& .startlist-note': {
          fontSize: '12px',
          display: 'inline-block',
          marginLeft: '5px',
          fontWeight: 'normal',
          background: theme.palette.primary.main,
          borderRadius: '4px',
          padding: '1px 6px 0',
          opacity: '0.9',
        },
        '& .bib': {
          fontSize: '17px',
          color: theme.palette.textTabs.bib,
          position: 'absolute',
          right: '10px',
          top: '9px',
        }
      },
      '& td.col-yob': {
        fontWeight: 'normal',
        '& span': {
          display: 'block',
          fontSize: '11px',
          opacity: '0.5'
        }
      },
      '& td.col-pb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-sb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-warank': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
      },
      '& td.col-qualify': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',

        '& .qualify-type': {
          color: theme.palette.primary.light,
          fontWeight: 'bold',
          display: 'inline-block',
          width: '23px',
          marginRight: '3px',
          fontSize: '13px',
        }
      },
      '& .col-no-athletes': {
        fontSize: '18px',
        textAlign: 'center',
        padding: '30px 10px',

        '& div': {
          /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
          animationName: 'fadeIn',
          animationDuration: '1.5s',
          transitionTimingFunction: 'linear',
        }
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },
      '& td button i': {
        opacity: '0.7',
        marginRight: '5px',
      },
      '& td button.attempt-ok': {
        width: "70px",
        justifyContent: 'flex-end',
        backgroundColor: '#127B12',
      },
      '& td button.attempt-fail': {
        backgroundColor: '#C41822',
        width: "70px",
        justifyContent: 'flex-end',
      },
      '& td button .infinity': {
        fontSize: '25px',
        lineHeight: 1,
      },
    },
    foulAssistWrap: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      zIndex: 2000,
      border: `5px solid black`,
      borderRadius: 10,
      overflow: 'hidden',

      '& .foul-assist-close-button': {
        position: 'absolute',
        height: 40,
        width: 40,
        top: 20,
        right: 20,
        fontSize: 30,
        textAlign: 'center',
        backgroundColor: 'rgba(196, 28, 44, 0.4)',
        borderRadius: 8,
        zIndex: 200,
      },
      '& .foul-assist-arrow': {
        position: 'absolute',
        height: 120,
        width: 60,
        top: 'calc(50% - 60px)',
        fontSize: 40,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
      },
      '& .foul-assist-arrow-left': {
        left: 0,
        borderBottomRightRadius: 20,
        borderTopRightRadius: 20,
        zIndex: 200,
      },
      '& .foul-assist-arrow-right': {
        right: 0,
        borderBottomLeftRadius: 20,
        borderTopLeftRadius: 20,
        zIndex: 200,
      },
      '& .foul-assist-center-image': {
        position: 'absolute',
        height: 40,
        width: 40,
        top: 20,
        right: 100,
        fontSize: 30,
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: 8,
        zIndex: 200,
        '& > *': {
          transform: 'rotateZ(45deg)'
        }
      },
      '& .foul-assist-info-box': {
        position: 'absolute',
        top: 20,
        left: 20,
        zIndex: 200,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        '& > .header': {
          background: `linear-gradient(90deg, ${theme.palette.appBarGradient.second} 0%,  ${theme.palette.appBarGradient.third} 100%)`,
          padding: '4px 15px',
          lineHeight: 1,
        },
        '& > :not(.header)': {
          display: 'flex',
          alignItems: 'center',
          '& > *': {
            textAlign: 'center',
          },
        },
        '& .flag-container': {
          position: 'relative',
          width: '32px',
          aspectRatio: '16/10',
          marginRight: '15px',
          border: `1px solid ${theme.palette.table.borderColor}`,
          '& .flag': {
            width: '32px',
            aspectRatio: '16/10',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '32px 20px',
            position: 'absolute',
            top: 0,
            left: 0,
          },
        },
      },
      '& img': {
        maxWidth: "100%",
        maxHeight: "100%",
        aspectRatio: '16/9',
      },
    },
  }),
);

export default useStyles;
