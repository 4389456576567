import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { EventState, ITimeTableItemPoints, ITimeTableItemStatePoints } from '@App/types';
import { DisciplineClass } from '@App/disciplines';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getPointListDataAsync,
} from './commonPointsSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './CommonPointsTabContent.styles';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import RecordsIndicator from '@Features/timeTableSelectedEvent/resultList/_components/RecordsIndicator';
import StartCardIndicator from '@Features/timeTableSelectedEvent/resultList/_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass } from '@Utils/ui';


type PollTimeoutTickHandler = () => PollingPromise;

type CommonPointsTabContentProps = {
  pointsEvent: ITimeTableItemPoints
  pointsEventState: ITimeTableItemStatePoints | null,
}

function CommonPointsTabContent({ pointsEvent, pointsEventState }: CommonPointsTabContentProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const isTeamCompetition = pointsEvent.disciplineClass === DisciplineClass.PointsTeams;
  const realEventState = (pointsEventState ? pointsEventState.state : pointsEvent.state);
  const realEventStateAfter = (pointsEventState ? pointsEventState.stateAfter : pointsEvent.stateAfter);
  const isOfficial = realEventState === EventState.Official || realEventState === EventState.Unofficial;

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getPointListDataAsync(pointsEvent.id));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={pointsEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.refreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="resultListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          {resultListData && frontendSettings &&
          <>
          {realEventStateAfter &&
          <div className={classes.stateAfterInfo}>
            <div className="spacer">
              <span className="state-text">State after:</span> {realEventStateAfter.split(', ').map((disc, i) => (<span className="discipline" key={i}>{disc}</span>))}
            </div>
          </div>}
          <table className={classes.startListTable}>
            <colgroup>
              <col style={{ width: '65px' } /* Place */} />
              <col style={{ width: '1%', minWidth: '480px' } /* Athlete */} />
              <col style={{ width: '130px' } /* Result */} />
              <col style={{ width: '80px' } /* Gap */} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Rank</th>
                <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                  {isTeamCompetition ? 'Team' : 'Athlete'}
                  {resultListData && resultListData.length > 0 && <span className="athlete-count">(<strong>{resultListData.length}</strong> total)</span>}
                  {frontendSettings?.displayBIBs && !isTeamCompetition && <span className="bib">Bib</span>}
                </th>
                <th className="text-right">Points</th>
                <th className="text-right">Gap</th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>
            {resultListData && resultListData.map(participiant => (
              <tr key={participiant.participiantId}>
                <td className={clsx('text-center', 'col-lane', isOfficial && getPlaceCssClass(participiant.place))}><span>{participiant.place}</span></td>
                <td className="text-left col-athlete">
                  <div className="athlete-flag-container">
                    <div className="flag-container">
                      <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                      <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                    </div>
                    <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isTeamCompetition && 'has-bibs')}>
                      <div className="athlete-name">
                        {!isTeamCompetition && <>{participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span></>}
                        {isTeamCompetition && <span className={clsx('first-name', 'relay-name')}>{participiant.clubFull || participiant.club}</span>}
                        {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                        {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      </div>
                      {!isTeamCompetition && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}</strong> - </>}{participiant.clubFull}</div>}
                      {isTeamCompetition && <div className="club-name">{participiant.club}</div>}
                      {frontendSettings?.displayBIBs && !isTeamCompetition && <span className="bib">{participiant.bib}</span>}
                    </div>
                  </div>
                </td>
                <td className="text-right col-result">
                  <div className="result-record-container">
                    <span className="result">{participiant.status || participiant.result}</span>
                    {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                    <RecordsIndicator records={participiant.records} />
                  </div>
                  {participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
                </td>
                <td className="text-right col-gap">{participiant.timeGap && <>{participiant.timeGap === '0' ? '' : ('-' + participiant.timeGap)}</>}</td>
                <td>&nbsp;</td>
              </tr>
            ))}
            {!error && resultListData && resultListData.length === 0 &&
            <tr>
              <td colSpan={100} className="col-no-athletes">
                <div>There are no athletes in this event yet</div>
              </td>
            </tr>}
            </tbody>
          </table>
          </>}
        </LegacyTouchWrapper>
      </SimpleBar>
      </div>
    </div>
  );
}

export default CommonPointsTabContent;