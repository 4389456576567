import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

type FullScreenToggleProps = {
  overrideFullscreenAvailable: boolean;
  onClick: () => void;
}

function FullScreenToggle({ onClick, overrideFullscreenAvailable }:FullScreenToggleProps) {
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);
  const [canControlFullscreen, setCanControlFullscreen] = useState(true);

  const handleExitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    onClick();
  };

  const handleSwitchToFullscreen = () => {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    }

    onClick();
  };

  useEffect(() => {
    // Kontrola, jestli při startu již nejsme ve fullscreenu / kiosku
    // https://stackoverflow.com/questions/16755129/detect-fullscreen-mode
    if((window.screen.availHeight || window.screen.height - 20) <= window.innerHeight || !document.documentElement.requestFullscreen) {
      if (!document.fullscreenElement && !overrideFullscreenAvailable) {
        setCanControlFullscreen(false);
      }
    }

    document.onfullscreenchange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    return () => {
      document.onfullscreenchange = null;
    };
  }, [overrideFullscreenAvailable, setIsFullscreen]);

  if (!canControlFullscreen) {
    return null;
  }

  if (isFullscreen) {
    return (
      <StyledMenuItem onClick={handleExitFullScreen}>
        <ListItemIcon>
          <FullscreenExitIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="inherit">Exit fullscreen</Typography>
      </StyledMenuItem>
    );
  }

  return (
    <StyledMenuItem onClick={handleSwitchToFullscreen}>
      <ListItemIcon>
        <FullscreenIcon fontSize="medium" />
      </ListItemIcon>
      <Typography variant="inherit">Switch to fullscreen</Typography>
    </StyledMenuItem>
  )
}

export default FullScreenToggle;