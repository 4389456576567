export function pingBackend() {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/ping`)
    .then(async (response): Promise<boolean> => response.ok)
    .catch(_ => false);
}

const defaultExport = {
  pingBackend
};

export default defaultExport;