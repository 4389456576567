import React, { useState } from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem, TimeTableEventTabType, EventState, TechnicalSortType } from '@App/types';
import { isTechnicalDisciplineClass } from '@App/disciplines';

import { eventsApi } from '@App/services/events/eventsApi';
import {
  selectFollowCurrentCompetitor,
  selectTechnicalSort,
  setFollowCurrentCompetitor,
  setTechnicalSort
} from '@App/appUserStateSlice';
import { getResultListDataAsync } from '@Features/timeTableSelectedEvent/resultList/resultListSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import TouchAppIcon from '@material-ui/icons/TouchApp';

const SHOW_OPTIONS_BUTTON_ID = 'btnShowResultsSortOptions';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    headerFlexContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    header: {

    },
    showOptionsBtn: {
      position: 'relative',
      marginLeft: '9px',
      paddingTop: '4px',
      marginRight: '-12px',

      '& .ripple': {
        animation: 'rippleResultsOpts 1.5s linear infinite',
        height: '5px',
        width: '5px',
        borderRadius: '50%',
        display: 'inline-block',
        position: 'absolute',
        top: '10px',
        left: '7px',
        zIndex: 1,
      },
      '& .MuiSvgIcon-root': {
        transform: 'rotate(-30deg)',
      }
    },
    sortInfo: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '9px',
      textTransform: 'none',
      color: theme.palette.primary.light,
    },
    sortOptionsBackdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      cursor: 'default',
      zIndex: 5,
      display: 'none',

      '&.is-open': {
        display: 'block',
      }
    },
    sortOptionsContainer: {
      position: 'fixed',
      left: '170px',
      top: '192px',
      width: '215px',
      background: theme.palette.records.lighter,
      zIndex: 6,
      height: '0',
      overflow: 'hidden',
      textAlign: 'left',
      transition: 'height 0.2s ease-out',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'default',

      '&.is-open': {
        height: '185px',
        transition: 'height 0.2s ease-in',
      },
      '& h4': {
        margin: '0',
        padding: '10px 15px 0',
        fontSize: '11px',
      },
      '& .option-btns': {
        margin: '0',
        padding: '0 15px',
        listStyleType: 'none',

        '& .option-btn': {
          backgroundColor: 'rgba(255, 255, 255, 0.04)',
          border: '2px solid rgba(255, 255, 255, 0.10)',
          borderRadius: '4px',
          fontSize: '13px',
          padding: '7px 8px',
          margin: '8px 0',
          transition: 'background-color 0.2s linear',
          cursor: 'pointer',

          '& span': {
            opacity: '0.55',
          },
          '& .MuiSvgIcon-root': {
            opacity: '0.1',
            float: 'right',
            marginTop: '-2px',
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.14)',
            border: '2px solid rgba(255, 255, 255, 0.20)',
            transition: 'background-color 0.2s linear',
          },
          '&.selected': {
            borderColor: theme.palette.primary.light,

            '& .MuiSvgIcon-root': {
              opacity: '1',
            },
          },
        }
      }
    }
  }),
);

type ResultsTabHeaderProps = {
  activeTabType: TimeTableEventTabType,
  selectedEvent: ITimeTableItem
}

function ResultsTabHeader({ activeTabType, selectedEvent }: ResultsTabHeaderProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [optionsOpen, setOptionsOpen] = useState(false);

  const { data: eventStatesData } = eventsApi.endpoints.getEventsStates.useQueryState("");
  const technicalSort = useAppSelector(selectTechnicalSort);
  const followCurrentCompetitor = useAppSelector(selectFollowCurrentCompetitor);

  const showTechnicalSortOptions = eventStatesData && activeTabType === TimeTableEventTabType.Results &&
                                  isTechnicalDisciplineClass(selectedEvent.disciplineClass) &&
                                  eventStatesData.states[selectedEvent.id]?.state === EventState.InProgress;

  const handleToggleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setOptionsOpen(!optionsOpen);
  };

  const handleOptionsBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOptionsOpen(false);
  };
  const handleOptionsContainerClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    // .. do notning, jinak se to zavře
  };

  const handleSortByRankClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTechnicalSort(TechnicalSortType.ByPlace));

    setTimeout(() => setOptionsOpen(false), 200); // Takový lepčí efekt

    // Refresh immediately
    dispatch(getResultListDataAsync({ timeTableId: selectedEvent.id}));
  };

  const handleSortByOrderClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTechnicalSort(TechnicalSortType.ByOrder));

    setTimeout(() => setOptionsOpen(false), 200); // Takový lepčí efekt

    // Refresh immediately
    dispatch(getResultListDataAsync({ timeTableId: selectedEvent.id}));
  };

  const handleFollowCurrentCompetitorClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    dispatch(setFollowCurrentCompetitor(!followCurrentCompetitor));

    setTimeout(() => setOptionsOpen(false), 200); // Takový lepčí efekt
  };

  if (!showTechnicalSortOptions) {
    return (
      <span>Results</span>
    );
  }

  return (
    <span id={SHOW_OPTIONS_BUTTON_ID} className={classes.root} onClick={handleToggleOptionsClick}>
      <span className={classes.headerFlexContainer}>
        <span className={classes.header}>
          <span>Results</span>
          <div className={classes.sortInfo}>{technicalSort === TechnicalSortType.ByPlace ? "by rank" : "by order"}</div>
        </span>
        <span className={classes.showOptionsBtn}><span className="ripple" /><TouchAppIcon /></span>
      </span>

      <div className={clsx(classes.sortOptionsBackdrop, optionsOpen && 'is-open')} onClick={handleOptionsBackdropClick} />
      <div className={clsx(classes.sortOptionsContainer, optionsOpen && 'is-open')} onClick={handleOptionsContainerClick}>
        <h4>Live view options</h4>
        <ul className="option-btns">
          <li><div className={clsx('option-btn', technicalSort === TechnicalSortType.ByPlace && 'selected')} onClick={handleSortByRankClick}><span>Sort</span> by rank <DoneIcon/></div></li>
          <li><div className={clsx('option-btn', technicalSort === TechnicalSortType.ByOrder && 'selected')} onClick={handleSortByOrderClick}><span>Sort</span> by order <DoneIcon/></div></li>
          <li><div className={clsx('option-btn', followCurrentCompetitor && 'selected')} onClick={handleFollowCurrentCompetitorClick}><span>Follow</span> current <DoneIcon/></div></li>
        </ul>
      </div>
    </span>
  )
}

export default ResultsTabHeader;
