import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { EventState, ITimeTableItemPoints, ITimeTableItemStatePoints } from '@App/types';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getPolyathlonPointListDataAsync,
} from './polyathlonPointsSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './PolyathlonTabContent.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { HI_RES_DEVICE_THRESHOLD_PX } from '@App/constants';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import RecordsIndicator from '@Features/timeTableSelectedEvent/resultList/_components/RecordsIndicator';
import StartCardIndicator from '@Features/timeTableSelectedEvent/resultList/_components/StartCardIndicator';
import DisciplinesHeader from './_components/DisciplinesHeader';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass } from '@Utils/ui';


type PollTimeoutTickHandler = () => PollingPromise;

type PolyathlonTabContentProps = {
  pointsEvent: ITimeTableItemPoints
  pointsEventState: ITimeTableItemStatePoints | null,
}

function PolyathlonTabContent({ pointsEvent, pointsEventState }: PolyathlonTabContentProps) {
  const dispatch = useAppDispatch();
  const isHiResDevice = useMediaQuery(`(min-width:${HI_RES_DEVICE_THRESHOLD_PX})`);
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const resultListDataItems = resultListData ? resultListData.pointItems : null;
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const realEventState = (pointsEventState ? pointsEventState.state : pointsEvent.state);
  const isOfficial = realEventState === EventState.Official || realEventState === EventState.Unofficial;

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getPolyathlonPointListDataAsync(pointsEvent.id));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={pointsEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.refreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="resultListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          {resultListData && frontendSettings &&
          <table className={classes.startListTable}>
            <colgroup>
              <col style={{ width: '60px' } /* Place */} />
              <col style={{ width: isHiResDevice ? '370px' : '320px' } /* Athlete */} />
              <col style={{ width: '110px' } /* Result */} />
              <col style={{ width: '65px' } /* Gap */} />
              <col style={{ width: '62px' } /* Disciplines */} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={4} className="state-after">
                {resultListData && resultListData.totalDisciplines > 0 &&
                  <div className={classes.stateAfterInfo}>
                    State after: <span>{resultListData.disciplinesCompleted} of {resultListData.totalDisciplines}</span> disciplines
                  </div>}
                </th>
                <th className="col-disciplines-header" rowSpan={2}>
                  {resultListData && resultListData.disciplines && <DisciplinesHeader disciplines={resultListData.disciplines} />}
                </th>
                <th className="text-right" rowSpan={2}></th>
              </tr>
              <tr className={clsx('second-row', resultListData && resultListData.totalDisciplines > 0 && 'has-total-disciplines')}>
                <th>Rank</th>
                <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                  Athlete
                  {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                  {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
                </th>
                <th className="text-right">Points</th>
                <th className="text-right">Gap</th>
              </tr>
            </thead>
            <tbody>
            {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
              <tr key={participiant.participiantId}>
                <td className={clsx('text-center', 'col-lane', isOfficial && getPlaceCssClass(participiant.place))}><span>{participiant.place}</span></td>
                <td className="text-left col-athlete">
                  <div className="athlete-flag-container">
                    <div className="flag-container">
                      <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                      <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                    </div>
                    <div className={clsx('athlete-container', isHiResDevice && 'hi-res', frontendSettings?.displayBIBs && 'has-bibs')}>
                      <div className="athlete-name">
                        {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                        {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                        {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      </div>
                      <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}</strong> - </>}{participiant.clubFull}</div>
                    </div>
                    {frontendSettings?.displayBIBs && <span className="bib">{participiant.bib}</span>}
                  </div>
                </td>
                <td className="text-right col-result">
                  <div className="result-record-container">
                    <span className="result">{participiant.status || participiant.result}</span>
                    {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                    <RecordsIndicator records={participiant.records} />
                  </div>
                  {participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
                </td>
                <td className="text-right col-gap">{participiant.timeGap && <>{participiant.timeGap === '0' ? '' : ('-' + participiant.timeGap)}</>}</td>
                <td className="col-discipline-results">
                  <div className="disc-results-wrapper">
                    {resultListData && resultListData.disciplines && resultListData.disciplines.map(disc => {
                      if (!disc.isOfficial) {
                        return (<div className="disc-result empty"></div>);
                      }

                      const discResult = participiant.disciplinesResults[disc.disciplineId];

                      if (!discResult) {
                        return (<div className="disc-result empty">-</div>);
                      }

                      return (
                        <div className="disc-result">
                          <div className="points">{discResult.points}</div>
                          <div className="place">{discResult.place}</div>
                          <div className="result-wind">
                            <span className={clsx('result', !discResult.points && 'no-points')}>{discResult.result}</span>{(discResult.wind && discResult.wind !== '?') && <span className="wind">/{discResult.wind}</span>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td>&nbsp;</td>
              </tr>
            ))}
            {!error && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
            <tr>
              <td colSpan={100} className="col-no-athletes">
                <div>There are no athletes in this event yet</div>
              </td>
            </tr>}
            </tbody>
          </table>}
        </LegacyTouchWrapper>
      </SimpleBar>
      </div>
    </div>
  );
}

export default PolyathlonTabContent;