import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem, TopListType } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

import {
  selectTopListType,
  selectTopListIsIndoor,
  selectTopListSeasonYear,
  setTopListType,
  setTopListIsIndoor,
  setTopListSeasonYear,
} from '@App/appUserStateSlice';

import { getTopListDataAsync } from '../topListSlice';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    optionBtns: {
      margin: '0',
      padding: '0 20px 0 0',
      listStyleType: 'none',
      textTransform: 'uppercase',
      display: 'flex',

      '& .option-btn': {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        border: '2px solid rgba(255, 255, 255, 0.10)',
        borderRadius: '4px',
        fontSize: '13px',
        padding: '7px 8px',
        margin: '8px 0 8px 8px',
        transition: 'background-color 0.2s linear',
        cursor: 'pointer',

        '& span': {
          opacity: '0.4',
        },
        '& .MuiSvgIcon-root': {
          opacity: '0.1',
          float: 'right',
          marginTop: '-2px',
          marginLeft: '5px',
        },
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.14)',
          border: '2px solid rgba(255, 255, 255, 0.20)',
          transition: 'background-color 0.2s linear',
        },
        '&.selected': {
          borderColor: theme.palette.primary.light,

          '& .MuiSvgIcon-root': {
            opacity: '1',
          },
        },
      }
    }
  }),
);

type TopListFiltersProps = {
  selectedEvent: ITimeTableItem,
  currentSeasonYear: number,
  isInternationalMeeting: boolean,
}

function TopListFilters({ selectedEvent, currentSeasonYear, isInternationalMeeting }: TopListFiltersProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const topListType = useAppSelector(selectTopListType);
  const topListIsIndoor = useAppSelector(selectTopListIsIndoor);
  const topListSeasonYear = useAppSelector(selectTopListSeasonYear);

  const handleTopListAllTimeChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListType(TopListType.AllTime));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  const handleTopListSeasonChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListType(TopListType.Season));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  const handleTopListIndoorChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListIsIndoor(true));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  const handleTopListOutdoorChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListIsIndoor(false));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  const handleTopListCurrentSeasonChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListSeasonYear(currentSeasonYear));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  const handleTopListCurrentPreviousSeasonChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setTopListSeasonYear(currentSeasonYear - 1));
    // Refresh immediately
    dispatch(getTopListDataAsync({ timeTableId: selectedEvent.id, isInternationalMeeting, currentSeasonYear }));
  };

  return (
    <div className={classes.root}>
      {!isInternationalMeeting && topListType === TopListType.Season &&
      <ul className={classes.optionBtns}>
        <li><div className={clsx('option-btn', topListSeasonYear === (currentSeasonYear - 1) && 'selected')} onClick={handleTopListCurrentPreviousSeasonChange}>{currentSeasonYear - 1} <span>season</span> <DoneIcon /></div></li>
        <li><div className={clsx('option-btn', topListSeasonYear === currentSeasonYear && 'selected')} onClick={handleTopListCurrentSeasonChange}>{currentSeasonYear} <span>season</span> <DoneIcon /></div></li>
      </ul>}
      <ul className={classes.optionBtns}>
        <li><div className={clsx('option-btn', topListIsIndoor && 'selected')} onClick={handleTopListIndoorChange}>Indoor <DoneIcon /></div></li>
        <li><div className={clsx('option-btn', !topListIsIndoor && 'selected')} onClick={handleTopListOutdoorChange}>Outdoor <DoneIcon /></div></li>
      </ul>
      <ul className={classes.optionBtns}>
        <li><div className={clsx('option-btn', topListType === TopListType.AllTime && 'selected')} onClick={handleTopListAllTimeChange}>All-time <span>toplists</span> <DoneIcon /></div></li>
        <li><div className={clsx('option-btn', topListType === TopListType.Season && 'selected')} onClick={handleTopListSeasonChange}>Season <span>toplists</span> <DoneIcon /></div></li>
      </ul>
    </div>
  )
}

export default TopListFilters;
