import { IStartListItem, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type StartListApiResponse = {
  data: IStartListItem[],
  eventState: ITimeTableItemState,
  heightProgression: string[],
}

export function fetchStartListData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/startlist/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<StartListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get StartList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchStartListData
};

export default defaultExport;
