import React from 'react';
import { EventState, ITimeTableCurrentEvent, ITimeTableItem, ITimeTableItemState } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CurrentEventButton from './CurrentEventButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    topBorder: {
      display: 'block',
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
      width: '100%',
    },
    currentEventsList: {
      listStyleType: 'none',
      padding: '0 3px',
      margin: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignContent: 'stretch',
      alignItems: 'center',

      '& li': {
        flexGrow: 1,
        height: '100%',
        padding: '8px 5px',
        maxWidth: '33%'
      }
    }
  }),
);

type CurrentDisicplinesProps = {
  currentEvents: ITimeTableCurrentEvent[],
  timeTableById:  { [id: number]: ITimeTableItem },
  timeTableSelectedEventId: number | undefined,
  timeTableStates: { [id: number]: ITimeTableItemState },
  onEventSelected: (timeTableId: number, eventState: EventState) => void;
};

function CurrentDisicplines({ currentEvents, timeTableById, timeTableSelectedEventId, timeTableStates, onEventSelected }: CurrentDisicplinesProps) {
  /*
  *- full event name
  *- start time / date
  - wind
  - event state
  *- sub meeting
  - qualification
  - temperature / humidity (only when official)
  - 5x discipline records
  */
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBorder} />
      <ul className={classes.currentEventsList}>
        {currentEvents.map(event => (
          <li key={event.id}>
            <CurrentEventButton event={event} timeTableItem={timeTableById[event.id]} timeTableSelectedEventId={timeTableSelectedEventId} timeTableStates={timeTableStates} onEventSelected={onEventSelected} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CurrentDisicplines;
