import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem } from '@App/types';
import moment from 'moment';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getRecordListDataAsync,
} from './recordListSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './RecordListTabContent.styles';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import { getCountryFlagImage } from '@Utils/ui';

type RecordListTabContentProps = {
  selectedEvent: ITimeTableItem,
}

type PollTimeoutTickHandler = () => PollingPromise;

function RecordListTabContent({ selectedEvent }: RecordListTabContentProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const recordListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getRecordListDataAsync(selectedEvent.id));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.refreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="recordListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          <table className={classes.startListTable}>
            <colgroup>
              <col style={{ width: '60px' }} />
              <col style={{ width: '1%', minWidth: '380px' }} />
              <col style={{ width: '80px' } /* Result */} />
              <col style={{ width: '65px' } /* Date */} />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Type</th>
                <th className="text-left">
                  Athlete
                </th>
                <th className="text-right">Result</th>
                <th className="text-left">Venue / Date</th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody>
            {recordListData && recordListData.map(recordItem => (
              <tr key={`${recordItem.type}_${recordItem.id}`}>
                <td className={clsx('text-center', 'col-type')}>
                  {recordItem.type}
                  {recordItem.countryNr && <div className="country-nr">{recordItem.countryNr}</div>}
                </td>
                <td className="text-left col-athlete">
                  <div className="athlete-flag-container">
                    <div className="flag-container">
                      <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(recordItem.country)}')`}} />
                    </div>
                    <div className="athlete-container">
                      <div className="athlete-name">
                        {recordItem.name}
                      </div>
                      <div className="club-name">
                        <strong>{(recordItem.country || '-')}</strong>{recordItem.club && " - "}{recordItem.club}
                        {recordItem.pending && <span className="pending-indicator">Pending</span>}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-right col-result">
                  {recordItem.result}
                </td>
                <td className="text-left col-venue-date">
                  <div className="venue">{recordItem.venue}</div>
                  <div className="date">{recordItem.date && moment(recordItem.date).format('YYYY-MM-DD')}</div>
                </td>
                <td>&nbsp;</td>
              </tr>
            ))}
            {!error && recordListData && recordListData.length === 0 && !isFetching &&
            <tr>
              <td colSpan={100} className="col-no-athletes">
                <div>There are no records for this event</div>
              </td>
            </tr>}
            </tbody>
          </table>
        </LegacyTouchWrapper>
      </SimpleBar>
      </div>
    </div>
  );
}

export default RecordListTabContent;
