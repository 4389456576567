import React from 'react';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem, ITimeTableItemState, EventState } from '@App/types';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getResultListDataAsync,
} from './resultListSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import RunsResultListTabContent from './runs/RunsResultListTabContent';
import TechnicalResultListTabContent from './technical/TechnicalResultListTabContent';
import IntermediateTimes from '@Features/timeTableSelectedEvent/resultList/runs/_components/IntermediateTimes';
import HeightProgression from '@Features/timeTableSelectedEvent/resultList/runs/_components/HeightProgression';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    scrollContainer: {
      height: '100%',
      flexGrow: 1,
      overflowY: 'auto'
    },
    simpleBar: {
      height: '100%',
    },
    progress: {
      position: 'absolute',
      right: '50%',
      bottom: '50%',

      '&.data-loaded': {
        position: 'absolute',
        right: '7px',
        bottom: '7px',
      }
    }
  }),
);

type StartListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
}

type PollTimeoutTickHandler = () => PollingPromise;

function StartListTabContent({ selectedEvent, selectedEventState }: StartListTabContentProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const scrollableNodeRef = React.createRef<any>();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');

  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const showStartListData = selectedEventState?.state === EventState.Scheduled;

  const intermediateTimes = resultListData && resultListData.isRuns && resultListData?.intermediateTimes && resultListData.intermediateTimes.length > 0 ?
                            resultListData?.intermediateTimes : null;

  const heightProgressions = resultListData && !resultListData.isRuns && resultListData.technicalResultState && resultListData.technicalResultState?.heightProgression.length > 0 ?
                            resultListData.technicalResultState?.heightProgression : null;

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getResultListDataAsync({ timeTableId: selectedEvent.id}));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.resultsRefreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
      {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
      <div id="resultListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar} scrollableNodeProps={{ ref: scrollableNodeRef }}>
          {resultListData && resultListData.isRuns &&
            <RunsResultListTabContent
              selectedEvent={selectedEvent}
              selectedEventState={selectedEventState}
              resultListData={resultListData}
              frontendSettings={frontendSettings}
              hasError={!!error}
              isFetching={isFetching}
            />}
          {resultListData && !resultListData.isRuns &&
            <TechnicalResultListTabContent
              selectedEvent={selectedEvent}
              selectedEventState={selectedEventState}
              resultListData={resultListData}
              frontendSettings={frontendSettings}
              hasError={!!error}
              isFetching={isFetching}
              scrollableNodeRef={scrollableNodeRef}
            />
          }
      </SimpleBar>
      </div>
      {intermediateTimes && frontendSettings &&
        <IntermediateTimes intermediateTimes={intermediateTimes} />}
      {showStartListData && heightProgressions &&
        <HeightProgression heightProgression={heightProgressions} />}
    </div>
  );
}

export default StartListTabContent;
