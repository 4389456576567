import { IFlashQuoteItem } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type RecordListApiResponse = IFlashQuoteItem[];

export function fetchFlashQuotesData(abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/flash-quotes`, {
    signal: abortSignal,
  }).then(async (response): Promise<RecordListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get FlashQuotes data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchFlashQuotesData
};

export default defaultExport;
