import React from 'react';
import { ITimeTableItemPoints, ITimeTableItemStatePoints } from '@App/types';
import { DisciplineClass } from '@App/disciplines';

import CommonPointsTabContent from './commonPoints/CommonPointsTabContent';
import PolyathlonTabContent from './polyathlonPoints/PolyathlonTabContent';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

function isPolyathlonPoints(disciplineClass: DisciplineClass) {
  switch (disciplineClass) {
    case DisciplineClass.Triathlon:
    case DisciplineClass.Pentathlon:
    case DisciplineClass.Heptathlon:
    case DisciplineClass.Decathlon:
      return true;
    default:
      return false;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: 'calc(100% - 10px)',
    },
  }),
);

type PointsTabContentProps = {
  pointsEvent: ITimeTableItemPoints
  pointsEventState: ITimeTableItemStatePoints | null,
}

function PointsTabContent({ pointsEvent, pointsEventState }: PointsTabContentProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isPolyathlonPoints(pointsEvent.disciplineClass) ?
        (<PolyathlonTabContent pointsEvent={pointsEvent} pointsEventState={pointsEventState} />) :
        (<CommonPointsTabContent pointsEvent={pointsEvent} pointsEventState={pointsEventState} />)}
    </div>
  );
}

export default PointsTabContent;