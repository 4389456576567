import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useAppSelector, useAppDispatch } from '@App/hooks';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getFlashQuotesDataAsync,
} from './flashQuotesSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import { useStyles } from './FlashQuotesScreen.styles';

import { Grid } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import PollingErrorHandler from '@Features/errorHandler/polling/PollingErrorHandler';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import FlashQuotesList from './FlashQuotesList';

type PollTimeoutTickHandler = () => PollingPromise;

function FlashQuotesScreen() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const flashQuotesData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getFlashQuotesDataAsync());
  };

  return (
    <div className={classes.root}>
      <Helmet title="CIS - Flash quotes" />
      <Grid container spacing={0} className="main-screen-grid">
        <Grid item xs={12} className={classes.spacerGrid}>
          <div className={classes.topBorder} />
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {frontendSettings &&
              <PollingContainer
                changeTimeStamp={changeTimeStamp}
                interval={frontendSettings.resultsRefreshInterval}
                onPollTimeoutTick={handlePollTimeoutTick}
              />}
              {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
              <div id="flashQuotesScrollContainer" className={classes.scrollContainer}>
                <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
                  <LegacyTouchWrapper>
                    {flashQuotesData && <FlashQuotesList frontendSettings={frontendSettings} flashQuotes={flashQuotesData} />}
                    {!error && flashQuotesData && flashQuotesData.length === 0 && !isFetching &&
                    <h2 className={classes.noQuotes}>There are no flash quotes yet, please check again later...</h2>}
                  </LegacyTouchWrapper>
                </SimpleBar>
              </div>
              <PollingErrorHandler errorCountThreshold={3} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default FlashQuotesScreen;