import React from 'react';
import { ITimeTableItemPoints, ITimeTableItemStatePoints } from '@App/types';
import EventStateIcon from '@Components/eventStateIcon';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    eventName: {

    },
    eventCategory: {
      textTransform: 'none',
      opacity: 0.7,
      fontWeight: 'normal',
      lineHeight: '18px',
      fontSize: '13px',
    },
    eventStateWrapper: {
      position: 'absolute',
      top: '25%',
      right: '-30px',
    }
  }),
);

type TabHeaderProps = {
  pointsEvent: ITimeTableItemPoints
  pointsEventState: ITimeTableItemStatePoints | null,
}

function TabHeader({ pointsEvent, pointsEventState }: TabHeaderProps) {
  const classes = useStyles();
  const eventState = pointsEventState ? pointsEventState.state : pointsEvent.state;

  return (
    <div className={classes.root}>
      <div className={classes.eventName}>{pointsEvent.eventNameFull}</div>
      <div className={classes.eventCategory}>{pointsEvent.eventCategoryFull}</div>
      <div className={classes.eventStateWrapper}>
        <EventStateIcon eventState={eventState} isStartlistOfficial={true} />
      </div>
    </div>
  );
}

export default TabHeader;