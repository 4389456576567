import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFinalLapListItem } from '@App/types';
import { RootState } from '@App/store';

import finalLapListApi from '@App/services/finalLapList/finalLapListApi';
import { eventsApi } from '@App/services/events/eventsApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

export interface IFinalLapCounterState {
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: IFinalLapListItem[];
}

const initialState: IFinalLapCounterState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
};

export const getFinalLapCounterDataAsync = createAsyncThunk(
  'finalLapList/fetchData',
  async (_: string, { dispatch, rejectWithValue, signal }) => {
    try {
      const response = await finalLapListApi.fetchFinalLapListData(signal);

      dispatch(pollingSucceeded());

      return { finalLapListData: response };
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'FinalLapList', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const finalLapCounterSlice = createSlice({
  name: 'finalLapCounterSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinalLapCounterDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getFinalLapCounterDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.data = action.payload.finalLapListData;
      })
      .addCase(getFinalLapCounterDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(eventsApi.internalActions.unsubscribeQueryResult, state => { // Clear state when movind to different screen
        Object.assign(state, initialState);
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.finalLapCounter.isFetching;
export const selectError = (state: RootState) => state.finalLapCounter.error;
export const selectDataLoaded = (state: RootState) => state.finalLapCounter.dataLoaded;
export const selectData = (state: RootState) => state.finalLapCounter.data;

export default finalLapCounterSlice.reducer;