import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TransponderPlaceChange } from '@App/types';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      width: '18px',
      padding: '0 5px 0 0',

      '& i': {
        lineHeight: '18px',
        fontSize: '18px',
      },
      '&.place-change-1': {
        color: 'red',
      },
      '&.place-change-2': {
        color: '#43BF4D',
      }
    }
  }),
);

type SmallPlaceChangeIndicatorProps = {
  placeChange: TransponderPlaceChange,
}

function SmallPlaceChangeIndicator({ placeChange }: SmallPlaceChangeIndicatorProps) {
  const classes = useStyles();

  return (
    <span className={clsx(classes.root, `place-change-${placeChange}`)}>
      {placeChange === TransponderPlaceChange.Worse && <i className="fas fa-long-arrow-alt-down" />}
      {placeChange === TransponderPlaceChange.Better && <i className="fas fa-long-arrow-alt-up" />}
    </span>
  )
}

export default SmallPlaceChangeIndicator;