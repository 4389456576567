import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { MAX_HEIGHT_PROGRASSIONS_COUNT } from '@App/constants';
import { useAppDispatch, useAppSelector } from '@App/hooks';
import { toggleHeightProgression, selectHeightProgressionVisible } from '@App/appUserStateSlice';
import { Button } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      bottom: '7px',
      right: '15px',
      zIndex: 2,
    },
    rootNotVisible: {
      position: 'absolute',
      bottom: '7px',
      right: '9px',
      zIndex: 2,
    },
    simpleBar: {
      height: '100%',
      overflowX: 'hidden',
    },
    scrollContainer: {
      /* Nutno takto napřesno nastavit výšku řádků, jinak to bude uskakovat */
      height: '115px',
      borderLeft: `1px solid ${theme.palette.records.lighter}`,
      borderRight: `1px solid ${theme.palette.records.lighter}`,
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

      '&.times-count-3': {
        height: '104px',
      },
      '&.times-count-2': {
        height: '77px',
      },
      '&.times-count-1': {
        height: '50px',
      }
    },
    tabHeader: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row-reverse',

      '& .border': {
        background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
        paddingTop: '2px',
      },
      '& .background': {
        background: theme.palette.records.lighter,
        padding: '1px 8px 0px 6px',
      },
      '& .header': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '1px',
        fontSize: '11px',
        padding: '0 3px 0 7px',

        '& .fas': {
          paddingLeft: '5px',
          color: theme.palette.primary.light,
        }
      },

      '&.not-visible': {
        '& .background': {
          padding: '4px 8px 3px 6px',
        },
        '& .header': {
          fontSize: '13px',
          padding: '3px 3px 4px 7px',
        }
      }
    },
    intermediateTimesTable: {
      borderCollapse: 'collapse',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '4px 8px 2px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',
      },
      '& tbody tr': {
        background: '#2B3947',

        '& .col-result': {
          background: theme.palette.table.altOddColBackground,
        }
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-result': {
          background: theme.palette.table.altColBackground,
        }
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.records.lighter}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& td.col-distance': {
        borderLeft: `1px solid ${theme.palette.records.lighter}`,
        textAlign: 'center',
      },
    },
    showButton: {
      display: 'block',
      fontWeight: 'bold',
      width: '100%',
      letterSpacing: '1px',
      fontSize: '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '2px solid rgba(255, 255, 255, 0.10)',
      padding: '3px 8px 1px 8px',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(255, 255, 255, 0.20)',
      },
      '& i': {
        paddingRight: '4px',
      }
    },
  }),
);

type ToggleHeightProgressionHandler = (event: React.MouseEvent<HTMLElement>) => void;

type HeightProgressionProps = {
  heightProgression: string[]
}

function HeightProgression({ heightProgression }: HeightProgressionProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const heightProgressionVisible = useAppSelector(selectHeightProgressionVisible);

  const handleHeightProgressionToggle: ToggleHeightProgressionHandler = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleHeightProgression());
  };

  if (!heightProgressionVisible) {
    return (
      <div className={classes.rootNotVisible}>
        <Button onClick={handleHeightProgressionToggle} className={classes.showButton}>
          <i className="fas fa-stopwatch" /> Show Height progression
        </Button>
      </div>
    );
  }

  const firstRowHeights = heightProgression.slice(0, MAX_HEIGHT_PROGRASSIONS_COUNT);
  const secondRowHeights = heightProgression.slice(MAX_HEIGHT_PROGRASSIONS_COUNT, 2 * MAX_HEIGHT_PROGRASSIONS_COUNT);

  return (
    <div className={classes.root}>
      <div className={classes.tabHeader} onClick={handleHeightProgressionToggle}>
        <div className="border">
          <div className="background">
            <span className="header">Height progression <i className="fas fa-times" /></span>
          </div>
        </div>
      </div>
      <table className={classes.intermediateTimesTable}>
        <thead>
          <tr>
            {firstRowHeights.map((_height, idx) => (<th key={idx}>{idx + 1}</th>))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {firstRowHeights.map((height, idx) => (
              <td key={idx} className="col-height">{height}m</td>
            ))}
          </tr>
        </tbody>
      </table>
      {secondRowHeights.length > 0 && <table className={classes.intermediateTimesTable}>
        <thead>
          <tr>
            {secondRowHeights.map((_height, idx) => (<th key={idx}>{MAX_HEIGHT_PROGRASSIONS_COUNT + idx + 1}</th>))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {secondRowHeights.map((height, idx) => (
              <td key={idx} className="col-height">{height}m</td>
            ))}
          </tr>
        </tbody>
      </table>}
    </div>
  );
}

export default HeightProgression;