import React, { useState, useEffect } from 'react';
import { useAppSelector } from '@App/hooks';
import { getErrorMessage } from '@Utils/error';
import {
  makeShouldDisplayPollingErrorHandler,
  selectErrorWindowStart,
  selectLastError,
  shouldReloadApplication
} from './pollingErrorHandlerSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { pingBackend } from '@App/services/pingApi';
import { PING_BACKEND_INTERVAL } from '@App/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      textAlign: 'center',
      background: 'rgb(0 0 0 / 65%)',
      width: '100%',
      height: '100%',
      zIndex: 40,

      '& .aligner': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      '& .fa-exclamation-triangle': {
        color: theme.palette.primary.light,
        fontSize: '25px',
        opacity: '0.9',
        paddingRight: '8px',
      }
    },
    reloadAppButton: {
      color: '#fff',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
      fontSize: '18px',
      border: '2px solid rgba(255, 255, 255, 0.20)',
      backgroundColor: 'rgba(255, 255, 255, 0.14)',
      marginTop: '20px',

      '& i': {
        paddingRight: '10px',
      }
    },
    diagnosticInfo: {
      opacity: '0.5',
      fontSize: '13px',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: '4px',
      color: '#000',
      padding: '8px 4px',

      '& h3': {
        margin: 0,
        fontFamily: 'monospace',
      },
      '& pre': {
        margin: 0,
        maxWidth: '480px',
        whiteSpace: 'normal',
        overflowY: 'auto',
        maxHeight: '120px',
      }
    }
  }),
);

type PollingErrorHandlerProps = {
  errorCountThreshold: number;
}

function PollingErrorHandler({ errorCountThreshold }: PollingErrorHandlerProps) {
  const classes = useStyles();
  const shouldDisplay = useAppSelector(makeShouldDisplayPollingErrorHandler(errorCountThreshold))
  const errorWindowStart = useAppSelector(selectErrorWindowStart);
  const lastError = useAppSelector(selectLastError);
  const [backendAvailable, setBackendAvailable] = useState(false);

  const handleRefreshButtonClick = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    if (shouldDisplay && backendAvailable && shouldReloadApplication(errorWindowStart)) {
      window.location.reload(true);
    }
  });

  useEffect(() => {
    let hnd: NodeJS.Timeout | null = null;

    function scheduleNextCheck() {
      hnd = setTimeout(() => {
        pingBackend().then(backendAvailable => {
          setBackendAvailable(backendAvailable);

          scheduleNextCheck();
        });
      }, PING_BACKEND_INTERVAL);
    }

    if (shouldDisplay) {
      scheduleNextCheck();
    }

    return () => {
      if (hnd) {
        clearTimeout(hnd);
      }
    };
  }, [shouldDisplay]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className="aligner">
        <h1><i className="fas fa-exclamation-triangle" /> Failed to get data from server!</h1>
        {lastError &&
        <div className={classes.diagnosticInfo}>
          <h3>Diagnostic information:</h3>
          <pre>
            {getErrorMessage(lastError)}
          </pre>
        </div>}
        <h2>Retrying to fetch data... </h2>
        {backendAvailable && <Button onClick={handleRefreshButtonClick} className={classes.reloadAppButton}> Reload application</Button>}
      </div>
    </div>
  );
}

export default PollingErrorHandler