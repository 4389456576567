import React from 'react';
import { IFlashQuoteItem, IFrontendSettings } from '@App/types';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

import FlashQuotesListItem from './FlashQuotesListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: 0,

      '& .MuiListItem-root': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        padding: 0,
      },
      '& .MuiListItem-root:nth-child(even)': {
        background: theme.palette.table.altRowBackground,
      },
      '& .Mui-selected': {
        background: 'rgba(255, 255, 255, 0.18) !important'
      },
    },
  }),
);

type FlashQuotesListProps = {
  flashQuotes: IFlashQuoteItem[],
  frontendSettings: IFrontendSettings | undefined,
};

function FlashQuotesList({ flashQuotes, frontendSettings }: FlashQuotesListProps) {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.list}>
      {flashQuotes.map(quote => (<FlashQuotesListItem key={quote.id} quote={quote} frontendSettings={frontendSettings} />))}
    </List>
  );
}

export default FlashQuotesList;