import { IRecordListItem, ITimeTableItemState } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type RecordListApiResponse = {
  data: IRecordListItem[],
  eventState: ITimeTableItemState,
}

export function fetchRecordListData(timeTableId: number, abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/recordlist/${timeTableId}`, {
    signal: abortSignal,
  }).then(async (response): Promise<RecordListApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get RecordList data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchRecordListData
};

export default defaultExport;
