import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFrontendSettings } from '@App/types';

export const frontendSettingsApi = createApi({
  reducerPath: 'frontendSettingsApi',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.PUBLIC_URL}/api/v1`
  }),
  tagTypes: ['IFrontendSettings'],
  endpoints: (build) => ({
    getFrontendSettings: build.query<IFrontendSettings, string>({
      query: () => 'settings/frontend',
      providesTags: ['IFrontendSettings'],
    })
  }),
});

export const { useGetFrontendSettingsQuery } = frontendSettingsApi;