import { IRecordsSummary } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type RecordsSummaryApiResponse = IRecordsSummary;

export function fetchRecordsSummaryData(abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/records-summary`, {
    signal: abortSignal,
  }).then(async (response): Promise<RecordsSummaryApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get records summary data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchRecordsSummaryData
};

export default defaultExport;
