import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITimeTableItem, TimeTableEventTabType, TimeTableFilters, TimeTableGenderFilters, EventState, ITimeTableItemPoints } from '@App/types';
import { getTimeTableStateFromSession, setTimeTableStateToSession } from '@App/services/settings/sessionSettingsApi';
import { eventsApi, EventsResponseById } from '@App/services/events/eventsApi';
import { filterTimeTable } from './selectors';
import { RootState } from '@App/store';

import { setActiveTab, selectActiveTab } from '@Features/timeTableSelectedEvent/timeTableSelectedEventSlice';
import { selectSelectedTimeTableId } from './selectors';
import { DisciplineClass } from '@App/disciplines';

export interface SetSelectedTimeTableIdPayload {
  timeTableId: number;
  hasChanged: boolean;
}

export interface SetSelectedTimeTableItemAndCheckActiveTabPayload {
  timeTableItem: ITimeTableItem;
  hasChanged: boolean;
}

export interface ITimeTableState {
  activeFilters: TimeTableFilters[] | null,
  activeGenderFilters: TimeTableGenderFilters | null,
  activeDayNumberFilter: number | null,
  activePolyathlonFilter: ITimeTableItemPoints | null,
  finalsOnlyFilter: boolean,
  activeOnlyFilter: boolean,
  selectedTimeTableId: number | null,
  dayFilterOnAppLoadChecked: boolean,
}

let initialState: ITimeTableState = {
  activeFilters: null,
  activeGenderFilters: null,
  activeDayNumberFilter: null,
  activePolyathlonFilter: null,
  finalsOnlyFilter: false,
  activeOnlyFilter: false,
  selectedTimeTableId: null,
  dayFilterOnAppLoadChecked: false,
};

const sessionInitialState = getTimeTableStateFromSession();
if (sessionInitialState) {
  initialState = {
    ...initialState,
    ...sessionInitialState,
    dayFilterOnAppLoadChecked: false,
  }
}

export const setSelectedTimeTableIdAndCheckActiveTabAsync = createAsyncThunk(
  'timeTable/setSelectedTimeTableIdAndCheckActiveTab',
  async ({ timeTableItem, hasChanged }: SetSelectedTimeTableItemAndCheckActiveTabPayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeTab = selectActiveTab(state);

    dispatch(setSelectedTimeTableId({ timeTableId: timeTableItem.id, hasChanged }));

    if ((!timeTableItem.isFinalPhase || timeTableItem.state !== EventState.Official) && activeTab === TimeTableEventTabType.VictoryCeremony) {
      dispatch(setActiveTab(TimeTableEventTabType.Results));
    }
    if (!timeTableItem.eventPhase && activeTab === TimeTableEventTabType.SummaryResults) {
      dispatch(setActiveTab(TimeTableEventTabType.Results));
    }
    if (!timeTableItem.eventPhaseNumber && activeTab === TimeTableEventTabType.SummaryResults) {
      dispatch(setActiveTab(TimeTableEventTabType.Results));
    }
    if (!timeTableItem.withTransponders && activeTab === TimeTableEventTabType.TransponderIntermediateTimes) {
      dispatch(setActiveTab(TimeTableEventTabType.Results));
    }
    if ((timeTableItem.disciplineClass !== DisciplineClass.LongJump) && (activeTab === TimeTableEventTabType.FoulAssist)) {
      dispatch(setActiveTab(TimeTableEventTabType.Results));
    }
  }
);

// Toto je big hack jak protože se tady k tomu nědá nijak rozumně dostat
const selectTimeTableById = (state: RootState) => (state.eventsApi.queries['getEvents("")']?.data as any)?.timeTableById as any;

export const checkActiveTabOnLoadAsync = createAsyncThunk(
  'timeTable/checkActiveTabOnLoad',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeTab = selectActiveTab(state);
    const selectedTimeTableId = selectSelectedTimeTableId(state);
    const timeTableById = selectTimeTableById(state);

    if (selectedTimeTableId && timeTableById) {
      const selectedTimeTableEvent = timeTableById[selectedTimeTableId] as ITimeTableItem;

      if (selectedTimeTableEvent) {
        if ((!selectedTimeTableEvent.isFinalPhase || (selectedTimeTableEvent.state !== EventState.Official)) && activeTab === TimeTableEventTabType.VictoryCeremony) {
          dispatch(setActiveTab(TimeTableEventTabType.Results));
        }
        if (!selectedTimeTableEvent.eventPhase && activeTab === TimeTableEventTabType.SummaryResults) {
          dispatch(setActiveTab(TimeTableEventTabType.Results));
        }
        if (!selectedTimeTableEvent.eventPhaseNumber && activeTab === TimeTableEventTabType.SummaryResults) {
          dispatch(setActiveTab(TimeTableEventTabType.Results));
        }
        if (!selectedTimeTableEvent.withTransponders && activeTab === TimeTableEventTabType.TransponderIntermediateTimes) {
          dispatch(setActiveTab(TimeTableEventTabType.Results));
        }
        if ((selectedTimeTableEvent.disciplineClass !== DisciplineClass.LongJump) && (activeTab === TimeTableEventTabType.FoulAssist)) {
          dispatch(setActiveTab(TimeTableEventTabType.Results));
        }
      }
    }
  }
);

export const timeTableSlice = createSlice({
  name: 'timeTable',
  initialState,
  reducers: {
    setDayFilterOnAppLoadChecked: (state, action: PayloadAction<boolean>) => {
      state.dayFilterOnAppLoadChecked = action.payload;
    },
    setActiveFilters: (state, action: PayloadAction<TimeTableFilters[] | null>) => {
      state.activeFilters = action.payload;

      setTimeTableStateToSession(state);
    },
    setActiveGenderFilters: (state, action: PayloadAction<TimeTableGenderFilters | null>) => {
      state.activeGenderFilters = action.payload;

      setTimeTableStateToSession(state);
    },
    setActiveDayNumberFilter: (state, action: PayloadAction<number | null>) => {
      state.activeDayNumberFilter = action.payload;

      setTimeTableStateToSession(state);
    },
    setActivePolyathlonFilter: (state, action: PayloadAction<ITimeTableItemPoints | null>) => {
      state.activePolyathlonFilter = action.payload;

      setTimeTableStateToSession(state);
    },
    setSelectedTimeTableId: (state, action: PayloadAction<SetSelectedTimeTableIdPayload>) => {
      state.selectedTimeTableId = action.payload.timeTableId;

      setTimeTableStateToSession(state);
    },
    toggleFinalsOnlyFilter: (state) => {
      state.finalsOnlyFilter = !state.finalsOnlyFilter;

      setTimeTableStateToSession(state);
    },
    toggleActiveOnlyFilter: (state) => {
      state.activeOnlyFilter = !state.activeOnlyFilter;

      setTimeTableStateToSession(state);
    },
    clearAllFilters: (state) => {
      state.activeFilters = null;
      state.activeGenderFilters = null;
      // state.activeDayNumberFilter = 0; ... dny necháme jak jsou
      state.finalsOnlyFilter = false;
      state.activePolyathlonFilter = null;

      setTimeTableStateToSession(state);
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(eventsApi.endpoints.getEvents.matchFulfilled, (state: ITimeTableState, action: PayloadAction<EventsResponseById>) => {
      // Po aktualizaci časového pořadu je nutno zkontrolovat, zda vybraná disciplína je v časovém pořadu
      const currentlySelectedTimeTableId = state.selectedTimeTableId;

      if (!action.payload) {
        return;
      }

      let selectedIdExistsInCurrentData = false;
      if (currentlySelectedTimeTableId) {
        selectedIdExistsInCurrentData = !!action.payload.timeTableById[currentlySelectedTimeTableId];
      }

      if (!selectedIdExistsInCurrentData && action.payload.timeTable.length > 0) {
        const filteredTimeTable = filterTimeTable(action.payload.timeTable, null, state.activeFilters, state.activeGenderFilters,
          state.activeDayNumberFilter, state.activePolyathlonFilter, state.finalsOnlyFilter, state.activeOnlyFilter, action.payload.serverTime, action.payload.activeEventFilterMinuteThreshold);

        if (filteredTimeTable && filteredTimeTable.length > 0) {
          state.selectedTimeTableId = filteredTimeTable[0].id;
        } else {
          state.selectedTimeTableId = action.payload.timeTable[0].id;
        }
      }
    });
  }
});

export const {
  setDayFilterOnAppLoadChecked,
  setActiveFilters,
  setActiveGenderFilters,
  setActiveDayNumberFilter,
  setSelectedTimeTableId,
  toggleFinalsOnlyFilter,
  toggleActiveOnlyFilter,
  clearAllFilters,
  setActivePolyathlonFilter,
} = timeTableSlice.actions;

export default timeTableSlice.reducer;