import React from 'react';
//import moment from 'moment';
import styled from 'styled-components';

const IS_PRODUCTION = (process.env.NODE_ENV === 'production');

const Wrapper = styled.div`
  position: relative;
`;

const ScrollMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

interface IProps {

}

class LegacyTouchWrapper extends React.Component<IProps> {
  private handleMouseDown(event: React.MouseEvent<HTMLDivElement>) {
    //event.preventDefault();
    //event.stopPropagation();
    //console.warn(moment().format('ss.SSS'), 'MOUSE DOWN!');
  };

  private onTouchStart(event: React.TouchEvent<HTMLDivElement>) {
    //console.warn(moment().format('ss.SSS'), 'TOUCH START!');
  }

  private handleTouchEnd(event: React.TouchEvent<HTMLDivElement>) {
    //console.warn(moment().format('ss.SSS'), 'TOUCH END!');
  }

  render() {
    const { children } = this.props;

    return (
      <Wrapper onMouseDown={this.handleMouseDown} onTouchStart={this.onTouchStart} onTouchEnd={this.handleTouchEnd}>
        {IS_PRODUCTION && <ScrollMask />}
        {children}
      </Wrapper>
    );
  }
}

export default LegacyTouchWrapper;
