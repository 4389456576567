import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const WR_RECORD = "WR";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      listStyleType: 'none',
      padding: '0',
      margin: '0',
      marginLeft: '-14px',
      marginRight: '12px',
      display: 'flex',
      width: '0px',
      alignItems: 'center',

      '& .record': {
        display: 'inline-block',
        fontWeight: 'bold',
        color: theme.palette.primary.light,
        textShadow: '0px 0px 2px rgba(0,0,0,0.73)',
        padding: '0 3px',

        '&.is-wr': {
          fontSize: '15px'
        }
      },

      '&.records-1': { // Jeden rekord
        width: '58px',
        height: '41px',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',

        '& .record': {
          fontSize: '14px',
          lineHeight: '21px',
        }
      },
      '&.records-2': { // Dva rekordy
        width: '60px',
        height: '41px',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',

        '& .record': {
          fontSize: '14px',
          lineHeight: '15px',
        },
        '& .record:first-child': {

        },
        '& .record:last-child': {
          fontSize: '13px',
        }
      },
      '&.records-3': { // Tři rekordy
        width: '58px',
        height: '41px',
        marginTop: '-1px',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',

        '& .record': {
          fontSize: '14px',
          lineHeight: '14px',
        },
        '& .record:first-child': {
          fontSize: '14px',
          lineHeight: '15px',
        },
        '& .record:last-child': {

        }
      },
      '&.records-4': { // Tři rekordy
        width: '58px',
        height: '41px',
        marginTop: '-1px',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',

        '& .record': {
          fontSize: '12px',
          lineHeight: '15px',
        },
        '& .record:first-child, .record:nth-of-type(2)': {
          fontSize: '14px',
        },
      }
    }
  }),
);

type RecordsIndicatorProps = {
  records: string[],
}

function RecordsIndicator({ records }: RecordsIndicatorProps) {
  const classes = useStyles();

  if (!records || records.length === 0) {
    return null;
  }

  if (records.length === 1) {
    return (
      <div className={clsx(classes.root, 'records-1')}>
        <div  className={clsx('record', records[0].indexOf(WR_RECORD) !== -1 && 'is-wr')}>{records[0]}</div>
      </div>
    );
  } else if (records.length === 2) {
    return (
      <div className={clsx(classes.root, 'records-2')}>
        <div  className="record">{records[0]}</div>
        <div  className="record">{records[1]}</div>
      </div>
    );
  } else if (records.length === 3) { // Schválně otáčíme pořadí, aby v prvním slupečku byly "horší rekordy"
    return (
      <div className={clsx(classes.root, 'records-3')}>
        <div  className="record">{records[0]}</div>
        <div  className="record">{records[1]}</div>
        <div  className="record">{records[2]}</div>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, 'records-4')}>
      <div  className="record">{records[0]}</div>
      <div  className="record">{records[1]}</div>
      <div  className="record">{records[2]}</div>
      <div  className="record">{records[3]}</div>
    </div>
  );
}

export default RecordsIndicator;