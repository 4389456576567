import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
      float: 'right',
      fontSize: '19px',
      fontFamily: 'WorldAthletics',
      fontWeight: 'bold',
      lineHeight: '20px',
      opacity: '0.9',
      textTransform: 'uppercase',
      position: 'absolute',
      right: '0px',
      bottom: '3px',

      '& .text': {
        background: theme.palette.records.darker,
        borderRadius: '4px 0 0 4px',
        padding: '0.15rem 0.4rem 0.14rem 0.5rem',
      },
      '& img': {
        height: '17px',
        marginBottom: '-1px',
      },
      '& .wind-value': {
        textTransform: 'none',
        borderRadius: '0 4px 4px 0',
        padding: '0.15rem 0.4rem 0.14rem',
        letterSpacing: '1px',
        background: theme.palette.records.lighter,

        '& .non-regular': {
          textDecoration: 'underline',
          textDecorationColor: 'rgba(255, 0, 0, 0.7)',
          textDecorationThickness: '3px',
        },
        '& .unit': {
          opacity: '0.7',
        }
      }
    },
  }),
);

type WindIndicatorProps = {
  wind: string,
}

function WindIndicator({ wind }: WindIndicatorProps) {
  const classes = useStyles();

  const windIsNotRegular = parseFloat(wind) > 2.0;

  return (
    <div className={classes.root}>
      <span className="text"><img src={`${process.env.PUBLIC_URL}/disciplines/wind.png`} alt="Wind icon" height={15} /></span>
      <span className="wind-value"><span className={clsx('value', windIsNotRegular && 'non-regular')}>{wind}</span> <span className="unit">m/s</span></span>
    </div>
  );
}

export default WindIndicator;
