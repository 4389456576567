import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeTableEventTabType } from '@App/types';
import { RootState } from '@App/store';
import { getTimeTableSelectedEventStateFromSession, setTimeTableSelectedEventStateToSession } from '@App/services/settings/sessionSettingsApi';

export interface ISelectedTimeTableEventState {
  activeTab: TimeTableEventTabType,
}

let initialState: ISelectedTimeTableEventState = {
  activeTab: TimeTableEventTabType.Results,
};

const sessionInitialState = getTimeTableSelectedEventStateFromSession();
if (sessionInitialState) {
  initialState = {
    ...initialState,
    ...sessionInitialState,
  }
}

export const timeTableSelectedEventSlice = createSlice({
  name: 'timeTableSelectedEvent',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<TimeTableEventTabType>) => {
      state.activeTab = action.payload;

      setTimeTableSelectedEventStateToSession(state);
    },
  }
});

export const {
  setActiveTab,
} = timeTableSelectedEventSlice.actions

export const selectActiveTab = (state: RootState) => state.timeTableSelectedEvent.activeTab;

export default timeTableSelectedEventSlice.reducer;