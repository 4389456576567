import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useAppSelector } from '@App/hooks';
import { ITimeTableItem, IResultList, IFrontendSettings, EventState, ITimeTableItemState, TechnicalSortType, ITechnicalResultState, ITechnicalResultListItem } from '@App/types';
import { HI_RES_DEVICE_THRESHOLD_PX } from '@App/constants';

import {selectFollowCurrentCompetitor, selectTechnicalSort} from '@App/appUserStateSlice';

import { useStyles } from './CommonTechnicalResultListTabContent.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RecordsIndicator from '../_components/RecordsIndicator';
import StartCardIndicator from '../_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass, getCompetitorCount, getUnofficialPlaceCssClass } from '@Utils/ui';
import { DisciplineClass } from '@App/disciplines';

function getAttemptCursor(attemptResult: string, hasWinds: boolean) {
  if (attemptResult) {
    return null;
  }
  return (<span className={clsx("attempt-cursor", hasWinds && "has-winds")} />);
}

function getFinalRoundIndicatorLabel(technicalResultState: ITechnicalResultState): string {
  if (technicalResultState.isSortedForFinalA5Enabled && technicalResultState.isSortedForFinalA5) {
    return "Final round (5th att)";
  }
  if (technicalResultState.isSortedForFinalA4Enabled && technicalResultState.isSortedForFinalA4) {
    return "Final round (4th att)";
  }
  return "Final round";
}

function getParticipiantLane(isSortedForFinal: boolean, isSortedForFinalA4: boolean, isSortedForFinalA5: boolean, participiant: ITechnicalResultListItem): string {
  if (isSortedForFinalA5) {
    return participiant.laneFinalA5;
  }
  if (isSortedForFinalA4) {
    return participiant.laneFinalA4;
  }
  if (isSortedForFinal) {
    return participiant.laneFinal;
  }

  return participiant.lane;
}

type CommonTechnicalResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
  scrollableNodeRef: React.Ref<any>,
}

function isNonRegularWind(wind: string) {
  if (!wind) {
    return false;
  }
  return parseFloat(wind) > 2.0;
}

function CommonTechnicalResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching, scrollableNodeRef }: CommonTechnicalResultListTabContentProps) {
  const followCurrentCompetitor = useAppSelector(selectFollowCurrentCompetitor);

  const hasWinds = selectedEvent.disciplineClass === DisciplineClass.LongJump;
  const resultListDataItems = resultListData ? resultListData.technicalResultItems : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);
  const technicalSort = useAppSelector(selectTechnicalSort);

  const showStartListData = selectedEventState?.state === EventState.Scheduled;
  const isInProgress = selectedEventState?.state === EventState.InProgress;
  const showFinalResultsData = selectedEventState?.state === EventState.Unofficial || selectedEventState?.state === EventState.Official;
  const showPointsColumn =  frontendSettings?.hasPoints &&  (frontendSettings?.isPolyathlonMeeting ? selectedEvent.isCombinedDiscipline : true);
  const currentAttemptNumber = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.currentAttemptNumber : -1;
  const currentParticipiantId = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.currentParticipiantId : -1;
  const isSortedForFinal = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.isSortedForFinal : false;
  const isSortedForFinalA4 = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.isSortedForFinalA4 : false;
  const isSortedForFinalA5 = resultListData && resultListData.technicalResultState ? resultListData.technicalResultState.isSortedForFinalA5 : false;

  const isHiResDevice = useMediaQuery(`(min-width:${HI_RES_DEVICE_THRESHOLD_PX})`);
  const classes = useStyles({ hasAnyQualify });

  useEffect(() => {
    if (!showStartListData && !showFinalResultsData) {
      const currentRow = document.querySelector('#technicalResultTable tr.current-attempt-row') as HTMLElement;

      if (currentRow && currentRow.offsetTop > 200 && scrollableNodeRef) {
        (scrollableNodeRef as any).current.scrollTop = currentRow.offsetTop;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ showStartListData, showFinalResultsData ]);

  useEffect(() => {
    if (!followCurrentCompetitor || !(scrollableNodeRef as any).current) return;

    if (showFinalResultsData) {
      (scrollableNodeRef as any).current.scrollTop = 0;
    } else {
      const verticalScrollNode = document.querySelector('#resultListScrollContainer .simplebar-content-wrapper');
      const currentRowElement = document.querySelector('#technicalResultTable .current-attempt-row') as HTMLElement | null;

      if (verticalScrollNode && currentRowElement) {
        const scrollHeight = verticalScrollNode.getBoundingClientRect().height;

        (scrollableNodeRef as any).current.scrollTop = currentRowElement.offsetTop - scrollHeight + 200;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followCurrentCompetitor, currentParticipiantId]);

  if (!frontendSettings) {
    return null;
  }

  if (showStartListData) {
    return (
      <div className={classes.root}>
      <table className={clsx(classes.startListTable, 'has-startlist-data')}>
          <colgroup>
            <col style={{ width: '60px' } /* Lane */} />
            <col style={{ width: '380px' } /* Athlete */} />
            {<col style={{ width: '80px' } /* Age */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col style={{ width: '100px' } /* SB */} />
            {selectedEvent.hasQualifyBest && <col style={{ width: '90px' } /* Qualify Best */} />}
            {frontendSettings?.isInternationalMeeting && <col style={{ width: '90px' } /* WA Rank */} />}
            <col />
          </colgroup>
          <thead>
            <tr>
              {<th>Order</th>}
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                Athlete
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
              </th>
              <th>Age</th>
              <th className="text-center">PB</th>
              <th className="text-center">SB</th>
              {selectedEvent.hasQualifyBest && <th className="text-center" style={{ paddingLeft: '23px', paddingRight: '0px'}}>Qualify</th>}
              {frontendSettings?.isInternationalMeeting && <th className="text-center">WA Rank</th>}
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              {<td className="text-center col-lane">{participiant.lane}</td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && 'has-bibs')}>
                    <div className="athlete-name">
                      {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                      {(participiant.hasDNSStatus || participiant.startlistNote) && <span className="startlist-note">{participiant.hasDNSStatus ? 'DNS' : participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                    </div>
                    <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>
                  </div>
                  {frontendSettings?.displayBIBs && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              <td className="text-center col-yob">{participiant.age || '-'}<span>{participiant.yearOfBirth || ''}</span></td>
              <td className="text-center col-pb">{participiant.personalBest}</td>
              <td className="text-center col-sb">{participiant.seasonBest}</td>
              {selectedEvent.hasQualifyBest && <td className="text-center col-qualify"><span className="qualify-type">{participiant.qualifyBestType}</span>{participiant.qualifyBest}</td>}
              {frontendSettings?.isInternationalMeeting && <td className="text-center col-warank">{participiant.worldRanking}</td>}
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
    </div>
    );
  }

  return (
    <div className={classes.root}>
      <table id="technicalResultTable" className={classes.startListTable}>
          <colgroup>
            <col style={{ width: '65px' } /* Place */} />
            {!showFinalResultsData && <col style={{ width: '30px' } /* Order */} />}
            <col style={{ width: isHiResDevice ? '320px' : '285px' } /* Athlete */} />
            <col style={{ width: '80px' } /* Result */} />
            <col style={{ width: '30px' } /* Gap */} />
            {showPointsColumn && <col style={{ width: '40px' } /* Points */} />}
            <col style={{ width: '15px' } /* Spacer */} />
            <col style={{ width: '62px' } /* A1 */} />
            <col style={{ width: '62px' } /* A2 */} />
            <col style={{ width: '62px' } /* A3 */} />
            {!isSortedForFinalA4 && !isSortedForFinalA5 && <col style={{ width: '60px' } /* Fin */} />}
            <col style={{ width: '62px' } /* A4 */} />
            {isSortedForFinalA4 && !isSortedForFinalA5 && <col style={{ width: '60px' } /* Fin A4 */} />}
            <col style={{ width: '62px' } /* A5 */} />
            {isSortedForFinalA5 && <col style={{ width: '60px' } /* Fin A5 */} />}
            <col style={{ width: '62px' } /* A6 */} />
            {isHiResDevice && <col style={{ width: '100px' } /* PB */} />}
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>
                Rank
                {!showFinalResultsData && technicalSort === TechnicalSortType.ByPlace && <span className="sort-indicator fas fa-long-arrow-alt-down" />}
              </th>
              {!showFinalResultsData && <th className="text-center" style={{ paddingLeft: 0, paddingRight: 0 }}>
                Ord
                {!showFinalResultsData && technicalSort === TechnicalSortType.ByOrder && <span className="sort-indicator fas fa-long-arrow-alt-down" />}
              </th>}
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                Athlete
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{getCompetitorCount(resultListDataItems)}</strong> total)</span>}
                {frontendSettings?.displayBIBs && <span className="bib">Bib</span>}
              </th>
              <th className="text-right">Result</th>
              <th className="text-right">Gap</th>
              {showPointsColumn && <th className="text-right">Pnts</th>}
              <th style={{ padding: '0px' }}></th>
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 1 && 'current-attempt')}><span>1</span></th>
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 2 && 'current-attempt')}><span>2</span></th>
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 3 && 'current-attempt')}><span>3</span></th>
              {!isSortedForFinalA4 && !isSortedForFinalA5 && <th></th>}
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 4 && 'current-attempt')}><span>4</span></th>
              {isSortedForFinalA4 && !isSortedForFinalA5 && <th></th>}
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 5 && 'current-attempt')}><span>5</span></th>
              {isSortedForFinalA5 && <th></th>}
              <th className={clsx('text-center', isInProgress && currentAttemptNumber === 6 && 'current-attempt')}><span>6</span></th>
              {isHiResDevice && <th className="text-center">PB / SB</th>}
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map((participiant, idx) => (
            <tr key={participiant.participiantId} className={clsx(!showFinalResultsData && participiant.isCurrent && 'current-attempt-row', !showFinalResultsData && idx + 1 === resultListData.technicalResultState.numberOfCompetitorsInFinalRound && 'final-round-separator')}>
              <td className={clsx('text-center', 'col-lane', selectedEvent.isFinalPhase && showFinalResultsData ? getPlaceCssClass(participiant.place) : getUnofficialPlaceCssClass(participiant.place))}>
                <span>{participiant.place}</span>
                {!showFinalResultsData && idx + 1 === resultListData.technicalResultState.numberOfCompetitorsInFinalRound && <span className="final-round-indicator">{getFinalRoundIndicatorLabel(resultListData.technicalResultState)}</span>}
              </td>
              {!showFinalResultsData && <td className="text-center col-result-lane">
                {getParticipiantLane(isSortedForFinal, isSortedForFinalA4, isSortedForFinalA5, participiant)}
              </td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && 'has-bibs')}>
                    <div className="athlete-name">
                      {participiant.lastName?.toLocaleUpperCase()} <span className="first-name">{participiant.firstName}</span>
                      {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                    </div>
                    <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>
                  </div>
                  {frontendSettings?.displayBIBs && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              <td className="text-right col-result">
                <div className="result-record-container">
                  <span className={clsx('result', participiant.records?.length > 0 && 'has-records', participiant.resultWind && 'has-wind')}>
                    {participiant.status || (participiant.result === '0' ? '' : participiant.result)}
                    {participiant.resultWind && <span className="result-wind">{participiant.resultWind}m/s</span>}
                  </span>
                  {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                  <RecordsIndicator records={participiant.records} />
                </div>
                {participiant.qualify && <span className={clsx('result-qualify', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
              </td>
              <td className="text-right col-gap">{participiant.timeGap}</td>
              {showPointsColumn && <td className="text-right col-points">{participiant.points}</td>}
              <td className="col-spacer"></td>
              <td className={clsx("text-center col-attempt first-attempt", participiant.bestAttemptNumber === 1 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 1 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[1] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 1 && getAttemptCursor(participiant.attempts?.[1], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[1]) && 'non-regular')}>{participiant.attemptsWinds?.[1]}</span>}
                </div>
              </td>
              <td className={clsx("text-center col-attempt", participiant.bestAttemptNumber === 2 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 2 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[2] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 2 && getAttemptCursor(participiant.attempts?.[2], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[2]) && 'non-regular')}>{participiant.attemptsWinds?.[2]}</span>}
                </div>
              </td>
              <td className={clsx("text-center col-attempt third-attempt", participiant.bestAttemptNumber === 3 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 3 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[3] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 3 && getAttemptCursor(participiant.attempts?.[3], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[3]) && 'non-regular')}>{participiant.attemptsWinds?.[3]}</span>}
                </div>
              </td>
              {!isSortedForFinalA4 && !isSortedForFinalA5 && <td className="text-center col-attempt-final">{participiant.resultFinal === '0' ? '-' : participiant.resultFinal}</td>}
              <td className={clsx("text-center col-attempt fourth-attempt", participiant.bestAttemptNumber === 4 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 4 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[4] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 4 && getAttemptCursor(participiant.attempts?.[4], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[4]) && 'non-regular')}>{participiant.attemptsWinds?.[4]}</span>}
                </div>
              </td>
              {isSortedForFinalA4 && !isSortedForFinalA5 && <td className="text-center col-attempt-final">{participiant.resultFinalA4 === '0' ? '-' : participiant.resultFinalA4}</td>}
              <td className={clsx("text-center col-attempt", participiant.bestAttemptNumber === 5 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 5 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[5] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 5 && getAttemptCursor(participiant.attempts?.[5], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[5]) && 'non-regular')}>{participiant.attemptsWinds?.[5]}</span>}
                </div>
              </td>
              {isSortedForFinalA5 && <td className="text-center col-attempt-final">{participiant.resultFinalA5 === '0' ? '-' : participiant.resultFinalA5}</td>}
              <td className={clsx("text-center col-attempt sixth-attempt", participiant.bestAttemptNumber === 6 && 'best-attempt', !showFinalResultsData && currentAttemptNumber === 6 && 'current-attempt-col')}>
                <div>
                  {participiant.attempts?.[6] || <span className="result-spacer" />}
                  {participiant.isCurrent && !showFinalResultsData && currentAttemptNumber === 6 && getAttemptCursor(participiant.attempts?.[6], hasWinds)}
                  {hasWinds && <span className={clsx('atttempt-wind', isNonRegularWind(participiant.attemptsWinds?.[6]) && 'non-regular')}>{participiant.attemptsWinds?.[6]}</span>}
                </div>
              </td>
              {isHiResDevice && <td className="text-center col-result-pb-sb">
                <div className="result-pb">{participiant.personalBest}</div>
                <div className="result-sb">{participiant.seasonBest}</div>
              </td>}
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
    </div>
  );
}

export default CommonTechnicalResultListTabContent;
