import React from 'react';
import { ITimeTableItem, IResultList, IFrontendSettings, RunsResultType, ITimeTableItemState } from '@App/types';

import TranspondersRunsResultListTabContent from './TranspondersRunsResultListTabContent';
import ResultsRunsResultListTabContent from './ResultsRunsResultListTabContent';

type RunsResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
}

function RunsResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching }: RunsResultListTabContentProps) {
  if (resultListData?.runsResultType === RunsResultType.Transponsers) {
    return (
      <TranspondersRunsResultListTabContent
        selectedEvent={selectedEvent}
        selectedEventState={selectedEventState}
        resultListData={resultListData}
        frontendSettings={frontendSettings}
        hasError={hasError}
        isFetching={isFetching}
      />
    );
  }

  return (
    <ResultsRunsResultListTabContent
      selectedEvent={selectedEvent}
      selectedEventState={selectedEventState}
      resultListData={resultListData}
      frontendSettings={frontendSettings}
      hasError={hasError}
      isFetching={isFetching}
    />
  );
}

export default RunsResultListTabContent;
