import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IRecordsSummaryTotalsTable } from '@App/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: '10px 0',
    },
    totalsTable: {
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '450px',
      margin: '0 auto',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '14px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-gold, .col-silver, .col-bronze': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr:last-child td': {
        borderBottom: '0',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-recordname': {
        fontWeight: 'normal',
      },
      '& td.col-recordtotal': {
        fontSize: '15px',
        textAlign: 'right',
        paddingRight: '20px',
      },
      '& tr.row-totals td': {
        fontSize: '16px',
        borderTop: `3px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-total': {
        fontSize: '19px',
        textAlign: 'right',
        paddingRight: '20px',
      },

      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },
    }
  }),
);

type RecordsSummaryTotalsTableProps = {
  totalsTable: IRecordsSummaryTotalsTable,
};

function RecordsSummaryTotalsTable({ totalsTable }: RecordsSummaryTotalsTableProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <table className={classes.totalsTable}>
        <colgroup>
            <col style={{ width: '40px' }} />
            <col />
            <col style={{ width: '100px' }} />
          </colgroup>
          <thead>
            <tr>
              <th className="text-left" colSpan={3}>Summary</th>
            </tr>
          </thead>
          <tbody>
            {totalsTable.items.map(totalItem => (
              <tr key={totalItem.recordShortcut}>
                <td className="col-shortcut">{totalItem.recordShortcut}</td>
                <td className="col-recordname">{totalItem.recordName}</td>
                <td className="col-recordtotal">{totalItem.total}</td>
              </tr>
            ))}
            <tr className="row-totals">
              <td />
              <td>Total</td>
              <td className="col-total">{totalsTable.totalCount}</td>
            </tr>
          </tbody>
      </table>
    </div>
  );
}

export default RecordsSummaryTotalsTable;