import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IRecordsSummary } from '@App/types';
import { RootState } from '@App/store';

import recordsSummaryApi from '@App/services/recordsSummary/recordsSummaryApi';
import { pollingSucceeded, pollingFailed } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { pollingErrorHandlerSlice } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

export interface IRecordsSummaryListState {
  changeTimestamp: number; // Tohle trigeruje mount/unmout pooling kontejneru, tak aby se forsnul reload při znovu kliku (jinak se vysypal state)
  dataLoaded: boolean;
  isFetching: boolean;
  error: any;
  data: IRecordsSummary | null;
}

const initialState: IRecordsSummaryListState = {
  changeTimestamp: 0,
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: null,
};

export const getRecordsSummaryDataAsync = createAsyncThunk(
  'recordsSummary/fetchData',
  async (_, { dispatch, rejectWithValue, signal }) => {
    try {
      const response = await recordsSummaryApi.fetchRecordsSummaryData(signal);

      dispatch(pollingSucceeded());

      return response;
    } catch (err) {
      dispatch(pollingFailed({ sectionName: 'RecordsSummary', error: err }));
      return rejectWithValue(err);
    }
  }
);

export const recordsSummarySlice = createSlice({
  name: 'recordsSummarySlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecordsSummaryDataAsync.pending, state => {
        state.isFetching = true;
      })
      .addCase(getRecordsSummaryDataAsync.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dataLoaded = true;
        state.data = action.payload;
      })
      .addCase(getRecordsSummaryDataAsync.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.isFetching = false;
          state.error = action.payload;
        }
      })
      .addCase(pollingErrorHandlerSlice.actions.clearPollingErrors, (state) => {
        state.error = null;
      });
  }
});

export const selectIsFetching = (state: RootState) => state.recordsSummary.isFetching;
export const selectError = (state: RootState) => state.recordsSummary.error;
export const selectDataLoaded = (state: RootState) => state.recordsSummary.dataLoaded;
export const selectData = (state: RootState) => state.recordsSummary.data;
export const selectChangeTimestamp = (state: RootState) => state.recordsSummary.changeTimestamp;

export default recordsSummarySlice.reducer;