import { DisciplineType, DisciplineClass } from './disciplines';

export enum ScreenTypes {
  TimeTable = 'TimeTable',
  Points = 'Points',
  FlashQuotes = 'FlashQuotes',
  RecordsSummary = 'RecordsSummary',
  MedalStandings = 'MedalStandings',
  FinalLapCounter = 'FinalLapCounter'
}

export enum TimeTableFilters {
  Sprints = 1,
  MiddleDistance = 2,
  LongDistance = 3,
  Relays = 4,
  VerticalJumps = 5,
  HorizontalJumps = 6,
  Throws = 7,
  AllTrackEvents = 8,
  AllFieldEvents = 9
}

export enum TimeTableGenderFilters {
  Female = 1,
  Male = 2,
  Mixed = 3
}

export enum TransponderPlaceChange {
  Worse = 1,
  Better = 2,
  Same = 3
}

export enum TechnicalSortType {
  ByOrder = 1,
  ByPlace = 2,
}

export enum TopListType {
  Season = 1,
  AllTime = 2,
}

export interface IFrontendSettings {
  meetingName: string;
  dateFrom: string;
  dateTo?: string;
  meetingPlace: string;
  isInternationalMeeting: boolean;
  isIndoor: boolean;
  isPolyathlonMeeting: boolean;
  hasPoints: boolean;
  seasonYear: number;
  meetingLogo: string;
  timeTableRefreshInterval: number;
  refreshInterval: number;
  resultsRefreshInterval: number;
  statesRefreshInterval: number;
  fullscreenPasscode: string;
  hideMeetingName: boolean;
  displayBIBs: boolean;
  availableScreens: ScreenTypes[];
  defaultScreens: ScreenTypes[];
  legacyTouchEnabled: boolean;
  isPublicInstance: boolean;
  photofinishEnabled: boolean;
  activeEventFilterMinuteThreshold: number;
  theme: IFrontendTheme;
}

export interface IFrontendTheme {
  background: string,
  meetingLogo: string;
  hideMeetingName?: boolean;
  colorPrimaryDark: string,
  colorPrimaryLight: string,
  colorPrimaryMain: string,
  colorSecondaryDark: string,
  colorSecondaryLight: string,
  colorSecondaryMain: string,
  appBarGradientFirst: string,
  appBarGradientSecond: string,
  appBarGradientThird: string,
  gradientFirst: string,
  gradientSecond: string,
  gradientThird: string,
  colorBib: string,
  colorClub: string,
  colorResult: string,
}

export enum StartCardType {
  None = "None",
  Yellow = "Yellow",
  Red = "Red",
  YellowBlack = "YellowBlack",
  RedBlack = "RedBlack",
  WhiteRed = "WhiteRed",
  Green = "Green"
}

export enum EventState {
  Scheduled = 1,
  InProgress = 2,
  Unofficial = 3,
  Official = 4,
  Judging = 5,
}

export enum CurrentEventType {
  Previous = 1,
  Current = 2,
  NextUp = 3,
}

export enum RunsResultType {
  Transponsers = "Transponsers",
  Results = "Results",
}

export interface ITimeTableDay {
  number: number;
  date: string;
  eventCount: number;
}

export interface ITimeTableItem {
  rowNumber: number;
  id: number;
  disciplineId: number;
  categoryId: number;
  recordsKey: string;
  startTime: string;
  day: number;
  startDateTime: string;
  isOfficial: boolean;
  eventName: string;
  eventNameFull: string;
  eventNameShort: string;
  eventCategory: string;
  eventCategoryFull: string;
  eventPhase?: string;
  eventPhaseCount: number;
  eventPhaseShort?: string;
  eventPhaseNumber?: string;
  eventSubMeeting?: string;
  gender?: string;
  cameraId: string;
  cameraIdOmega?: string;
  disciplineClass: DisciplineClass;
  disciplineType: DisciplineType;
  withTransponders: boolean;
  liveResultsFromTrasponders: boolean;
  withJudgeOffice: boolean;
  startListNote: string;
  resultListNote: string;
  qualifyFull: string;
  qualify: string;
  qualifyTo: string;
  temperature?: number;
  humidity?: number;
  isFinalPhase: boolean;
  state: EventState;
  isStartlistOfficial: boolean;
  hasQualifyBest: boolean;
  hasReactions: boolean;
  dateOfficial: string;
  isCombinedDiscipline: boolean;
  startOfMainProgramme: boolean;
}

export interface ITimeTableItemState {
  id: number;
  isOfficial: number;
  temperature?: number;
  humidity?: number;
  temperatureEnd?: number;
  humidityEnd?: number;
  state: EventState;
  isStartlistOfficial: boolean;
  liveResultsFromTrasponders: boolean;
  photofinishAvailable: boolean;
  wind: string;
  dateOfficial: string;
}

export interface ITimeTableCurrentEvent {
  id: number;
  state: EventState;
  currentType: CurrentEventType;
}

export interface ITimeTableItemPoints {
  rowNumber: number;
  id: number;
  disciplineId: number;
  categoryId: number;
  recordsKey: string;
  isOfficial: boolean;
  eventName: string;
  eventNameFull: string;
  eventCategory: string;
  eventCategoryFull: string;
  eventPhase?: string;
  eventPhaseNumber?: string;
  eventSubMeeting?: string;
  gender?: string;
  disciplineClass: DisciplineClass;
  disciplineType: DisciplineType;
  startListNote: string;
  state: EventState;
  stateAfter: string;
}

export interface ITimeTableItemStatePoints {
  id: number;
  isOfficial: number;
  state: EventState;
  stateAfter: string;
}

export interface IEventRecord {
  recordsKey: string;
  type: string;
  result: string;
  name: string;
  country: string;
  date: string;
  venue: string;
  id: number;
}

export interface IStartListItem {
  participiantId: number;
  lane: string;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  team: string;
  personalBest: string;
  seasonBest: string;
  qualifyBest: string;
  qualifyBestType: string;
  relayLabel: string;
  country: string;
  hasDNSStatus: boolean;
  startCard: StartCardType;
  age: number;
  worldRanking: string;
  startlistNote: string;
  yearOfBirth: number;
  relayMembers: IStartListRelayMember[]
}

export interface IStartListRelayMember {
  relayId: number;
  athleteId: number;
  firstName: string;
  lastName: string;
  order: number;
  yearOfBirth: number;
  age: number;
}

export interface IResultListItem {
  participiantId: number;
  lane: string;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  team: string;
  personalBest: string;
  seasonBest: string;
  qualifyBest: string;
  qualifyBestType: string;
  relayLabel: string;
  country: string;
  hasDNSStatus: boolean;
  startCard: StartCardType;
  age: number;
  worldRanking: string;
  startlistNote: string;
  yearOfBirth: number;
  relayMembers: IStartListRelayMember[]
  place: string;
  result: string;
  resultThousands: string;
  showThousandsResult: string;
  points: string;
  reaction: string;
  reactionRank: number;
  qualify: string;
  records: string[];
  status: string;
  timeGap: string;
  dqRule: string;
  dqRuleDescription: string;
  judgeOfficeCardsCount?: number;
}

export interface ITransponderResultListItem {
  participiantId: number;
  lane: string;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  team: string;
  personalBest: string;
  seasonBest: string;
  qualifyBest: string;
  qualifyBestType: string;
  relayLabel: string;
  country: string;
  hasDNSStatus: boolean;
  startCard: StartCardType;
  age: number;
  worldRanking: string;
  startlistNote: string;
  yearOfBirth: number;
  relayMembers: IStartListRelayMember[]
  place: string;
  placeChange: TransponderPlaceChange,
  status: string;
  timeGap: string;
  transponderTime: string;
  dqRule: string;
  dqRuleDescription: string;
  transponderLaps: number;
  transponderLastMeta: number;
  transponderLastMetaName: string;
  transponderLastMetaSort: number;
  judgeOfficeCardsCount?: number;
}

export interface ITechnicalResultListItem extends IResultListItem {
  resultWind: string;
  laneFinal: string;
  laneFinalA4: string;
  laneFinalA5: string;
  resultFinal: string;
  resultFinalA4: string;
  resultFinalA5: string;
  placeFinal: string;
  placeFinalA4: string;
  placeFinalA5: string;
  hasRetired: boolean;
  isCurrent: boolean;
  bestAttemptNumber: number;
  attempts: { [attemptNumber: number]: string};
  attemptsWinds: { [attemptNumber: number]: string};
}

export interface ITransponderIntermediateResultListItem extends IResultListItem {
  transponderLaps: number;
  transponderLastMeta: number;
  transponderLastMetaSort: number;
  transponderLastMetaName: number;
  intermediateTimes: { [meta: number]: ITransponderParticipiantIntermediateResultListItem };
}

export interface ITransponderParticipiantIntermediateResultListItem {
  meta: number;
  place: string;
  placeChange: TransponderPlaceChange;
  transponderTime: string;
}

export interface ITransponderIntermediateMeta {
  meters: number,
  splitName: string,
}

export interface ITransponderIntermediateResultCurrentMeta {
  meta: number | null,
  metaName: string | null,
}

export interface IPointListItem {
  participiantId: number;
  lane: string;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  personalBest: string;
  seasonBest: string;
  country: string;
  hasDNSStatus: boolean;
  startCard: StartCardType;
  age: number;
  worldRanking: string;
  startlistNote: string;
  yearOfBirth: number;
  place: string;
  result: string;
  reactionRank: number;
  qualify: string;
  records: string[];
  status: string;
  timeGap: string;
  dqRule: string;
  dqRuleDescription: string;
}

export interface IPolyathlonPointListItem extends IPointListItem {
  disciplinesResults: { [ disciplineId: number]: IPolyathlonDisciplineResult }
}

export interface IPolyathlonDisciplineResult {
  disciplineId: number;
  result: string;
  points: string;
  place: string;
  wind: string;
}

export interface IPolyathlonPointList {
  pointItems: IPolyathlonPointListItem[],
  totalDisciplines: number,
  disciplinesCompleted: number,
  disciplines: IPolyathlonDiscipline[],
}

export interface IPolyathlonDiscipline {
  sort: number;
  disciplineId: number;
  name: string;
  disciplineType: number;
  isOfficial: boolean;
}

export interface ITechnicalResultState {
  isSortedForFinal: boolean;
  isSortedForFinalA4: boolean;
  isSortedForFinalA5: boolean;
  isSortedForFinalA4Enabled: boolean;
  isSortedForFinalA5Enabled: boolean;
  currentAttemptNumber: number;
  currentParticipiantId: number;
  jumpOffAfterHeight: number | null;
  maxAttemptCount: number;
  numberOfCompetitorsInFinalRound: number;
  athletesRemainingInCompetitionCount: number;
  athletesRemainingInCompetition: { [participiantId: number]: boolean};
  heightProgression: string[];
}

export interface IResultList {
  isRuns: boolean;
  runsResultType?: RunsResultType,
  resultItems: IResultListItem[],
  technicalResultItems: ITechnicalResultListItem[],
  intermediateTimes: IIntermediateTime[],
  technicalResultState: ITechnicalResultState,
  trasponderCurrentMeta: ITransponderIntermediateResultCurrentMeta | null,
  transponderResultItems: ITransponderResultListItem[]
}

export interface ITransponderIntermediateResultList {
  currentMeta: ITransponderIntermediateResultCurrentMeta | null;
  metas: ITransponderIntermediateMeta[];
  intermediateTimes: ITransponderIntermediateResultListItem[];
}

export interface IRecordListItem {
  type: string;
  result: string;
  name: string;
  pending: boolean;
  countryNr: string;
  country: string;
  club: string;
  date: string;
  venue: string;
  id: number;
}

export interface IIntermediateTime {
  distance: string;
  bib: string;
  name: string;
  club: string;
  result: string;
}

export interface ISummaryResultListItem {
  participiantId: number;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  team: string;
  personalBest: string;
  seasonBest: string;
  qualifyBest: string;
  qualifyBestType: string;
  relayLabel: string;
  country: string;
  hasDNSStatus: boolean;
  startCard: StartCardType;
  age: number;
  worldRanking: string;
  startlistNote: string;
  yearOfBirth: number;
  relayMembers: IStartListRelayMember[]
  place: string;
  placeInPhase: string;
  result: string;
  resultThousands: string;
  showThousandsResult: string;
  points: string;
  reaction: string;
  reactionRank: number;
  qualify: string;
  records: string[];
  status: string;
  timeGap: string;
  dqRule: string;
  dqRuleDescription: string;
}

export interface ISummaryResultList {
  resultItems: ISummaryResultListItem[],
  phaseNumbers: string[],
}

export interface IVictoryListItem extends ISummaryResultListItem {
  coach: string;
}

export interface IVictoryCeremonyList {
  resultItems: IVictoryListItem[],
  phaseNumbers: string[],
}

export interface ITopListItem {
  id: string;
  result: string;
  wind: string;
  rank: string;
  name: string;
  country: string;
  club: string;
  venue: string;
  date: string;
}

export interface IFlashQuoteItem {
  id:number,
  prticipiantId: number,
  firstName: string,
  lastName: string,
  club: string,
  clubFull: string,
  country: string,
  qualify: string,
  status: string,
  records: string[],
  place: string,
  result: string,
  startTime: string,
  day: string,
  eventDateTime: string,
  eventName: string,
  eventCategory: string,
  eventPhase?: string,
  eventPhaseNumber?: string,
  eventSubMeeting?: string,
  gender?: string,
  isRelays: boolean,
  htmlText?: string,
  htmlTextEnglish: string,
  interviewDate: string,
}

export interface IMedalStandingsItem {
  id: number;
  place: string;
  placeTotal: string;
  sort: number;
  sortTotal: number;
  country: string;
  shortName: string;
  fullName: string;
  gold: number;
  silver: number;
  bronze: number;
  totalMedals: number;
}

export interface ISystemInfo {
  appVersion: string,
  buildDate: string,
  serverTime: string,
  currentDayNumber: number;
  flashQuotesCount: number,
}

export enum TimeTableEventTabType {
  StartList = "StartList",
  Results = "Results",
  SummaryResults = "SummaryResults",
  Records = "Records",
  TopList = "TopList",
  VictoryCeremony = 'VictoryCeremony',
  TransponderIntermediateTimes = 'TransponderIntermediateTimes',
  FoulAssist = 'FoulAssist',
};

export interface IFoulAssistAttempt {
  attempt: number;
  value: string;
}

export interface IFoulAssistItem {
  athleteData: IStartListItem;
  athleteAttempts: IFoulAssistAttempt[];
}

export interface IRecordsSummary {
  records: IRecordsSummaryItem[];
  totals: IRecordsSummaryTotalsTable;
}

export interface IRecordsSummaryTotalsTable {
  items: IRecordsSummaryTotalsTableItem[];
  totalCount: number;
}

export interface IRecordsSummaryTotalsTableItem {
  recordShortcut: string;
  recordName: string;
  total: number;
}

export interface IRecordsSummaryItem {
  record: string;
  participiants: IRecordsSummaryParticipiantItem[];
}

export interface IRecordsSummaryParticipiantItem {
  participiantId: number;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  relayLabel: string;
  country: string;
  isRelay: boolean;
  eventName: string;
  eventCategory: string;
  eventPhase: string;
  eventPhaseNumber: string;
  result: string;
  recordType: string;
  sort: number;
}

export interface IFinalLapListItem {
  participiantId: number;
  lane: string;
  bib: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  club: string;
  clubFull: string;
  personalBest: string;
  seasonBest: string;
  qualifyBest: string;
  qualifyBestType: string;
  country: string;
  startCard: StartCardType;
}