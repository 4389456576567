import React, { useEffect } from 'react';

export type PollingPromise = Promise<any> & { abort: () => void, unwrap: () => Promise<any> };

type PollingPromiseError = { name: string, message: string };

type PollingContainerProps = {
  argDeps?: any;
  changeTimeStamp?: number;
  interval: number;
  onPollTimeoutTick: () => PollingPromise;
}

function PollingContainer({ argDeps, changeTimeStamp, interval, onPollTimeoutTick }: PollingContainerProps) {
  const useEffectDeps = argDeps ? [argDeps, changeTimeStamp, interval] : [changeTimeStamp, interval];

  useEffect(() => {
    let ptr:NodeJS.Timeout;
    let pendingPromise:PollingPromise;
    let unmounting:boolean = false;

    // Run then promise & schedule next run after it finishes
    function onPollTimeoutTickInternal() {
      pendingPromise = onPollTimeoutTick();
      pendingPromise
        .unwrap()
        .then(() => {
          scheduleNextPollTimeout();
        })
        .catch((err: PollingPromiseError) => {
          if (err.name !== 'AbortError' && !unmounting) { // Do not schedule next run after abort
            scheduleNextPollTimeout();
          }
        });
    }

    function scheduleNextPollTimeout() {
      if (!unmounting) { // Race condition
        clearInterval(ptr);
        ptr = setTimeout(onPollTimeoutTickInternal, interval);
      }
    }

    // Run for first time & schedule next run
    onPollTimeoutTickInternal();
    scheduleNextPollTimeout();

    // Cleanup on unmount
    return () => {
      unmounting = true;
      clearTimeout(ptr);

      if (pendingPromise) {
        pendingPromise.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDeps);

  return (
    <span />
  );
}

export default PollingContainer;