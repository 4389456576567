export const PING_BACKEND_INTERVAL = 1000;

export const HI_RES_DEVICE_THRESHOLD_PX = '1400px';
export const CURRENT_EVENTS_STATE_THRESHOLD_PX = '1150px';

export const MAX_HEIGHT_PROGRASSIONS_COUNT = 13;

export const RETIRED_MARK = 'r';

export const DNS_MARK = 'DNS';

export const PASSES_PARTIAL_ATTEMPT_MARK = 'O';

export const FINAL_ATTEMPT_MARK = 'XXX';

export const RENDER_CURRENT_ATTEMPT_TIMEOUT = 10000;

export const TIME_TABLE_TOUCH_END_DIFF_THRESHOLD = 700;

export const TIME_TABLE_TOUCH_SELECT_DELAY = 300;

export const TIME_TABLE_SCROLLABLE_PARENT_ID = 'timeTableScrollWrapper';

export const TIME_TABLE_LIST_ELEMENT_ID = 'timeTableList';

export const MAX_DEFAULT_SCREENS = 3;