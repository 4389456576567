import {range} from "lodash";
import React from 'react';
import clsx from 'clsx';
import {
  ITimeTableItem,
  IResultList,
  IFrontendSettings,
  EventState,
  ITimeTableItemState,
  StartCardType
} from '@App/types';

import { useStyles } from './ResultsRunsResultListTabContent.styles';
import { DisciplineType } from '@App/disciplines';

import StartCardIndicator from '../_components/StartCardIndicator';
import PlaceChangeIndicator from '../_components/PlaceChangeIndicator';

import {  getFlagOrClubImage, getCountryFlagImage, getUnofficialPlaceCssClass, getCompetitorCount } from '@Utils/ui';

type TranspondersRunsResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
  resultListData: IResultList,
  frontendSettings: IFrontendSettings | undefined,
  hasError: boolean,
  isFetching: boolean,
}

function TranspondersRunsResultListTabContent({ selectedEvent, selectedEventState, resultListData, frontendSettings, hasError, isFetching }: TranspondersRunsResultListTabContentProps) {
  const resultListDataItems = resultListData ? resultListData.transponderResultItems : null;

  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;
  const showStartListData = selectedEventState?.state === EventState.Scheduled;

  const classes = useStyles({ hasAnyQualify: false });

  if (!frontendSettings) {
    return null;
  }

  if (showStartListData) {
    return (
      <div className={classes.root}>
      <table className={clsx(classes.startListTable, 'has-startlist-data')}>
          <colgroup>
            <col style={{ width: '60px' } /* Lane */} />
            <col style={{ width: isRelays ? '560px' : '380px' } /* Athlete */} />
            {!isRelays && <col style={{ width: '80px' } /* Age */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col style={{ width: '100px' } /* SB */} />
            {selectedEvent.hasQualifyBest && <col style={{ width: '90px' } /* Qualify Best */} />}
            {frontendSettings?.isInternationalMeeting && <col style={{ width: '90px' } /* WA Rank */} />}
            <col />
          </colgroup>
          <thead>
            <tr>
              {<th>Lane</th>}
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                {isRelays ? 'Relay' : 'Athlete'}
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
              </th>
              {!isRelays && <th>Age</th>}
              <th className="text-center">PB</th>
              <th className="text-center">SB</th>
              {selectedEvent.hasQualifyBest && <th className="text-center" style={{ paddingLeft: '23px', paddingRight: '0px'}}>Qualify</th>}
              {frontendSettings?.isInternationalMeeting && <th className="text-center">WA Rank</th>}
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              {<td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays')}>{participiant.lane}</td>}
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                    <div className="athlete-name">
                      {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                      {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                      {(participiant.hasDNSStatus || participiant.startlistNote) && <span className="startlist-note">{participiant.hasDNSStatus ? 'DNS' : participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      {isRelays && participiant.relayMembers &&
                      <ul className="relay-members">
                        {participiant.relayMembers.map(member => (
                          <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                        ))}
                      </ul>}
                    </div>
                    {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                  </div>
                  {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              {!isRelays && <td className="text-center col-yob">{participiant.age || '-'}<span>{participiant.yearOfBirth || ''}</span></td>}
              <td className="text-center col-pb">{participiant.personalBest}</td>
              <td className="text-center col-sb">{participiant.seasonBest}</td>
              {selectedEvent.hasQualifyBest && <td className="text-center col-qualify"><span className="qualify-type">{participiant.qualifyBestType}</span>{participiant.qualifyBest}</td>}
              {frontendSettings?.isInternationalMeeting && <td className="text-center col-warank">{participiant.worldRanking}</td>}
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
        </table>
    </div>
    );
  }

  return (
    <div className={classes.root}>
      {resultListData && resultListData.trasponderCurrentMeta?.meta &&
      <div className={classes.currentTransponderDistance}>
        Intermediate results from transponders at distance - <span>{resultListData.trasponderCurrentMeta.metaName}</span>
      </div>}
      <table className={classes.startListTable}>
          <colgroup>
            <col style={{ width: '65px' } /* Place */} />
            <col style={{ width: '30px' } /* Lane */} />
            <col style={{ width: isRelays ? '550px' : '380px' } /* Athlete */} />
            <col style={{ width: '100px' } /* Result */} />
            <col style={{ width: '30px' } /* Gap */} />
            <col style={{ width: '36px' } /* Lap */} />
            {selectedEvent.withJudgeOffice && <col style={{ width: '90px' } /* RedCards */} />}
            <col style={{ width: '100px' } /* PB */} />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Rank</th>
              <th className="text-center" style={{ paddingLeft: '5px' }}>LN</th>
              <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                {isRelays ? 'Relay' : 'Athlete'}
                {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{getCompetitorCount(resultListDataItems)}</strong> total)</span>}
                {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
              </th>
              <th className="text-right">Result</th>
              <th className="text-right">Gap</th>
              <th className="text-center">Laps</th>
              {selectedEvent.withJudgeOffice && <th className="text-left">Red Cards</th>}
              <th className="text-center">PB / SB</th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
          {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
            <tr key={participiant.participiantId}>
              <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays', getUnofficialPlaceCssClass(participiant.place))}>
                <span>{participiant.place}</span>
              </td>
              <td className="text-center col-result-lane">{participiant.lane}</td>
              <td className="text-left col-athlete">
                <div className="athlete-flag-container">
                  <div className="flag-container">
                    <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                    <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                  </div>
                  <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                    <div className="athlete-name">
                      {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                      {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                      {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                      {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                      {isRelays && participiant.relayMembers &&
                      <ul className="relay-members">
                        {participiant.relayMembers.map(member => (
                          <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                        ))}
                      </ul>}
                    </div>
                    {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                  </div>
                  {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                </div>
              </td>
              <td className="text-right col-result">
                <div className="result-record-container">
                  <span className="result">
                    {participiant.status || participiant.transponderTime}
                    {resultListData.trasponderCurrentMeta && participiant.transponderLastMeta && <span className="result-last-meta">{participiant.transponderLastMetaSort && <span className="last-meta-sort">( {participiant.transponderLastMetaSort} )</span>} {participiant.transponderLastMetaName}</span>}
                  </span>
                  {!participiant.status && <PlaceChangeIndicator placeChange={participiant.placeChange} />}
                  {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                </div>
              </td>
              <td className="text-right col-gap">{(participiant.timeGap?.trim() ? `+${participiant.timeGap}` : '')}</td>
              <td className="text-center col-laps">{participiant.transponderLaps}</td>
              {selectedEvent.withJudgeOffice && <td className="text-left col-red-cards">
                {participiant.judgeOfficeCardsCount && range(0, participiant.judgeOfficeCardsCount).map(i => (<StartCardIndicator key={i} startCard={StartCardType.Red} />))}
              </td>}
              <td className="text-center col-result-pb-sb">
                <div className="result-pb">{participiant.personalBest}</div>
                <div className="result-sb">{participiant.seasonBest}</div>
              </td>
              <td>&nbsp;</td>
            </tr>
          ))}
          {!hasError && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
          <tr>
            <td colSpan={100} className="col-no-athletes">
              <div>There are no athletes in this event yet</div>
            </td>
          </tr>}
          </tbody>
      </table>
    </div>
  );
}

export default TranspondersRunsResultListTabContent;
