import React, { useEffect, useState  } from 'react';
import { getErrorMessage } from '@Utils/error';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { pingBackend } from '@App/services/pingApi';
import { PING_BACKEND_INTERVAL } from '@App/constants';

const PAGE_REFRESH_TIMEOUT = 20000;

type InitErrorHandlerProps = {
  error: FetchBaseQueryError | SerializedError | undefined
};

function InitErrorHandler({ error }: InitErrorHandlerProps) {
  const [autoReload, setAutoReload] = useState(true);

  const [backendAvailable, setBackendAvailable] = useState(false);

  const handleRefreshButtonClick = () => {
    window.location.reload(true);
  };

  const handleCancelAutoReload = () => {
    setAutoReload(false);
  };

  useEffect(() => {
    let reloadTimeoutHandle:NodeJS.Timeout | null;

    if (autoReload && backendAvailable) {
      reloadTimeoutHandle = setInterval(() => {
        console.warn('Auto reloading application after settings initialization failure...');
        window.location.reload(true);
      }, PAGE_REFRESH_TIMEOUT);
    }

    return () => {
      if (reloadTimeoutHandle) {
        clearInterval(reloadTimeoutHandle);
      }
    }
  }, [autoReload, backendAvailable]);

  useEffect(() => {
    let hnd: NodeJS.Timeout | null = null;

    function scheduleNextCheck() {
      hnd = setTimeout(() => {
        pingBackend().then(backendAvailable => {
          setBackendAvailable(backendAvailable);

          scheduleNextCheck();
        });
      }, PING_BACKEND_INTERVAL);
    }

    scheduleNextCheck();

    return () => {
      if (hnd) {
        clearTimeout(hnd);
      }
    };
  }, []);

  return (
    <div className="global-error-root">
      <div className="aligner">
        <i className="fas fa-exclamation-triangle" />
        <h1>Failed to initialize CIS application!</h1>
        <h2>Application will reload automatically...</h2>
        <div>
          <button onClick={handleRefreshButtonClick} className="reload-app-button">Reload application</button>
        </div>
        {(error) &&
        <div className="global-error-diagnostic-info">
          <h3>Diagnostic information:</h3>
          <pre>
            {getErrorMessage(error)}
          </pre>
        </div>}
        <div>
          {autoReload && <button onClick={handleCancelAutoReload} className="reload-app-button cancel">Cancel auto reload</button>}
        </div>
      </div>
    </div>
  );
}

export default InitErrorHandler;