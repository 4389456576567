import React from 'react';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { ITimeTableItem, ITimeTableItemState } from '@App/types';

import { useStyles } from './SummaryResultListTabContent.styles';
import { DisciplineType, isTechnicalDisciplineClass } from '@App/disciplines';

import {
  selectIsFetching,
  selectError,
  selectData,
  selectDataLoaded,
  selectChangeTimestamp,
  getSummaryResultListDataAsync,
} from './summaryResultListSlice';
import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';

import LegacyTouchWrapper from '@Components/legacyTouchWrapper';
import PollingContainer from '@Components/pollingContainer';
import { PollingPromise } from '@Components/pollingContainer/PollingContainer';
import SimpleBar from 'simplebar-react';
import { CircularProgress } from '@material-ui/core';

import RecordsIndicator from '@Features/timeTableSelectedEvent/resultList/_components/RecordsIndicator';
import StartCardIndicator from '@Features/timeTableSelectedEvent/resultList/_components/StartCardIndicator';

import { getFlagOrClubImage, getCountryFlagImage, getPlaceCssClass, getResultThousandsPart } from '@Utils/ui';


type SummaryResultListTabContentProps = {
  selectedEvent: ITimeTableItem,
  selectedEventState: ITimeTableItemState | null,
}

type PollTimeoutTickHandler = () => PollingPromise;

function SummaryResultListTabContent({ selectedEvent, selectedEventState }: SummaryResultListTabContentProps) {
  const dispatch = useAppDispatch();
  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');

  const isFetching = useAppSelector(selectIsFetching);
  const error = useAppSelector(selectError);
  const dataLoaded = useAppSelector(selectDataLoaded);
  const resultListData = useAppSelector(selectData);
  const changeTimeStamp = useAppSelector(selectChangeTimestamp);

  const resultListDataItems = resultListData ? resultListData.resultItems : null;
  const hasAnyQualify = resultListDataItems && resultListDataItems.some(x => x.qualify);
  const showPointsColumn =  frontendSettings?.hasPoints &&  (frontendSettings?.isPolyathlonMeeting ? selectedEvent.isCombinedDiscipline : true);

  const classes = useStyles({ hasAnyQualify });
  const isRelays = selectedEvent.disciplineType === DisciplineType.Relays;
  const isTechnicalDiscipline = isTechnicalDisciplineClass(selectedEvent.disciplineClass);

  const handlePollTimeoutTick: PollTimeoutTickHandler = () => {
    return dispatch(getSummaryResultListDataAsync({ timeTableId: selectedEvent.id}));
  };

  return (
    <div className={classes.root}>
      {frontendSettings &&
      <PollingContainer
        argDeps={selectedEvent.id}
        changeTimeStamp={changeTimeStamp}
        interval={frontendSettings.resultsRefreshInterval}
        onPollTimeoutTick={handlePollTimeoutTick}
      />}
    {(isFetching || error) && <CircularProgress className={clsx(classes.progress, dataLoaded && 'data-loaded')} size={dataLoaded ? 20 : 60} />}
    <div id="resultListScrollContainer" className={classes.scrollContainer}>
      <SimpleBar autoHide={false} scrollbarMinSize={125} className={classes.simpleBar}>
        <LegacyTouchWrapper>
          {resultListData && resultListData.phaseNumbers &&
          <div className={classes.summaryResultsInfo}>
            Summary results of <span>{selectedEvent.eventNameFull} {selectedEvent.eventCategoryFull} {selectedEvent.eventPhase} {resultListData.phaseNumbers.join(', ')}</span>
          </div>}
          <table className={classes.startListTable}>
              <colgroup>
                <col style={{ width: '65px' } /* Place */} />
                <col style={{ width: '30px' } /* Lane */} />
                <col style={{ width: '1%', minWidth: isRelays ? '560px' : '380px' } /* Athlete */} />
                <col style={{ width: '80px' } /* Result */} />
                <col style={{ width: '30px' } /* Gap */} />
                {showPointsColumn && <col style={{ width: '30px' } /* Points */} />}
                {!isTechnicalDiscipline && selectedEvent.hasReactions && <col style={{ width: '30px' } /* Reaction */} />}
                <col style={{ width: '100px' } /* PB */} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th className="text-center" style={{ paddingLeft: '5px' }}>Phase</th>
                  <th className="text-left" style={frontendSettings?.displayBIBs ? { paddingRight: '0px'} : {}}>
                    {isRelays ? 'Relay' : 'Athlete'}
                    {resultListData && resultListDataItems && resultListDataItems.length > 0 && <span className="athlete-count">(<strong>{resultListDataItems.length}</strong> total)</span>}
                    {frontendSettings?.displayBIBs && !isRelays && <span className="bib">Bib</span>}
                  </th>
                  <th className="text-right">Result</th>
                  <th className="text-right">Gap</th>
                  {showPointsColumn && <th className="text-right">Points</th>}
                  {!isTechnicalDiscipline && selectedEvent.hasReactions && <th className="text-right">Reaction</th>}
                  <th className="text-center">PB / SB</th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
              {resultListData && resultListDataItems && resultListDataItems.map(participiant => (
                <tr key={participiant.participiantId}>
                  <td className={clsx('text-center', 'col-lane', isRelays && 'col-lane-relays', selectedEvent.isFinalPhase && getPlaceCssClass(participiant.place))}><span>{participiant.place}</span></td>
                  <td className={clsx('text-center col-result-lane', isRelays && 'is-relays')}>{participiant.placeInPhase}</td>
                  <td className="text-left col-athlete">
                    <div className="athlete-flag-container">
                      <div className="flag-container">
                        <div className="flag" style={{backgroundImage: `url('${getCountryFlagImage(participiant.country)}')`}} />
                        <div className="flag" style={{backgroundImage: `url('${getFlagOrClubImage(participiant.country, participiant.club)}')`}} />
                      </div>
                      <div className={clsx('athlete-container', frontendSettings?.displayBIBs && !isRelays && 'has-bibs')}>
                        <div className="athlete-name">
                          {!isRelays && participiant.lastName?.toLocaleUpperCase()} <span className={clsx('first-name', isRelays && 'relay-name')}>{participiant.firstName}</span>
                          {isRelays && participiant.relayLabel && <span className="relay-label">"{participiant.relayLabel}"</span>}
                          {participiant.startlistNote && <span className="startlist-note">{participiant.startlistNote}</span>}
                          {participiant.startCard && <StartCardIndicator startCard={participiant.startCard} />}
                          {isRelays && participiant.relayMembers &&
                          <ul className="relay-members">
                            {participiant.relayMembers.map(member => (
                              <li key={member.athleteId}><span className="member-order">{member.order}.</span> <span className="member-last-name">{member.lastName?.toLocaleUpperCase()}</span> <span className="member-first-name">{member.firstName}</span> <span className="member-yob">({member.yearOfBirth})</span></li>
                            ))}
                          </ul>}
                        </div>
                        {!isRelays && <div className="club-name">{frontendSettings?.isInternationalMeeting && <><strong>{participiant.club}{participiant.team && <span> ({participiant.team})</span>}</strong> - </>}{participiant.clubFull}</div>}
                        {frontendSettings?.displayBIBs && !isRelays && <span className="bib">{participiant.bib}</span>}
                      </div>
                    </div>
                  </td>
                  <td className="text-right col-result">
                    <div className="result-record-container">
                      <span className={clsx('result', participiant.records?.length > 0 && 'has-records', participiant.showThousandsResult && 'has-thousands')}>
                        {participiant.status || participiant.result}
                        {participiant.showThousandsResult && <span className="result-thousands">{getResultThousandsPart(participiant.resultThousands)}</span>}
                      </span>
                      {participiant.dqRule && <span className="result-disqualify-rule">{participiant.dqRule}</span>}
                      <RecordsIndicator records={participiant.records} />
                    </div>
                    {participiant.qualify && <span className={clsx('result-qualify', isRelays && 'is-relays', (participiant.qualify === 'Q?' || participiant.qualify === 'q?') && 'conflicting')}>{participiant.qualify}</span>}
                  </td>
                  <td className="text-right col-gap">{participiant.timeGap}</td>
                  {showPointsColumn && <td className="text-right col-points">{participiant.points}</td>}
                  {!isTechnicalDiscipline && selectedEvent.hasReactions && <td className="text-right col-reaction">
                    <span className={clsx(participiant.reactionRank === 1 && 'fastest')}>{participiant.reaction}</span> {participiant.reactionRank && <span className="reaction-rank">{participiant.reactionRank}</span>}
                  </td>}
                  <td className="text-center col-result-pb-sb">
                    <div className="result-pb">{participiant.personalBest}</div>
                    <div className="result-sb">{participiant.seasonBest}</div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
              {!error && resultListData && resultListDataItems && resultListDataItems.length === 0 &&
              <tr>
                <td colSpan={100} className="col-no-athletes">
                  <div>There are no summary results in this event yet</div>
                </td>
              </tr>}
              </tbody>
            </table>
          </LegacyTouchWrapper>
        </SimpleBar>
      </div>
    </div>
  );
}

export default SummaryResultListTabContent;
