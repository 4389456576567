import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@App/hooks';
import { EventState, TimeTableEventTabType } from '@App/types';

import { frontendSettingsApi } from '@App/services/settings/frontendSettingsApi';
import { eventsApi } from '@App/services/events/eventsApi';
import { makeTimeTableSelectedEventSelector } from '@Features/timeTable/selectors';

import { setSelectedTimeTableId } from '@Features/timeTable/timeTableSlice';
import { setActiveTab, selectActiveTab } from './timeTableSelectedEventSlice';
import { clearPollingErrors } from '@Features/errorHandler/polling/pollingErrorHandlerSlice';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, Tabs, Tab } from '@material-ui/core';

import EventHeader from './eventHeader';
import TabPanel from './TabPanel';
import StartListTabContent from './startList';
import ResultListTabContent from './resultList';
import SummaryResultListTabContent from './summaryResultList';
import TopListTabContent from './topList';
import VictoryCeremonyTabContent from './victoryCeremony';
import TransponderIntermediateTimesTabContent from './transponderIntermediateTimes';
import RecordListTabContent from './recordList';
import PollingErrorHandler from '@Features/errorHandler/polling/PollingErrorHandler';
import ResultsTabHeader from './_components/ResultsTabHeader';
import CurrentEvents from './currentEvents';
import { DisciplineClass } from '@App/disciplines';
import FoulAssistTabContent from './foulAssist';
import TakeOffBoardTabHeader from './_components/TakeOffBoardTabHeader';

const currentEventsHeight = 56;

function getCardHeight(headerHasThreeRows: boolean | null, timeTableSelectedEvent: boolean, hasAnyCurrentEvents: boolean | undefined) {
  if (timeTableSelectedEvent) {
    if (headerHasThreeRows) {
      if (hasAnyCurrentEvents) {
        return `calc(100% - ${(101 + currentEventsHeight - 3)}px)`;
      }
      return 'calc(100% - 98px)';
    }
    if (hasAnyCurrentEvents) {
      return `calc(100% - ${(80 + currentEventsHeight - 3)}px)`;
    }
    return 'calc(100% - 78px)';
  }
  if (hasAnyCurrentEvents) {
    return `calc(100% - ${(12 + currentEventsHeight - 3)}px)`;
  }
  return 'calc(100% - 9px)';
}

function getTabsPanelWrapperHeight(headerHasThreeRows: boolean | null, hasAnyCurrentEvents: boolean | undefined) {
  if (headerHasThreeRows) {
    if (hasAnyCurrentEvents) {
      return `calc(100vh - ${(218 + currentEventsHeight - 3)}px)`;
    }
    return 'calc(100vh - 218px)';
  }
  if (hasAnyCurrentEvents) {
    return `calc(100vh - ${(198 + currentEventsHeight - 3)}px)`;
  }
  return 'calc(100vh - 198px)';
}

type StyleProps = {
  headerHasThreeRows: boolean | null;
  timeTableSelectedEvent: boolean;
  hasAnyCurrentEvents: boolean | undefined;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    topBorder: {
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
    },
    card: {
      height: ({ headerHasThreeRows, timeTableSelectedEvent, hasAnyCurrentEvents }) => getCardHeight(headerHasThreeRows, timeTableSelectedEvent, hasAnyCurrentEvents),
      borderRadius: '0 0 4px 4px',
      marginTop: '8px',
      background: 'transparent',
    },
    cardEvent: {

    },
    cardEventContent: {
      padding: '2px 12px 8px 12px !important',
      borderRadius: '0 0 4px 4px',
    },
    cardContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-24px',
      padding: '0 !important',
    },
    cardCurrentEvents: {
      height: `${currentEventsHeight}px`,
      display: 'flex',
      flexDirection: 'column',
      padding: '0 !important',
      marginBottom: '-24px',
      borderRadius: '0 0 4px 4px',

      '& > .MuiCardContent-root': {
        padding: 0,
        height: '100%',
      }
    },

    tabsWrapper: {
      position: 'relative',
      height: '100%',
    },
    tabs: {
      minHeight: '40px',

      '& .MuiTab-textColorPrimary': {
        color: '#fff',
        fontWeight: 'bold',
        letterSpacing: '1px',
      },
      '& .MuiTabs-indicator': {
        bottom: 'auto',
        top: '0',
        background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      },
      '& .MuiButtonBase-root': {
        paddingTop: '2px',
        paddingBottom: '0',
        minHeight: '40px',
        lineHeight: '21px',
        // borderRight: '1px solid #4a4d50',
        marginRight: '2px',
        background: theme.palette.tabs.tabStripBackground,
      },
      '& .MuiButtonBase-root:first-child': {
        borderTopLeftRadius: '4px',
      },
      '& .MuiButtonBase-root:last-child': {
        borderRight: '0',
        borderTopRightRadius: '4px',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        background: theme.palette.records.lighter,
      },
      '& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons': {
        background: theme.palette.primary.main,
      }
    },
    tabsPanelWrapper: {
      background: theme.palette.background.paper,
      height: ({ headerHasThreeRows, hasAnyCurrentEvents }) => getTabsPanelWrapperHeight(headerHasThreeRows, hasAnyCurrentEvents),

      '& > div': { // To je první div v contentu tabu
        height: '100%',
      },
      '& > div > div': {
        height: '100%',
      },
    },
    timeTableLoader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center',
      opacity: '0.5',
    }
  }),
);

type ActiveTabChangedHandler = (event: React.ChangeEvent<{}>, newTab: TimeTableEventTabType) => void;
type CurrentEventSelectedHandler = (timeTableId: number, eventState: EventState) => void;

function TimeTableSelectedEvent() {
  const dispatch = useAppDispatch();

  const { data: frontendSettings } = frontendSettingsApi.endpoints.getFrontendSettings.useQueryState('');
  const { data: timeTableData, isLoading, isUninitialized } = eventsApi.endpoints.getEvents.useQueryState('');
  const { data: timeTableStatesData, isLoading: statesIsLoading } = eventsApi.endpoints.getEventsStates.useQueryState('');

  const timeTableSelectedEvent = useAppSelector(makeTimeTableSelectedEventSelector(timeTableData));
  const selectedEventState = timeTableStatesData && timeTableSelectedEvent ? timeTableStatesData.states[timeTableSelectedEvent.id] : null;
  const eventRecords = timeTableData && timeTableSelectedEvent ? timeTableData.records[timeTableSelectedEvent.recordsKey] : null
  const activeTab = useAppSelector(selectActiveTab);
  const hasMultipleDays = !!frontendSettings?.dateTo;
  const hasAnyCurrentEvents = timeTableData && timeTableStatesData && timeTableStatesData.currentEvents?.length > 0;

  const classes = useStyles({ headerHasThreeRows: timeTableSelectedEvent && !!timeTableSelectedEvent.qualifyFull, timeTableSelectedEvent: !!timeTableSelectedEvent, hasAnyCurrentEvents });

  useEffect(() => { // Clear polling error handler after unmount
    return () => {
      dispatch(clearPollingErrors());
    };
  }, [timeTableSelectedEvent?.id, dispatch]);

  const handleActiveTabChange: ActiveTabChangedHandler = (event: React.ChangeEvent<{}>, newTab: TimeTableEventTabType) => {
    dispatch(setActiveTab(newTab))
  };

  const handleCurrentEventSelected: CurrentEventSelectedHandler = (timeTableId: number, eventState: EventState) => {
    const hasChanged = timeTableId !== timeTableSelectedEvent?.id;

    if (hasChanged) {
      if (activeTab !== TimeTableEventTabType.Results) {
        dispatch(setActiveTab(TimeTableEventTabType.Results));
      }

      dispatch(setSelectedTimeTableId({ timeTableId, hasChanged }));
    }
  }

  return (
    <div className={classes.root}>
      {timeTableSelectedEvent && <div className={classes.topBorder} />}
      {timeTableSelectedEvent &&
      <Card className={classes.cardEvent}>
        <CardContent className={classes.cardEventContent}>
          <EventHeader
            event={timeTableSelectedEvent}
            eventState={selectedEventState}
            records={eventRecords}
            hasMultipleDays={hasMultipleDays}
          />
        </CardContent>
      </Card>}

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {(isLoading || statesIsLoading) &&
          <div className={classes.timeTableLoader}>
            <h2>Waiting for time table to be loaded...</h2>
          </div>}
          {!isUninitialized && !isLoading && !timeTableSelectedEvent &&
          <div className={classes.timeTableLoader}>
            <h2>Please select event from time table...</h2>
          </div>}
          {timeTableSelectedEvent &&
          <>
            <div className={classes.tabsWrapper}>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleActiveTabChange}
                className={classes.tabs}
              >
                <Tab value={TimeTableEventTabType.StartList} label="Startlist" />
                <Tab value={TimeTableEventTabType.Results} label={<ResultsTabHeader activeTabType={activeTab} selectedEvent={timeTableSelectedEvent} />} />
                {timeTableSelectedEvent.withTransponders && <Tab value={TimeTableEventTabType.TransponderIntermediateTimes} label="Intermediate times" />}
                {timeTableSelectedEvent.eventPhaseNumber && <Tab value={TimeTableEventTabType.SummaryResults} label="Summary Results" />}
                <Tab value={TimeTableEventTabType.Records} label="Records" />
                <Tab value={TimeTableEventTabType.TopList} label="Top Lists" />
                {timeTableSelectedEvent.isFinalPhase && timeTableSelectedEvent.state === EventState.Official && <Tab value={TimeTableEventTabType.VictoryCeremony} label="Victory ceremony" />}
                {!frontendSettings?.isPublicInstance && timeTableSelectedEvent.disciplineClass === DisciplineClass.LongJump && <Tab value={TimeTableEventTabType.FoulAssist} label={<TakeOffBoardTabHeader />} />}
              </Tabs>
              <div className={classes.tabsPanelWrapper}>
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.StartList}>
                  <StartListTabContent selectedEvent={timeTableSelectedEvent} />
                </TabPanel>
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.Results}>
                  <ResultListTabContent selectedEvent={timeTableSelectedEvent} selectedEventState={selectedEventState} />
                </TabPanel>
                {timeTableSelectedEvent.withTransponders && <TabPanel value={activeTab} tabType={TimeTableEventTabType.TransponderIntermediateTimes}>
                  <TransponderIntermediateTimesTabContent selectedEvent={timeTableSelectedEvent} selectedEventState={selectedEventState} />
                </TabPanel>}
                {timeTableSelectedEvent.eventPhaseNumber &&
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.SummaryResults}>
                  <SummaryResultListTabContent selectedEvent={timeTableSelectedEvent} selectedEventState={selectedEventState} />
                </TabPanel>}
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.Records}>
                  <RecordListTabContent selectedEvent={timeTableSelectedEvent} />
                </TabPanel>
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.TopList}>
                  <TopListTabContent selectedEvent={timeTableSelectedEvent} />
                </TabPanel>
                {timeTableSelectedEvent.isFinalPhase && timeTableSelectedEvent.state === EventState.Official &&
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.VictoryCeremony}>
                  <VictoryCeremonyTabContent selectedEvent={timeTableSelectedEvent} />
                </TabPanel>}
                {!frontendSettings?.isPublicInstance && timeTableSelectedEvent.disciplineClass === DisciplineClass.LongJump &&
                <TabPanel value={activeTab} tabType={TimeTableEventTabType.FoulAssist}>
                  <FoulAssistTabContent selectedEvent={timeTableSelectedEvent} />
                </TabPanel>}
                <PollingErrorHandler errorCountThreshold={3} />
              </div>
            </div>
          </>}
        </CardContent>
      </Card>

      {timeTableData && timeTableStatesData && timeTableStatesData && timeTableStatesData.currentEvents?.length > 0 &&
      <Card className={classes.cardCurrentEvents}>
        <CardContent>
          <CurrentEvents
            currentEvents={timeTableStatesData.currentEvents}
            timeTableById={timeTableData.timeTableById}
            onEventSelected={handleCurrentEventSelected}
            timeTableSelectedEventId={timeTableSelectedEvent?.id}
            timeTableStates={timeTableStatesData.states}
          />
        </CardContent>
      </Card>}

    </div>
  );
}

export default TimeTableSelectedEvent;
