import React from 'react';
//import moment from 'moment';
import styled from 'styled-components';
import { ITimeTableItem } from '@App/types';
import { EventsStatesResponse } from '@App/services/events/eventsApi';
import TimeTableList from '@Features/timeTable/TimeTableList';

import { TIME_TABLE_TOUCH_END_DIFF_THRESHOLD, TIME_TABLE_TOUCH_SELECT_DELAY } from '@App/constants';

const Wrapper = styled.div`
  position: relative;
`;

const ScrollMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

interface IProps {
  legacyTouchEnabled: boolean,
  filteredTimeTable: ITimeTableItem[],
  timeTableById: { [id: number]: ITimeTableItem }
  selectedTimeTableId: number | null,
  hasMultipleDays: boolean,
  eventStatesData: EventsStatesResponse | undefined,
  onTimeTableItemSelected: (newSelectedTimeTableItem: ITimeTableItem) => void,
  serverTime: string | null,
}

class TimeTableLegacyTouchList extends React.Component<IProps> {
  private _deviceHasTouch:boolean = 'ontouchend' in document;

  private _lastTouchEnd:number;
  private _touchEndDiff:number;
  private _fireSelectedEventTimeout:NodeJS.Timeout|null;

  constructor(props: IProps) {
    super(props);

    this._lastTouchEnd = 0;
    this._touchEndDiff = 999999999;
    this._fireSelectedEventTimeout = null;
  }

  private handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!this.props.legacyTouchEnabled) {
      return;
    }
    if (this._fireSelectedEventTimeout) {
      clearTimeout(this._fireSelectedEventTimeout);
    }

    const thisTouchEnd = (new Date()).getTime();
    this._touchEndDiff = thisTouchEnd - this._lastTouchEnd;
    this._lastTouchEnd = thisTouchEnd;
    //console.warn(moment().format('ss.SSS'), this._touchEndDiff);
  }

  private handleTimeTableItemSelected = (newSelectedTimeTableItem: ITimeTableItem) => {
    if (this._fireSelectedEventTimeout) {
      clearTimeout(this._fireSelectedEventTimeout);
    }

    if (this._deviceHasTouch || !this.props.legacyTouchEnabled) {
      this.props.onTimeTableItemSelected(newSelectedTimeTableItem);
    } else {
      if (this._touchEndDiff > TIME_TABLE_TOUCH_END_DIFF_THRESHOLD) {
        this._fireSelectedEventTimeout = setTimeout(() => {
          //console.warn(moment().format('ss.SSS'), 'SEL!!!!');
          this.props.onTimeTableItemSelected(newSelectedTimeTableItem);
        }, TIME_TABLE_TOUCH_SELECT_DELAY);
      }
    }
  }

  render() {
    const {
      filteredTimeTable,
      selectedTimeTableId,
      timeTableById,
      hasMultipleDays,
      eventStatesData,
      serverTime,
    } = this.props;

    return (
      <Wrapper onTouchEnd={this.handleTouchEnd}>
        <ScrollMask />
        <TimeTableList
          filteredTimeTable={filteredTimeTable}
          selectedTimeTableId={selectedTimeTableId}
          timeTableById={timeTableById}
          onTimeTableItemSelected={this.handleTimeTableItemSelected}
          hasMultipleDays={hasMultipleDays}
          eventStatesData={eventStatesData}
          serverTime={serverTime}
        />
      </Wrapper>
    );
  }
}

export default TimeTableLegacyTouchList;
