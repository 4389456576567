import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  hasAnyQualify?: boolean | null;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    startListTable: {
      borderCollapse: 'collapse',
      width: '100%',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '& .bib': {
          float: 'right',
          opacity: '0.8',
        },
        '& .athlete-count': {
          paddingLeft: '8px',
          color: '#fff',
          fontWeight: 'normal',
          opacity: '0.8',
          paddingRight: '8px',
        },
        '& .sort-indicator': {
          display: 'inline-block',
          marginLeft: '3px',
          color: '#DADADA',
        },
        '&.current-attempt span': {
          backgroundColor: '#fff',
          color: '#000',
          padding: '1px 10px',
          borderRadius: '3px',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '12px'
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-reaction, .col-record, .col-attempt-final': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
        '&.current-attempt-row': {
          '& td.col-athlete .athlete-container::after': {
            background: `linear-gradient(90deg, transparent 0%, #515D68 100%)`,
          },
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-pb, .col-sb, .col-result, .col-points, .col-gap, .col-reaction, .col-record, .col-attempt-final,': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, #313F4C 100%)`,
        },
        '&.current-attempt-row': {
          '& td.col-athlete .athlete-container::after': {
            background: `linear-gradient(90deg, transparent 0%, #515D68 100%)`,
          },
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-lane': {
        fontSize: '18px',
        position: 'relative',

        '&.place-first span, &.place-second span, &.place-third span': {
          color: '#000',
          borderRadius: '4px',
          padding: '3px 10px',
        },
        '&.place-first span': {
          backgroundColor: '#FFC540',
        },
        '&.place-second span': {
          backgroundColor: '#D3D3D3',
        },
        '&.place-third span': {
          backgroundColor: '#DC8B53',
        },
        '&.unof-place-first span, &.unof-place-second span, &.unof-place-third span': {
          textDecoration: 'underline',
          textDecorationThickness: '3px',
          textUnderlineOffset: '2px',

          '&.final-round-indicator': {
            textDecoration: 'none',
          }
        },
        '&.unof-place-first span': {
          textDecorationColor: '#FFC540',
        },
        '&.unof-place-second span': {
          textDecorationColor: '#D3D3D3',
        },
        '&.unof-place-third span': {
          textDecorationColor: '#DC8B53',
        },
        '& .final-round-indicator': {
          position: 'absolute',
          left: '0',
          bottom: '-9px',
          color: '#000',
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          textTransform: 'uppercase',
          fontSize: '11px',
          fontWeight: 'normal',
          padding: '1px 6px 0 6px',
          letterSpacing: '1px',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }
      },
      '& td.col-lane-relays': {
        verticalAlign: 'top',
        paddingTop: '12px',
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '41px',
          height: '27px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '41px',
          height: '27px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '41px 27px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '350px',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '&.has-bibs': {
            width: '320px',
          }
        },
        '& .athlete-name': {
          fontSize: '16px',

          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
          '& .relay-label': {
            paddingLeft: '7px',
            opacity: '1',
          },
          '& .relay-members': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            listStyleType: 'none',
            fontWeight: 'normal',
            fontSize: '14px',
            margin: '0',
            padding: '0',

            '& li': {
              padding: '2px 5px 2px 0',
              fontSize: '12px',
              width: '220px',
            },
            '& .member-yob': {
              opacity: '0.5',
              paddingLeft: '4px',
            },
            '& .member-last-name': {
              fontWeight: 'bold',
              fontSize: '13px',
              paddingLeft: '3px',
            },
            '& .member-first-name': {
              opacity: '0.8',
            },
            '& .member-order': {
              background: theme.palette.secondary.dark,
              borderRadius: '4px',
              padding: '2px 4px',
              fontWeight: 'bold',
            }
          }
        },
        '& .club-name': {
          fontWeight: 'normal',
          color: theme.palette.textTabs.club,
          fontSize: '13px',
        },
        '& .startlist-note': {
          fontSize: '12px',
          display: 'inline-block',
          marginLeft: '5px',
          fontWeight: 'normal',
          background: theme.palette.primary.main,
          borderRadius: '4px',
          padding: '1px 6px 0',
          opacity: '0.9',
        },
        '& .bib': {
          fontSize: '15px',
          color: theme.palette.textTabs.bib,
          position: 'absolute',
          right: '1px',
          top: '4px',
        }
      },
      '& td.col-yob': {
        fontWeight: 'normal',
        '& span': {
          display: 'block',
          fontSize: '11px',
          opacity: '0.5'
        }
      },
      '& td.col-result': {
        position: 'relative',
        paddingLeft: '15px',
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        paddingRight: ({ hasAnyQualify }) => hasAnyQualify ? '22px' : '8px',

        '& .result-record-container': {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        '& .result-disqualify-rule': {
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '24px',
          paddingRight: '6px',
          opacity: '0.7',
        },
        '& .result': {
          display: 'flex',
          flexDirection: 'column',
          fontSize: '17px',
          marginTop: '-2px',
          color: theme.palette.textTabs.result,

          '& .result-wind': {
            fontSize: '13px',
            fontWeight: 'normal',
            opacity: '0.9',
          },
          '&.has-records:not(&.has-wind)': {
            paddingTop: '9px',
          }
        },
        '& .result-qualify': {
          fontSize: '14px',
          color: theme.palette.primary.light,
          fontWeight: 'normal',
          paddingLeft: '0',
          position: 'absolute',
          top: '13px',
          right: '6px',

          '&.conflicting': {
            fontSize: '12px',
            top: '15px',
            right: '4px',
          }
        }
      },
      '& td.col-gap': {
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
        opacity: '0.7',
      },
      '& td.col-points': {
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        lineHeight: '13px',
      },
      '& td.col-result-lane': {
        fontWeight: 'normal',
        fontSize: '13px',
        opacity: '0.7',
      },
      '& td.col-result-pb-sb': {
        borderLeft: 'none',
        opacity: '0.7',
        fontWeight: 'normal',
        fontSize: '13px',

        '& .result-sb': {
          fontSize: '11px',
        }
      },
      '& td.col-pb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-sb': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
      },
      '& td.col-warank': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',
      },
      '& td.col-qualify': {
        borderLeft: `1px solid ${theme.palette.table.borderColor}`,
        fontWeight: 'normal',

        '& .qualify-type': {
          color: theme.palette.primary.light,
          fontWeight: 'bold',
          display: 'inline-block',
          width: '23px',
          marginRight: '3px',
          fontSize: '13px',
        }
      },
      '& td.col-attempt': {
        fontWeight: 'normal',
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        position: 'relative',

        '&.best-attempt': {
          color: theme.palette.textTabs.result,
          textShadow: '0px 0px 2px rgba(0,0,0,0.73)',
          fontWeight: 'bold',
        },
        '&.first-attempt, &.fourth-attempt': {
          borderLeft: `3px solid ${theme.palette.table.borderColor}`,
        },
        '&.sixth-attempt, &.third-attempt': {
          borderRight: `3px solid ${theme.palette.table.borderColor}`,
        },
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minWidth: '40px',
        },
        '& .atttempt-wind': {
          opacity: '0.8',
          fontSize: '13px',
          position: 'relative',

          '&.non-regular::after': {
            content: '""',
            position: 'absolute',
            top: '6px',
            right: '-1px',
            width: '5px',
            height: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(255, 0, 0, 0.85)'
          }
        },
        '& .attempt-cursor': {
          width: '24px',
          height: '3px',
          background: 'rgba(0, 0, 0, 0.88)',
          animation: 'blinker 1.2s infinite',
          borderRadius: '4px',
          position: 'absolute',
          bottom: '0',
          left: 'calc(50% - 12px)',

          '&.has-winds': {
            top: '18px',
          }
        },
        '& .result-spacer': {
          display: 'inline-block',
          height: '20px',
        }
      },
      '& td.col-attempt-final': {
        fontWeight: 'normal',
        borderRight: `1px solid ${theme.palette.table.borderColor}`,
        fontSize: '12px',
        opacity: '0.7',
      },
      '& tr.current-attempt-row': {
        background: 'rgba(255, 255, 255, 0.18) !important',

        '& td': {
          borderLeftColor: 'rgba(255, 255, 255, 0.28) !important',
          borderRightColor: 'rgba(255, 255, 255, 0.28) !important',

          '& .result-wind, .club-name': {
            opacity: '0.8 !important',
          }
        },
        '& td.current-attempt-col': {
          background: '#fff !important',
          color: '#000',
          fontWeight: 'bold',
          position: 'relative',
        },
        '& .best-attempt.current-attempt-col': {
          textShadow: 'none',
        },
      },
      '& tr.final-round-separator td': {
        borderBottom: `3px solid rgba(255, 255, 255, 0.58)`,
      },
      '& td.current-attempt-col': {
        background: 'rgba(255, 255, 255, 0.18)',
      },
      '& .col-spacer': {
        padding: '0px',
      },
      '& .col-no-athletes': {
        fontSize: '18px',
        textAlign: 'center',
        padding: '30px 10px',

        '& div': {
          /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
          animationName: 'fadeIn',
          animationDuration: '1s',
          transitionTimingFunction: 'linear',
        }
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },

      '&:not(.has-startlist-data)': {
        '& th .bib': {
          marginRight: '8px',
        },
        '& td.col-athlete': {
          paddingRight: '30px',

          '& .bib': {
            right: '8px',
          },
          '& .athlete-container': {
            width: '230px',

            '&.has-bibs': {
              width: '190px',
            },
          }
        },
      }
    },
  }),
);