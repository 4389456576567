import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@App/store';

import { eventsApi, EventsResponseById } from '@App/services/events/eventsApi';

export interface IInitErrorHandlerState {
  eventsLoaded: boolean;
  eventsError: any;
}

let initialState:IInitErrorHandlerState = {
  eventsLoaded: false,
  eventsError: null,
};

export const initErrorHandlerSlice = createSlice({
  name: 'initError',
  initialState,
  reducers: {
    eventsHasLoaded: (state) => {
      state.eventsLoaded = true;
    },
    eventsLoadFailure: (state, action: PayloadAction<any>) => {
      state.eventsError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(eventsApi.endpoints.getEvents.matchFulfilled, (state, action: PayloadAction<EventsResponseById>) => {
        state.eventsLoaded = true;
        state.eventsError = null;
      })
      .addMatcher(eventsApi.endpoints.getEvents.matchRejected, (state, action) => {
        state.eventsError = action.payload;
      });
  },
});

export const {
  eventsHasLoaded,
  eventsLoadFailure,
} = initErrorHandlerSlice.actions;

export const selectEventsLoaded = (state: RootState) => state.initError.eventsLoaded;
export const selectEventsLoadError = (state: RootState) => state.initError.eventsError;

export default initErrorHandlerSlice.reducer;