import { IMedalStandingsItem } from '@App/types';
import HttpRequestError from '@Utils/HttpRequestError';

export type MedalStandingsApiResponse = IMedalStandingsItem[];

export function fetchMedalStandingsData(abortSignal: AbortSignal) {
  return fetch(`${process.env.PUBLIC_URL}/api/v1/medal-standings`, {
    signal: abortSignal,
  }).then(async (response): Promise<MedalStandingsApiResponse> => {
    if (!response.ok) {
      const textResponse = await response.text();
      throw new HttpRequestError(response.status, 'Failed to get medal standings data', textResponse);
    }

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
  });
}

const defaultExport = {
  fetchMedalStandingsData
};

export default defaultExport;
