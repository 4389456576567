import { red } from '@material-ui/core/colors';
import { createTheme, Theme } from '@material-ui/core/styles';
import { IFrontendTheme } from './types';

const DEFAULT_THEME = {
  palette: {
    primary: {
      light: '#ff8040',
      main: '#fa4517',
      dark: '#d12630',
    },
    secondary: {
      light: '#2D3D4D',
      main: '#293847',
      dark: '#212932',
    },
    records: {
      lighter: '#243039',
      darker: '#212C34',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#273947',
      paper: 'rgba(41, 56, 71, 0.9)',
    },
    tabs: {
      tabStripBackground: 'rgba(41, 56, 71, 0.7)',
      selectedTabBackground: 'rgba(41, 56, 71, 0.95)',
    },
    table: {
      borderColor: '#4a4d50',
      borderColorLighter: '#63666B',
      headerBackground: 'rgba(41, 56, 71, 0.90)',
      rowBackgroundFade: '#2B3947',
      altRowBackground: 'rgba(52,63,74,0.9)',
      altRowBackgroundFade: '#323E49',
      altColBackground: 'rgba(255,255,255,0.05)',
      altOddColBackground: 'rgba(255,255,255,0.03)',
    },
    text: {
      primary: '#fff',
    },
    textTabs: {
      bib: 'rgba(255,255,0,0.8)',
      club: 'rgba(255,255,0,0.6)',
      result: 'rgba(255,255,255,1)',
    },
    appBarGradient: {
      first: '#293847',
      second: '#fa4517',
      third: '#d12630',
    },
    gradient: {
      first: '#ff8040',
      second: '#fa4517',
      third: '#d12630',
    },
  },
  typography: {
    fontFamily: [
      'WorldAthletics',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
};

// A custom theme for this app
const theme = createTheme(DEFAULT_THEME);

declare module "@material-ui/core/styles/createPalette" {
  interface ThemedBackground extends TypeBackground {
    altRow: React.CSSProperties['color'],
  }

  interface Palette {
    appBarGradient: {
      first: React.CSSProperties['color'],
      second: React.CSSProperties['color'],
      third: React.CSSProperties['color'],
    },
    records: {
      lighter: React.CSSProperties['color'],
      darker: React.CSSProperties['color'],
    },
    gradient: {
      first: React.CSSProperties['color'],
      second: React.CSSProperties['color'],
      third: React.CSSProperties['color'],
    },
    tabs: {
      tabStripBackground: React.CSSProperties['color'],
      selectedTabBackground: React.CSSProperties['color'],
    },
    table: {
      rowBackgroundFade: React.CSSProperties['color'],
      altRowBackground: React.CSSProperties['color'],
      altRowBackgroundFade: React.CSSProperties['color'],
      altColBackground: React.CSSProperties['color'],
      altOddColBackground: React.CSSProperties['color'],
      headerBackground: React.CSSProperties['color'],
      borderColor: React.CSSProperties['color'],
      borderColorLighter: React.CSSProperties['color'],
    },
    textTabs: {
      bib: React.CSSProperties['color'],
      club: React.CSSProperties['color'],
      result: React.CSSProperties['color']
    }
  }
  interface PaletteOptions {
    appBarGradient: {
      first: React.CSSProperties['color'],
      second: React.CSSProperties['color'],
      third: React.CSSProperties['color'],
    },
    records: {
      lighter: React.CSSProperties['color'],
      darker: React.CSSProperties['color'],
    },
    gradient: {
      first: React.CSSProperties['color'],
      second: React.CSSProperties['color'],
      third: React.CSSProperties['color'],
    },
    tabs: {
      tabStripBackground: React.CSSProperties['color'],
      selectedTabBackground: React.CSSProperties['color'],
    },
    table: {
      rowBackgroundFade: React.CSSProperties['color'],
      altRowBackground: React.CSSProperties['color'],
      altRowBackgroundFade: React.CSSProperties['color'],
      altColBackground: React.CSSProperties['color'],
      altOddColBackground: React.CSSProperties['color'],
      headerBackground: React.CSSProperties['color'],
      borderColor: React.CSSProperties['color'],
      borderColorLighter: React.CSSProperties['color'],
    },
  }
}

export function createCustomTheme(themePalette: IFrontendTheme): Theme {
  const cutomTheme = Object.assign({}, DEFAULT_THEME, {
    palette: {
      ...DEFAULT_THEME.palette,
      primary: {
        light: themePalette.colorPrimaryLight,
        main: themePalette.colorPrimaryMain,
        dark: themePalette.colorPrimaryDark,
      },
      secondary: {
        light: themePalette.colorSecondaryLight,
        main: themePalette.colorSecondaryMain,
        dark: themePalette.colorSecondaryDark,
      },
      appBarGradient: {
        first: themePalette.appBarGradientFirst,
        second: themePalette.appBarGradientSecond,
        third: themePalette.appBarGradientThird,
      },
      gradient: {
        first: themePalette.gradientFirst,
        second: themePalette.gradientSecond,
        third: themePalette.gradientThird,
      },
      textTabs: {
        bib: themePalette.colorBib,
        club: themePalette.colorClub,
        result: themePalette.colorResult,
      },
    }
  });

  return createTheme(cutomTheme);
}

export default theme;