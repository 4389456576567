import { DisciplineType } from "@App/disciplines";
import {StartCardType} from "@App/types";
import { sumBy } from 'lodash';

export function getCompetitorCount(competitorList: { status: string }[]): number | null {
  if (!competitorList) {
    return null;
  }

  return sumBy(competitorList, c => c.status ? 0 : 1);
};

export function getFlagOrClubImage(country: string, club: string): string {
  if ((country === 'CZE' && club !== 'CZE') || (country === 'SVK' && club !== 'SVK') || !country /* body družstva */) {
    return `${process.env.PUBLIC_URL}/flags/clubs/${club}.png`;
  }
  return `${process.env.PUBLIC_URL}/flags/countries/${country}.png`;
}

export function getCountryFlagImage(country: string): string {
  return `${process.env.PUBLIC_URL}/flags/countries/${country}.png`;
}

export function getStartCardImage(startCard: string) {
  switch (startCard) {
    case StartCardType.None:
    case StartCardType.Yellow:
    case StartCardType.Red:
    case StartCardType.YellowBlack:
    case StartCardType.RedBlack:
    case StartCardType.WhiteRed:
    case StartCardType.Green:
      return `${process.env.PUBLIC_URL}/startCards/${startCard}.png`;
    default:
      return `${process.env.PUBLIC_URL}/startCards/Default.png`;
  }
}

export function getUnofficialPlaceCssClass(place: string): string | undefined {
  switch (place) {
    case '1':
    case '1=':
      return 'unof-place-first';
    case '2':
    case '2=':
      return 'unof-place-second';
    case '3':
    case '3=':
      return 'unof-place-third';
    default:
      return undefined;
  }
}

export function getPlaceCssClass(place: string): string | undefined {
  switch (place) {
    case '1':
    case '1=':
      return 'place-first';
    case '2':
    case '2=':
      return 'place-second';
    case '3':
    case '3=':
      return 'place-third';
    default:
      return undefined;
  }
}

export function getDisciplineIcon(disciplineType: DisciplineType) {
  switch (disciplineType) {
    case DisciplineType.CricketBall:
      return 'CricketBall';
    case DisciplineType.Discus:
      return 'Discus';
    case DisciplineType.Hammer:
      return 'Hammer';
    case DisciplineType.HighJump:
      return 'HighJump';
    case DisciplineType.Hurdles:
      return 'Hurdles';
    case DisciplineType.Javelin:
      return 'Javelin';
    case DisciplineType.LongJump:
      return 'LongJump';
    case DisciplineType.PoleValut:
      return 'PoleValut';
    case DisciplineType.Relays:
      return 'Relays';
    case DisciplineType.Run800M:
      return 'Run800M';
    case DisciplineType.LongRun:
      return 'LongRun';
    case DisciplineType.ShotPut:
      return 'ShotPut';
    case DisciplineType.Sprint:
      return 'Sprint';
    case DisciplineType.Sprint400M:
      return 'Sprint400M';
    case DisciplineType.Steelplechase:
      return 'Steelplechase';
    case DisciplineType.TripleJump:
      return 'TripleJump';
    case DisciplineType.Walk:
      return 'Walk';
    default:
      return null;
  }
}

export function getResultThousandsPart(thousandsResult: string) {
  const dotIndex = thousandsResult.indexOf('.');

  if (dotIndex !== -1) {
    return thousandsResult.slice(dotIndex);
  }
  return null;
}

const HAMMER_THROW = 'Hammer Throw';
const DISCUS_THROW = 'Discus Throw';
const JAVELIN_THROW = 'Javelin Throw';
export function shortenEventPhase(eventName: string, eventPhase: string) { // Tohle je hlavne pro "Hammer Throw Qualifications B" což je dlouhé
  if (!eventPhase) {
    return null;
  }

  if (eventName === HAMMER_THROW) {
    if (eventPhase.length < 7) {
      return eventPhase;
    }
    return eventPhase.substring(0, 7);
  }

  if (eventName === DISCUS_THROW || eventName === JAVELIN_THROW) {
    if (eventPhase.length < 8) {
      return eventPhase;
    }
    return eventPhase.substring(0, 8);
  }

  if (eventPhase.length < 10) {
    return eventPhase;
  }
  return eventPhase.substring(0, 10);
}