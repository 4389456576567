import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    progress: {
      position: 'absolute',
      right: '50%',
      bottom: '50%',

      '&.data-loaded': {
        position: 'absolute',
        right: '7px',
        bottom: '7px',
      }
    },
    spacerGrid: {
      height: '100%',
      padding: theme.spacing(1),
    },
    card: {
      height: 'calc(100% - 3px)',
      borderRadius: '0 0 4px 4px',
      marginTop: '0',
      background: 'transparent',
    },
    cardContent: {
      height: '100%',
      position: 'relative',
      padding: '0 !important',
      background: theme.palette.background.paper,
    },
    topBorder: {
      background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 50%, ${theme.palette.gradient.third} 100%)`,
      height: '2px',
    },
    scrollContainer: {
      height: '100%',
      overflowY: 'auto'
    },
    simpleBar: {
      height: '100%',
    },
    finalLapCounterTable: {
      borderCollapse: 'collapse',
      width: '100%',
      fontWeight: 'bold',

      '& td, th': {
        whiteSpace: 'nowrap',
      },
      '& thead th': {
        border: `0px solid ${theme.palette.table.borderColor}`,
        background: theme.palette.records.lighter,
        borderTop: '0',
        borderLeft: '0',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        padding: '6px 8px 5px 6px',
        position: 'sticky',
        zIndex: '2',
        top: '0',

        '& .bib': {
          float: 'right',
          opacity: '0.8',
        },
        '& .athlete-count': {
          paddingLeft: '8px',
          color: '#fff',
          fontWeight: 'normal',
          opacity: '0.8',
          paddingRight: '8px',
        }
      },
      '& thead th:last-child': {
        borderRight: '0',
      },
      '& tbody tr:nth-child(even)': {
        background: theme.palette.table.altRowBackground,

        '& .col-pb, .col-sb': {
          background: theme.palette.table.altColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.altRowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr:nth-child(odd)': {
        '& .col-pb, .col-sb': {
          background: theme.palette.table.altOddColBackground,
        },
        '& td.col-athlete .athlete-container::after': {
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.rowBackgroundFade} 100%)`,
        },
      },
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.table.borderColor}`,
        borderTop: '0',
        borderLeft: '0',
        padding: '3px 8px',
      },
      '& tbody tr td:last-child': {
        borderRight: '0',
      },
      '& td.col-lane': {
        fontSize: '25px',
      },
      '& td.col-lane-relays': {
        verticalAlign: 'top',
        paddingTop: '12px',
      },
      '& td.col-athlete': {
        position: 'relative',

        '& .athlete-flag-container': {
          display: 'flex',
        },
        '& .flag-container': {
          position: 'relative',
          width: '56px',
          height: '37px',
          marginRight: '15px',
          marginTop: '5px',
          border: `1px solid ${theme.palette.table.borderColor}`,
        },
        '& .flag': {
          width: '56px',
          height: '37px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '56px 37px',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .athlete-container': {
          position: 'relative',
          overflow: 'hidden',

          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '20px',
            right: 0,
            top: 0,
          },
          '&.has-bibs': {
            width: '320px',
          }
        },
        '& .athlete-name': {
          fontSize: '25px',

          '& .first-name': {
            fontWeight: 'normal',
            opacity: '0.8',
          },
          '& .relay-name': {
            fontSize: '19px',
            fontWeight: 'bold',
            opacity: '1',
          },
          '& .relay-label': {
            paddingLeft: '7px',
            opacity: '1',
          },
          '& .relay-members': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            listStyleType: 'none',
            fontWeight: 'normal',
            fontSize: '14px',
            margin: '0',
            padding: '0',

            '& li': {
              padding: '2px 5px 2px 0',
              fontSize: '12px',
              width: '220px',
            },
            '& .member-yob': {
              opacity: '0.5',
              paddingLeft: '4px',
            },
            '& .member-last-name': {
              fontWeight: 'bold',
              fontSize: '13px',
              paddingLeft: '3px',
            },
            '& .member-first-name': {
              opacity: '0.8',
            },
            '& .member-order': {
              background: '#212932',
              borderRadius: '4px',
              padding: '2px 4px',
              fontWeight: 'bold',
            }
          }
        },
        '& .club-name': {
          color: theme.palette.textTabs.club,
          fontWeight: 'normal',
          fontSize: '16px',
          opacity: 0.6
        },
        '& .bib': {
          color: theme.palette.textTabs.bib,
          fontSize: '25px',
          paddingRight: '5px',
          position: 'absolute',
          right: '0',
          top: '16px',
        }
      },
      '& .col-final-indicator': {
        textTransform: 'uppercase',
        fontSize: '20px',
        textAlign: 'center',

        '& .wrapper': {
          color: '#000',
          background: '#fff',
          lineHeight: '20px',
          padding: '5px 20px',
          borderRadius: '4px',
          fontWeight: 'bold',
        }
      },
      '& .col-no-athletes': {
        fontSize: '60px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '50px 10px',
        fontWeight: 'bold',
        whiteSpace: 'normal',

        '& div': {
          /* Docházelo k problikávání "No athletes in startlist", takto obejito fade efektem */
          animationName: 'fadeIn',
          animationDuration: '1.5s',
          transitionTimingFunction: 'linear',
        }
      },
      '& .text-right': {
        textAlign: 'right',
      },
      '& .text-left': {
        textAlign: 'left',
      },
      '& .text-center': {
        textAlign: 'center',
      },
    }
  }),
);
